import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DummyToRentCategory } from "../../Dummydata";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import axios from "../../axios";
import imageCompression from "browser-image-compression";

const EditEvent = () => {
  const { user } = useSelector((state) => state.auth);
  // fetch the event
  const { id } = useParams();
  const [myEvent, setMyEvent] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchTheEvent = async () => {
    try {
      setLoading(true);
      let checkParam = id;
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get("/event/" + checkParam, config);
      if (response) {
        setLoading(false);
        setMyEvent([response.data]);
        // console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Event.");
      toast.error("Network error or doesn't exist");
    }
  };

  useEffect(() => {
    fetchTheEvent();
  }, []);

  // upload photot to cloudinary
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const postPhoto = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingPhoto(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingPhoto(false);
      setPhoto(urlData.url);
      toast.success("Uploaded Photo");
    } catch (error) {
      setLoadingPhoto(false);
      toast.error("Error uploading Photo");
    }
  };

  //update data
  const [showForm, setShowForm] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photo, setPhoto] = useState("");
  const [specificAddress, setSpecificAddress] = useState("");
  const [timeAndDate, setTimeAndDate] = useState("");
  const [searchTerms, setSearchTerms] = useState("");
  const [prices, setPrices] = useState("");
  const [visibleToAllCampuses, setVisibleToAllCampuses] = useState("");

  const [updatetitle, setupdateTitle] = useState("");
  const [updatedescription, setupdateDescription] = useState("");
  const [updatephoto, setupdatePhoto] = useState("");
  const [updateSpecificAddress, setupdateSpecificAddress] = useState("");
  const [updateTimeAndDate, setupdateTimeAndDate] = useState("");
  const [updatesearchTerms, setupdateSearchTerms] = useState("");
  const [updatePrices, setupdatePrices] = useState("");
  const [updateVisibleToAllCampuses, setUpdateVisibleToAllCampuses] =
    useState("");

  useEffect(() => {
    setTitle(updatetitle);
    setDescription(updatedescription);
    setPhoto(updatephoto);
    setSpecificAddress(updateSpecificAddress);
    setTimeAndDate(updateTimeAndDate);
    setSearchTerms(updatesearchTerms);
    setPrices(updatePrices);
    setVisibleToAllCampuses(updateVisibleToAllCampuses);
  }, [updatetitle, updatephoto, updateSpecificAddress]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!title) return toast.error("title missing");
    if (!prices) return toast.error("Prices Info missing");
    if (!description) return toast.error("description missing");
    if (!photo) return toast.error("photo missing");
    if (!specificAddress) return toast.error("specific Address missing");
    if (!timeAndDate) return toast.error("time and date missing");
    if (!searchTerms) return toast.error("searchTerms missing");
    if (!visibleToAllCampuses) return toast.error("visibility missing");

    try {
      let location = user?.location;
      let phone = user?.phone;
      let owner = user?.username;
      let dataToSend = {
        title,
        location,
        photo,
        phone,
        owner,
        description,
        prices,
        timeAndDate,
        specificAddress,
        searchTerms,
        visibleToAllCampuses,
      };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put("/event/" + id, dataToSend, config);
      if (response) {
        setLoading(false);
        toast.success("Updated " + title);
        navigate("/my-profile");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error updating Event");
    }
  };

  return (
    <div>
      {/* wrapper */}
      <div className="px-[10px] pt-[10px]">
        {/* topbar */}
        <div>
          <div className="flex gap-4 items-center">
            <Link to="/my-profile">
              <AiOutlineArrowLeft className="text-lg" />
            </Link>
            <div>
              <h2 className="font-bold text-md text-zinc-400">
                Update Event Details
              </h2>
              <Link to="/change-location">
                <p className="text-sm underline text-emerald-600">
                  Your Location : {user?.location}
                </p>
              </Link>
            </div>
          </div>
        </div>

        {myEvent?.map((item) => (
          <div key={item._id}>
            {showForm ? (
              <>
                {/* form */}
                <div className="mt-[2em] pb-[1em] w-full">
                  <form
                    className=" w-[98%] sm:w-[80%] lg:w-[50%] m-auto"
                    onSubmit={handleUpdate}
                  >
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="title"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        Update Name of The Event
                      </label>
                      <input
                        type="text"
                        placeholder="title here"
                        id="title"
                        value={title}
                        required
                        minLength={2}
                        maxLength={60}
                        onChange={(e) => setTitle(e.target.value)}
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      />
                    </div>
                    {/* description */}
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="desc"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        Update Event Description
                      </label>
                      <textarea
                        name="desc"
                        id="desc"
                        cols="30"
                        rows="3"
                        minLength={5}
                        maxLength={500}
                        required
                        placeholder="event description (500)"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      ></textarea>
                    </div>

                    {/* charging rates */}
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="chargeRates"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        Update Your Charging Rates
                      </label>

                      <textarea
                        name="chargeRates"
                        id="chargeRates"
                        cols="30"
                        rows="2"
                        minLength={5}
                        maxLength={500}
                        required
                        placeholder="Discounts, offers, holiday cuts e.t.c"
                        value={prices}
                        onChange={(e) => setPrices(e.target.value)}
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      ></textarea>
                    </div>

                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="timeAndDate"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        Update Time And Date Of Event
                      </label>
                      <input
                        type="text"
                        placeholder="i.e Friday 17th 6:00PM till dawn"
                        id="timeAndDate"
                        value={timeAndDate}
                        required
                        minLength={2}
                        maxLength={100}
                        onChange={(e) => setTimeAndDate(e.target.value)}
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      />
                    </div>

                    {/* upload image */}
                    <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                      <div className="flex flex-col gap-2 mt-[20px]">
                        <label
                          htmlFor="mainPhoto"
                          className="flex items-center gap-[20px] flex-wrap"
                        >
                          <p>Update Event Photo</p>
                          <div className="flex flex-col items-center">
                            {loadingPhoto ? (
                              <Spinner message="uploading ..." />
                            ) : (
                              <img
                                src={
                                  photo
                                    ? photo
                                    : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                }
                                alt=""
                                className="w-[100px] h-[100px] object-cover"
                              />
                            )}
                          </div>
                        </label>
                        <input
                          type="file"
                          placeholder="Add Image"
                          accept="image/*"
                          onChange={(e) => postPhoto(e.target.files[0])}
                          required
                          id="mainPhoto"
                          className="hidden"
                        />
                      </div>
                    </div>

                    {/* specificAddress */}
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="specificAdd"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        <p>Update Event Address</p>
                        <p className="text-sm text-emerald-600">
                          Please Enter A Popular Location
                        </p>
                      </label>
                      <input
                        type="text"
                        placeholder="popular local name"
                        id="specificAdd"
                        value={specificAddress}
                        required
                        minLength={2}
                        maxLength={50}
                        onChange={(e) => setSpecificAddress(e.target.value)}
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      />
                    </div>

                    {/* event visibility */}
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="eventVisibility"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        <p>Visible to other campuses ?</p>
                        <p className="text-sm text-emerald-600">
                          {user?.location} and other campuses ?
                        </p>
                      </label>
                      <select
                        name="eventVisibility"
                        id="eventVisibility"
                        className="p-[8px]  border bg-[#212121] border-zinc-400 rounded-lg"
                        value={visibleToAllCampuses}
                        onChange={(e) =>
                          setVisibleToAllCampuses(e.target.value)
                        }
                      >
                        <option value="">Choose</option>
                        <option value="nope">No</option>
                        <option value="yes">Yes</option>
                      </select>
                    </div>

                    {/* searchTerms */}
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="specificAdd"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        <p>Update Search terms associated.</p>
                        <p className="text-sm text-emerald-600">
                          separate by comma (,)
                        </p>
                      </label>
                      <input
                        type="text"
                        placeholder="searchTerms separated by comma"
                        id="specificAdd"
                        value={searchTerms}
                        required
                        minLength={2}
                        maxLength={70}
                        onChange={(e) => setSearchTerms(e.target.value)}
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      />
                    </div>
                    <div className="mb-[14px]">
                      <h2 className="text-center font-bold mb-[10px] text-zinc-500">
                        IMPORTANT
                      </h2>
                      <p className="text-center">
                        {user.phone} is the phone number to be used.
                      </p>
                      <p className="text-center">
                        {user.location} will be used as your location
                      </p>
                      <Link to="/my-profile" className="text-center">
                        <div className="text-emerald-600 underline">
                          You Can Change Here
                        </div>
                      </Link>
                    </div>
                    {loading || loadingPhoto ? (
                      <div className="w-full">
                        <p className="text-center">Please wait 😊 ... </p>
                      </div>
                    ) : (
                      <button
                        className="bg-emerald-800 text-white w-full p-[8px] rounded-md"
                        onClick={handleUpdate}
                      >
                        Update Event Details
                      </button>
                    )}
                  </form>
                </div>
              </>
            ) : (
              <>
                <h2
                  className="font-bold flex w-full h-[70vh] justify-center items-center text-emerald-500 underline cursor-pointer text-center"
                  onClick={() => {
                    setShowForm(true);

                    setupdateTitle(item.title);
                    setupdateDescription(item.description);
                    setupdatePhoto(item.photo);
                    setupdateSpecificAddress(item.specificAddress);
                    setupdateTimeAndDate(item.timeAndDate);
                    setupdateSearchTerms(item.searchTerms);
                    setupdatePrices(item.prices);
                    setUpdateVisibleToAllCampuses(item.visibleToAllCampuses);
                  }}
                >
                  Click Here To Update {item.title}
                </h2>
              </>
            )}
          </div>
        ))}

        {/*  */}
      </div>
    </div>
  );
};

export default EditEvent;
