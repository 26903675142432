import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../axios";
import Hello from "../assets/hello.png";
import Spinner from "../components/Spinner";

const Report = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      toast.warning("You need to sign in");
      navigate("/login");
      return;
    }
  }, [user, navigate]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const owner = searchParams.get("owner");
  const ownerPhone = searchParams.get("phone");

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const createNewReport = async (e) => {
    e.preventDefault();
    // your name, phone, location
    let reporter = user?.username;
    let reporterPhone = user?.phone;
    let reporterLocation = user?.location;

    if (!message)
      return toast.error("report cannot be empty", { theme: "dark" });

    // Check if the input contains any numbers
    if (/\d/.test(message)) {
      // If it contains numbers, replace them
      toast.error("report cannot have numbers");
    }

    if (!ownerPhone || !ownerPhone) return;

    let dataToSend = {
      reporter,
      reporterPhone,
      reporterLocation,
      owner,
      ownerPhone,
      message,
    };

    try {
      setLoading(true);
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let response = await axios.post("/reports", dataToSend, config);
      if (response) {
        setLoading(false);
        setSuccess(true);
        setMessage("");
        toast.success(`Reported ${owner}`);
      }
    } catch (error) {
      setLoading(false);
      setSuccess(false);

      toast.error("Action Failed. Check Network");
    }
  };

  return (
    <div>
      {/* wrapper */}
      <div className=" px-[10px] sm:px-[2em] md:px-[5em] pt-[1em]">
        {/* topbar */}
        <div className="mb-[2em]">
          <Link to="/business">
            <IoArrowBack className="text-2xl" />
          </Link>
        </div>

        {success ? (
          <>
            <div className="mt-[20vh] flex justify-center">
              <div>
                <img src={Hello} alt="Hello" />
                <h2 className="my-2 text-center">
                  We have received your report against{" "}
                  <span className="text-emerald-700">{owner}</span>
                </h2>
                <p className="text-center">
                  We will reach out to you soon. Asante
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mt-[20vh] flex justify-center">
              <div className=" w-full sm:w-[75%] xl:w-[50%] m-auto">
                <h2 className="font-bold text-2xl text-zinc-500">
                  Report {owner}⚡
                </h2>
                {/* <h2>Report {ownerPhone}</h2> */}
                <form className="mt-[3em]" onSubmit={createNewReport}>
                  <div className="flex flex-col gap-[10px]">
                    <label htmlFor="message">Please Enter Description</label>
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="2"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      // onChange={(e) => handleMessageChange(e)}
                      placeholder="Enter Reason For Report"
                      className="bg-transparent rounded-md outline-none border border-zinc-300 p-1"
                      style={{ border: "1px solid gray" }}
                    ></textarea>
                  </div>
                  {loading ? (
                    <Spinner message="reporting ..." />
                  ) : (
                    <button
                      className="mt-[20px] w-full flex justify-center bg-red-800 text-white p-2 rounded-md"
                      onClick={createNewReport}
                    >
                      Report Now
                    </button>
                  )}
                </form>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Report;
