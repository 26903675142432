import React from "react";
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { useSelector } from "react-redux";
import Scrollbanner from "../../components/innovations/Scrollbanner";
const Innovate = () => {
  const { user } = useSelector((state) => state.auth);
  const getCurrentTime = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  let spanStyling = "text-emerald-500 text-xl font-bold";

  const pathData = [
    {
      id: 1,
      title: "Research",
      link: "/innovation-research",
      description:
        "Any innovation / company is centered towards tackling a specific pain point. Research helps us understand what the problem is, who is facing this problem and where they are. With a good understanding of what the problem is, you will be ready to form a team equipped to tackle this problem",
      photo:
        "https://images.pexels.com/photos/1181569/pexels-photo-1181569.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 2,
      title: "Team Formation",
      link: "/innovation-team-formation",
      description:
        "When you walk into a room asking for money to start your business or company, investors(the people with the money) invest in the right team as well as the solution. The right team will be the vehicle to success. Without a proper team with well defined roles, you risk failure",
      photo:
        "https://images.pexels.com/photos/7551442/pexels-photo-7551442.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 3,
      title: "Brainstorm",
      link: "/innovation-brainstorm",
      description:
        "Once you have a specific problem to solve and the right team behind you, you need to sit down with your team and think on how to solve this problem. It is very important. You need to brainstorm on the right solution that works for the people facing the problem.",
      photo:
        "https://images.pexels.com/photos/935943/pexels-photo-935943.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 4,
      title: "Ideation",
      link: "/innovation-ideation",
      description:
        "After brainstorming and finding the right solution, you and your team need to work on the solution. You need to ask yourself, what will it take for this solution to work ? Does this solution really work ? How will we make money ? How will we onboard users / customers ?",
      photo:
        "https://images.pexels.com/photos/6693596/pexels-photo-6693596.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 5,
      title: "Prototyping",
      link: "/innovation-prototyping",
      description:
        "This is the process of showing how your solution will work in the real world. It needs to do the job intended even if not efficiently. This helps show investors that the solution is actually doable and works",
      photo:
        "https://images.pexels.com/photos/239419/pexels-photo-239419.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 6,
      title: "Pitch Deck",
      link: "/innovation-pitchdeck",
      description:
        "You now have your research, an amazing team, a good solution and a prototype. What you need next is to raise money / seed-money to build your start-up. You therefore need a document called a pitch deck to help you convince investors.",
      photo:
        "https://images.pexels.com/photos/15225230/pexels-photo-15225230/free-photo-of-man-showing-a-graph-in-a-classroom.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 7,
      title: "Pitching",
      link: "/innovation-pitching",
      description:
        "You now need to be exposed to platforms where you can show investors and granters your innovation / startup. You need to know how to pitch and where to pitch. We will set the platform for you.",
      photo:
        "https://images.pexels.com/photos/6150527/pexels-photo-6150527.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
  ];

  const weCanDoForYou = [
    {
      id: 1,
      title: "Trainings & Mentorships",
      description:
        "Without knowledge we are helpless. Therefore the first thing we offer you is the knowledge you need to start the journey",
      photo:
        "https://images.pexels.com/photos/4164451/pexels-photo-4164451.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 2,
      title: "A Community",
      description:
        "The journey is long, it's better to walk with a community. If you want to go far, walk with a team. ",
      photo:
        "https://images.pexels.com/photos/5384621/pexels-photo-5384621.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 3,
      title: "Platform",
      description:
        "Once you have your idea well formed with a pitch deck and prototype, we will offer you the platform to pitch and get the money you need to actualize the idea",
      photo:
        "https://images.pexels.com/photos/391535/pexels-photo-391535.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 4,
      title: "Resources",
      description:
        "We accumulate the latest and the best resources for you to take the journey. The right tools will make the journey much lighter",
      photo:
        "https://images.pexels.com/photos/5022456/pexels-photo-5022456.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
  ];

  return (
    <div>
      {/* wrapper */}
      <div className=" px-[8px] sm:px-[2em] lg:px-[5em] pt-[1em]">
        {/* topbar */}
        <div className="flex justify-between items-center">
          <Link to="/home">
            <IoArrowBack className="text-xl text-emerald-400" />
          </Link>
          <h2>
            {getCurrentTime()}, {user?.username}
          </h2>
        </div>
        {/*  */}
        <div className="mt-[3em]">
          <div className="mb-[2em]">
            <Scrollbanner />
          </div>
          <h2 className="font-bold text-zinc-400 text-3xl mb-[1.4em]">
            Innovation
          </h2>
          <p className="text-zinc-300">
            If you don't want to set up a normal business in the business
            section and you require funding for your new business or company, we
            will show you the path.{" "}
          </p>
          <p className="text-zinc-300">
            The journey to innovation is not easy. But we will hold your hand
            every step of the way. Entrepreneurship is an amazing concept{" "}
          </p>
          <p className="text-zinc-300">
            Please follow the path we have carefully laid out for you. All the
            resources you need to be pitch ready 💪💪
          </p>
        </div>
        {/*  */}
        <div className=" mt-[3em] ">
          <h2 className="text-zinc-400 text-xl font-bold pb-[1em]">
            The Process in a nutshell flows this way
          </h2>
          <div className="movieGrid">
            {pathData.map((item, idx) => (
              <div key={item.id}>
                <img
                  src={item.photo}
                  alt=""
                  className="max-h-[200px] w-full rounded-xl object-cover"
                />
                <h2 className="font-bold text-emerald-600 mb-3 mt-2">
                  {idx + 1}. {item.title}
                </h2>
                <p className="text-zinc-400 mb-3">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
        {/*  */}
        <div className=" mt-[3em] ">
          <h2 className="text-zinc-400 text-xl font-bold pb-[1em]">
            What We Can Do For You
          </h2>
          <div className="movieGrid">
            {weCanDoForYou.map((item, idx) => (
              <div key={item.id}>
                <img
                  src={item.photo}
                  alt=""
                  className="max-h-[200px] w-full rounded-xl object-cover"
                />
                <h2 className="font-bold text-emerald-600 mb-3 mt-2">
                  {idx + 1}. {item.title}
                </h2>
                <p className="text-zinc-400 mb-3">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );
};

export default Innovate;
