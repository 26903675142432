import React, { useEffect, useState } from "react";
import {
  AiFillStar,
  AiOutlineArrowLeft,
  AiOutlineArrowUp,
  AiOutlineComment,
  AiOutlineDislike,
  AiOutlineEye,
  AiOutlineLike,
  AiOutlinePhone,
} from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "../axios";
import { toast } from "react-toastify";
import moment from "moment";
import Spinner from "../components/Spinner";
import { Helmet } from "react-helmet";
import { appBaseUrl } from "../constants";
import { IoShareSocialOutline, IoTrashOutline } from "react-icons/io5";
import FununuComment from "../components/FununuComment";
import imageCompression from "browser-image-compression";
import { BsPen } from "react-icons/bs";

const SpecificPost = () => {
  const { user } = useSelector((state) => state.auth);

  // fetch the post
  const { id } = useParams();
  const [singlePost, setSinglePost] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPost = async () => {
    try {
      setLoading(true);
      let checkParam = id;
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get("/fununu/" + checkParam, config);
      if (response) {
        setLoading(false);
        setSinglePost([response.data]);
        console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Post.", { theme: "dark" });
      toast.error("Network error or doesn't exist", { theme: "dark" });
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);

  // like a post
  const handleLikePost = async (post) => {
    try {
      if (!user) {
        toast.error("Please Login To leave a like", { theme: "dark" });
        return;
      }

      let username = user?.username;
      let id = post._id;
      let likeData = { username };

      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let res = await axios.post("/fununu/like/" + id, likeData, config);
      toast.success("Action Succesful", { theme: "dark" });
      if (res) {
        // notification
        if (user?.username !== post.owner) {
          // create notification
          let sender = user?.username;
          let receiver = post.owner;
          let receiverEmail = post.ownerEmail;
          let message = ` ${post.title} got a like 👍 from ${sender} at ${user?.location}`;
          let referID = `/social/${id}`;
          let notifyData = {
            sender,
            receiver,
            receiverEmail,
            message,
            referID,
          };
          await axios.post("/notify/create", notifyData, config);
        }
      }
      window.location.reload();
    } catch (error) {
      toast.error("Failed To Like", { theme: "dark" });
    }
  };

  // dislike a post
  const handleDisLikePost = async (post) => {
    try {
      if (!user) {
        toast.error("Please Login To leave a Dislike", { theme: "dark" });
        return;
      }

      let username = user?.username;
      let id = post._id;
      let likeData = { username };

      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let res = await axios.post("/fununu/dislike/" + id, likeData, config);
      toast.success("Dislike added", { theme: "dark" });

      if (res) {
        // notification
        if (user?.username !== post.owner) {
          // create notification
          let sender = user?.username;
          let receiver = post.owner;
          let receiverEmail = post.ownerEmail;
          let message = ` ${post.title} got a dislike 👍 from ${sender} at ${user?.location}`;
          let referID = `/social`;
          let notifyData = {
            sender,
            receiver,
            receiverEmail,
            message,
            referID,
          };

          // await axios.post("/notify/create", notifyData, config);
        }
      }
    } catch (error) {
      toast.error("Failed To Dislike", { theme: "dark" });
    }
  };

  // scroll to top functionality
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCopyToClipboard = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      // Log success or update UI accordingly
      console.log("Text copied to clipboard:", textToCopy);
      toast.success("Copied The Link. Share", { theme: "dark" });
    } catch (err) {
      // Handle errors
      console.error("Error copying to clipboard:", err);
    }
  };

  const [photo, setPhoto] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [searchTerms, setSearchTerms] = useState("");
  const [visibleToAllCampuses, setVisibleToAllCampuses] = useState("");

  //update data
  const [postId, setPostId] = useState("");
  const [updatePhoto, setUpdatePhoto] = useState("");
  const [updateTitle, setUpdateTitle] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [updateSearchTerms, setUpdateSearchTerms] = useState("");
  const [updateVisibleToAllCampuses, setUpdateVisibleToAllCampuses] =
    useState("");

  useEffect(() => {
    setPhoto(updatePhoto);
    setTitle(updateTitle);
    setDescription(updateDescription);
    setSearchTerms(updateSearchTerms);
    setVisibleToAllCampuses(updateVisibleToAllCampuses);
  }, [
    updatePhoto,
    updateTitle,
    updateDescription,
    updateSearchTerms,
    updateVisibleToAllCampuses,
  ]);

  const [showUpdate, setShowUpdate] = useState(false);

  // upload photo to cloudinary
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const postPhoto = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingPhoto(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingPhoto(false);
      setPhoto(urlData.url);
      toast.success("Uploaded Photo", { theme: "dark" });
    } catch (error) {
      setLoadingPhoto(false);
      toast.error("Error uploading Photo", { theme: "dark" });
    }
  };

  const handleUpdatePost = async (e) => {
    e.preventDefault();
    if (!title) return toast.error("Title missing", { theme: "dark" });
    if (!description)
      return toast.error("Description missing", { theme: "dark" });

    try {
      setLoading(true);
      let location = user?.location;
      let phone = user?.phone;
      let owner = user?.username;
      let dataToSend = {
        title,
        description,
        location,
        photo,
        phone,
        owner,
        searchTerms,
        visibleToAllCampuses,
      };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put("/fununu/" + id, dataToSend, config);
      if (response) {
        setLoading(false);
        toast.success("updated Post", { theme: "dark" });
        await fetchPost();
        setShowUpdate(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error Updating Post", { theme: "dark" });
    }
  };

  const navigate = useNavigate("");
  const handleDelete = async (id) => {
    try {
      const userConfirmed = window.confirm("Do you want to delete?");
      if (userConfirmed) {
        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        let response = await axios.delete("/fununu/" + id, config);
        if (response) {
          toast.success("Deleted", { theme: "dark" });
          await navigate("/social");
        }
      }
    } catch (error) {
      toast.error("Failed to delete", { theme: "dark" });
      console.log(error);
    }
  };

  return (
    <div>
      {/* arrow to scroll to top */}
      {showArrow && (
        <div
          className="fixed bottom-20 right-4 text-3xl z-[999] cursor-pointer bg-teal-700 text-zinc-50 rounded-full p-[5px]"
          onClick={handleScrollTop}
        >
          <AiOutlineArrowUp />
        </div>
      )}
      {/* wrapper */}
      <div className=" px-[8px] sm:px-[2em] lg:px-[4em] pt-2">
        {/* topbar */}
        <div>
          {user ? (
            <Link to="/social">
              <AiOutlineArrowLeft className="text-2xl" />
            </Link>
          ) : (
            <>
              <Link to="/login">GET STARTED</Link>
            </>
          )}
        </div>

        {/* form to update */}
        {showUpdate && (
          <div className="w-full mt-[3em]">
            <form
              className=" w-[98%] sm:w-[70%]  lg:w-[50%] m-auto "
              onSubmit={handleUpdatePost}
            >
              <h2 className="mb-[10px] text-zinc-300 font-bold">
                Update This Post
              </h2>
              <div className="flex flex-col gap-[15px]">
                <input
                  type="text"
                  placeholder="Title Of Post"
                  className="bg-transparent p-[6px] border border-zinc-500 rounded-md outline-none"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  minLength={3}
                  maxLength={50}
                />
                <textarea
                  cols="30"
                  rows="2"
                  placeholder="Enter a short description"
                  className="bg-transparent p-[6px] border border-zinc-500 rounded-md outline-none"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  minLength={3}
                  maxLength={160}
                ></textarea>
                <div>
                  {/* upload image */}
                  <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                    <div className="flex flex-col gap-2 mt-[20px]">
                      <label
                        htmlFor="mainPhoto"
                        className="flex items-center gap-[20px] flex-wrap"
                      >
                        <div>
                          <p className="text-zinc-400">Update Photo</p>
                          <p className="text-sm">
                            We only accept .jpg .jpeg or png format
                          </p>
                        </div>
                        <div className="flex flex-col items-center">
                          {loadingPhoto ? (
                            <Spinner message="uploading ..." />
                          ) : (
                            <img
                              src={
                                photo
                                  ? photo
                                  : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                              }
                              alt=""
                              className="w-[100px] h-[100px] object-cover"
                            />
                          )}
                        </div>
                      </label>
                      <input
                        type="file"
                        placeholder="Add Image"
                        accept="image/*"
                        onChange={(e) => postPhoto(e.target.files[0])}
                        required
                        id="mainPhoto"
                        className="hidden"
                      />
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Add hashtags, separate by comma"
                  className="bg-transparent p-[6px] border border-zinc-500 rounded-md outline-none"
                  value={searchTerms}
                  onChange={(e) => setSearchTerms(e.target.value)}
                  required
                  minLength={3}
                  maxLength={50}
                />
                {/* visibleToAllCampuses */}
                <div className="flex flex-col gap-2">
                  <label htmlFor="visibleToAllCampuses">
                    Share With All Campuses apart from {user?.location} ?
                  </label>
                  <select
                    name="visibleToAllCampuses"
                    id="visibleToAllCampuses"
                    value={visibleToAllCampuses}
                    onChange={(e) => setVisibleToAllCampuses(e.target.value)}
                    className="bg-[#212121] p-[6px] border border-zinc-500 rounded-md outline-none"
                  >
                    <option value="">Choose</option>
                    <option value="nope">No</option>
                    <option value="yes">Yes</option>
                  </select>
                </div>

                {loading ? (
                  <Spinner message="Updating Post" />
                ) : (
                  <button
                    className="mb-[10px] bg-emerald-700 text-white p-[6px] rounded-md"
                    onClick={handleUpdatePost}
                  >
                    Update Post
                  </button>
                )}
                <p
                  className="mb-[10px] bg-red-700 text-white p-[6px] rounded-md text-center cursor-pointer"
                  onClick={() => {
                    setShowUpdate(false);
                  }}
                >
                  Close Window ?
                </p>
              </div>
            </form>
          </div>
        )}

        {/* data */}
        {loading ? (
          <>
            <div className="h-[80vh] w-full flex justify-between items-center">
              <Spinner message="Fetching Post Details" />
            </div>
          </>
        ) : (
          <>
            <div className=" mt-[30px] ">
              {singlePost?.map((item) => (
                <div key={item._id}>
                  <div className="flex flex-col lg:flex-row gap-[20px]  justify-between">
                    {/* image side */}
                    <div className="flex-[0.5%]">
                      <img
                        src={item.photo}
                        alt=""
                        className=" max-h-[85vh] w-full object-contain rounded-lg"
                      />
                    </div>

                    {/* {console.log(item)} */}
                    <Helmet>
                      <title> iLocca | Social| {item.owner}</title>
                      <meta
                        name="description"
                        content={`${item.username} owns ${item.title}. Check Out His Local Business Today In ${item.location}`}
                      />
                    </Helmet>

                    {/* text side */}
                    <div className="flex-[0.5%]">
                      {/* share */}
                      <div className="flex justify-between gap-[20px] mb-4 items-center ">
                        <div>
                          <button
                            onClick={() =>
                              handleCopyToClipboard(
                                ` ${appBaseUrl}business/${item._id}`
                              )
                            }
                            className="flex justify-center gap-[20px] border border-emerald-400 rounded-lg px-4 py-1  "
                          >
                            <IoShareSocialOutline
                              className="text-2xl text-emerald-400"
                              title="Click To Copy"
                            />
                            <p>Share With Friends</p>
                          </button>
                        </div>
                        <div>
                          <div className="">
                            <div>
                              <Link
                                to={
                                  item.owner == user?.username
                                    ? "/my-profile"
                                    : `/other-profile?owner=${item.owner}`
                                }
                              >
                                <p className="underline text-zinc-300">
                                  {item.owner == user?.username
                                    ? "you"
                                    : item.owner.substring(0, 12)}
                                </p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* edit and delete icon */}
                      <div className="my-4">
                        {item.owner === user?.username && (
                          <div className="w-full flex justify-end mb-4">
                            <div className="flex items-center gap-8">
                              {/* edit button */}
                              <div
                                className="flex items-center gap-2 text-emerald-400 cursor-pointer"
                                onClick={() => {
                                  setShowUpdate(true);

                                  window.scrollTo(0, 0);

                                  setPostId(item._id);

                                  setUpdatePhoto(item.photo);
                                  setUpdateTitle(item.title);
                                  setUpdateDescription(item.description);
                                  setUpdateSearchTerms(item.searchTerms);
                                  setUpdateVisibleToAllCampuses(
                                    item.visibleToAllCampuses
                                  );
                                }}
                              >
                                <p>
                                  <BsPen />
                                </p>
                                <p>Edit</p>
                              </div>
                              {/* delete button */}
                              <div>
                                <IoTrashOutline
                                  className="text-2xl text-red-500 cursor-pointer"
                                  onClick={() => handleDelete(item._id)}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* owner info */}
                      <div className="mb-[20px]">
                        <div>
                          <p className="text-xl font-bold text-zinc-300 mb-5">
                            {item.title}
                          </p>
                          <p className="my-[15px] text-zinc-300">
                            {item.description}
                          </p>
                        </div>
                      </div>

                      {/* options */}
                      <div>
                        {item.owner == user?.username && (
                          <p className="mb-[10px] text-zinc-400">
                            Only you can see dislikes
                          </p>
                        )}

                        {user ? (
                          <div className="flex justify-between">
                            <div className="flex items-center gap-3 cursor-pointer">
                              <AiOutlineEye className="text-xl" />
                              <p>{item.views.length}</p>
                              {/* {console.log(item)} */}
                            </div>
                            <div
                              className="flex items-center gap-3 cursor-pointer"
                              onClick={() => handleLikePost(item)}
                            >
                              <AiOutlineLike className="text-xl" />
                              <p>{item.likes.length}</p>
                            </div>
                            <div
                              className="flex items-center gap-3 cursor-pointer"
                              onClick={() => handleDisLikePost(item)}
                            >
                              <AiOutlineDislike className="text-xl" />
                              {item.owner == user?.username && (
                                <p>{item.dislikes.length}</p>
                              )}
                            </div>
                            <div className="flex items-center gap-3">
                              <AiOutlineComment className="text-xl" />
                              <p>{item.comments.length}</p>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="flex justify-between">
                              <div className="flex items-center gap-3 cursor-pointer">
                                <AiOutlineEye className="text-xl" />
                                <p>{item.views.length}</p>
                                {/* {console.log(item)} */}
                              </div>
                              <div
                                className="flex items-center gap-3 cursor-pointer"
                                // onClick={() => handleLikeBiz(item)}
                              >
                                <AiOutlineLike className="text-xl" />
                                <p>{item.likes.length}</p>
                              </div>
                              <div
                                className="flex items-center gap-3 cursor-pointer"
                                // onClick={() => handleDisLikeBiz(item)}
                              >
                                <AiOutlineDislike className="text-xl" />
                                {/* <p>{item.dislikes.length}</p> */}
                              </div>
                              <div className="flex items-center gap-3">
                                <AiOutlineComment className="text-xl" />
                                <p>{item.comments.length}</p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="flex justify-between items-center my-5">
                        <div>
                          <p className=" mt-2 mb-[15px] text-sm text-teal-600">
                            {moment(item.createdAt).fromNow()}
                          </p>
                        </div>

                        <div className="flex items-center gap-[6px]">
                          <p>
                            <CiLocationOn className="text-xl" />
                          </p>

                          <p className="text-zinc-300 text-sm ">
                            {item.location}
                          </p>
                        </div>
                      </div>

                      <div className="flex gap-2 mb-6 items-center ">
                        <p>
                          <AiOutlinePhone className="text-white text-2xl" />
                        </p>
                        <a
                          href={`tel:${item.phone}`}
                          className="text-emerald-400"
                        >
                          {item.phone}
                        </a>
                      </div>

                      {/* comments*/}
                      <div>
                        {user ? (
                          <>
                            {/* comment */}
                            <div className="mt-[2em]">
                              <FununuComment item={item} />
                            </div>
                          </>
                        ) : (
                          <div>
                            <Link to="/login">
                              <p className="text-emerald-700 font-bold">
                                LOGIN TO ACCESS COMMENTS
                              </p>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SpecificPost;
