import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../features/auth/authSlice";

import { AiOutlineArrowLeft } from "react-icons/ai";
import MyBusinesses from "../components/mine/MyBusinesses";
import MyAds from "../components/mine/MyAds";
import MyRents from "../components/mine/MyRents";
import MyTrades from "../components/mine/MyTrades";
import MyEvents from "../components/mine/MyEvents";
import { toast } from "react-toastify";
import { getUser } from "../features/auth/authSlice";
import Spinner from "../components/Spinner";
import axios from "../axios";
import { DummyLocations } from "../Dummydata";
import { MdOutlineContentCopy } from "react-icons/md";
import MyFununu from "../components/mine/MyFununu";
import { Helmet } from "react-helmet";
import { appBaseUrl } from "../constants";
import imageCompression from "browser-image-compression";
import { IoShareSocialOutline } from "react-icons/io5";

const MyProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }
  }, [user, navigate]);

  const handleLogout = async () => {
    let userId = user?._id;
    await dispatch(logout(userId));
    navigate("/login");
  };

  // update user
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profile, setProfile] = useState("");
  const [coverPic, setCoverPic] = useState("");
  const [instaUsername, setInstaUsername] = useState("");

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      setPhone(user?.phone);
      setLocation(user?.location);
      setCoverPic(user?.coverPic);
      setEmail(user?.email);
      setInstaUsername(user?.instaUsername);
      setProfile(user?.profile);
    }
  }, [user]);

  const handleChange = async (e) => {
    e.preventDefault();

    if (!location) {
      return toast.error("Location is missing", { theme: "dark" });
    }

    if (!phone) return toast.error("Phone is missing", { theme: "dark" });
    if (!email) return toast.error("email is missing", { theme: "dark" });

    if (!profile) {
      setProfile(
        "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&w=600"
      );
    }

    if (!coverPic) {
      setCoverPic(
        "https://images.pexels.com/photos/459277/pexels-photo-459277.jpeg?auto=compress&cs=tinysrgb&w=600"
      );
    }

    try {
      setLoading(true);
      let userId = user?._id;

      if (password) {
        let dataToSend = {
          location,
          phone,
          email,
          profile,
          coverPic,
          password,
          instaUsername,
        };
        // Make the PUT request to update the user's location
        let response = await axios.put(`/users/${userId}`, dataToSend);

        if (response) {
          setLoading(false);
          await handleLogout();
          toast.success("Login Again", { theme: "dark" });
          // let username = user?.username;
          // let newData = { username };
          // dispatch(getUser(newData));
          // // Update the user's location in local storage
          // if (user) {
          //   // user.location = location;
          //   localStorage.setItem("user", JSON.stringify(response.data));
          // }

          // toast.success("Details updated", { theme: "dark" });
          // setLocation("");
          // setPhone("");
          // navigate("/home");
        }
      } else {
        //
        let dataToSend = {
          location,
          email,
          phone,
          profile,
          coverPic,
          instaUsername,
        };
        // Make the PUT request to update the user's location
        let response = await axios.put(`/users/${userId}`, dataToSend);

        if (response) {
          setLoading(false);
          await handleLogout();
          toast.success("Login Again", { theme: "dark" });
          // let username = user?.username;
          // let newData = { username };
          // dispatch(getUser(newData));
          // // Update the user's location in local storage
          // if (user) {
          //   // user.location = location;
          //   localStorage.setItem("user", JSON.stringify(response.data));
          // }

          // toast.success("Details updated", { theme: "dark" });
          // setLocation("");
          // setPhone("");
          // navigate("/home");
        }
        //
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to update details", { theme: "dark" });
    }
  };

  // upload photot to cloudinary
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const postPhoto = async (pic) => {
    //check availability
    setLoadingPhoto(true);
    if (pic === null || undefined) {
      toast.error("Please select photo");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      setLoadingPhoto(true);
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingPhoto(false);
      setCoverPic(urlData.url);
      toast.success("Uploaded Photo", { theme: "dark" });
    } catch (error) {
      setLoadingPhoto(false);
      toast.error("Error uploading Photo", { theme: "dark" });
    }
  };

  // upload profile to cloudinary
  const [loadingProfile, setLoadingProfile] = useState(false);
  const postProfile = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo", { theme: "dark" });
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingProfile(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingProfile(false);
      setProfile(urlData.url);
      toast.success("Uploaded Photo", { theme: "dark" });
    } catch (error) {
      setLoadingProfile(false);
      toast.error("Error uploading Photo");
    }
  };

  const handleCopyToClipboard = async (textToCopy) => {
    try {
      // Get the text from your input or any other source
      // You can replace this with your dynamic input

      // Use the Clipboard API to write the text to the clipboard
      await navigator.clipboard.writeText(textToCopy);

      // Log success or update UI accordingly
      console.log("Text copied to clipboard:", textToCopy);
      toast.success("Copied The Link. Share", { theme: "dark" });
    } catch (err) {
      // Handle errors
      console.error("Error copying to clipboard:", err);
    }
  };

  return (
    <div>
      {/* wrapper */}
      <div className=" px-[10px] sm:px-[2em] py-[1em]">
        {/* topbar */}
        <div className="flex justify-between mb-[20px]">
          <div className="flex flex-col gap-[16px]">
            <Link to="/home">
              <AiOutlineArrowLeft className="text-2xl" />
            </Link>
            {/* <h2>Hello There {user?.username}. All your stuff is here</h2> */}
          </div>

          {/* {console.log(user)} */}

          <p
            className="text-2xl text-emerald-500 font-bold cursor-pointer"
            onClick={handleLogout}
          >
            LOGOUT
          </p>
        </div>

        {/* cover pic and profile */}
        <div>
          {/* cover */}
          <div>
            <img
              src={
                user?.coverPic ||
                "https://images.pexels.com/photos/459277/pexels-photo-459277.jpeg?auto=compress&cs=tinysrgb&w=600"
              }
              alt=""
              className="max-h-[300px] w-full object-cover rounded-lg"
            />
          </div>

          {/* profile */}
          <div className="w-full flex justify-center mt-[-80px]">
            <img
              src={
                user?.profile ||
                "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&w=600"
              }
              alt=""
              className="w-[150px] h-[150px] object-cover rounded-full"
            />
          </div>
        </div>

        <Helmet>
          <title>{`${user?.username} | ${user?.location} | iLocca`}</title>
          <meta
            name="description"
            content={`${user?.username} is helping bridge the gap between local businesses and the community at ${user?.location}, Join iLocca today to see how`}
          />
        </Helmet>
        {/* share profile */}
        <h2 className="mt-[20px] mb-[10px] text-center">
          Copy And Share This Link To Your Friends
        </h2>
        <h2 className="text-center mb-[15px]">
          This is the link to your profile
        </h2>

        <div className="flex justify-center mt-3">
          <button
            onClick={() =>
              handleCopyToClipboard(
                `${appBaseUrl}other-profile?owner=${user?.username}`
              )
            }
            className="flex justify-center gap-[20px] items-center border border-emerald-500 p-2 rounded-lg"
          >
            <IoShareSocialOutline
              className="text-xl text-emerald-500"
              title="Click To Copy"
            />
            share profile
          </button>
        </div>

        {/* update my data */}
        {user && (
          <div className="mt-[30px]">
            <h2 className="mb-[20px]">
              Hello {user?.username}. Update Details Here
            </h2>
            <div className="w-full m-auto sm:w-[70%] lg:w-[50%]">
              <div className="flex flex-col gap-[10px] mb-[22px] w-full">
                <label htmlFor="location">
                  <p className="font-bold text-zinc-300 text-lg ">
                    Update Your Campus
                  </p>
                </label>
                <select
                  name="location"
                  id="location"
                  className="bg-[#1E1C1C] border border-zinc-400 p-[8px] rounded-md outline-none"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <option value="">Choose</option>
                  {DummyLocations.map((item) => (
                    <option key={item.id}>{item.title}</option>
                  ))}
                </select>
              </div>
              {/* phone */}
              <div className="flex flex-col gap-[10px] mb-[22px] w-full">
                <label htmlFor="phone">
                  <p className="font-bold text-zinc-500 mb-2">
                    Update your phone number
                  </p>
                  <p className="text-sm text-emerald-700">
                    {user?.phone} is current phone
                  </p>
                </label>
                <input
                  type="text"
                  id="phone"
                  value={phone}
                  placeholder="New Phone Number Here"
                  onChange={(e) => setPhone(e.target.value)}
                  className="bg-transparent border border-zinc-400 p-[8px] rounded-md outline-none"
                />
              </div>
              {/* email address */}
              <div className="flex flex-col gap-[10px] mb-[22px] w-full">
                <label htmlFor="email">
                  <p className="font-bold text-zinc-500 mb-2">
                    Update your Email Address
                  </p>
                  <p className="text-sm text-emerald-700">
                    {user?.email.substring(0, 8)}... is current email
                  </p>
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  placeholder="new email address"
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-transparent border border-zinc-400 p-[8px] rounded-md outline-none"
                />
              </div>

              {/* {console.log(user)} */}
              {user.isStudent && (
                <div className="flex flex-col gap-[10px] mb-[22px] w-full">
                  <label htmlFor="instagram">
                    <p className="font-bold text-zinc-500 mb-2">
                      Update your Instagram
                    </p>
                    <p className="text-zinc-400">
                      By Adding, you will show up in social page
                    </p>
                    <p className="text-emerald-600">
                      We Advise a public IG profile
                    </p>
                    <p className="text-zinc-400">
                      If not interested, please leave empty and click update
                      button
                    </p>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    value={instaUsername}
                    placeholder="Enter IG username for chats"
                    onChange={(e) => setInstaUsername(e.target.value)}
                    className="bg-transparent border border-zinc-400 p-[8px] rounded-md outline-none"
                  />
                </div>
              )}
              {console.log(user)}
              {/* password */}
              <div className="flex flex-col gap-[10px] mb-[22px] w-full">
                <label htmlFor="password">
                  <p className="font-bold text-zinc-500 mb-2">
                    Update your password ?
                  </p>
                </label>
                <input
                  type="text"
                  id="password"
                  value={password}
                  placeholder="New Password ?"
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-transparent border border-zinc-400 p-[8px] rounded-md outline-none"
                />
              </div>
              <div>
                {/* cover photo */}
                <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                  <div className="flex flex-col gap-2 mt-[20px]">
                    <label
                      htmlFor="coverPic"
                      className="flex items-center gap-[20px] flex-wrap"
                    >
                      <p>Update Cover Pic</p>
                      <div className="flex flex-col items-center">
                        {loadingPhoto ? (
                          <Spinner message="uploading ..." />
                        ) : (
                          <img
                            src={coverPic ? coverPic : user?.coverPic}
                            alt=""
                            className="w-[100px] h-[100px] object-cover"
                          />
                        )}
                      </div>
                    </label>
                    <input
                      type="file"
                      placeholder="Add Image"
                      accept="image/*"
                      onChange={(e) => postPhoto(e.target.files[0])}
                      required
                      id="coverPic"
                      className="hidden"
                    />
                  </div>
                </div>
                {/* profile photo */}
                <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                  <div className="flex flex-col gap-2 mt-[20px]">
                    <label
                      htmlFor="profilePic"
                      className="flex items-center gap-[20px] flex-wrap"
                    >
                      <p>Update Profile Pic</p>
                      <div className="flex flex-col items-center">
                        {loadingProfile ? (
                          <Spinner message="uploading ..." />
                        ) : (
                          <img
                            src={profile ? profile : user?.profile}
                            alt=""
                            className="w-[100px] h-[100px] object-cover"
                          />
                        )}
                      </div>
                    </label>
                    <input
                      type="file"
                      placeholder="Add Image"
                      accept="image/*"
                      onChange={(e) => postProfile(e.target.files[0])}
                      required
                      id="profilePic"
                      className="hidden"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full">
                {loading ? (
                  <Spinner message="updating" />
                ) : (
                  <button
                    className="w-full bg-emerald-800 text-white p-2 rounded-md"
                    onClick={handleChange}
                  >
                    Update Now
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {/* all stuff */}
        {user && (
          <div className="mt-[20px]">
            {/* my businesses */}
            <div className="mb-[1.6em]">
              {user?.username && <MyBusinesses />}
            </div>
            {/* Ads */}
            {/* <MyAds /> */}
            {/* to rent */}
            {/* <MyRents /> */}
            {/* trades */}
            <div className="mb-[1.6em]">{user?.username && <MyTrades />}</div>
            <div className="mb-[1.6em]">{user?.username && <MyRents />}</div>
            {/* events */}
            <div className="mb-[1.6em]">
              <MyEvents />
            </div>
            {/* my posts */}
            <MyFununu />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyProfile;
