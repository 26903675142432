import React, { useState, useEffect } from "react";
import { MdOutlineDateRange } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoPersonOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";

const ImageChanger = () => {
  const { user } = useSelector((state) => state.auth);
  const dummyPictures = [
    {
      id: 1,
      title: "Noah Distributors",
      owner: "noah",
      location: "Daystar University",
      description:
        "A biking service that offers fun and exciting adventures for students in Lukenya Hills. Whether you're a seasoned cyclist or just looking for a fun day out.",
      link: "",
      photo:
        "https://images.pexels.com/photos/11935543/pexels-photo-11935543.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
    {
      id: 2,
      title: "Kiu Fresh",
      link: "",
      owner: "erico",
      location: "Daystar University",
      description:
        "Kiu Fresh has been providing fresh water to the students of Daystar University for over three years. With a commitment to quality and convenience, Kiu Fresh not only sells water but also offers a reliable delivery service to over 800 students.",
      photo:
        "https://images.pexels.com/photos/416528/pexels-photo-416528.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
    {
      id: 3,
      title: "Chilltons Hotel",
      owner: "jason",
      location: "Daystar University",
      link: "https://youtube.com",
      description:
        "Jason is the esteemed manager of Chilltons, one of the premier restaurants at Daystar University. With over three years of experience in the industry, he has successfully led his team to serve over 2000 students, staff, and guests. ",
      photo:
        "https://images.pexels.com/photos/2983101/pexels-photo-2983101.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [slideDirection, setSlideDirection] = useState("slideIn"); // Initial direction

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Toggle the slide direction
      setSlideDirection((prevDirection) =>
        prevDirection === "slideIn" ? "slideOut" : "slideIn"
      );
      setTimeout(() => {
        // Rotate to the next image and toggle the slide direction back
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % dummyPictures.length
        );
        setSlideDirection("slideIn");
      }, 400); // Adjust the duration based on your transition duration
    }, 8000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const currentImage = dummyPictures[currentImageIndex];

  // check size of screen
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      // Check if the screen width is less than or equal to 600 pixels
      setIsSmallScreen(window.matchMedia("(max-width: 300px)").matches);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {/* laptop screen */}
      <div className="hidden lg:block">
        {/* wrapper */}
        <a href={currentImage.link} target="_blank" rel="noreferrer">
          <div className=" text-white h-[52vh] w-full rounded-xl p-6 relative flex gap-2  justify-evenly  items-center">
            {/* sliding image */}
            <div className="flex-[0.5] ">
              <img
                src={currentImage.photo}
                alt={currentImage.title}
                className={`object-cover rounded-xl max-h-[98%] ${
                  slideDirection === "slideIn" ? "slide-in" : "slide-out"
                }`}
              />
            </div>
            {/* text side */}
            <div
              className={`flex-[0.5]   ${
                slideDirection === "slideIn" ? "scale-in-center" : ""
              }`}
            >
              <p className="text-3xl font-bold mb-12">{currentImage.title}</p>
              <p>{currentImage.description.substring(0, 400)} </p>

              <div className="mt-[20px]">
                <Link
                  to={
                    currentImage.owner == user?.username
                      ? "/my-profile"
                      : `/other-profile?owner=${currentImage.owner}`
                  }
                >
                  <div className="flex items-center gap-2 mb-6">
                    <p>
                      <IoPersonOutline />
                    </p>
                    <p className="underline">{currentImage.owner}</p>
                  </div>
                </Link>
                <div className="flex items-center gap-2 mb-6">
                  <p>
                    <CiLocationOn />
                  </p>
                  <p>{currentImage.location}</p>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>

      {/* mobile screen */}
      <div className="lg:hidden">
        <a href={currentImage.link} target="_blank" rel="noreferrer">
          <div className="w-full h-[65vh] relative ">
            <img
              src={currentImage.photo}
              alt={currentImage.title}
              className={`object-cover rounded-xl w-full h-[65vh] `}
            />

            {/* overlay div */}
            <div className="absolute top-0 left-0 w-full h-[65vh] bg-[rgba(0,0,0,.5)] rounded-xl" />
            <div className="absolute w-full h-full top-0 flex flex-col justify-center items-start text-white">
              <div className="p-3">
                {/*  */}
                <div
                  className={` ${
                    slideDirection === "slideIn" ? "scale-in-center" : ""
                  }`}
                >
                  <p className="text-3xl font-bold mb-10">
                    {currentImage.title}
                  </p>
                  <p className={`${isSmallScreen && "hidden"}`}>
                    {currentImage.description.substring(0, 400)}{" "}
                  </p>

                  <div className="mt-[20px]">
                    <Link
                      to={
                        currentImage.owner == user?.username
                          ? "/my-profile"
                          : `/other-profile?owner=${currentImage.owner}`
                      }
                    >
                      <div className="flex items-center gap-2 mb-6">
                        <p>
                          <IoPersonOutline />
                        </p>
                        <p className="underline">{currentImage.owner}</p>
                      </div>
                    </Link>
                    <div className="flex items-center gap-2 mb-6">
                      <p>
                        <CiLocationOn />
                      </p>
                      <p>{currentImage.location}</p>
                    </div>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </a>
      </div>
      {/*  */}
    </div>
  );
};

export default ImageChanger;
