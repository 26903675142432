// import { useState } from "react";
import Bg from "../assets/jbg2.png";
import Logo from "../assets/officialOne.png";
import { Link } from "react-router-dom";
import { SiVerizon } from "react-icons/si";
import { IoMdClose } from "react-icons/io";
import { Helmet } from "react-helmet";
import Social from "../assets/social.png";
import Innovate from "../assets/innovate.png";
import Enter from "../assets/enter.png";

const Splash = () => {
  const DummyBizOffer = [
    {
      id: 1,
      title: "Online Business",
      photo:
        "https://images.pexels.com/photos/4350216/pexels-photo-4350216.jpeg?auto=compress&cs=tinysrgb&w=600",
      description:
        "Add up to 4 businesses for your campus to promote visibility. Add products easily without any capital",
    },
    {
      id: 2,
      title: "Market Access",
      photo:
        "https://images.pexels.com/photos/6476808/pexels-photo-6476808.jpeg?auto=compress&cs=tinysrgb&w=600",
      description:
        "As we market the platform, we will market your business and services. Sell Across Campuses",
    },
    {
      id: 3,
      title: "Direct Calls",
      photo:
        "https://images.pexels.com/photos/3206114/pexels-photo-3206114.jpeg?auto=compress&cs=tinysrgb&w=600",
      description:
        "Receive direct phone calls from potential customers. Negotiate prices and make deals",
    },
    {
      id: 4,
      title: "Customer Feedback",
      photo:
        "https://images.pexels.com/photos/2379886/pexels-photo-2379886.jpeg?auto=compress&cs=tinysrgb&w=600",
      description:
        "Ratings, Likes, Dislikes and comments will give you all the feedback you need for your business",
    },
  ];

  const DummyCustOffer = [
    {
      id: 1,
      title: "Discover Businesses",
      photo:
        "https://images.pexels.com/photos/3768905/pexels-photo-3768905.jpeg?auto=compress&cs=tinysrgb&w=600",
      description:
        "Let us surprise you on the many products and services available in your campus and beyond. ",
    },
    {
      id: 2,
      title: "Discover Local Events",
      photo:
        "https://images.pexels.com/photos/6192554/pexels-photo-6192554.jpeg?auto=compress&cs=tinysrgb&w=600",
      description:
        "Are you looking to dive into the local campus, check out campus events and meet new people.",
    },
    {
      id: 3,
      title: "Entrepreneurship",
      photo:
        "https://images.pexels.com/photos/3811082/pexels-photo-3811082.jpeg?auto=compress&cs=tinysrgb&w=600",
      description:
        "An easy way to find market gaps in the campus community which you can use to your advantage",
    },
    {
      id: 4,
      title: "Window Shopping",
      photo:
        "https://images.pexels.com/photos/3765160/pexels-photo-3765160.jpeg?auto=compress&cs=tinysrgb&w=600",
      description:
        "Say goodbye to the old days of walking around markets asking for products.Our platform is the market you need.",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>{` Home | iLocca`}</title>
        <meta
          name="description"
          content="Bridging the gap between local businesses and the community, offering a streamlined platform for residents to discover and acquire quality local products and services."
        />
      </Helmet>
      {/* top overlay details */}
      <div className="w-full h-[80vh]">
        <img
          // src={Bg}
          // src="https://images.pexels.com/photos/6193638/pexels-photo-6193638.jpeg?auto=compress&cs=tinysrgb&w=1600"
          src="https://images.pexels.com/photos/6193638/pexels-photo-6193638.jpeg?auto=compress&cs=tinysrgb&w=1600"
          alt="Background Placeholder"
          className="w-full h-[80vh] object-cover"
        />

        {/* overlay div */}
        <div className="absolute top-0 left-0 w-full h-[80vh] bg-[rgba(0,0,0,.7)]" />
        {/* content */}
        <div className="absolute w-full h-[80vh] top-0 text-white pt-[10px] sm:pt-[1em] px-[10px] sm:px-[2em] z-10">
          <div className="flex justify-between items-center z-50">
            <div>
              <img src={Logo} alt="" className="w-[60px] object-contain" />
            </div>
            <div className="z-50 ">
              <div className="flex items-center gap-[44px]">
                <Link to="/login">
                  <p
                    className="bg-emerald-800 px-4 py-2 rounded-md font-bold"
                    style={{ letterSpacing: "1px" }}
                  >
                    LOGIN
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute w-full h-full top-0 flex flex-col justify-center items-center text-white">
          <h2 className=" text-2xl sm:text-4xl md:text-6xl font-bold mb-[25px]">
            Welcome to ilocca
          </h2>
          <h4 className=" text-md sm:text-xl text-center md:text-2xl mb-[25px]">
            Economically Empowering Campus Students Across Africa
          </h4>
          <Link to="/register" className="z-50">
            <p className="bg-emerald-800 px-6 py-3 rounded-md">
              ACCOUNT NEEDED TO PROCEED
            </p>
          </Link>
        </div>
      </div>

      <div className=" w-[98%]  sm:w-[90%]  md:w-[89%]  lg:w-[70%] m-auto py-[2em] z-20">
        {/* top after image overlay */}
        <div className="flex flex-col md:flex-row items-center ">
          <div className=" flex-[0.5] lg:flex-[0.4]">
            <div
              className="font-bold text-3xl mb-3 text-emerald-400"
              style={{ lineHeight: "1.6em" }}
            >
              <h2>Growing Local Economies</h2>
              <h2>One Campus </h2>
              <h2>At a Time</h2>
            </div>
            <p>
              Our Platform helps local businesses and customers connect easily.
              We want to make sure local businesses do well in today's digital
              world and that people can find great local products and services.
              Join us in supporting local communities!
            </p>
          </div>
          <div className=" flex-[0.5] lg:flex-[0.6]">
            <img src={Bg} alt="background" className="object-contain" />
          </div>
        </div>
        {/* why iLocca */}
        <div className="pt-[4em] pb-[4em]">
          <h2 className="text-emerald-500 font-bold text-2xl mb-[1em]">
            Our Pillars
          </h2>
          <div className="pillarsSection">
            <div className="w-full flex flex-col justify-center items-center">
              <img src={Enter} alt="" />
              <h2 className="text-zinc-400 text-lg">Entrepreneurship</h2>
            </div>
            <div className="w-full flex flex-col justify-center items-center">
              <img src={Innovate} alt="" />
              <h2 className="text-zinc-400 text-lg">Innovations</h2>
            </div>
            <div className="w-full flex flex-col justify-center items-center">
              <img src={Social} alt="" />
              <h2 className="text-zinc-400 text-lg">Social Inclusion</h2>
            </div>
          </div>
        </div>
        {/* what we can do for you */}
        <div className="py-[2em]">
          <h2 className="text-emerald-500 font-bold text-2xl mb-[1em]">
            What We Can Do For You
          </h2>
          {/* businesses */}
          <div>
            <h2 className="mb-3">Business Owners & Service Providers</h2>
            {/*  */}
            <div>
              <div className="movieGrid ">
                {DummyBizOffer?.map((item) => (
                  <div key={item.id} className="flex-shrink-0 ">
                    <div className="relative rounded-lg group ">
                      <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                        <div
                          className="bg-gradient-to-b
                                  from-transparent to-black opacity-95 w-full h-full rounded-md"
                        >
                          {/* top stats */}
                          <h2 className="text-white bottom-0 pl-2">
                            {/* {item.description} */}
                          </h2>
                          {/*  */}
                          <div className="absolute bottom-[20px] px-1  flex gap-[10%] w-full ">
                            <div>
                              <div className="flex gap-[10px] text-white">
                                <p>{item.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <img
                        src={item.photo}
                        alt=""
                        className="w-full h-80 rounded-md object-cover"
                      />
                    </div>

                    {/* options */}
                    <div className="mt-2">
                      <p className="text-sm font-bold text-emerald-400">
                        {item.title}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* customer */}
          <div className="mt-10">
            <h2 className="mb-3">Local Customers & Entrepreneurs</h2>
            {/*  */}
            <div>
              <div className="movieGrid ">
                {DummyCustOffer?.map((item) => (
                  <div key={item.id} className="flex-shrink-0">
                    <div className="relative rounded-lg group ">
                      <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                        <div
                          className="bg-gradient-to-b
                                  from-transparent to-black opacity-95 w-full h-full rounded-md"
                        >
                          {/* top stats */}
                          <h2 className="text-white bottom-0 pl-2">
                            {/* {item.description} */}
                          </h2>
                          {/*  */}
                          <div className="absolute bottom-[20px] px-1  flex gap-[10%] w-full ">
                            <div>
                              <div className="flex gap-[10px] text-white">
                                <p>{item.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <img
                        src={item.photo}
                        alt=""
                        className="w-full h-80 rounded-md object-cover"
                      />
                    </div>

                    {/* options */}
                    <div className="mt-2">
                      <p className="text-sm font-bold text-emerald-400">
                        {item.title}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* freemium model */}
        {/* <div className="py-[2em]">
          <h2 className="text-emerald-700 font-bold text-2xl ">Our Pricing</h2>
          <h2 className="mb-[1em]">Business Owners & Service Providers</h2>

          <div className="mt-10 flex gap-[10px] justify-evenly  py-7 w-full">
           
            <div
              style={{
                background: "rgba(233, 240, 234, 0.7)",
                backdropFilter: "blur(4px)",
                zIndex: 2,
              }}
              className="p-4 rounded-md"
            >
              <h2 className="font-bold mb-3">Biz Plan</h2>
              <h2 className="text-emerald-700 font-bold mb-1">
                Ksh. 50 Per Month
              </h2>
              <div>
                <ul>
                  <li className="flex gap-1 items-center mb-2 mt-2">
                    <p>
                      <SiVerizon className="text-emerald-500" />
                    </p>
                    <p>Add Unlimited Businesses</p>
                  </li>
                  <li className="flex gap-1 items-center mb-2 mt-2">
                    <p>
                      <SiVerizon className="text-emerald-500" />
                    </p>
                    <p>Add Products / Services</p>
                  </li>
                  <li className="flex gap-1 items-center mb-2 mt-2">
                    <p>
                      <SiVerizon className="text-emerald-500" />
                    </p>
                    <p>Unlimited Calls</p>
                  </li>
                  <li className="flex gap-1 items-center mb-2 mt-2">
                    <p>
                      <SiVerizon className="text-emerald-500" />
                    </p>
                    <p>Add Events</p>
                  </li>
                  <li className="flex gap-1 items-center mb-2 mt-2">
                    <p>
                      <SiVerizon className="text-emerald-500" />
                    </p>
                    <p>Sharing Profile</p>
                  </li>
                  <li className="flex gap-1 items-center mb-2 mt-2">
                    <p>
                      <IoMdClose className="text-red-600 text-xl" />
                    </p>
                    <p>Articles & Stories</p>
                  </li>
                </ul>
              </div>
            </div>
            
            <div
              style={{
                background: "rgba(233, 240, 234, 0.7)",
                backdropFilter: "blur(4px)",
                zIndex: 2,
              }}
              className="p-4 rounded-md"
            >
              <h2 className="font-bold mb-3">Student Plan</h2>
              <h2 className="text-emerald-700 font-bold mb-1">
                Ksh. 30 Per Month
              </h2>
              <h2 className="text-emerald-700 font-bold mb-1">
                All Features Unlocked
              </h2>

              <div>
                <ul>
                  <li className="flex gap-1 items-center mb-4 mt-2">
                    <p>
                      <SiVerizon className="text-emerald-500" />
                    </p>
                    <p>Articles & Stories</p>
                  </li>
                  <li className="text-emerald-700 font-bold my-2">
                    Be Our University Ambassador
                  </li>
                  <li>Must be a Registered Student</li>
                </ul>
              </div>
            </div>
            
          </div>
        </div> */}
        {/* footer  */}
        <div className="mt-[3em]">
          <p className="text-center">
            &copy; Copyright | All Rights Reserverd | iLocca
          </p>
        </div>
        {/*  */}
      </div>
    </div>
  );
};

export default Splash;
