import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DummyBizCategory, DummyToRentCategory } from "../Dummydata";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import axios from "../axios";
import imageCompression from "browser-image-compression";
import Hello from "../assets/hello.png";

const CreateToRent = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
      toast.warning("Please Login First");
    }
  }, [user, navigate]);

  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [firstPhoto, setFirstPhoto] = useState("");
  const [secPhoto, setSecPhoto] = useState("");
  const [thirdPhoto, setThirdPhoto] = useState("");
  const [availability, setAvailability] = useState("");
  const [searchTerms, setSearchTerms] = useState("");
  const [chargeRates, setChargeRates] = useState("");
  const [delivery, setDelivery] = useState("");

  // upload firstPhoto to cloudinary
  const [loadingFirstPhoto, setLoadingFirstPhoto] = useState(false);
  const postFirstPhoto = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingFirstPhoto(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      setLoadingFirstPhoto(true);
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingFirstPhoto(false);
      setFirstPhoto(urlData.url);
      toast.success("Uploaded The First Photo", { theme: "dark" });
    } catch (error) {
      setLoadingFirstPhoto(false);
      toast.error("Error uploading First Photo", { theme: "dark" });
    }
  };

  // upload secPhoto to cloudinary
  const [loadingSecPhoto, setLoadingSecPhoto] = useState(false);
  const postSecPhoto = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingSecPhoto(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingSecPhoto(false);
      setSecPhoto(urlData.url);
      toast.success("Uploaded The Sec Photo", { theme: "dark" });
    } catch (error) {
      setLoadingSecPhoto(false);
      toast.error("Error uploading Sec Photo", { theme: "dark" });
    }
  };

  // upload thirdPhoto to cloudinary
  const [loadingThirdPhoto, setLoadingThirdPhoto] = useState(false);
  const postThirdPhoto = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingThirdPhoto(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingThirdPhoto(false);
      setThirdPhoto(urlData.url);
      toast.success("Uploaded The Third Photo", { theme: "dark" });
    } catch (error) {
      setLoadingThirdPhoto(false);
      toast.error("Error uploading Third Photo", { theme: "dark" });
    }
  };

  // we don't want paid user to create more than 5 item to rent
  const [rentCount, setRentCount] = useState(0);
  const [loadCount, setLoadCount] = useState(false);
  const handleFetchRentCount = async () => {
    try {
      setLoadCount(true);
      let owner = user?.username;
      let dataToSend = { owner };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let response = await axios.post("/rent/counter", dataToSend, config);
      if (response) {
        console.log(response.data);
        setLoadCount(false);
        setRentCount(parseInt(response.data));
      }
    } catch (error) {
      navigate("/trade");
      setLoadCount(false);
      toast.warning("Error Validating");
    }
  };

  useEffect(() => {
    handleFetchRentCount();
  }, []);

  const [loading, setLoading] = useState(false);
  const [nowValidateTrade, setNowValidateTrade] = useState(false);
  const handleCreate = async (e) => {
    e.preventDefault();
    if (!title) return toast.error("title missing", { theme: "dark" });
    if (!category) return toast.error("category missing", { theme: "dark" });
    if (!description)
      return toast.error("description missing", { theme: "dark" });
    if (!firstPhoto)
      return toast.error("First Photo missing", { theme: "dark" });
    if (!secPhoto)
      return toast.error("Second Photo missing", { theme: "dark" });
    if (!thirdPhoto)
      return toast.error("Third Photo missing", { theme: "dark" });
    if (!delivery)
      return toast.error("Delivery Option missing", { theme: "dark" });
    if (!availability)
      return toast.error("availability missing", { theme: "dark" });
    if (!chargeRates)
      return toast.error("charging rates missing", { theme: "dark" });
    if (!searchTerms)
      return toast.error("searchTerms missing", { theme: "dark" });

    try {
      setLoading(true);
      let location = user?.location;
      let phone = user?.phone;
      let owner = user?.username;
      let ownerEmail = user?.email;
      let dataToSend = {
        title,
        category,
        description,
        location,
        firstPhoto,
        secPhoto,
        thirdPhoto,
        phone,
        owner,
        ownerEmail,
        chargeRates,
        delivery,
        availability,
        searchTerms,
      };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post("/rent/", dataToSend, config);
      if (response) {
        setLoading(false);
        toast.success("Added " + title, { theme: "dark" });
        navigate("/rent-out");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Adding Item", { theme: "dark" });
    }
  };

  return (
    <div>
      {loadCount ? (
        <>
          <div className="w-full h-[78vh] flex justify-center items-center">
            <Spinner message="Verifying Status ..." />
          </div>
        </>
      ) : (
        <>
          {/* wrapper */}
          <div className="px-[10px] pt-[10px]">
            {/* topbar */}
            <div>
              <div className="flex gap-4 items-center">
                <Link to="/rent-out">
                  <AiOutlineArrowLeft className="text-lg" />
                </Link>
                <div>
                  <h2 className="font-bold text-md text-zinc-300">
                    Add An Item To Rent
                  </h2>
                  <h2 className=" text-md text-zinc-500">
                    Available After Verification
                  </h2>
                </div>
              </div>
            </div>

            {nowValidateTrade ? (
              <>
                <div className=" px-[8px] sm:px-[2em] lg:px-[5em] py-[1em] mt-10">
                  <div className="h-[60vh] w-full flex flex-col justify-center ">
                    <div className="flex justify-center">
                      <div>
                        <div className="w-full flex justify-center mb-2">
                          <img src={Hello} alt="hello" />
                        </div>
                        <h2 className="mb-2 text-center">
                          Hello Thank You For Renting Out
                        </h2>
                        <h2 className="mb-2 text-center">
                          For safety and credibility, we will{" "}
                          <span className="text-emerald-300">verify</span> this
                          trade and get back to you.
                        </h2>
                        <h2 className="mb-2 text-center">
                          Verification process up to 48 hours
                        </h2>
                        <p className="pb-2 text-center">
                          We will email you when completed.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* you have 0 - 4 items */}
                {rentCount >= 0 && rentCount < 4 && (
                  <>
                    {/* form */}
                    <div className="mt-[2em] pb-[1em] w-full">
                      <form
                        className=" w-[98%] sm:w-[80%] lg:w-[50%] m-auto"
                        onSubmit={handleCreate}
                      >
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="title"
                            className=""
                            style={{ fontWeight: 600 }}
                          >
                            Name of Item To Rent Out
                          </label>
                          <input
                            type="text"
                            placeholder="title here"
                            id="title"
                            value={title}
                            required
                            minLength={2}
                            maxLength={80}
                            onChange={(e) => setTitle(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          />
                        </div>
                        {/* category */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="category"
                            className=""
                            style={{ fontWeight: 600 }}
                          >
                            Choose The Best Category
                          </label>

                          <select
                            name="category"
                            id="category"
                            className="p-[8px] bg-[#212121] border border-zinc-400 rounded-lg"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option value="">Choose</option>
                            {DummyToRentCategory.map((item) => (
                              <option key={item.id}>{item.title}</option>
                            ))}
                          </select>
                        </div>
                        {/* description */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="desc"
                            className=""
                            style={{ fontWeight: 600 }}
                          >
                            Please Add Brief Detailed Info
                          </label>
                          <textarea
                            name="desc"
                            id="desc"
                            cols="30"
                            rows="3"
                            minLength={5}
                            maxLength={500}
                            required
                            placeholder="what you do max(500)"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          ></textarea>
                        </div>
                        {/* charging rates */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="chargeRates"
                            className=""
                            style={{ fontWeight: 600 }}
                          >
                            Charging Rates per hour (Ksh.) ?
                          </label>

                          <textarea
                            name="chargeRates"
                            id="chargeRates"
                            cols="30"
                            rows="2"
                            minLength={5}
                            maxLength={500}
                            required
                            placeholder="Ksh. 200 per hour e.t.c, discounts ..."
                            value={chargeRates}
                            onChange={(e) => setChargeRates(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          ></textarea>
                        </div>
                        {/* upload first image */}
                        <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                          <div className="flex flex-col gap-2 mt-[20px]">
                            {loadingFirstPhoto ? (
                              <div className="w-full flex justify-center">
                                <Spinner message="uploading ..." />
                              </div>
                            ) : (
                              <>
                                <label
                                  htmlFor="firstPhoto"
                                  className="flex items-center gap-[20px] flex-wrap"
                                >
                                  <div className="flex flex-col gap-2">
                                    <p className="">Upload Item Photo </p>
                                    <p>Please Select First Photo</p>
                                  </div>
                                  <div className="flex flex-col items-center">
                                    <img
                                      src={
                                        firstPhoto
                                          ? firstPhoto
                                          : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                      }
                                      alt=""
                                      className="w-[100px] h-[100px] object-cover"
                                    />
                                  </div>
                                </label>
                                <input
                                  type="file"
                                  placeholder="Add Image"
                                  accept="image/*"
                                  onChange={(e) =>
                                    postFirstPhoto(e.target.files[0])
                                  }
                                  required
                                  id="firstPhoto"
                                  className="hidden"
                                />
                              </>
                            )}
                          </div>
                        </div>
                        {/* upload sec image */}
                        <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                          <div className="flex flex-col gap-2 mt-[20px]">
                            {loadingSecPhoto ? (
                              <div className="w-full flex justify-center">
                                <Spinner message="uploading ..." />
                              </div>
                            ) : (
                              <>
                                <label
                                  htmlFor="secPhoto"
                                  className="flex items-center gap-[20px] flex-wrap"
                                >
                                  <div className="flex flex-col gap-2">
                                    <p className="">Upload Item Photo </p>
                                    <p>Please Select Second Photo</p>
                                  </div>
                                  <div className="flex flex-col items-center">
                                    <img
                                      src={
                                        secPhoto
                                          ? secPhoto
                                          : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                      }
                                      alt=""
                                      className="w-[100px] h-[100px] object-cover"
                                    />
                                  </div>
                                </label>
                                <input
                                  type="file"
                                  placeholder="Add Image"
                                  accept="image/*"
                                  onChange={(e) =>
                                    postSecPhoto(e.target.files[0])
                                  }
                                  required
                                  id="secPhoto"
                                  className="hidden"
                                />
                              </>
                            )}
                          </div>
                        </div>
                        {/* upload third image */}
                        <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                          <div className="flex flex-col gap-2 mt-[20px]">
                            {loadingThirdPhoto ? (
                              <div className="w-full flex justify-center">
                                <Spinner message="uploading ..." />
                              </div>
                            ) : (
                              <>
                                <label
                                  htmlFor="thirdPhoto"
                                  className="flex items-center gap-[20px] flex-wrap"
                                >
                                  <div className="flex flex-col gap-2">
                                    <p className="">Upload Item Photo </p>
                                    <p>Please Select Third Photo</p>
                                  </div>
                                  <div className="flex flex-col items-center">
                                    <img
                                      src={
                                        thirdPhoto
                                          ? thirdPhoto
                                          : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                      }
                                      alt=""
                                      className="w-[100px] h-[100px] object-cover"
                                    />
                                  </div>
                                </label>
                                <input
                                  type="file"
                                  placeholder="Add Image"
                                  accept="image/*"
                                  onChange={(e) =>
                                    postThirdPhoto(e.target.files[0])
                                  }
                                  required
                                  id="thirdPhoto"
                                  className="hidden"
                                />
                              </>
                            )}
                          </div>
                        </div>
                        {/* specificAddress */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="delivery"
                            className=""
                            style={{ fontWeight: 600 }}
                          >
                            <p>Is delivery available?</p>
                          </label>
                          <input
                            type="text"
                            placeholder="enter delivery terms."
                            id="delivery"
                            value={delivery}
                            required
                            minLength={2}
                            maxLength={50}
                            onChange={(e) => setDelivery(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          />
                        </div>
                        {/* availability */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="availability"
                            className=""
                            style={{ fontWeight: 600 }}
                          >
                            <p>Enter Item / service Availability</p>
                            <p className="text-sm text-emerald-600"></p>
                          </label>
                          <input
                            type="text"
                            placeholder="time span available"
                            id="availability"
                            value={availability}
                            required
                            minLength={2}
                            maxLength={100}
                            onChange={(e) => setAvailability(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          />
                        </div>
                        {/* searchTerms */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="specificAdd"
                            className=""
                            style={{ fontWeight: 600 }}
                          >
                            <p>Search terms associated.</p>
                            <p className="text-sm text-emerald-600">
                              separate by comma (,)
                            </p>
                          </label>
                          <input
                            type="text"
                            placeholder="searchTerms separated by comma"
                            id="specificAdd"
                            value={searchTerms}
                            required
                            minLength={2}
                            maxLength={70}
                            onChange={(e) => setSearchTerms(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          />
                        </div>
                        <div className="mb-[14px]">
                          <h2 className="text-center font-bold mb-[10px] text-zinc-500">
                            IMPORTANT
                          </h2>
                          <p className="text-center">
                            {user.phone} is the phone number to be used.
                          </p>
                          <p className="text-center">
                            {user.location} will be used as your location
                          </p>
                          <p className="text-center">
                            You can change visibility later
                          </p>
                          {/* <Link to="/my-profile" className="text-center">
                            <div className="text-emerald-600 underline">
                              You Can Change Here
                            </div>
                          </Link> */}
                        </div>
                        {loading ||
                        loadingFirstPhoto ||
                        loadingSecPhoto ||
                        loadingThirdPhoto ? (
                          <div className="w-full">
                            <p className="text-center">Please wait 😊 ... </p>
                          </div>
                        ) : (
                          <button
                            className="bg-emerald-800 text-white w-full p-[8px] rounded-md"
                            onClick={handleCreate}
                          >
                            Add New To Rent
                          </button>
                        )}
                      </form>
                    </div>
                  </>
                )}

                {/* no creation at all. Limit reached */}
                {rentCount > 4 && (
                  <div>
                    <div className="h-[80vh] w-full flex justify-center items-center">
                      <div>
                        <div className="w-full flex justify-center">
                          <img src={Hello} alt="" />
                        </div>
                        <h2 className="text-xl  text-zinc-300 text-center">
                          Hold Up ✋. Limit Reached
                        </h2>
                        <p className="text-md text-zinc-300 text-center mb-3">
                          You can only create a maximum of 4 rentals at a time
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {/*  */}
          </div>
        </>
      )}
    </div>
  );
};

export default CreateToRent;
