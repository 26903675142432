import React, { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { DummyLocations } from "../Dummydata";
import { Link, useNavigate } from "react-router-dom";
import axios from "../axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../features/auth/authSlice";
import Spinner from "../components/Spinner";

const ChnageLocation = () => {
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handleChange = async (e) => {
  //   e.preventDefault();

  //   if (!location) {
  //     return toast.error("username missing");
  //   }

  //   try {
  //     setLoading(true);
  //     let userId = user?._id;
  //     let dataToSend = { location };
  //     let response = await axios.put(`/users/${userId}`, dataToSend);
  //     if (response) {
  //       setLoading(false);
  //       toast.success("updated");
  //       navigate("/home");
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error("Failed to update");
  //   }
  // };

  const handleChange = async (e) => {
    e.preventDefault();

    if (!location) {
      return toast.error("Location is missing");
    }

    try {
      setLoading(true);
      let userId = user?._id;

      let dataToSend = { location };

      // Make the PUT request to update the user's location
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let response = await axios.put(`/users/${userId}`, dataToSend, config);

      if (response) {
        setLoading(false);
        let username = user?.username;
        let newData = { username };
        dispatch(getUser(newData));
        // Update the user's location in local storage
        if (user) {
          // user.location = location;
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        toast.success("Location updated");
        navigate("/home");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to update location");
    }
  };

  return (
    <div>
      <div>
        <Link to="/home">
          <div className="flex items-center gap-2 px-3 pt-2">
            <AiOutlineArrowLeft />
            <p>BACK</p>
          </div>
        </Link>
      </div>
      {/*  */}
      <div className=" h-[65vh] w-full flex  items-center px-2">
        <div className="w-full">
          <div className="flex flex-col gap-[10px] mb-[22px] w-full">
            <label htmlFor="location" className="font-bold text-zinc-500">
              Update your location
            </label>
            <select
              name="location"
              id="location"
              className="bg-transparent border border-zinc-400 p-[8px] rounded-md outline-none"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            >
              <option value="">Choose</option>
              {DummyLocations.map((item) => (
                <option key={item.id}>{item.title}</option>
              ))}
            </select>
          </div>
          <div className="w-full">
            {loading ? (
              <Spinner message="updating" />
            ) : (
              <button
                className="w-full bg-emerald-800 text-white p-2 rounded-md"
                onClick={handleChange}
              >
                Update Now
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChnageLocation;
