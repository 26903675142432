import "./App.css";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import MyProfile from "./pages/MyProfile";
import UserProfile from "./pages/UserProfile";
import Notifications from "./pages/Notifications";
import Favorites from "./pages/Favorites";
import Businesses from "./pages/Businesses";
import SpecificBiz from "./pages/SpecificBiz";
import RentOut from "./pages/RentOut";
import Events from "./pages/Events";
import Trade from "./pages/Trade";
import Splash from "./pages/Splash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChnageLocation from "./pages/ChnageLocation";
import CreateBiz from "./pages/CreateBiz";
import EditBiz from "./components/edits/EditBiz";
import CreateToRent from "./pages/CreateToRent";
import EditToRent from "./components/edits/EditToRent";
import CreateTrade from "./pages/CreateTrade";
import EditTrade from "./components/edits/EditTrade";
import CreateEvent from "./pages/CreateEvent";
import Report from "./pages/Report";
import EditEvent from "./components/edits/EditEvent";
import Fununu from "./pages/Fununu";
// import About from "./pages/About";
import AddNewLocation from "./pages/AddNewLocation";
import SpecificEvent from "./pages/SpecificEvent";
import Terms from "./pages/Terms";
import Feedback from "./pages/Feedback";
import Test from "./pages/Test";
import Movies from "./pages/Movies";
import Innovate from "./pages/innovate/Innovate";
import SocialPage from "./pages/SocialPage";
import { useEffect } from "react";
import SpecificTrade from "./pages/SpecificTrade";
import Payment from "./pages/Payment";
import SpecificRental from "./pages/SpecificRental";
import SpecificPost from "./pages/SpecificPost";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "./axios";
import { logout } from "./features/auth/authSlice";
import Articles from "./pages/Articles";
import CreateArticle from "./pages/CreateArticle";

function App() {
  //function to connect to service worker
  const registerServiceWorker = async () => {
    let sw = await navigator.serviceWorker.register("./sw.js");
    // console.log(sw);
  };

  useEffect(() => {
    registerServiceWorker();
  }, []);

  //fetch last paid
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const handleLogout = async () => {
    let userId = user?._id;
    await dispatch(logout(userId));
  };

  const handleNeedToRenewAccount = async () => {
    try {
      let isPaid = false;
      let dataToSend = { isPaid };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put(
        `/users/${user?._id}`,
        dataToSend,
        config
      );
      if (response) {
        // toast.error("Please Relogin");
      } else {
        handleLogout();
      }
    } catch (error) {
      toast.error("Failed To Load Subscription", { theme: "warning" });
      handleLogout();
    }
  };

  //we only want user logged in for one day
  const handleGetLastLogin = () => {
    let lastLogin = localStorage.getItem("newLogIn");
    // Current time
    var currentTime = new Date().getTime();
    // Calculate the difference in milliseconds
    var timeDifference = currentTime - lastLogin;
    // Convert milliseconds to minutes
    var minutesPassed = Math.floor(timeDifference / (1000 * 60));

    if (minutesPassed > 1400) {
      toast.warning("Please re-login", { theme: "dark" });
      handleLogout();
    }
  };

  //  if last paid is > 31 days then set to not paid
  useEffect(() => {
    handleGetLastLogin();
    let timeSincePayment = new Date(user?.lastPaid);
    // Get Current time
    var currentTime = new Date();
    // Calculate the difference in milliseconds
    var timeDifference = currentTime - timeSincePayment;
    // Convert milliseconds to minutes
    var minutesPassed = Math.floor(timeDifference / (1000 * 60));
    // Convert minutes to hours
    var hoursPassed = Math.floor(minutesPassed / (1000 * 60));

    // console.log(hoursPassed);
    // console.log(minutesPassed);

    //if > 31 days that is 744
    if (minutesPassed > 44630) {
      console.log("last paid 31 days ago");
      // toast.warning("last paid 1 hour ago, recharge");
      handleNeedToRenewAccount();
    }
  }, [user]);

  return (
    <div className="bg-[#1E1C1C] text-[#fff] min-h-[100vh]">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Splash />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/article/create" element={<CreateArticle />} />
          <Route path="/home" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/business" element={<Businesses />} />
          <Route path="/create-biz" element={<CreateBiz />} />
          <Route path="/edit-biz/:id" element={<EditBiz />} />
          <Route path="/business/:id" element={<SpecificBiz />} />
          <Route path="/rent-out" element={<RentOut />} />
          <Route path="/rent-out/:id" element={<SpecificRental />} />
          <Route path="/create-rent-out" element={<CreateToRent />} />
          <Route path="/edit-to-rent/:id" element={<EditToRent />} />
          <Route path="/favorites" element={<Favorites />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:id" element={<SpecificEvent />} />
          <Route path="/edit-event/:id" element={<EditEvent />} />
          <Route path="/create-event" element={<CreateEvent />} />
          <Route path="/other-profile" element={<UserProfile />} />
          <Route path="/report" element={<Report />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/trade" element={<Trade />} />
          <Route path="/trade/:id" element={<SpecificTrade />} />
          <Route path="/create-trade" element={<CreateTrade />} />
          <Route path="/edit-trade/:id" element={<EditTrade />} />
          <Route path="/change-location" element={<ChnageLocation />} />
          <Route path="/social" element={<Fununu />} />
          <Route path="/social/:id" element={<SpecificPost />} />
          <Route path="/gold-subscription" element={<Payment />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/add-new-location" element={<AddNewLocation />} />
          <Route path="/test" element={<Test />} />
          <Route path="/movies" element={<Movies />} />
          <Route path="/innovate" element={<Innovate />} />
          <Route path="/social-profiles" element={<SocialPage />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
