import React, { useEffect, useState } from "react";
import {
  AiFillStar,
  AiOutlineArrowLeft,
  AiOutlineArrowUp,
  AiOutlineClose,
  AiOutlineComment,
  AiOutlineDislike,
  AiOutlineEye,
  AiOutlineLike,
  AiOutlinePhone,
  AiOutlineStar,
} from "react-icons/ai";
import { BsPerson, BsPersonCheck } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import { FcAddImage } from "react-icons/fc";

import { Link, useNavigate, useParams } from "react-router-dom";
import Comment from "../components/Comments";
import { useSelector } from "react-redux";
import axios from "../axios";
import { toast } from "react-toastify";
import moment from "moment";
import AddProduct from "../components/AddProduct";
import Spinner from "../components/Spinner";
import { MdCancel, MdDateRange, MdOutlineVerifiedUser } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa";

const SpecificEvent = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!user) {
  //     navigate("/login");
  //     toast.warning("Please Login");
  //   } else if (!user.username) {
  //     navigate("/login");
  //     toast.warning("Please Login");
  //   }
  // }, []);

  // fetch the event
  const { id } = useParams();
  const [singleEvent, setSingleEvent] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchEvent = async () => {
    try {
      setLoading(true);
      let checkParam = id;
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get("/event/" + checkParam, config);
      if (response) {
        setLoading(false);
        setSingleEvent([response.data]);
        console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Event.");
      toast.error("Network error or doesn't exist");
    }
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  // like a event
  const handleLikeEvent = async (event) => {
    try {
      if (!user) {
        toast.error("Please Login To leave a like", { theme: "dark" });
        return;
      }

      let username = user?.username;
      let id = event._id;
      let likeData = { username };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.post("/event/like/" + id, likeData, config);
      if (res) {
        // notification
        if (user?.username !== event.owner) {
          // create notification
          let sender = user.username;
          let receiver = event.owner;
          let receiverEmail = event.ownerEmail;
          let message = `${user?.username} from ${user?.location} Liked Your Event ${event.title} 😃`;
          let referID = `/events/${id}`;
          let notifyData = {
            sender,
            receiver,
            receiverEmail,
            message,
            referID,
          };

          await axios.post("/notify/create", notifyData, config);
        }

        toast.success("Action Succesful");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed To Like");
    }
  };

  // attend event
  const [attendLoad, setAttendLoading] = useState(false);
  const handleAttend = async (event) => {
    try {
      if (!user) {
        toast.error("Please Login", { theme: "dark" });
        return;
      }

      setAttendLoading(true);

      let username = user?.username;
      let phone = user?.phone;
      let id = event._id;
      let likeData = { username, phone };

      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post("/event/attend/" + id, likeData, config);

      setAttendLoading(false);

      //   notification
      if (user?.username !== event.owner) {
        // create notification
        let sender = user.username;
        let receiver = event.owner;
        let receiverEmail = event.ownerEmail;
        let message = `${user?.username} from ${user?.location} wants to attend ${event.title} 😃`;
        let referID = `/events/${event._id}`;
        let notifyData = { sender, receiver, receiverEmail, message, referID };

        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios.post("/notify/create", notifyData, config);
        setAttendLoading(false);
      }
      setAttendLoading(false);
      window.location.reload();
    } catch (error) {
      setAttendLoading(false);
      toast.error("Failed To Add", { theme: "dark" });
    }
  };

  // scroll to top functionality
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCall = async (item) => {
    try {
      if (user && user?.username !== item.owner) {
        let caller = user?.username;
        let receiver = item.owner;
        let receiverEmail = item.ownerEmail;
        let dataToSend = { caller, receiver };
        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios.post("/call/create", dataToSend, config);

        // create notification
        let sender = user.username;
        let message = `${caller} called your event : ${item.title}. phone number is ${user?.phone}`;
        let referID = `/events/${item._id}`;
        let notifyData = { sender, receiver, receiverEmail, message, referID };

        try {
          let token = user?.token;
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await axios.post(
            "/notify/create",
            notifyData,
            config
          );

          if (response) {
            console.log("call sent successfully");
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // working on modal
  const [selectedPost, setSelectedPost] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  // what happens when we click on a post
  const handlePostClick = (post) => {
    setSelectedPost(post);
    setIsPopUpOpen(true);
  };

  const PopUpPage = ({ post, onClose }) => {
    return (
      <div className="pop-up-page hide-scrollbar ">
        {console.log(post)}
        <div className="flex justify-center mb-[20px] mt-[50px] z-[999] hide-scrollbar">
          <button onClick={onClose}>
            <AiOutlineClose
              className="text-5xl text-white p-[10px] rounded-full "
              style={{
                border: "2px solid #188c56",
                position: "sticky",
                top: "60px",
              }}
              title="close"
            />
          </button>
        </div>
        {/* close btn */}
        {post.map((item) => (
          <>
            {/* data */}
            <div className="prompt mb-14">
              <p className="mb-4">
                Username :{" "}
                <span className="text-emerald-500">{item.username}</span>
              </p>

              {item.phone && (
                <a
                  href={`tel:+${item.phone}`}
                  className="flex gap-2 items-center hover:text-emerald-500"
                >
                  <AiOutlinePhone className="text-2xl" />
                  {item.phone}
                </a>
              )}
            </div>
          </>
        ))}
      </div>
    );
  };

  return (
    <div>
      {/*  */}
      <div>
        {/* arrow to scroll to top */}
        {showArrow && (
          <div
            className="fixed bottom-20 right-4 text-3xl z-[999] cursor-pointer bg-teal-700 text-zinc-50 rounded-full p-[5px]"
            onClick={handleScrollTop}
          >
            <AiOutlineArrowUp />
          </div>
        )}
        {/* wrapper */}
        <div className=" px-[8px] sm:px-[2em] lg:px-[4em] pt-2">
          {/* topbar */}
          <div>
            <AiOutlineArrowLeft
              className="text-2xl"
              onClick={() => navigate("/events")}
            />
          </div>
          {/* data */}
          {loading ? (
            <>
              <div className="h-[80vh] w-full flex justify-between items-center">
                <Spinner message="Fetching Event Details" />
              </div>
            </>
          ) : (
            <>
              <div className=" mt-[15px] ">
                {singleEvent?.map((item) => (
                  <div key={item._id}>
                    <div className="flex flex-col md:flex-row gap-[20px]  justify-between">
                      {/* image side */}
                      <div className="flex-[0.5%]">
                        <img
                          src={item.photo}
                          alt=""
                          className="  sm:max-h-[500px] lg:max-h-[550px] w-full object-contain rounded-lg"
                        />
                      </div>
                      {/* {console.log(item)} */}
                      {/* text side */}
                      <div className="flex-[0.5%]">
                        <div className="mb-[10px] flex justify-between items-center">
                          <p className="text-xl font-bold">{item.title}</p>
                        </div>

                        <div className="mb-[30px]">
                          <Link
                            to={
                              item.owner == user?.username
                                ? "/my-profile"
                                : `/other-profile?owner=${item.owner}`
                            }
                          >
                            <div className="flex items-center gap-[20px]">
                              <p
                                className={`w-8 h-8 flex items-center justify-center rounded-full bg-emerald-800 text-white`}
                              >
                                {item.owner.substring(0, 1)}
                              </p>

                              <p className=" text-sm text-teal-600">
                                Created {moment(item.createdAt).fromNow()}
                              </p>
                            </div>
                          </Link>
                        </div>

                        <div className="flex justify-between items-center mb-4 ">
                          <div>
                            {item.approved == "yes" ? (
                              <div className="flex items-center gap-2 text-emerald-400">
                                <p>
                                  <MdOutlineVerifiedUser />
                                </p>
                                <p>approved</p>
                              </div>
                            ) : (
                              <div className="flex items-center gap-2 text-red-400">
                                <p>
                                  <MdCancel />
                                </p>
                                <p>Not - approved</p>
                              </div>
                            )}
                          </div>
                          <div>
                            {item?.owner == user?.username && (
                              <div>
                                <h2
                                  onClick={() => {
                                    handlePostClick(item.attending);
                                  }}
                                  className="cursor-pointer text-end text-emerald-500"
                                >
                                  See All Attending ?
                                </h2>
                              </div>
                            )}
                          </div>
                        </div>

                        <div
                          className="flex gap-2 items-center mb-[10px] bg-emerald-800 text-white p-1 justify-center rounded-lg"
                          onClick={() => handleCall(item)}
                        >
                          <AiOutlinePhone className="text-xl text-white" />
                          <a href={`tel:${item.phone}`} className="">
                            Call now {item.phone}
                          </a>
                        </div>

                        <p className="mb-[15px]">{item.description}</p>

                        <div className="mb-[15px]">
                          <div className="flex items-center gap-[6px] mb-2">
                            <FaDollarSign className="text-2xl text-zinc-600" />
                            <h2 className="font-bold text-zinc-500 ">
                              Charges
                            </h2>
                          </div>
                          <p>{item.prices}</p>
                        </div>

                        <div className="mb-[15px]">
                          <div className="flex items-center gap-[6px] mb-2">
                            <MdDateRange className="text-2xl text-zinc-600" />
                            <h2 className="font-bold text-zinc-500 ">
                              Date And Time
                            </h2>
                          </div>

                          <p>{item.timeAndDate}</p>
                        </div>

                        <div className="mb-[15px]">
                          <div className="flex items-center gap-[6px] mb-2">
                            <CiLocationOn className="text-2xl text-zinc-600" />
                            <h2 className="font-bold text-zinc-500 ">
                              Specific Location
                            </h2>
                          </div>
                          <p>{item.specificAddress}</p>
                        </div>

                        {/* options with user */}
                        {user ? (
                          <>
                            <div className="mt-[30px] flex justify-between w-full gap-[10px] flex-wrap">
                              <div className="flex  flex-col gap-[20px] justify-start">
                                <div
                                  className="flex gap-2 items-center"
                                  onClick={() => handleAttend(item)}
                                >
                                  <BsPerson className="text-xl text-emerald-700" />
                                  {item.attending.length === 1
                                    ? `${item.attending.length} attending`
                                    : `${item.attending.length} attending`}
                                </div>
                                <div
                                  className="flex gap-2 items-center"
                                  onClick={() => handleLikeEvent(item)}
                                >
                                  <AiOutlineLike className="text-xl text-emerald-700" />
                                  {item.likes.length === 1
                                    ? `${item.likes.length} like`
                                    : `${item.likes.length} likes`}
                                </div>
                                <div className="flex gap-2 items-center">
                                  <AiOutlineEye className="text-xl text-emerald-700" />
                                  {item.views.length === 1
                                    ? `${item.views.length} view`
                                    : `${item.views.length} views`}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="mt-[30px] flex justify-between w-full gap-[10px] flex-wrap">
                              <div className="flex  flex-col gap-[20px] justify-start">
                                <div
                                  className="flex gap-2 items-center"
                                  // onClick={() => handleAttend(item)}
                                >
                                  <BsPerson className="text-xl text-emerald-700" />
                                  {item.attending.length === 1
                                    ? `${item.attending.length} attending`
                                    : `${item.attending.length} attending`}
                                </div>
                                <div
                                  className="flex gap-2 items-center"
                                  // onClick={() => handleLikeEvent(item)}
                                >
                                  <AiOutlineLike className="text-xl text-emerald-700" />
                                  {item.likes.length === 1
                                    ? `${item.likes.length} like`
                                    : `${item.likes.length} likes`}
                                </div>
                                <div className="flex gap-2 items-center">
                                  <AiOutlineEye className="text-xl text-emerald-700" />
                                  {item.views.length === 1
                                    ? `${item.views.length} view`
                                    : `${item.views.length} views`}
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {user ? (
                          <>
                            <div className="mt-[22px] pb-[10px] w-full flex justify-center">
                              {attendLoad ? (
                                <>
                                  <Spinner message="please wait" />
                                </>
                              ) : (
                                <>
                                  <button
                                    className="bg-teal-800 text-white p-[8px] rounded-lg"
                                    onClick={() => handleAttend(item)}
                                  >
                                    CONFIRM ATTENDING ?
                                  </button>
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="mt-[22px] pb-[10px] w-full flex justify-center">
                              <Link to="/login" className="underline">
                                PLEASE LOGIN TO ATTEND
                              </Link>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      {/*  */}
      {/* pop up screen */}
      {isPopUpOpen && (
        <div className="pop-up-overlay">
          <PopUpPage
            post={selectedPost}
            onClose={() => setIsPopUpOpen(false)}
          />
        </div>
      )}
    </div>
  );
};

export default SpecificEvent;
