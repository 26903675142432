import React, { useEffect, useState } from "react";
import {
  AiFillStar,
  AiOutlineArrowLeft,
  AiOutlineArrowUp,
  AiOutlineComment,
  AiOutlineDislike,
  AiOutlineEye,
  AiOutlineLike,
  AiOutlinePhone,
  AiOutlineStar,
} from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import { Link, useNavigate, useParams } from "react-router-dom";
import Comment from "../components/Comments";
import { useSelector } from "react-redux";
import axios from "../axios";
import { toast } from "react-toastify";
import moment from "moment";
import AddProduct from "../components/AddProduct";
import Spinner from "../components/Spinner";
import {
  MdAssistantDirection,
  MdCancel,
  MdOutlineVerifiedUser,
} from "react-icons/md";
import Recommended from "../components/Recommended";
import { Helmet } from "react-helmet";
import { appBaseUrl } from "../constants";
import { IoShareSocialOutline } from "react-icons/io5";

const SpecificBiz = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // fetch the biz
  const { id } = useParams();
  const [singleBiz, setSingleBiz] = useState([]);
  const [singleBizAvgRating, setSingleBizAvgRating] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchBiz = async () => {
    try {
      setLoading(true);
      let checkParam = id;
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get("/biz/" + checkParam, config);
      if (response) {
        setLoading(false);
        setSingleBiz([response.data.biz]);
        // console.log(response.data.biz);
        setSingleBizAvgRating(response.data.averageRating);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Biz.");
      toast.error("Network error or doesn't exist");
    }
  };

  useEffect(() => {
    fetchBiz();
  }, []);

  // like biz
  const handleLikeBiz = async (biz) => {
    try {
      if (!user) {
        toast.error("Please Login To leave a like", { theme: "dark" });
        return;
      }

      let username = user?.username;
      let id = biz._id;
      let likeData = { username };

      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post("/biz/like/" + id, likeData, config);
      toast.success("Like Added", { theme: "dark" });

      if (user?.username !== biz.owner) {
        // create notification
        let sender = user?.username;
        let receiver = biz.owner;
        let receiverEmail = biz.ownerEmail;
        let message = `Your business ${biz.title} Got a Like 👍 From ${user?.username} in ${user?.location}`;
        let referID = `/business/${id}`;
        let notifyData = { sender, receiver, receiverEmail, message, referID };

        await axios.post("/notify/create", notifyData, config);
      }

      window.location.reload();
    } catch (error) {
      toast.error("Failed To Like");
    }
  };

  // dislike biz
  const handleDisLikeBiz = async (biz) => {
    try {
      if (!user) {
        toast.error("Please Login To dislike", { theme: "dark" });
        return;
      }

      let username = user?.username;
      let id = biz._id;
      let likeData = { username };

      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post("/biz/dislike/" + id, likeData, config);
      toast.success("Dislike Added", { theme: "dark" });
      if (user?.username !== biz.owner) {
        // create notification
        let sender = user?.username;
        let receiver = biz.owner;
        let receiverEmail = biz.ownerEmail;
        let message = `Your business ${biz.title} Got a Dislike 👎 From ${user?.username} from ${user?.location}`;
        let referID = `/business/${id}`;
        let notifyData = { sender, receiver, receiverEmail, message, referID };

        // await axios.post("/notify/create", notifyData, config);
      }
      window.location.reload();
    } catch (error) {
      toast.error("Failed To Dislike");
    }
  };

  // rate biz
  const [rating, setRating] = useState(1);

  const handleRateBiz = async (e) => {
    e.preventDefault();
    try {
      if (!rating) {
        toast.error("Rating cannot be empty", { theme: "dark" });
        return;
      }
      let username = user?.username;
      let ratingData = { username, rating };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.post("/biz/rate/" + id, ratingData, config);

      window.location.reload();
    } catch (error) {
      toast.error("Already Rated");
    }
  };

  // scroll to top functionality
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCall = async (item) => {
    try {
      if (user && user?.username !== item.owner) {
        let caller = user?.username;
        let receiver = item.owner;
        let receiverEmail = item.ownerEmail;
        let dataToSend = { caller, receiver };
        // console.log(dataToSend);
        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios.post("/call/create", dataToSend, config);

        // create notification
        let sender = user?.username;
        let message = `${caller} called your Businesses : ${item.title}. phone number is ${user?.phone}`;
        let referID = `/business/${item._id}`;
        let notifyData = { sender, receiver, receiverEmail, message, referID };

        try {
          let token = user?.token;
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await axios.post(
            "/notify/create",
            notifyData,
            config
          );

          if (response) {
            console.log("call sent successfully");
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handle hard code avgRating for gen feed
  // const [toUpdateID, setToUpdateID] = useState("");
  const handleUpdateAvgRating = async (newRating, id) => {
    const calcAvgRate = newRating;
    const dataToSend = { calcAvgRate };

    if (id) {
      try {
        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.put("/biz/" + id, dataToSend, config);
        if (response) {
          console.log("updated rating");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("no ID");
    }
  };

  useEffect(() => {
    if (singleBizAvgRating) {
      singleBiz?.map((item) =>
        handleUpdateAvgRating(singleBizAvgRating, item._id)
      );
    }
  }, [singleBizAvgRating, setSingleBizAvgRating, setRating, navigate]);

  const handleCopyToClipboard = async (textToCopy) => {
    try {
      // Get the text from your input or any other source
      // You can replace this with your dynamic input

      // Use the Clipboard API to write the text to the clipboard
      await navigator.clipboard.writeText(textToCopy);

      // Log success or update UI accordingly
      console.log("Text copied to clipboard:", textToCopy);
      toast.success("Copied The Link. Share", { theme: "dark" });
    } catch (err) {
      // Handle errors
      console.error("Error copying to clipboard:", err);
    }
  };

  return (
    <div>
      {/* arrow to scroll to top */}
      {showArrow && (
        <div
          className="fixed bottom-20 right-4 text-3xl z-[999] cursor-pointer bg-teal-700 text-zinc-50 rounded-full p-[5px]"
          onClick={handleScrollTop}
        >
          <AiOutlineArrowUp />
        </div>
      )}
      {/* wrapper */}
      <div className=" px-[8px] sm:px-[2em] lg:px-[4em] pt-2">
        {/* topbar */}
        <div>
          {user ? (
            <Link to="/business">
              <AiOutlineArrowLeft className="text-2xl" />
            </Link>
          ) : (
            <>
              <Link to="/">GET STARTED</Link>
            </>
          )}
        </div>
        {/* data */}
        {loading ? (
          <>
            <div className="h-[80vh] w-full flex justify-between items-center">
              <Spinner message="Fetching Biz Details" />
            </div>
          </>
        ) : (
          <>
            <div className=" mt-[15px] ">
              {singleBiz?.map((item) => (
                <div key={item._id}>
                  <div className="flex flex-col lg:flex-row gap-[20px]  justify-between">
                    {/* image side */}
                    <div className="flex-[0.5%]">
                      <img
                        src={item.photo}
                        alt=""
                        className=" max-h-[85vh] w-full object-contain rounded-lg"
                      />
                    </div>

                    {/* {console.log(item)} */}
                    <Helmet>
                      <title>
                        {" "}
                        {item.title} | {item.owner} | {item.location} | iLocca
                      </title>
                      <meta
                        name="description"
                        content={`${item.username} owns ${item.title}. Check Out His Local Business Today In ${item.location}`}
                      />
                    </Helmet>

                    {/* text side */}
                    <div className="flex-[0.5%]">
                      {/* share */}
                      <div className="flex justify-center gap-[20px] mb-4  ">
                        <button
                          onClick={() =>
                            handleCopyToClipboard(
                              ` ${appBaseUrl}business/${item._id}`
                            )
                          }
                          className="flex justify-center gap-[20px] border border-emerald-400 rounded-lg px-4 py-1  "
                        >
                          <IoShareSocialOutline
                            className="text-2xl text-emerald-400"
                            title="Click To Copy"
                          />
                          <p>Share With Friends</p>
                        </button>
                      </div>
                      {/* owner info */}
                      <div className="mb-[20px]">
                        <div className="mb-[10px] flex justify-between items-center">
                          <p className="text-xl font-bold text-zinc-300">
                            {item.title}
                          </p>
                          <p className="text-emerald-600">#{item.category}</p>
                        </div>

                        <div className="flex justify-between items-center mb-6 ">
                          <div>
                            {item.approved == "yes" ? (
                              <div className="flex items-center gap-2 text-emerald-400">
                                <p>
                                  <MdOutlineVerifiedUser />
                                </p>
                                <p>approved</p>
                              </div>
                            ) : (
                              <div className="flex items-center gap-2 text-red-400">
                                <p>
                                  <MdCancel />
                                </p>
                                <p>Not - approved</p>
                              </div>
                            )}
                          </div>
                          <div>
                            <Link
                              to={
                                item.owner == user?.username
                                  ? "/my-profile"
                                  : `/other-profile?owner=${item.owner}`
                              }
                            >
                              <p className="underline text-zinc-300">
                                Owner :{" "}
                                {item.owner == user?.username
                                  ? "you"
                                  : item.owner.substring(0, 12)}
                              </p>
                            </Link>
                          </div>
                        </div>

                        <a
                          href={`tel:${item.phone}`}
                          className=" mt-[18px] bg-emerald-800 text-white p-2 rounded-lg w-full flex  gap-2 justify-center"
                          onClick={() => handleCall(item)}
                        >
                          <span>
                            <AiOutlinePhone className="text-xl" />
                          </span>
                          <span>{item.phone}(Call Now)</span>
                        </a>

                        {item.googleMaps !== "" && (
                          <a
                            href={item.googleMaps}
                            target="_blank"
                            rel="noopener noreferrer"
                            className=" mt-[18px] bg-emerald-800 text-white p-2 rounded-lg w-full flex  gap-2 justify-center"
                          >
                            <span>
                              <MdAssistantDirection className="text-xl" />
                            </span>
                            <a href={item.googleMaps}>Directions ?</a>
                          </a>
                        )}
                      </div>

                      {/* display rating */}
                      <div className="flex items-center justify-between gap-[10px] flex-wrap mt-[1.1em] mb-[2em]">
                        <div>
                          <p>
                            {singleBizAvgRating < 1 && (
                              <div className="flex items-center gap-1">
                                <AiFillStar className="text-3xl text-yellow-400" />
                                <AiOutlineStar className="text-3xl text-yellow-400" />
                                <AiOutlineStar className="text-3xl text-yellow-400" />
                                <AiOutlineStar className="text-3xl text-yellow-400" />
                                <AiOutlineStar className="text-3xl text-yellow-400" />
                              </div>
                            )}
                          </p>
                          <p>
                            {singleBizAvgRating >= 1 &&
                              singleBizAvgRating <= 2 && (
                                <div className="flex items-center gap-1">
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                  <AiOutlineStar className="text-3xl text-yellow-400" />
                                  <AiOutlineStar className="text-3xl text-yellow-400" />
                                  <AiOutlineStar className="text-3xl text-yellow-400" />
                                </div>
                              )}
                          </p>
                          <p>
                            {singleBizAvgRating > 2 &&
                              singleBizAvgRating <= 3 && (
                                <div className="flex items-center gap-1">
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                  <AiOutlineStar className="text-3xl text-yellow-400" />
                                  <AiOutlineStar className="text-3xl text-yellow-400" />
                                </div>
                              )}
                          </p>
                          <p>
                            {singleBizAvgRating > 3 &&
                              singleBizAvgRating <= 4 && (
                                <div className="flex items-center gap-1">
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                  <AiOutlineStar className="text-3xl text-yellow-400" />
                                </div>
                              )}
                          </p>
                          <p>
                            {singleBizAvgRating > 4 &&
                              singleBizAvgRating <= 5 && (
                                <div className="flex items-center gap-1">
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                  <AiFillStar className="text-3xl text-yellow-400" />
                                </div>
                              )}
                          </p>
                        </div>
                      </div>

                      {/* options */}
                      {item.owner == user?.username && (
                        <p className="mb-[10px] text-zinc-400">
                          Only you can see dislikes
                        </p>
                      )}
                      {user ? (
                        <div className="flex justify-between">
                          <div className="flex items-center gap-3 cursor-pointer">
                            <AiOutlineEye className="text-xl" />
                            <p>{item.views.length}</p>
                            {/* {console.log(item)} */}
                          </div>
                          <div
                            className="flex items-center gap-3 cursor-pointer"
                            onClick={() => handleLikeBiz(item)}
                          >
                            <AiOutlineLike className="text-xl" />
                            <p>{item.likes.length}</p>
                          </div>
                          <div
                            className="flex items-center gap-3 cursor-pointer"
                            onClick={() => handleDisLikeBiz(item)}
                          >
                            <AiOutlineDislike className="text-xl" />
                            {item.owner == user?.username && (
                              <p>{item.dislikes.length}</p>
                            )}
                          </div>
                          <div className="flex items-center gap-3">
                            <AiOutlineComment className="text-xl" />
                            <p>{item.comments.length}</p>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="flex justify-between">
                            <div className="flex items-center gap-3 cursor-pointer">
                              <AiOutlineEye className="text-xl" />
                              <p>{item.views.length}</p>
                              {/* {console.log(item)} */}
                            </div>
                            <div
                              className="flex items-center gap-3 cursor-pointer"
                              // onClick={() => handleLikeBiz(item)}
                            >
                              <AiOutlineLike className="text-xl" />
                              <p>{item.likes.length}</p>
                            </div>
                            <div
                              className="flex items-center gap-3 cursor-pointer"
                              // onClick={() => handleDisLikeBiz(item)}
                            >
                              <AiOutlineDislike className="text-xl" />
                              {/* <p>{item.dislikes.length}</p> */}
                            </div>
                            <div className="flex items-center gap-3">
                              <AiOutlineComment className="text-xl" />
                              <p>{item.comments.length}</p>
                            </div>
                          </div>
                        </>
                      )}

                      {/* rate biz */}
                      {user && (
                        <div className="flex items-center gap-3 my-[1em]">
                          <form className="w-full" onSubmit={handleRateBiz}>
                            <div className="flex flex-col gap-[10px] w-full ">
                              <label
                                htmlFor="rate"
                                className="font-bold text-xl text-zinc-500"
                              >
                                Rate Now
                              </label>
                              <select
                                name="rate"
                                id="option"
                                value={rating}
                                onChange={(e) => setRating(e.target.value)}
                                required
                                className="bg-[#1E1C1C] text-white w-full  border border-zinc-400 p-2 rounded-lg outline-none"
                              >
                                <option value="">Choose</option>
                                <option value={1}>Poor</option>
                                <option value={2}>Fair</option>
                                <option value={3}>Average</option>
                                <option value={4}>Good</option>
                                <option value={5}>Really Good</option>
                              </select>
                              <button
                                className="border-2 border-emerald-800 text-zinc-200 p-2 rounded-lg"
                                onClick={handleRateBiz}
                              >
                                Rate {item.title}
                              </button>
                            </div>
                          </form>
                        </div>
                      )}

                      <p className="my-[15px] text-zinc-300">
                        {item.description}
                      </p>

                      <p className="mb-[15px] text-sm text-teal-600">
                        Joined {moment(item.createdAt).fromNow()}
                      </p>

                      <div>
                        <h2 className="font-bold text-zinc-300 mb-2">
                          Price Rates
                        </h2>
                        <p className="mb-[15px] text-zinc-300">
                          {item.chargeRates}
                        </p>
                      </div>

                      <div className="flex justify-between items-center mb-[15px]">
                        <div className="flex items-center gap-[6px]">
                          <CiLocationOn className="text-2xl" />
                          <div>
                            <p className="text-zinc-300 mb-2">
                              {item.location}
                            </p>
                            <p className="text-zinc-300">
                              {item.specificAddress}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="mb-[20px]">
                        <h2 className="mb-[8px] text-zinc-300">
                          We are open from
                        </h2>
                        <p className="text-zinc-300">{item.availability}</p>
                      </div>

                      {/* comments*/}
                      <div>
                        {user ? (
                          <Comment item={item} />
                        ) : (
                          <div>
                            <Link to="/login">
                              <p className="text-emerald-700 font-bold">
                                LOGIN TO ACCESS COMMENTS
                              </p>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* products */}
                  <div>{user && <AddProduct item={item} />}</div>
                  {/* recommended */}
                  <div className="mt-[3em]">
                    <div className=" flex justify-between items-center">
                      <h1 className="text-zinc-400 text-2xl font-semibold">
                        Recommended
                      </h1>
                    </div>
                    {/* <Recommended ownerUsername={item.owner} category={""} />
                    <div className="my-[1em]" /> */}
                    <Recommended
                      ownerUsername={""}
                      category={item.category}
                      productID={item._id}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SpecificBiz;
