import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DummyBizCategory } from "../Dummydata";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import axios from "../axios";
import Hello from "../assets/hello.png";
import imageCompression from "browser-image-compression";

const CreateEvent = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  if (!user) {
    navigate("/login");
    toast.warning("Please Login", { theme: "dark" });
  }

  const [eventCount, setEventCount] = useState(0);
  const [loadCount, setLoadCount] = useState(false);
  const handleFetchEventCount = async () => {
    try {
      setLoadCount(true);
      let owner = user?.username;
      let dataToSend = { owner };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let response = await axios.post("/event/count", dataToSend, config);
      if (response) {
        console.log(response.data);
        setLoadCount(false);
        setEventCount(response.data);
      }
    } catch (error) {
      navigate("/events");
      setLoadCount(false);
      toast.warning("Could not validate count");
    }
  };

  useEffect(() => {
    handleFetchEventCount();
  }, []);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photo, setPhoto] = useState("");
  const [specificAddress, setSpecificAddress] = useState("");
  const [timeAndDate, setTimeAndDate] = useState("");
  const [searchTerms, setSearchTerms] = useState("");
  const [prices, setPrices] = useState("");
  const [visibleToAllCampuses, setVisibileToAllCampuses] = useState("nope");

  // upload photo to cloudinary
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const postPhoto = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo", { theme: "dark" });
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingPhoto(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingPhoto(false);
      setPhoto(urlData.url);
      toast.success("Uploaded Photo", { theme: "dark" });
    } catch (error) {
      setLoadingPhoto(false);
      toast.error("Error uploading Photo", { theme: "dark" });
    }
  };

  const [loading, setLoading] = useState(false);
  const [nowValidateEvent, setNowValidateEvent] = useState(false);

  const handleCreate = async (e) => {
    e.preventDefault();
    if (!title) return toast.error("title missing", { theme: "dark" });
    if (!prices) return toast.error("Prices Info missing", { theme: "dark" });
    if (!description)
      return toast.error("description missing", { theme: "dark" });
    if (!photo) return toast.error("photo missing", { theme: "dark" });
    if (!specificAddress)
      return toast.error("specific Address missing", { theme: "dark" });
    if (!timeAndDate)
      return toast.error("time and date missing", { theme: "dark" });
    if (!searchTerms)
      return toast.error("searchTerms missing", { theme: "dark" });
    if (!visibleToAllCampuses)
      return toast.error("select visibility", { theme: "dark" });

    try {
      setLoading(true);
      let location = user?.location;
      let phone = user?.phone;
      let owner = user?.username;
      let ownerEmail = user?.email;
      if (!ownerEmail || ownerEmail == "")
        return toast.error("Please re-login, we can't find your email", {
          theme: "dark",
        });

      let dataToSend = {
        title,
        location,
        photo,
        phone,
        owner,
        ownerEmail,
        description,
        prices,
        timeAndDate,
        specificAddress,
        searchTerms,
        visibleToAllCampuses,
      };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post("/event/", dataToSend, config);
      if (response) {
        setLoading(false);
        toast.success("Created " + title, { theme: "dark" });
        navigate("/events");
        // setNowValidateEvent(true);
      }
    } catch (error) {
      setLoading(false);
      setNowValidateEvent(false);
      toast.error("Error creating Event", { theme: "dark" });
    }
  };

  return (
    <div>
      {loadCount ? (
        <>
          <div className="w-full h-[78vh] flex justify-center items-center">
            <Spinner message="Verifying Status ..." />
          </div>
        </>
      ) : (
        <>
          {/* allow creation of event */}
          {eventCount === 0 && (
            <>
              {nowValidateEvent ? (
                <div>
                  <div className=" px-[8px] sm:px-[2em] lg:px-[5em] py-[1em]">
                    <div>
                      <Link to="/events">
                        <AiOutlineArrowLeft className="text-2xl font-bold" />
                      </Link>
                    </div>
                    <div className="h-[60vh] w-full flex flex-col justify-center ">
                      <div className="flex justify-center">
                        <div>
                          <div className="w-full flex justify-center mb-2">
                            <img src={Hello} alt="hello" />
                          </div>
                          <h2 className="mb-2 text-center">
                            Hello Thank You For Adding An Event In{" "}
                            {user?.location}
                          </h2>
                          <h2 className="mb-2 text-center">
                            For safety and credibility, we will{" "}
                            <span className="text-emerald-300">verify</span>{" "}
                            this event and get back to you.
                          </h2>
                          <h2 className="mb-2 text-center">
                            Verification process up to 48 hours
                          </h2>
                          <p className="pb-2 text-center">
                            We will text you using 0798 556471 when completed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="px-[10px] pt-[10px]">
                  {/* topbar */}
                  <div>
                    <div className="flex gap-4 items-center">
                      <Link to="/events">
                        <AiOutlineArrowLeft className="text-2xl" />
                      </Link>
                      <div>
                        <h2 className="font-bold text-md text-zinc-300">
                          Add New Event
                        </h2>
                        <Link to="/my-profile">
                          <p className="text-sm underline text-emerald-600">
                            Your Location : {user?.location}
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* form */}
                  <div className="mt-[2em] pb-[1em] w-full">
                    <form
                      className=" w-[98%] sm:w-[80%] lg:w-[50%] m-auto"
                      onSubmit={handleCreate}
                    >
                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="title"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          Name of The Event
                        </label>
                        <input
                          type="text"
                          placeholder="title here"
                          id="title"
                          value={title}
                          required
                          minLength={2}
                          maxLength={60}
                          onChange={(e) => setTitle(e.target.value)}
                          className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                        />
                      </div>
                      {/* description */}
                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="desc"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          Briefly Describe The Event
                        </label>
                        <textarea
                          name="desc"
                          id="desc"
                          cols="30"
                          rows="3"
                          minLength={5}
                          maxLength={500}
                          required
                          placeholder="event description (500)"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                        ></textarea>
                      </div>

                      {/* charging rates */}
                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="chargeRates"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          What Are Your Charging Rates
                        </label>

                        <textarea
                          name="chargeRates"
                          id="chargeRates"
                          cols="30"
                          rows="2"
                          minLength={5}
                          maxLength={500}
                          required
                          placeholder="Discounts, offers, holiday cuts e.t.c"
                          value={prices}
                          onChange={(e) => setPrices(e.target.value)}
                          className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                        ></textarea>
                      </div>

                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="timeAndDate"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          Enter Time And Date Of Event
                        </label>
                        <input
                          type="text"
                          placeholder="i.e Friday 17th 6:00PM till dawn"
                          id="timeAndDate"
                          value={timeAndDate}
                          required
                          minLength={2}
                          maxLength={100}
                          onChange={(e) => setTimeAndDate(e.target.value)}
                          className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                        />
                      </div>

                      {/* upload image */}
                      <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                        <div className="flex flex-col gap-2 mt-[20px]">
                          <label
                            htmlFor="mainPhoto"
                            className="flex items-center gap-[20px] flex-wrap"
                          >
                            <p>Please Select Photo</p>
                            <div className="flex flex-col items-center">
                              {loadingPhoto ? (
                                <Spinner message="uploading ..." />
                              ) : (
                                <img
                                  src={
                                    photo
                                      ? photo
                                      : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                  }
                                  alt=""
                                  className="w-[100px] h-[100px] object-cover"
                                />
                              )}
                            </div>
                          </label>
                          <input
                            type="file"
                            placeholder="Add Image"
                            accept="image/*"
                            onChange={(e) => postPhoto(e.target.files[0])}
                            required
                            id="mainPhoto"
                            className="hidden"
                          />
                        </div>
                      </div>

                      {/* specificAddress */}
                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="specificAdd"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          <p>Specify Event Address</p>
                          <p className="text-sm text-emerald-600">
                            Please Enter A Popular Location
                          </p>
                        </label>
                        <input
                          type="text"
                          placeholder="popular local name"
                          id="specificAdd"
                          value={specificAddress}
                          required
                          minLength={2}
                          maxLength={50}
                          onChange={(e) => setSpecificAddress(e.target.value)}
                          className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                        />
                      </div>

                      {/* event visibility */}
                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="eventVisibility"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          <p>Visible to other campuses ?</p>
                          <p className="text-sm text-emerald-600">
                            {user?.location} and other campuses ?
                          </p>
                        </label>
                        <select
                          name="eventVisibility"
                          id="eventVisibility"
                          className="p-[8px]  border bg-[#212121] border-zinc-400 rounded-lg"
                          value={visibleToAllCampuses}
                          onChange={(e) =>
                            setVisibileToAllCampuses(e.target.value)
                          }
                        >
                          <option value="">Choose</option>
                          <option value="nope">No</option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>

                      {/* searchTerms */}
                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="specificAdd"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          <p>Search terms associated.</p>
                          <p className="text-sm text-emerald-600">
                            separate by comma (,)
                          </p>
                        </label>
                        <input
                          type="text"
                          placeholder="searchTerms separated by comma"
                          id="specificAdd"
                          value={searchTerms}
                          required
                          minLength={2}
                          maxLength={70}
                          onChange={(e) => setSearchTerms(e.target.value)}
                          className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                        />
                      </div>
                      <div className="mb-[14px]">
                        <h2 className="text-center font-bold mb-[10px] text-zinc-500">
                          IMPORTANT
                        </h2>
                        <p className="text-center">
                          {user.phone} is the phone number to be used.
                        </p>
                        <p className="text-center">
                          {user.location} will be used as your location
                        </p>

                        <Link to="/my-profile" className="text-center">
                          <div className="text-emerald-600 underline">
                            You Can Change Here
                          </div>
                        </Link>
                      </div>
                      {loading || loadingPhoto ? (
                        <div className="w-full">
                          <p className="text-center">Please wait 😊 ... </p>
                        </div>
                      ) : (
                        <button
                          className="bg-emerald-800 text-white w-full p-[8px] rounded-md"
                          onClick={handleCreate}
                        >
                          Add New Event
                        </button>
                      )}
                    </form>
                  </div>
                  {/*  */}
                </div>
              )}
            </>
          )}

          {/* allow creation of upto 4 events if user has paid */}
          {eventCount > 1 && eventCount < 4 && user?.isPaid && (
            <>
              {nowValidateEvent ? (
                <div>
                  <div className=" px-[8px] sm:px-[2em] lg:px-[5em] py-[1em]">
                    <div>
                      <Link to="/events">
                        <AiOutlineArrowLeft className="text-2xl font-bold" />
                      </Link>
                    </div>
                    <div className="h-[60vh] w-full flex flex-col justify-center ">
                      <div className="flex justify-center">
                        <div>
                          <div className="w-full flex justify-center mb-2">
                            <img src={Hello} alt="hello" />
                          </div>
                          <h2 className="mb-2 text-center">
                            Hello Thank You For Adding An Event In{" "}
                            {user?.location}
                          </h2>
                          <h2 className="mb-2 text-center">
                            For safety and credibility, we will{" "}
                            <span className="text-emerald-300">verify</span>{" "}
                            this event and get back to you.
                          </h2>
                          <h2 className="mb-2 text-center">
                            Verification process up to 48 hours
                          </h2>
                          <p className="pb-2 text-center">
                            We will text you using 0798 556471 when completed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="px-[10px] pt-[10px]">
                  {/* topbar */}
                  <div>
                    <div className="flex gap-4 items-center">
                      <Link to="/events">
                        <AiOutlineArrowLeft className="text-2xl" />
                      </Link>
                      <div>
                        <h2 className="font-bold text-md text-zinc-600">
                          Add New Event
                        </h2>
                        <Link to="/my-profile">
                          <p className="text-sm underline text-emerald-700">
                            Your Location : {user?.location}
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* form */}
                  <div className="mt-[2em] pb-[1em] w-full">
                    <form
                      className=" w-[98%] sm:w-[80%] lg:w-[50%] m-auto"
                      onSubmit={handleCreate}
                    >
                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="title"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          Name of The Event
                        </label>
                        <input
                          type="text"
                          placeholder="title here"
                          id="title"
                          value={title}
                          required
                          minLength={2}
                          maxLength={60}
                          onChange={(e) => setTitle(e.target.value)}
                          className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                        />
                      </div>
                      {/* description */}
                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="desc"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          Briefly Describe The Event
                        </label>
                        <textarea
                          name="desc"
                          id="desc"
                          cols="30"
                          rows="3"
                          minLength={5}
                          maxLength={500}
                          required
                          placeholder="event description (500)"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                        ></textarea>
                      </div>

                      {/* charging rates */}
                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="chargeRates"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          What Are Your Charging Rates
                        </label>

                        <textarea
                          name="chargeRates"
                          id="chargeRates"
                          cols="30"
                          rows="2"
                          minLength={5}
                          maxLength={500}
                          required
                          placeholder="Discounts, offers, holiday cuts e.t.c"
                          value={prices}
                          onChange={(e) => setPrices(e.target.value)}
                          className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                        ></textarea>
                      </div>

                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="timeAndDate"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          Enter Time And Date Of Event
                        </label>
                        <input
                          type="text"
                          placeholder="i.e Friday 17th 6:00PM till dawn"
                          id="timeAndDate"
                          value={timeAndDate}
                          required
                          minLength={2}
                          maxLength={100}
                          onChange={(e) => setTimeAndDate(e.target.value)}
                          className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                        />
                      </div>

                      {/* upload image */}
                      <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                        <div className="flex flex-col gap-2 mt-[20px]">
                          <label
                            htmlFor="mainPhoto"
                            className="flex items-center gap-[20px] flex-wrap"
                          >
                            <p>Please Select Photo</p>
                            <div className="flex flex-col items-center">
                              {loadingPhoto ? (
                                <Spinner message="uploading ..." />
                              ) : (
                                <img
                                  src={
                                    photo
                                      ? photo
                                      : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                  }
                                  alt=""
                                  className="w-[100px] h-[100px] object-cover"
                                />
                              )}
                            </div>
                          </label>
                          <input
                            type="file"
                            placeholder="Add Image"
                            accept="image/*"
                            onChange={(e) => postPhoto(e.target.files[0])}
                            required
                            id="mainPhoto"
                            className="hidden"
                          />
                        </div>
                      </div>

                      {/* specificAddress */}
                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="specificAdd"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          <p>Specify Event Address</p>
                          <p className="text-sm text-emerald-600">
                            Please Enter A Popular Location
                          </p>
                        </label>
                        <input
                          type="text"
                          placeholder="popular local name"
                          id="specificAdd"
                          value={specificAddress}
                          required
                          minLength={2}
                          maxLength={50}
                          onChange={(e) => setSpecificAddress(e.target.value)}
                          className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                        />
                      </div>

                      {/* event visibility */}
                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="eventVisibility"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          <p>Visible to other campuses ?</p>
                          <p className="text-sm text-emerald-600">
                            {user?.location} and other campuses ?
                          </p>
                        </label>
                        <select
                          name="eventVisibility"
                          id="eventVisibility"
                          className="p-[8px]  border bg-[#212121] border-zinc-400 rounded-lg"
                          value={visibleToAllCampuses}
                          onChange={(e) =>
                            setVisibileToAllCampuses(e.target.value)
                          }
                        >
                          <option value="">Choose</option>
                          <option value="nope">No</option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>

                      {/* searchTerms */}
                      <div className="flex flex-col gap-[8px] mb-[12px]">
                        <label
                          htmlFor="specificAdd"
                          className=""
                          style={{ fontWeight: 600 }}
                        >
                          <p>Search terms associated.</p>
                          <p className="text-sm text-emerald-600">
                            separate by comma (,)
                          </p>
                        </label>
                        <input
                          type="text"
                          placeholder="searchTerms separated by comma"
                          id="specificAdd"
                          value={searchTerms}
                          required
                          minLength={2}
                          maxLength={70}
                          onChange={(e) => setSearchTerms(e.target.value)}
                          className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                        />
                      </div>
                      <div className="mb-[14px]">
                        <h2 className="text-center font-bold mb-[10px] text-zinc-500">
                          IMPORTANT
                        </h2>
                        <p className="text-center">
                          {user.phone} is the phone number to be used.
                        </p>
                        <p className="text-center">
                          {user.location} will be used as your location
                        </p>

                        <Link to="/my-profile" className="text-center">
                          <div className="text-emerald-600 underline">
                            You Can Change Here
                          </div>
                        </Link>
                      </div>
                      {loading || loadingPhoto ? (
                        <div className="w-full">
                          <p className="text-center">Please wait 😊 ... </p>
                        </div>
                      ) : (
                        <button
                          className="bg-emerald-800 text-white w-full p-[8px] rounded-md"
                          onClick={handleCreate}
                        >
                          Add New Event
                        </button>
                      )}
                    </form>
                  </div>
                  {/*  */}
                </div>
              )}
            </>
          )}

          {/* no creation at all. Limit reached */}
          {eventCount > 4 && (
            <div>
              <div className="h-[80vh] w-full flex justify-center items-center">
                <div>
                  <div className="w-full flex justify-center">
                    <img src={Hello} alt="" />
                  </div>
                  <h2 className="text-xl  text-zinc-300 text-center">
                    Hold Up ✋. Limit Reached
                  </h2>
                  <p className="text-md text-zinc-300 text-center mb-3">
                    You can only create a maximum of 4 events
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* user has not paid */}
          {eventCount > 1 && !user?.isPaid && (
            <>
              <div>
                <div className="h-[80vh] w-full flex justify-center items-center">
                  <div>
                    <div className="w-full flex justify-center">
                      <img src={Hello} alt="" />
                    </div>
                    <h2 className="text-xl  text-zinc-300 text-center">
                      Hold Up ✋. You can Only Create One Event On Free Account
                    </h2>
                    <p className="text-md text-zinc-300 text-center mb-3">
                      Upgrade to our gold plan to add up to 4 events
                    </p>
                    <p className="text-sm text-zinc-300 text-center">
                      Alternatively you can replace your current event with a
                      new one
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CreateEvent;
