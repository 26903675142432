import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";

const Favorites = () => {
  return (
    <div>
      {/* wrapper */}
      <div className=" px-[10px] sm:px-[2em] md:px-[3em] pt-[1em]">
        {/* topbar */}
        <div>
          <Link to="/home">
            <AiOutlineArrowLeft className="text-2xl" />
          </Link>
          <h2 className="font-bold mb-1 mt-5">
            All Your Favorite Stuff so far
          </h2>
        </div>
        <div>
          <div className="w-full h-[75vh] flex justify-center items-center">
            <div>
              <h2 className="font-bold text-xl mb-3 text-center">
                😢You Have Not Saved Anything So Far
              </h2>
              <Link to="/home">
                <p className="text-center text-teal-800 underline">BACK HOME</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Favorites;
