import { useEffect, useState } from "react";
import logo from "../../assets/officialOne.png";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlinePhone,
} from "react-icons/ai";
// import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../components/Spinner";
import { login, reset } from "../../features/auth/authSlice";

import { Helmet } from "react-helmet";

const Login = () => {
  const [seePass, setSeePass] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      toast.error("Please Check Login Details", { theme: "dark" });
      toast.error("Also Check Network", { theme: "dark" });
    }

    if (user) {
      // handleLogout();
      navigate("/home");
      // toast.success("Welcome Back");
    }

    if (navigator.onLine) {
      console.log("online");
    } else {
      toast.error("Network Error", { theme: "dark" });
    }
    dispatch(reset());
  }, [user, isError, isSuccess, message, isLoading, navigate]);

  const [loading, setLoading] = useState(false);

  const handleSignin = async (e) => {
    e.preventDefault();

    if (!username) {
      return toast.error("username missing", { theme: "dark" });
    }

    if (!password) {
      return toast.error("password missing", { theme: "dark" });
    }

    try {
      setLoading(true);
      const userData = { username, password };
      await dispatch(login(userData));

      // navigate("/");
      localStorage.setItem("newLogIn", new Date().getTime());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to sign you in", { theme: "dark" });
    }
    setLoading(false);
  };
  return (
    <div>
      {/* topbar */}
      <div className=" w-full h-full top-0  text-white px-[10px] sm:px-[1em] md:px-[3em] lg:px-[4em] xl:px-[5em]  ">
        <div className="flex justify-between items-center pt-4">
          {/* logo */}
          <div>
            <Link to="/">
              <img src={logo} alt="" className="w-[60px] object-contain" />
            </Link>
          </div>
        </div>
      </div>

      <Helmet>
        <title>{`Login | Account | iLocca`}</title>
        <meta
          name="description"
          content="Bridging the gap between local businesses and the community, offering a streamlined platform for residents to discover and acquire quality local products and services."
        />
      </Helmet>

      <div className=" mt-[13vh]  sm:mt-[20vh]">
        <h2 className="text-center mb-[2em] text-2xl text-zinc-400 mt-[1em] font-bold">
          👋Please sign in.
        </h2>

        <form
          className=" w-[93%] sm:w-[80%]  md:w-[60%] xl:w-[50%]  2xl:w-[40%] m-auto"
          onSubmit={handleSignin}
        >
          <div className="flex flex-col gap-[10px] mb-[22px]">
            <label
              htmlFor="username"
              className="font-bold text-zinc-400 text-lg"
            >
              Enter Your Username
            </label>
            <input
              type="text"
              id="username"
              placeholder="username i.e lucythegreat"
              className="bg-transparent border border-zinc-400 p-[8px] rounded-md outline-none"
              value={username}
              maxLength={40}
              minLength={3}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="flex items-center gap-[10px]">
            <div className="flex flex-col gap-[10px] mb-[22px] flex-[0.98]">
              <label
                htmlFor="password"
                className="font-bold text-zinc-400 text-lg"
              >
                Enter Your Password
              </label>
              <input
                type={seePass ? "text" : "password"}
                id="password"
                placeholder="Password"
                className="bg-transparent border border-zinc-400 p-[8px] rounded-md outline-none"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex-[0.02]">
              {seePass ? (
                <AiOutlineEyeInvisible
                  className="text-2xl cursor-pointer"
                  onClick={() => setSeePass(false)}
                />
              ) : (
                <AiOutlineEye
                  className="text-2xl cursor-pointer"
                  onClick={() => setSeePass(true)}
                />
              )}
            </div>
          </div>
          <div>
            {isLoading || loading ? (
              <>
                <Spinner message="Verifying" />
              </>
            ) : (
              <button
                className="bg-emerald-800 text-white p-[10px] w-full rounded-md"
                onClick={handleSignin}
              >
                Log in
              </button>
            )}
          </div>
          <div className=" mt-4 flex justify-between items-center">
            <Link to="/register">
              <p className="underline text-emerald-300">Register instead</p>
            </Link>
            <div className="flex items-center gap-2 text-emerald-400">
              <AiOutlinePhone className="text-xl" />
              <a href="tel:0798556471">Call Us</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
