import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  AiFillStar,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClose,
  AiOutlineComment,
  AiOutlineDislike,
  AiOutlineEye,
  AiOutlineLike,
  AiOutlinePhone,
  AiOutlineStar,
} from "react-icons/ai";
import { BsPen, BsPerson, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import TradeComment from "../TradeComment";
import { FaRegCommentDots } from "react-icons/fa";
import moment from "moment";
import FununuComment from "../FununuComment";

const OtherFununu = ({ data }) => {
  // fetch posts

  const [allPosts, setAllPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const handleFetchPosts = async () => {
    try {
      setLoading(true);
      let username = data.username;
      let dataToSend = { username };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post("/fununu/mine", dataToSend, config);
      if (response) {
        setLoading(false);
        setAllPosts(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Posts");
    }
  };

  useEffect(() => {
    handleFetchPosts();
  }, []);

  // working on modal
  const [selectedPost, setSelectedPost] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  // what happens when we click on a post
  const handlePostClick = (post) => {
    setSelectedPost(post);
    document.body.style.overflow = "hidden";
    setIsPopUpOpen(true);
  };

  // like a post
  const handleLikePost = async (post) => {
    try {
      if (!user) {
        toast.error("Please Login To leave a like", { theme: "dark" });
        return;
      }

      let username = user.username;
      let id = post._id;
      let likeData = { username };

      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let res = await axios.post("/fununu/like/" + id, likeData, config);
      if (res) {
        // notification
        if (user?.username !== post.owner) {
          // create notification
          let sender = user.username;
          let receiver = post.owner;
          let receiverEmail = post.ownerEmail;
          let message = `Your post ${post.title} got a like 👍 from ${sender} `;
          let referID = `/fununu`;
          let notifyData = {
            sender,
            receiver,
            receiverEmail,
            message,
            referID,
          };

          await axios.post("/notify/create", notifyData, config);
        }

        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed To Like");
    }
  };

  const PopUpPage = ({ post, onClose }) => {
    return (
      <div className="">
        {/* close btn */}

        {/* data */}
        <div className="pop-up-content prompt px-3">
          <div className="flex justify-center mb-[20px] mt-[50px] z-[999] hide-scrollbar">
            <button onClick={onClose}>
              <AiOutlineClose
                className="text-5xl text-white p-[10px] rounded-full "
                style={{
                  border: "2px solid #188c56",
                  position: "sticky",
                  top: "60px",
                }}
                title="close"
              />
            </button>
          </div>
          <div className=" h-[70vh] sm:h-[60vh] overflow-y-scroll prompt">
            <div>
              <div className="mb-[20px]">
                <div className="w-full flex justify-center">
                  <img src={post.photo} alt="" className="max-h-[400px] " />
                </div>
                <p
                  className="text-lg text-white font-bold mb-3"
                  style={{ wordWrap: "break-word" }}
                >
                  {post.title}
                </p>
                <div className="flex gap-2 items-center ">
                  <p>
                    <AiOutlinePhone className="text-white text-2xl" />
                  </p>
                  <a href={`tel:${post.phone}`} className="text-emerald-400">
                    {post.phone}
                  </a>
                </div>
              </div>
            </div>

            {/* <div>
              <img
                src={
                  post.photo ||
                  "https://images.pexels.com/photos/235994/pexels-photo-235994.jpeg?auto=compress&cs=tinysrgb&w=1600"
                }
                alt=""
                className="max-h-[400px] object-contain w-full"
              />
            </div> */}

            <div className="mt-[20px] flex items-center justify-between w-full gap-[10px] flex-wrap">
              <div className="flex gap-[30px] items-center">
                <div
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => handleLikePost(post)}
                >
                  <AiOutlineLike className="text-xl text-white" />
                  <p className="text-white">{post.likes.length}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <AiOutlineComment className="text-xl text-white" />
                  <p className="text-white">{post.comments.length}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <AiOutlineEye className="text-xl text-white" />
                  <p className="text-white">{post.views.length}</p>
                </div>
              </div>
            </div>

            <div className="mt-10">
              <p>{post.description}</p>
            </div>

            {/* comment */}
            <div className="mt-[2em]">
              <FununuComment item={post} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/*  */}

      {loading ? (
        <>
          <Spinner message="Fetching Posts" />
        </>
      ) : (
        <>
          {allPosts.length < 1 ? (
            <>
              {/* <div className="h-[20vh] w-full flex justify-center items-center">
                <div>
                  <h2 className="text-center mb-2">😢Ouch.</h2>
                  <h2>{data.username} has not added a post in soko</h2>
                </div>
              </div> */}
            </>
          ) : (
            <>
              <div>
                <h2 className="text-xl mb-[10px] text-zinc-400 font-bold">
                  {data.username}'s Posts
                </h2>
              </div>

              <div className="flex gap-[10px] overflow-x-scroll w-full prompt pb-2">
                <div className="flex flex-nowrap">
                  {allPosts?.map((item) => (
                    <div
                      key={item._id}
                      className="flex-shrink-0 mr-[15px] cursor-pointer"
                    >
                      <div
                        className="relative rounded-lg group "
                        onClick={() => handlePostClick(item)}
                      >
                        <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                          <div
                            className="bg-gradient-to-t
                                  from-transparent to-black opacity-80 w-full h-full rounded-md"
                          >
                            {/* top stats */}
                            <div>
                              <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                                <div>
                                  <a href={`tel:+${item.phone}`}>
                                    {" "}
                                    <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                  </a>
                                </div>
                                <div className="flex gap-[20px]">
                                  {/* <p className="text-white text-md flex items-center gap-[5px]">
                                    <FaRegCommentDots className="text-lg" />
                                    <span>{item.comments.length}</span>
                                  </p> */}
                                  <p className="text-white text-md flex items-center gap-[5px]">
                                    <AiOutlineLike className="text-lg" />
                                    <span>{item.likes.length}</span>
                                  </p>
                                </div>
                              </div>
                              <div className="absolute top-[80px] left-3">
                                <p className="text-white">{item.title}</p>
                              </div>
                            </div>
                            {/*  */}
                            <div className="absolute bottom-[20px] left-[20px]  flex gap-[10%] w-full ">
                              <div>
                                <div className="flex gap-[10px] text-zinc-300">
                                  {/* <p>{item.location}</p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <img
                          src={item.photo}
                          alt=""
                          className="w-72 h-80 rounded-md object-cover"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}

      {/*  */}
      {/* pop up screen */}
      {isPopUpOpen && (
        <div className="">
          {/* overlay div */}
          <div
            className="fixed z-[999] top-0 bottom-0 w-full  bg-[rgba(0,0,0,0.6)]"
            onClick={() => {
              setIsPopUpOpen(false);
              document.body.style.overflow = "auto";
            }}
          >
            <div className=" mt-[23%] md:mt-[15%] lg:mt-[13%]  xl:mt-[7%] w-[96%]  md:w-[80%]   lg:w-[70%]   2xl:w-[60%] m-auto">
              <div
                className="  bg-[rgba(0,0,0,1)] rounded-xl"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <PopUpPage
                  post={selectedPost}
                  onClose={() => {
                    setIsPopUpOpen(false);
                    document.body.style.overflow = "auto";
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OtherFununu;
