import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/officialOne.png";
import Hello from "../assets/hello.png";
import { useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import axios from "../axios";
import { toast } from "react-toastify";

const Feedback = () => {
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }
  }, [user, navigate]);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handlesendMessage = async (e) => {
    e.preventDefault();

    if (!message) {
      return toast.error("Message Needed", { theme: "dark" });
    }

    if (user) {
      let senderName = user?.username;
      let senderPhone = user?.phone;
      let senderLocation = user?.location;

      let dataToSend = { senderName, senderPhone, senderLocation, message };

      try {
        setLoading(true);
        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        let response = await axios.post("/feedback", dataToSend, config);
        if (response) {
          setLoading(false);
          setSuccess(true);
          setMessage("");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        setSuccess(false);
        setMessage("");
        toast.error("Failed To Send. Check Network", { theme: "dark" });
      }
    }
  };

  return (
    <div>
      {/* wrapper */}
      <div className="px-[10px] sm:px-[3em] pt-[15px] pb-[10px]">
        {/* topbar */}
        <div
          className=" py-[5px] top-0 left-0 fixed w-full px-[10px] sm:px-[2em]  xl:px-[5em] "
          style={{
            background: "rgba(34, 33, 33, 0.9)",
            backdropFilter: "blur(4px)",
            zIndex: 2,
          }}
        >
          <Link to="/home">
            <img src={Logo} alt="" className="w-[5em] h-16 object-contain" />
          </Link>
        </div>

        <div className="mt-[4em]"></div>

        {success ? (
          <>
            <div>
              <div className=" w-[100%]  sm:w-[70%]  lg:w-[55%] 2xl:w-[40%] m-auto">
                <div className="mb-[2em]">
                  <div className="mb-[1.3em] w-full flex justify-center">
                    <img src={Hello} alt="Hello" />
                  </div>
                  <h2 className="text-center mb-3">Hello {user?.username}</h2>
                  <p className="text-center mb-3">
                    We have received your message
                  </p>
                  <p className="text-center mb-1">
                    We may call you back on your phone {user?.phone}.
                  </p>
                  <p className="text-center text-emerald-600 font-bold">
                    Thank You
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <form
                className=" w-[100%]  sm:w-[70%]  lg:w-[55%] 2xl:w-[40%] m-auto"
                onSubmit={handlesendMessage}
              >
                {!message && (
                  <>
                    <div className="mb-[2em]">
                      <div className="mb-[1.3em] w-full flex justify-center">
                        <img src={Hello} alt="Hello" />
                      </div>
                      <p>
                        Hello there! Your suggestions, feedback, and reports are
                        highly valued. We are committed to enhancing your
                        experience and making it truly delightful. Feel free to
                        share your thoughts with us!
                      </p>
                    </div>
                  </>
                )}

                <div className="flex flex-col gap-[10px] mb-[20px]">
                  <label htmlFor="message">
                    What Would You like to tell us today ?
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="3"
                    className="bg-transparent rounded-lg p-1 border border-zinc-700 outline-none"
                    placeholder="Talk To Us"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>

                <div>
                  <div>
                    {loading ? (
                      <Spinner message="sending ..." />
                    ) : (
                      <button
                        className="bg-emerald-800 text-white p-[10px] w-full rounded-md outline-none"
                        onClick={handlesendMessage}
                      >
                        Send Now
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Feedback;
