import React, { useState } from "react";
import Hello from "../assets/hello.png";
import Logo from "../assets/officialOne.png";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import axios from "../axios";
import {} from "react-toastify";
import { useSelector } from "react-redux";

const AddNewLocation = () => {
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [addLocation, setAddLocation] = useState("");

  const [success, setSuccess] = useState(false);
  const [locReq, setLocReq] = useState([]);

  const handlesendRequest = async (e) => {
    e.preventDefault();

    if (!fullName) return toast.error("Full Name required", { theme: "dark" });
    if (!phone) return toast.error("Phone Number required", { theme: "dark" });
    if (!addLocation)
      return toast.error("Location To Be Added required", { theme: "dark" });

    try {
      setLoading(true);
      let dataToSend = { fullName, phone, addLocation };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let response = await axios.post("/locationreq", dataToSend, config);
      if (response) {
        setLocReq(response.data);
        // console.log(response.data);
        setSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed To Send Request");
      setLoading(false);
      setSuccess(false);
    }
  };

  return (
    <div>
      {/* wrapper */}
      <div className="px-[10px] sm:px-[3em] pt-[15px] pb-[10px]">
        {/* topbar */}
        <div
          className=" py-[5px] top-0 left-0 fixed w-full px-[10px] sm:px-[2em]  xl:px-[5em] "
          style={{
            background: "rgba(34, 33, 33, 0.9)",
            backdropFilter: "blur(4px)",
            zIndex: 2,
          }}
        >
          <Link to="/register">
            <img src={Logo} alt="" className="w-16 h-16" />
          </Link>
        </div>

        <div className="mt-[4em]"></div>

        {success ? (
          <>
            <div>
              <div className=" w-[100%]  sm:w-[70%]  lg:w-[55%] 2xl:w-[40%] m-auto">
                <div className="mb-[2em]">
                  <div className="mb-[1.3em] w-full flex justify-center">
                    <img src={Hello} alt="Hello" />
                  </div>
                  <h2 className="text-center mb-3">Hello {locReq.fullName}</h2>
                  <p className="text-center mb-3">
                    We have received your request on adding{" "}
                    <span className="font-bold">{locReq.addLocation}</span> to
                    our network
                  </p>
                  <p className="text-center mb-1">We will call you back.</p>
                  <p className="text-center text-emerald-600 font-bold">
                    Thank You
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <form
                className=" w-[100%]  sm:w-[70%]  lg:w-[55%] 2xl:w-[40%] m-auto"
                onSubmit={handlesendRequest}
              >
                <div className="mb-[2em]">
                  <div className="mb-[1.3em] w-full flex justify-center">
                    <img src={Hello} alt="Hello" />
                  </div>
                  <p>
                    Thank you for reaching out. As we continue to evolve and
                    expand, we are eager to include your campus on our platform.
                    This way, we aim to enhance convenience and foster economic
                    growth within your campus.{" "}
                  </p>
                </div>

                <div className="flex flex-col gap-[10px] mb-[20px]">
                  <label htmlFor="fullName" className="text-zinc-500 font-bold">
                    What is your name?
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    placeholder="full name"
                    className="p-1 bg-transparent border border-zinc-500 outline-none rounded-md"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-[10px] mb-[20px]">
                  <label htmlFor="phone" className="text-zinc-500 font-bold">
                    What is your phone number?
                  </label>
                  <input
                    type="text"
                    id="phone"
                    placeholder="enter phone number"
                    className="p-1 bg-transparent border border-zinc-500 outline-none rounded-md"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-[10px] mb-[20px]">
                  <label htmlFor="location" className="text-zinc-500 font-bold">
                    Name of your campus?
                  </label>
                  <input
                    type="text"
                    id="location"
                    placeholder="campus to be added"
                    className="p-1 bg-transparent border border-zinc-500 outline-none rounded-md"
                    value={addLocation}
                    onChange={(e) => setAddLocation(e.target.value)}
                  />
                </div>
                <div>
                  <div>
                    {loading ? (
                      <Spinner message="sending ..." />
                    ) : (
                      <button
                        className="bg-emerald-800 text-white p-[10px] w-full rounded-md outline-none"
                        onClick={handlesendRequest}
                      >
                        Send Now
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddNewLocation;
