import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DummyBizCategory, DummyToTradeCategory } from "../../Dummydata";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import axios from "../../axios";
import imageCompression from "browser-image-compression";

const EditTrade = () => {
  const { user } = useSelector((state) => state.auth);
  // fetch the trade
  const { id } = useParams();
  const [myTrade, setMyTrade] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchTheTrade = async () => {
    try {
      setLoading(true);
      let checkParam = id;
      const response = await axios.get("/trade/" + checkParam);
      if (response) {
        setLoading(false);
        setMyTrade([response.data]);
        console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Trade.");
      toast.error("Network error or doesn't exist");
    }
  };

  useEffect(() => {
    fetchTheTrade();
  }, []);

  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [firstPhoto, setFirstPhoto] = useState("");
  const [secPhoto, setSecPhoto] = useState("");
  const [thirdPhoto, setThirdPhoto] = useState("");
  const [exchangeFor, setExchangeFor] = useState("");
  const [defect, setDefect] = useState("");
  const [specificAddress, setSpecificAddress] = useState("");
  const [availability, setAvailability] = useState("");
  const [searchTerms, setSearchTerms] = useState("");

  // upload firstPhoto to cloudinary
  const [loadingFirstPhoto, setLoadingFirstPhoto] = useState(false);
  const postFirstPhoto = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingFirstPhoto(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      setLoadingFirstPhoto(true);
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingFirstPhoto(false);
      setFirstPhoto(urlData.url);
      toast.success("Uploaded The First Photo", { theme: "dark" });
    } catch (error) {
      setLoadingFirstPhoto(false);
      toast.error("Error uploading First Photo", { theme: "dark" });
    }
  };

  // upload secPhoto to cloudinary
  const [loadingSecPhoto, setLoadingSecPhoto] = useState(false);
  const postSecPhoto = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingSecPhoto(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingSecPhoto(false);
      setSecPhoto(urlData.url);
      toast.success("Uploaded The Sec Photo", { theme: "dark" });
    } catch (error) {
      setLoadingSecPhoto(false);
      toast.error("Error uploading Sec Photo", { theme: "dark" });
    }
  };

  // upload thirdPhoto to cloudinary
  const [loadingThirdPhoto, setLoadingThirdPhoto] = useState(false);
  const postThirdPhoto = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingThirdPhoto(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingThirdPhoto(false);
      setThirdPhoto(urlData.url);
      toast.success("Uploaded The Third Photo", { theme: "dark" });
    } catch (error) {
      setLoadingThirdPhoto(false);
      toast.error("Error uploading Third Photo", { theme: "dark" });
    }
  };

  //update data
  const [showForm, setShowForm] = useState(false);

  const [updatetitle, setupdateTitle] = useState("");
  const [updatecategory, setupdateCategory] = useState("");
  const [updatedescription, setupdateDescription] = useState("");
  const [updateFirstphoto, setupdateFirstPhoto] = useState("");
  const [updateSecphoto, setupdateSecPhoto] = useState("");
  const [updateThirdphoto, setupdateThirdPhoto] = useState("");
  const [updateExchangeFor, setupdateExchangeFor] = useState("");
  const [updateDefect, setupdateDefect] = useState("");
  const [updatespecificAddress, setupdateSpecificAddress] = useState("");
  const [updateavailability, setupdateAvailability] = useState("");
  const [updatesearchTerms, setupdateSearchTerms] = useState("");

  useEffect(() => {
    setTitle(updatetitle);
    setCategory(updatecategory);
    setDescription(updatedescription);
    setFirstPhoto(updateFirstphoto);
    setSecPhoto(updateSecphoto);
    setThirdPhoto(updateThirdphoto);
    setExchangeFor(updateExchangeFor);
    setDefect(updateDefect);
    setSpecificAddress(updatespecificAddress);
    setAvailability(updateavailability);
    setSearchTerms(updatesearchTerms);
  }, [updatetitle, updatespecificAddress, updateDefect]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!title) return toast.error("title missing");
    if (!category) return toast.error("category missing");
    if (!description) return toast.error("description missing");
    if (!firstPhoto) return toast.error("first photo missing");
    if (!secPhoto) return toast.error("sec photo missing");
    if (!thirdPhoto) return toast.error("third photo missing");
    if (!defect) return toast.error("defect details missing");
    if (!availability) return toast.error("availability missing");
    if (!exchangeFor) return toast.error("exchange for missing");
    if (!searchTerms) return toast.error("searchTerms missing");

    try {
      setLoading(true);
      let location = user?.location;
      let phone = user?.phone;
      let owner = user?.username;
      let dataToSend = {
        title,
        category,
        description,
        location,
        firstPhoto,
        secPhoto,
        thirdPhoto,
        phone,
        owner,
        exchangeFor,
        defect,
        specificAddress,
        availability,
        searchTerms,
      };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put("/trade/" + id, dataToSend, config);
      if (response) {
        setLoading(false);
        toast.success("Updated " + title, { theme: "dark" });
        // navigate("/my-profile");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error updating Trade", { theme: "dark" });
    }
  };

  return (
    <div>
      {/* wrapper */}
      <div className="px-[10px] pt-[10px]">
        {/* topbar */}
        <div>
          <div className="flex gap-4 items-center">
            <Link to="/my-profile">
              <AiOutlineArrowLeft className="text-lg" />
            </Link>
            <div>
              <h2 className="font-bold text-md text-zinc-400">Update Trade</h2>
              <Link to="/change-location">
                <p className="text-sm underline text-emerald-700">
                  Your Campus : {user?.location}
                </p>
              </Link>
            </div>
          </div>
        </div>

        {myTrade?.map((item) => (
          <div key={item._id}>
            {showForm ? (
              <>
                {/* form */}
                <form
                  className=" w-[99%] sm:w-[75%] lg:w-[50%] m-auto pb-[1em]"
                  onSubmit={handleUpdate}
                >
                  <div className="flex flex-col gap-[8px] mb-[12px]">
                    <label
                      htmlFor="title"
                      className=""
                      style={{ fontWeight: 600 }}
                    >
                      Update Item / Service Name
                    </label>
                    <input
                      type="text"
                      placeholder="title here"
                      id="title"
                      value={title}
                      required
                      minLength={2}
                      maxLength={14}
                      onChange={(e) => setTitle(e.target.value)}
                      className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                    />
                  </div>
                  {/* category */}
                  <div className="flex flex-col gap-[8px] mb-[12px]">
                    <label
                      htmlFor="category"
                      className=""
                      style={{ fontWeight: 600 }}
                    >
                      Update Category For This Item
                    </label>

                    <select
                      name="category"
                      id="category"
                      className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">Choose</option>
                      {DummyToTradeCategory.map((item) => (
                        <option key={item.id}>{item.title}</option>
                      ))}
                    </select>
                  </div>
                  {/* description */}
                  <div className="flex flex-col gap-[8px] mb-[12px]">
                    <label
                      htmlFor="desc"
                      className=""
                      style={{ fontWeight: 600 }}
                    >
                      Update Description
                    </label>
                    <textarea
                      name="desc"
                      id="desc"
                      cols="30"
                      rows="3"
                      minLength={5}
                      maxLength={700}
                      required
                      placeholder="short, good description of item"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                    ></textarea>
                  </div>
                  {/* exchangeFor */}
                  <div className="flex flex-col gap-[8px] mb-[12px]">
                    <label
                      htmlFor="exchangeFor"
                      className=""
                      style={{ fontWeight: 600 }}
                    >
                      Update what you want in exchange
                    </label>

                    <textarea
                      name="exchangeFor"
                      id="exchangeFor"
                      cols="30"
                      rows="2"
                      minLength={5}
                      maxLength={500}
                      required
                      placeholder="I want item x in exchange."
                      value={exchangeFor}
                      onChange={(e) => setExchangeFor(e.target.value)}
                      className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                    ></textarea>
                  </div>
                  {/* upload first image */}
                  <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                    <div className="flex flex-col gap-2 mt-[20px]">
                      {loadingFirstPhoto ? (
                        <div className="w-full flex justify-center">
                          <Spinner message="uploading ..." />
                        </div>
                      ) : (
                        <>
                          <label
                            htmlFor="firstPhoto"
                            className="flex items-center gap-[20px] flex-wrap"
                          >
                            <p>Update First Photo</p>
                            <div className="flex flex-col items-center">
                              <img
                                src={
                                  firstPhoto
                                    ? firstPhoto
                                    : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                }
                                alt=""
                                className="w-[100px] h-[100px] object-cover"
                              />
                            </div>
                          </label>
                          <input
                            type="file"
                            placeholder="Add Image"
                            accept="image/*"
                            onChange={(e) => postFirstPhoto(e.target.files[0])}
                            required
                            id="firstPhoto"
                            className="hidden"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* upload sec image */}
                  <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                    <div className="flex flex-col gap-2 mt-[20px]">
                      {loadingSecPhoto ? (
                        <div className="w-full flex justify-center">
                          <Spinner message="uploading ..." />
                        </div>
                      ) : (
                        <>
                          <label
                            htmlFor="secPhoto"
                            className="flex items-center gap-[20px] flex-wrap"
                          >
                            <p>Update Second Photo</p>
                            <div className="flex flex-col items-center">
                              <img
                                src={
                                  secPhoto
                                    ? secPhoto
                                    : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                }
                                alt=""
                                className="w-[100px] h-[100px] object-cover"
                              />
                            </div>
                          </label>
                          <input
                            type="file"
                            placeholder="Add Image"
                            accept="image/*"
                            onChange={(e) => postSecPhoto(e.target.files[0])}
                            required
                            id="secPhoto"
                            className="hidden"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* upload third image */}
                  <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                    <div className="flex flex-col gap-2 mt-[20px]">
                      {loadingThirdPhoto ? (
                        <div className="w-full flex justify-center">
                          <Spinner message="uploading ..." />
                        </div>
                      ) : (
                        <>
                          <label
                            htmlFor="thirdPhoto"
                            className="flex items-center gap-[20px] flex-wrap"
                          >
                            <div className="flex flex-col gap-2">
                              <p className="">Upload Item Photo </p>
                              <p>Please Select Third Photo</p>
                            </div>
                            <div className="flex flex-col items-center">
                              <img
                                src={
                                  thirdPhoto
                                    ? thirdPhoto
                                    : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                }
                                alt=""
                                className="w-[100px] h-[100px] object-cover"
                              />
                            </div>
                          </label>
                          <input
                            type="file"
                            placeholder="Add Image"
                            accept="image/*"
                            onChange={(e) => postThirdPhoto(e.target.files[0])}
                            required
                            id="thirdPhoto"
                            className="hidden"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* defects */}
                  <div className="flex flex-col gap-[8px] mb-[12px]">
                    <label
                      htmlFor="defects"
                      className=""
                      style={{ fontWeight: 600 }}
                    >
                      <p>Updaye Defects details</p>
                      <p className="text-sm text-emerald-600">
                        Be honest to avoid legal issues
                      </p>
                    </label>
                    <textarea
                      name="defects"
                      id="defects"
                      cols="30"
                      rows="3"
                      value={defect}
                      placeholder=" clearly state defects "
                      onChange={(e) => setDefect(e.target.value)}
                      className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                    ></textarea>
                  </div>
                  {/* specific address */}
                  <div className="flex flex-col gap-[8px] mb-[12px]">
                    <label
                      htmlFor="specificAddress"
                      className=""
                      style={{ fontWeight: 600 }}
                    >
                      <p>Update exact location?</p>
                      <p className="text-sm text-emerald-600">
                        Location well known by the local community
                      </p>
                    </label>
                    <input
                      type="text"
                      placeholder="specify location"
                      id="specificAddress"
                      value={specificAddress}
                      required
                      minLength={2}
                      maxLength={50}
                      onChange={(e) => setSpecificAddress(e.target.value)}
                      className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                    />
                  </div>
                  {/* availability */}
                  <div className="flex flex-col gap-[8px] mb-[12px]">
                    <label
                      htmlFor="availability"
                      className=""
                      style={{ fontWeight: 600 }}
                    >
                      <p>Update Availability</p>
                      <p className="text-sm text-emerald-600">
                        i.e 8:00AM - 10:00PM
                      </p>
                    </label>
                    <input
                      type="text"
                      placeholder="time span available to trade"
                      id="availability"
                      value={availability}
                      required
                      minLength={2}
                      maxLength={100}
                      onChange={(e) => setAvailability(e.target.value)}
                      className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                    />
                  </div>
                  {/* searchTerms */}
                  <div className="flex flex-col gap-[8px] mb-[12px]">
                    <label
                      htmlFor="specificAdd"
                      className=""
                      style={{ fontWeight: 600 }}
                    >
                      <p>Search terms associated.</p>
                      <p className="text-sm text-emerald-600">
                        separate by comma (,)
                      </p>
                    </label>
                    <input
                      type="text"
                      placeholder="searchTerms separated by comma"
                      id="specificAdd"
                      value={searchTerms}
                      required
                      minLength={2}
                      maxLength={70}
                      onChange={(e) => setSearchTerms(e.target.value)}
                      className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                    />
                  </div>
                  <div className="mb-[14px]">
                    <h2 className="text-center font-bold mb-[10px] text-zinc-500">
                      IMPORTANT
                    </h2>
                    <p className="text-center">
                      {user.phone} is the phone number to be used.
                    </p>
                    <p className="text-center">
                      {user.location} will be used as your campus
                    </p>
                  </div>
                  {loading || loadingFirstPhoto || loadingSecPhoto ? (
                    <div className="w-full">
                      <p className="text-center">Please wait 😊 ... </p>
                    </div>
                  ) : (
                    <button
                      className="bg-emerald-800 text-white w-full p-[8px] rounded-md"
                      onClick={handleUpdate}
                    >
                      Update Trade
                    </button>
                  )}
                </form>
              </>
            ) : (
              <div
                onClick={() => {
                  setShowForm(true);

                  setupdateTitle(item.title);
                  setupdateCategory(item.category);
                  setupdateDescription(item.description);
                  setupdateFirstPhoto(item.firstPhoto);
                  setupdateSecPhoto(item.secPhoto);
                  setupdateThirdPhoto(item.thirdPhoto);
                  setupdateExchangeFor(item.exchangeFor);
                  setupdateDefect(item.defect);
                  setupdateSpecificAddress(item.specificAddress);
                  setupdateAvailability(item.availability);
                  setupdateSearchTerms(item.searchTerms);
                }}
                className="flex w-full h-[70vh] justify-center items-center"
              >
                <div>
                  <h2 className="font-bold  text-green-800 underline cursor-pointer text-center mb-[15px]">
                    Click Here To Update {item.title}
                  </h2>
                  <div className="flex justify-center">
                    <img
                      src={item.firstPhoto}
                      alt=""
                      className="h-[120px] w-[120px] object-cover rounded-lg"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}

        {/*  */}
      </div>
    </div>
  );
};

export default EditTrade;
