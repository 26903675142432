import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  AiFillStar,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClose,
  AiOutlineComment,
  AiOutlineDislike,
  AiOutlineLike,
  AiOutlinePhone,
  AiOutlineStar,
} from "react-icons/ai";
import { BsPen, BsPerson, BsTrash } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import TradeComment from "../TradeComment";

const OtherEvents = ({ data }) => {
  // fetch all my events
  const [allMyEvents, setAllMyEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const handleFetchMyEvents = async () => {
    try {
      setLoading(true);
      let username = data.username;
      let dataToSend = { username };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post("/event/mine", dataToSend, config);
      if (response) {
        setLoading(false);
        setAllMyEvents(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Your Events");
    }
  };

  useEffect(() => {
    handleFetchMyEvents();
  }, []);

  // delete my to rent
  const handleDeleteMyEvents = async (id) => {
    if (!id) return toast.error("Error mapping id. Contact admin");

    const userConfirmed = window.confirm("Delete This Event ? ");

    if (userConfirmed) {
      try {
        setLoading(true);
        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        await axios.delete("/event/" + id, config);
        toast.success("deleted succesfully");
        await handleFetchMyEvents();
      } catch (error) {
        setLoading(false);
        toast.error("failed to delete");
      }
    }
  };

  // working on modal
  const [selectedPost, setSelectedPost] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  // what happens when we click on a post
  const handlePostClick = (post) => {
    setSelectedPost(post);
    setIsPopUpOpen(true);
  };

  // like a event
  const handleLikeEvent = async (event) => {
    try {
      if (!user) {
        toast.error("Please Login To leave a like", { theme: "dark" });
        return;
      }

      let username = user?.username;
      let id = event._id;
      let likeData = { username };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.post("/event/like/" + id, likeData, config);
      if (res) {
        toast.success("Action Succesful");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed To Like");
    }
  };

  // attend event
  const handleAttend = async (event) => {
    try {
      if (!user) {
        toast.error("Please Login", { theme: "dark" });
        return;
      }

      let username = user.username;
      let id = event._id;
      let likeData = { username };

      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post("/event/attend/" + id, likeData, config);
      window.location.reload();
    } catch (error) {
      toast.error("Failed To Add");
    }
  };

  const PopUpPage = ({ post, onClose }) => {
    return (
      <div className="pop-up-page prompt ">
        {/* close btn */}

        {/* data */}
        <div className="pop-up-content">
          <div className="flex justify-center my-[20px] z-[999] ">
            <button onClick={onClose}>
              <AiOutlineClose
                className="text-5xl text-white p-[10px] rounded-full "
                style={{
                  border: "2px solid #188c56",
                  position: "sticky",
                  top: "20px",
                }}
                title="close"
              />
            </button>
          </div>
          <div className=" h-[70vh] sm:h-[60vh] overflow-y-scroll prompt">
            <div className="px-[10px]">
              <div className="mb-[40px] flex justify-between items-center">
                <p className="text-lg text-white font-bold mb-2">
                  {post.title}
                </p>
              </div>
            </div>

            <div>
              <img
                src={post.photo}
                alt=""
                className="max-h-[400px] object-contain w-full"
              />
            </div>

            <div className="mt-[20px] flex justify-between w-full gap-[10px] flex-wrap">
              <div className="flex gap-[30px] items-center">
                <div
                  className="flex gap-2 items-center"
                  onClick={() => handleAttend(post)}
                >
                  <BsPerson className="text-xl text-white" />
                  <p className="text-white">{post.attending.length}</p>
                </div>
                <div
                  className="flex gap-2 items-center"
                  onClick={() => handleLikeEvent(post)}
                >
                  <AiOutlineLike className="text-xl text-white" />
                  <p className="text-white">{post.likes.length}</p>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <AiOutlinePhone className="text-white" />
                <a href={`tel:${post.phone}`} className="text-white">
                  {post.phone}
                </a>
              </div>
            </div>

            <div className=" my-[30px]">
              <div className="mb-[22px]">
                <h2 className="text-emerald-700 font-bold mb-2">
                  Location Details
                </h2>
                <p className="text-zinc-200">{post.location}</p>
                <p className="text-zinc-200">{post.specificAddress}</p>
              </div>
              <div className="mb-[22px]">
                <h2 className="text-emerald-700 font-bold mb-2">
                  Prices and Charges
                </h2>
                <p className="text-zinc-200"> {post.prices}</p>
              </div>

              <div className="mb-[22px]">
                <h2 className="text-emerald-700 font-bold mb-2">
                  Time And Date Details
                </h2>
                <p className="text-zinc-200"> {post.timeAndDate}</p>
              </div>

              <p className="text-zinc-200 mb-[12px]">{post.description}</p>
            </div>

            {/* comment */}
            <div className="mt-[22px] pb-[10px] w-full flex justify-center">
              {user ? (
                <button
                  className="bg-teal-800 text-white p-[8px] rounded-lg"
                  onClick={() => handleAttend(post)}
                >
                  CONFIRM ATTENDING ?
                </button>
              ) : (
                <Link to="/login">
                  <p className="text-zinc-300 hover:text-teal-300 underline cursor-pointer">
                    PLEASE LOGIN TO CONFIRM ATTENDING
                  </p>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/*  */}

      {loading ? (
        <>
          <Spinner message="Fetching to rent .." />
        </>
      ) : (
        <>
          {allMyEvents.length < 1 ? (
            <>
              {/* <div className="h-[20vh] w-full flex justify-center items-center">
                <div>
                  <h2 className="text-center mb-2">😢Ouch.</h2>
                  <h2>{data.username} has not added an event</h2>
                </div>
              </div> */}
            </>
          ) : (
            <>
              <div>
                <h2 className="text-xl mb-[10px] text-zinc-400 font-bold">
                  {data.username}'s Events
                </h2>
              </div>

              <div className="flex gap-[10px] overflow-x-scroll w-full prompt pb-2">
                <div className="flex flex-nowrap">
                  {allMyEvents?.map((item) => (
                    <div
                      key={item._id}
                      className="flex-shrink-0 mr-[15px] cursor-pointer"
                    >
                      <div
                        className="relative rounded-lg group "
                        onClick={() => navigate(`/events/${item._id}`)}
                      >
                        <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                          <div
                            className="bg-gradient-to-t
                                  from-transparent to-black opacity-80 w-full h-full rounded-md"
                          >
                            {/* top stats */}
                            <div>
                              <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                                <div>
                                  <a href={`tel:+${item.phone}`}>
                                    {" "}
                                    <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                  </a>
                                </div>
                                <div className="flex gap-[20px]">
                                  <p className="text-white text-md flex items-center gap-[5px]">
                                    <BsPerson className="text-lg" />
                                    <span>{item.attending.length}</span>
                                  </p>
                                  {/* <p className="text-white text-md flex items-center gap-[5px]">
                                    <AiOutlineLike className="text-lg" />
                                    <span>{item.likes.length}</span>
                                  </p> */}
                                </div>
                              </div>
                              <div className="absolute top-[80px] left-3">
                                <p className="text-white">{item.title}</p>
                              </div>
                            </div>
                            {/*  */}
                            <div className="absolute bottom-[20px] left-[20px]  flex gap-[10%] w-full ">
                              <div>
                                <div className="flex gap-[10px] text-zinc-300">
                                  {/* <p>{item.location}</p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <img
                          src={item.photo}
                          alt=""
                          className="w-72 h-80 rounded-md object-cover"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}

      {/*  */}
      {/* pop up screen */}
      {isPopUpOpen && (
        <div className="pop-up-overlay">
          <PopUpPage
            post={selectedPost}
            onClose={() => setIsPopUpOpen(false)}
          />
        </div>
      )}
    </div>
  );
};

export default OtherEvents;
