export const DummyProducts = [
  {
    id: 1,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 2,
    title: "Niko shoe",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/2529147/pexels-photo-2529147.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 3,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/1785493/pexels-photo-1785493.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 4,
    title: "Yooh",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/572897/pexels-photo-572897.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 5,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 6,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 7,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/804049/pexels-photo-804049.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 8,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/2440061/pexels-photo-2440061.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 9,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 10,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 11,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 12,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 13,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 14,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 15,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 16,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 17,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 18,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 19,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 20,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
  {
    id: 21,
    title: "Microwave",
    description:
      "Ramtons microwave at a good price. Contact me if you want to purchase it",
    image1:
      "https://images.pexels.com/photos/4686822/pexels-photo-4686822.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/211761/pexels-photo-211761.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/16501297/pexels-photo-16501297/free-photo-of-corner-of-a-kitchen.jpeg?auto=compress&cs=tinysrgb&w=1600",
    featured: false,
    rating: 4,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    price: "Ksh. 5000",
    location: "Nairobi CBD",
    delivery: "Available within Nairobi",
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    searchTerms: "microwave, best, ramtons, nairobi",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
  },
];

export const DummyServices = [
  {
    id: 1,
    title: "Mama fua Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "wash, fua, clean, laundry ",
    image1:
      "https://images.pexels.com/photos/412020/pexels-photo-412020.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: 2,
    title: "Biking Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "bike, fun, friends, cycle ",
    image1:
      "https://images.pexels.com/photos/4834142/pexels-photo-4834142.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: 3,
    title: "Mama fua Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "wash, fua, clean, laundry ",
    image1:
      "https://images.pexels.com/photos/4834142/pexels-photo-4834142.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: 4,
    title: "Mama fua Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "wash, fua, clean, laundry ",
    image1:
      "https://images.pexels.com/photos/4834142/pexels-photo-4834142.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: 5,
    title: "Mama fua Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "wash, fua, clean, laundry ",
    image1:
      "https://images.pexels.com/photos/4834142/pexels-photo-4834142.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: 6,
    title: "Mama fua Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "wash, fua, clean, laundry ",
    image1:
      "https://images.pexels.com/photos/4834142/pexels-photo-4834142.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: 7,
    title: "Mama fua Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "wash, fua, clean, laundry ",
    image1:
      "https://images.pexels.com/photos/4834142/pexels-photo-4834142.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: 8,
    title: "Mama fua Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "wash, fua, clean, laundry ",
    image1:
      "https://images.pexels.com/photos/4834142/pexels-photo-4834142.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: 9,
    title: "Mama fua Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "wash, fua, clean, laundry ",
    image1:
      "https://images.pexels.com/photos/4834142/pexels-photo-4834142.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: 10,
    title: "Mama fua Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "wash, fua, clean, laundry ",
    image1:
      "https://images.pexels.com/photos/4834142/pexels-photo-4834142.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: 11,
    title: "Mama fua Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "wash, fua, clean, laundry ",
    image1:
      "https://images.pexels.com/photos/4834142/pexels-photo-4834142.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: 12,
    title: "Mama fua Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "wash, fua, clean, laundry ",
    image1:
      "https://images.pexels.com/photos/4834142/pexels-photo-4834142.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: 13,
    title: "Mama fua Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "wash, fua, clean, laundry ",
    image1:
      "https://images.pexels.com/photos/4834142/pexels-photo-4834142.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: 14,
    title: "Mama fua Services",
    description:
      "We wash clothes at an affordable rate. Call us to get in touch",
    rating: 3,
    comments: [
      {
        id: 1,
        sender: "mercydoe",
        comment: "That is a really good price",
        createdAt: "20 mins ago",
      },
      {
        id: 2,
        sender: "jamesdoe",
        comment: "I love ramtons. They are really good",
        createdAt: "28 mins ago",
      },
    ],
    likes: [
      {
        id: 1,
        sender: "jamesdoe",
      },
      {
        id: 2,
        sender: "mercydoe",
      },
      {
        id: 3,
        sender: "austin",
      },
    ],
    dislikes: [
      {
        id: 1,
        sender: "chris",
      },
      {
        id: 2,
        sender: "malek",
      },
    ],
    availability: "immediately",
    qualifications: "We have been servicing clients for 3 years",
    featured: "yes",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1600",
    username: "johndoe",
    condition: "used for 4 months",
    phone: "0786 665767",
    searchTerms: "wash, fua, clean, laundry ",
    image1:
      "https://images.pexels.com/photos/4834142/pexels-photo-4834142.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image2:
      "https://images.pexels.com/photos/4926677/pexels-photo-4926677.jpeg?auto=compress&cs=tinysrgb&w=1600",
    image3:
      "https://images.pexels.com/photos/1164339/pexels-photo-1164339.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
];

export const DummyLocations = [
  {
    id: 1,
    title: "Daystar University",
  },
];

export const DummyBizCategory = [
  {
    id: 1,
    title: "hardware",
  },
  {
    id: 2,
    title: "food&Drinks",
  },
  {
    id: 3,
    title: "groceries",
  },
  {
    id: 4,
    title: "housing",
  },
  {
    id: 5,
    title: "fun",
  },
  {
    id: 6,
    title: "transport",
  },
  {
    id: 7,
    title: "fashion",
  },
  {
    id: 8,
    title: "accomodation",
  },
  {
    id: 9,
    title: "laundry",
  },
  {
    id: 10,
    title: "social",
  },
  {
    id: 11,
    title: "others",
  },
];

export const DummyToRentCategory = [
  {
    id: 1,
    title: "fashion",
  },
  {
    id: 2,
    title: "electronics",
  },
  {
    id: 3,
    title: "services",
  },
  {
    id: 4,
    title: "automobile",
  },
  {
    id: 5,
    title: "housing",
  },
  {
    id: 6,
    title: "hardware",
  },
  {
    id: 7,
    title: "gaming",
  },
  {
    id: 8,
    title: "music",
  },
  {
    id: 9,
    title: "stationery",
  },
  {
    id: 10,
    title: "others",
  },
];

export const DummyToTradeCategory = [
  {
    id: 1,
    title: "fashion",
  },
  {
    id: 2,
    title: "electronics",
  },
  {
    id: 3,
    title: "services",
  },
  {
    id: 4,
    title: "automobile",
  },
  {
    id: 5,
    title: "gaming",
  },
  {
    id: 6,
    title: "music",
  },
  {
    id: 7,
    title: "appliances",
  },
  {
    id: 8,
    title: "fun",
  },
  {
    id: 9,
    title: "others",
  },
];

export const DummyMovies = [
  {
    id: 1,
    title: "Raya And The Last Dragon",
    category: "Movie",
    likes: [],
    createdAt: "20 mins Ago",
    genre: "Drama",
    link: "https://youtube.com",
    owner: "Josh",
  },
  {
    id: 1,
    title: "Prison Break",
    category: "Movie",
    likes: [],
    createdAt: "20 mins Ago",
    genre: "Drama",
    link: "https://youtube.com",
    owner: "Josh",
  },
  {
    id: 1,
    title: "Prison Break",
    category: "Movie",
    likes: [],
    createdAt: "20 mins Ago",
    genre: "Drama",
    link: "https://youtube.com",
    owner: "Josh",
  },
  {
    id: 1,
    title: "Prison Break",
    category: "Movie",
    likes: [],
    createdAt: "20 mins Ago",
    genre: "Drama",
    link: "https://youtube.com",
    owner: "Josh",
  },
  {
    id: 1,
    title: "Prison Break",
    category: "Movie",
    likes: [],
    createdAt: "20 mins Ago",
    genre: "Drama",
    link: "https://youtube.com",
    owner: "Josh",
  },
  {
    id: 1,
    title: "Prison Break",
    category: "Movie",
    likes: [],
    createdAt: "20 mins Ago",
    genre: "Drama",
    link: "https://youtube.com",
    owner: "Josh",
  },
  {
    id: 1,
    title: "Prison Break",
    category: "Movie",
    likes: [],
    createdAt: "20 mins Ago",
    genre: "Drama",
    link: "https://youtube.com",
    owner: "Josh",
  },
];

export const DummyGenre = [
  { id: 1, title: "Action" },
  { id: 2, title: "Romance" },
  { id: 3, title: "Animation" },
  { id: 4, title: "Anime" },
  { id: 5, title: "Comedy" },
  { id: 6, title: "Thriller" },
  { id: 7, title: "Horror" },
  { id: 8, title: "Documentary" },
  { id: 9, title: "Other" },
];

export const Articlecategories = [
  { id: 1, title: "News" },
  { id: 2, title: "Startups" },
  { id: 3, title: "Politics" },
  { id: 4, title: "Fashion" },
  { id: 5, title: "Stories" },
  { id: 6, title: "Entrepreneurship" },
  { id: 7, title: "Campus" },
  { id: 8, title: "Tech" },
  { id: 9, title: "Dating" },
  { id: 10, title: "Other" },
];

export const DummyArticles = [
  {
    id: 1,
    title: "Roadmap to heaven is the best",
    profile:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=400",
    owner: "John Doe",
    createdAt: "20 days ago",
    photo:
      "https://images.pexels.com/photos/1547813/pexels-photo-1547813.jpeg?auto=compress&cs=tinysrgb&w=400",
    category: "Nature",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus magnam quasi deserunt. Porro fugiat possimus iusto dolores corporis nulla voluptates modi. Cumque doloribus quia vel tenetur consectetur voluptatibus pariatur tempore!",
    comments: 20,
    likes: 10,
  },
  {
    id: 2,
    title: "Soccer and the wolves",
    profile:
      "https://images.pexels.com/photos/1181686/pexels-photo-1181686.jpeg?auto=compress&cs=tinysrgb&w=400",
    owner: "cheptoo",
    createdAt: "22 days ago",
    photo:
      "https://images.pexels.com/photos/2209/people-men-grass-sport.jpg?auto=compress&cs=tinysrgb&w=400",
    category: "Nature",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus magnam quasi deserunt. Porro fugiat possimus iusto dolores corporis nulla voluptates modi. Cumque doloribus quia vel tenetur consectetur voluptatibus pariatur tempore!",
    comments: 20,
    likes: 10,
  },
  {
    id: 3,
    title: "We can make it if we try",
    profile:
      "https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg?auto=compress&cs=tinysrgb&w=400",
    owner: "Stacy Mercy",
    createdAt: "26 days ago",
    photo:
      "https://images.pexels.com/photos/962312/pexels-photo-962312.jpeg?auto=compress&cs=tinysrgb&w=400",
    category: "Nature",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus magnam quasi deserunt. Porro fugiat possimus iusto dolores corporis nulla voluptates modi. Cumque doloribus quia vel tenetur consectetur voluptatibus pariatur tempore!",
    comments: 20,
    likes: 10,
  },
  {
    id: 4,
    title: "Monkeys are very smart",
    profile:
      "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=400",
    owner: "Alexis Doe",
    createdAt: "29 days ago",
    photo:
      "https://images.pexels.com/photos/1670413/pexels-photo-1670413.jpeg?auto=compress&cs=tinysrgb&w=400",
    category: "Nature",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus magnam quasi deserunt. Porro fugiat possimus iusto dolores corporis nulla voluptates modi. Cumque doloribus quia vel tenetur consectetur voluptatibus pariatur tempore!",
    comments: 20,
    likes: 10,
  },
  {
    id: 5,
    title: "Rivers are the flowing heartbeat",
    profile:
      "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&w=400",
    owner: "John Doe",
    createdAt: "30 days ago",
    photo:
      "https://images.pexels.com/photos/1179225/pexels-photo-1179225.jpeg?auto=compress&cs=tinysrgb&w=400",
    category: "Nature",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus magnam quasi deserunt. Porro fugiat possimus iusto dolores corporis nulla voluptates modi. Cumque doloribus quia vel tenetur consectetur voluptatibus pariatur tempore!",
    comments: 20,
    likes: 10,
  },
];
