import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../axios";
import moment from "moment";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";

const Comment = ({ item }) => {
  const { user } = useSelector((state) => state.auth);

  // comment
  const [comment, setComment] = useState("");
  const [loadingComment, setLoadingComment] = useState(false);

  const handleComment = async (product) => {
    if (!comment) {
      toast.error("Comment cannot be empty", { theme: "dark" });
      return;
    }

    // comment
    try {
      try {
        setLoadingComment(true);
        let username = user.username;
        let profile = user.profile;
        let id = product._id;
        let commentData = { username, comment, profile };

        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        // await axios.post("/biz/comment/" + id, commentData, config);
        setLoadingComment(false);
        setComment("");
      } catch (error) {
        setLoadingComment(false);
        toast.error("Failed To Comment", { theme: "dark" });
      }

      // create notification

      try {
        if (user?.username !== product.owner) {
          let sender = user.username;
          let receiver = item.owner;
          let id = product._id;
          let receiverEmail = product.ownerEmail;
          let message = `Your Business has a new comment -  ${comment}`;
          let referID = `/business/${id}`;
          let notifyData = {
            sender,
            receiver,
            receiverEmail,
            message,
            referID,
          };

          let token = user?.token;
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          await axios.post("/notify/create", notifyData, config);
        }
      } catch (error) {
        console.log("failed to notify", error);
      }

      // window.location.reload();
    } catch (error) {
      toast.error("Action Failed", { theme: "dark" });
    }
  };

  return (
    <div>
      {/*  */}
      <>
        {!user ? (
          <>
            <p className="font-bold">You Must Sign In To Comment</p>
          </>
        ) : (
          <>
            <form onSubmit={() => handleComment(item)}>
              <div>
                <label htmlFor="comment" className="flex items-center gap-8">
                  {user?.profile !== "" ? (
                    <div className="">
                      <img
                        src={user?.profile}
                        alt="profilePic"
                        className="w-[40px] h-[40px] rounded-full object-cover"
                      />
                    </div>
                  ) : (
                    <div className="w-[40px] h-[40px] bg-orange-700 rounded-full flex justify-center items-center text-zinc-200 text-xl">
                      {user?.username.substring(0, 1)}
                    </div>
                  )}

                  <p className="text-zinc-300">Add A Comment</p>
                </label>
              </div>
              <div className="flex items-center pt-[30px] w-[100%]  gap-[10px] ">
                <textarea
                  name="comment"
                  id="comment"
                  cols="30"
                  rows="2"
                  placeholder="Enter Comment"
                  className="w-[100%] bg-transparent p-[8px] outline-none border-none rounded-md"
                  style={{ border: "1px solid #5e5d5d" }}
                  required
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>

                <p
                  className="cursor-pointer"
                  onClick={() => handleComment(item)}
                >
                  {loadingComment ? (
                    <p className="bg-orange-800 text-zinc-200 p-[8px] rounded-lg">
                      wait...
                    </p>
                  ) : (
                    <p className="bg-[#087fd4] p-[8px] text-white rounded-lg">
                      comment
                    </p>
                  )}
                </p>
              </div>
            </form>
          </>
        )}
      </>
      {/*  */}
      {/* show all comments */}
      <div className="mt-[30px] max-h-[50vh] overflow-y-scroll prompt  p-[5px] rounded-lg">
        {/* fetch comments from latest to earliest */}
        {item.comments.length >= 1 ? (
          <>
            {[...item.comments].reverse().map((item, index) => (
              <div className="" key={index}>
                <div className=" block md:flex items-start md:items-center gap-[20px] mb-[16px] pb-[10px]">
                  <p className="">
                    <span className="text-emerald-600">
                      {item.username == user?.username ? (
                        "You"
                      ) : (
                        <Link to={`/other-profile?owner=${item.username}`}>
                          {item.profile && item.profile !== "" ? (
                            <>
                              <div className="">
                                <img
                                  src={item?.profile}
                                  alt="profilePic"
                                  className="w-[40px] h-[40px] rounded-full object-cover"
                                />
                              </div>
                            </>
                          ) : (
                            item.username
                          )}
                        </Link>
                      )}
                    </span>
                  </p>
                  <div className="flex flex-col md:flex-row justify-between  gap-[10px] md:gap-[40px] md:items-center">
                    <p className="text-zinc-400 text-md">{item.comment}</p>
                    <p className="text-zinc-500 text-sm">
                      {moment(item.createdAt).fromNow()}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="p-[10px] text-gray-400">
            <p>
              No Comments for{" "}
              <span className="text-gray-600">{item?.title}</span> Yet
            </p>
          </div>
        )}
      </div>
      {/*  */}
    </div>
  );
};

export default Comment;
