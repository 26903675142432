import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  AiFillStar,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClose,
  AiOutlineComment,
  AiOutlineDislike,
  AiOutlineEye,
  AiOutlineLike,
  AiOutlinePhone,
  AiOutlineStar,
} from "react-icons/ai";
import { BsPen, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import TradeComment from "../TradeComment";

const MyTrades = () => {
  // fetch all my trades
  const [allMyTrades, setAllMyTrades] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const handleFetchMyTrades = async () => {
    try {
      setLoading(true);
      let username = user.username;
      let dataToSend = { username };
      const response = await axios.post("/trade/mine", dataToSend);
      if (response) {
        setLoading(false);
        setAllMyTrades(response.data);
        console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Your Trades");
    }
  };

  useEffect(() => {
    handleFetchMyTrades();
  }, []);

  // delete my to rent
  const handleDeleteMyTrades = async (id) => {
    if (!id) return toast.error("Error mapping id. Contact admin");

    const userConfirmed = window.confirm("Delete This Trade ? ");

    if (userConfirmed) {
      try {
        setLoading(true);
        await axios.delete("/trade/" + id);
        toast.success("deleted succesfully");
        await handleFetchMyTrades();
      } catch (error) {
        setLoading(false);
        toast.error("failed to delete");
      }
    }
  };

  // working on modal
  const [selectedPost, setSelectedPost] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  // what happens when we click on a post
  const handlePostClick = (post) => {
    setSelectedPost(post);
    setIsPopUpOpen(true);
  };

  // like a trade
  const handleLikeTrade = async (trade) => {
    try {
      if (!user) {
        toast.error("Please Login To leave a like", { theme: "dark" });
        return;
      }

      let username = user.username;
      let id = trade._id;
      let likeData = { username };

      let res = await axios.post("/trade/like/" + id, likeData);
      if (res) {
        toast.success("Action Succesful");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed To Like");
    }
  };

  // dislike biz
  const handleDislikeTrade = async (trade) => {
    try {
      if (!user) {
        toast.error("Please Login To dislike", { theme: "dark" });
        return;
      }

      let username = user.username;
      let id = trade._id;
      let likeData = { username };

      await axios.post("/trade/dislike/" + id, likeData);
      window.location.reload();
    } catch (error) {
      toast.error("Failed To Dislike");
    }
  };

  const PopUpPage = ({ post, onClose }) => {
    // moving between images
    const [currentIndex, setCurrentIndex] = useState(0);

    const imagesArray = [`${post.firstPhoto}`, `${post.secPhoto}`];

    const prevImage = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? imagesArray.length - 1 : prevIndex - 1
      );
    };

    const nextImage = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === imagesArray.length - 1 ? 0 : prevIndex + 1
      );
    };
    return (
      <div className="pop-up-page prompt ">
        {/* close btn */}

        {/* data */}
        <div className="pop-up-content">
          <div className="flex justify-center my-[20px] z-[999] ">
            <button onClick={onClose}>
              <AiOutlineClose
                className="text-5xl text-white p-[10px] rounded-full "
                style={{
                  border: "2px solid #188c56",
                  position: "sticky",
                  top: "20px",
                }}
                title="close"
              />
            </button>
          </div>
          <div className=" h-[70vh] sm:h-[60vh] overflow-y-scroll prompt">
            <div className="px-[10px]">
              <div className="mb-[40px] flex justify-between items-center">
                <p className="text-lg text-white font-bold mb-2">
                  {post.title}
                </p>
                <p className="text-teal-600">#{post.category}</p>
              </div>
            </div>

            {/* images */}
            <div className="relative">
              <img
                src={imagesArray[currentIndex]}
                alt=""
                className="max-h-[400px] object-contain w-full"
              />
              <div className="absolute bottom-0 right-0 left-0 flex justify-center space-x-4">
                <button
                  onClick={prevImage}
                  className="text-white bg-teal-800 p-2 rounded-full"
                >
                  <AiOutlineArrowLeft className="text-2xl" />
                </button>
                <button
                  onClick={nextImage}
                  className="text-white bg-teal-800 p-2 rounded-full"
                >
                  <AiOutlineArrowRight className="text-2xl" />
                </button>
              </div>
            </div>
            {/*  */}

            <div className="mt-[20px] flex flex-col-reverse md:flex-row justify-between w-full gap-[20px] flex-wrap">
              <div className="flex gap-[40px] items-center">
                <div
                  className="flex gap-2 items-center"
                  onClick={() => handleLikeTrade(post)}
                >
                  <AiOutlineLike className="text-xl text-teal-500" />
                  <p className="text-teal-500">{post.likes.length}</p>
                </div>
                <div
                  className="flex gap-2 items-center"
                  onClick={() => handleDislikeTrade(post)}
                >
                  <AiOutlineDislike className="text-xl text-teal-500" />
                  <p className="text-teal-500">{post.dislikes.length}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <AiOutlineComment className="text-xl text-white" />
                  <p className="text-white">{post.comments.length}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <AiOutlineEye className="text-xl text-white" />
                  <p className="text-white">{post.views.length}</p>
                </div>
              </div>
              <div className="flex gap-2 items-center ">
                <AiOutlinePhone className=" text-xl text-teal-500" />
                <a href={`tel:${post.phone}`} className="text-teal-500">
                  {post.phone}
                </a>
              </div>
            </div>

            <div className=" my-[30px]">
              <h2 className="mb-[10px] font-bold text-zinc-500 text-lg">
                Description
              </h2>
              <p className="text-zinc-200">{post.description}</p>
            </div>

            <div className=" my-[30px]">
              <h2 className="mb-[10px] font-bold text-zinc-500 text-lg">
                Defects Information
              </h2>
              <p className="text-zinc-200">{post.defect}</p>
            </div>

            <div className=" my-[30px]">
              <h2 className="mb-[10px] font-bold text-zinc-500 text-lg">
                In Exchange
              </h2>
              <p className="text-zinc-200">{post.exchangeFor}</p>
            </div>

            <div className=" my-[30px]">
              <h2 className="mb-[10px] font-bold text-zinc-500 text-lg">
                Specific Address
              </h2>
              <p className="text-zinc-200">{post.specificAddress}</p>
            </div>

            <div className=" my-[30px]">
              <h2 className="mb-[10px] font-bold text-zinc-500 text-lg">
                Availability
              </h2>
              <p className="text-zinc-200">{post.availability}</p>
            </div>

            {/* comment */}

            <TradeComment item={post} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/*  */}

      {loading ? (
        <>
          <Spinner message="Fetching Your to rent .." />
        </>
      ) : (
        <>
          {allMyTrades.length < 1 ? (
            <>
              <div className="h-[20vh] w-full flex justify-center items-center">
                <div>
                  <h2 className="text-center mb-2">😢Ouch.</h2>
                  <h2>You have not added an item to trade</h2>
                  <Link to="/create-rent-out">
                    <h2 className="mt-[10px] mb-[10px] text-center text-emerald-600 underline">
                      Add one in {user?.location}
                    </h2>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <h2 className="text-xl mb-[10px] text-zinc-400 font-bold">
                  Your Trades
                </h2>
              </div>

              <div className="flex gap-[10px] overflow-x-scroll w-full prompt pb-2">
                <div className="flex flex-nowrap">
                  {allMyTrades?.map((item) => (
                    <div
                      key={item._id}
                      className="flex-shrink-0 mr-[15px] cursor-pointer"
                    >
                      <Link to={`/trade/${item._id}`}>
                        <div className="relative rounded-lg group ">
                          <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                            <div
                              className="bg-gradient-to-t
                                  from-transparent to-black opacity-90 w-full h-full rounded-md"
                            >
                              {/* top stats */}
                              <div>
                                <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                                  <div>
                                    <a href={`tel:+${item.phone}`}>
                                      {" "}
                                      <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                    </a>
                                  </div>
                                  <div className="flex gap-[20px]">
                                    {/* <p className="text-white text-md flex items-center gap-[5px]">
                                    <AiOutlineDislike className="text-lg" />
                                    <span>{item.dislikes.length}</span>
                                  </p> */}
                                    <p className="text-white text-md flex items-center gap-[5px]">
                                      <AiOutlineLike className="text-lg" />
                                      <span>{item.likes.length}</span>
                                    </p>
                                    {/* <p className="text-white text-md flex items-center gap-[5px]">
                                      <AiOutlineEye className="text-lg" />
                                      <span>{item.views.length}</span>
                                    </p> */}
                                    {/* <p className="text-white text-md flex items-center gap-[5px]">
                                    <AiOutlineComment className="text-lg" />
                                    <span>{item.comments.length}</span>
                                  </p> */}
                                  </div>
                                </div>
                                <div className="absolute top-[80px] left-3">
                                  <p className="text-white">{item.title}</p>
                                </div>
                              </div>
                              {/*  */}
                              <div className="absolute bottom-[20px] left-[20px]  flex gap-[10%] w-full ">
                                <div>
                                  <div className="flex gap-[10px] text-zinc-300">
                                    {/* <p>{item.location}</p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="min-h-[300px]">
                            <img
                              src={item.firstPhoto}
                              alt=""
                              className="w-72 h-80 rounded-md object-cover"
                            />
                          </div>
                        </div>
                      </Link>

                      {/* options */}
                      <div className="flex justify-between items-center mt-[10px]">
                        <Link to={`/edit-trade/${item._id}`}>
                          <BsPen
                            className="text-xl text-emerald-600 cursor-pointer"
                            title="update"
                          />
                        </Link>
                        <BsTrash
                          className="text-xl text-red-600 cursor-pointer"
                          onClick={() => handleDeleteMyTrades(item._id)}
                          title="delete"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}

      {/*  */}
      {/* pop up screen */}
      {isPopUpOpen && (
        <div className="pop-up-overlay">
          <PopUpPage
            post={selectedPost}
            onClose={() => setIsPopUpOpen(false)}
          />
        </div>
      )}
    </div>
  );
};

export default MyTrades;
