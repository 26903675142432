import React, { useState, useEffect } from "react";
import {
  AiFillStar,
  AiOutlineArrowRight,
  AiOutlineComment,
  AiOutlineDislike,
  AiOutlineLike,
  AiOutlinePhone,
  AiOutlineStar,
} from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "../axios";

const PopularBiz = () => {
  const [popularBiz, setPopularBiz] = useState([]);
  const handleFetchFeatured = async () => {
    try {
      let location = user?.location;

      let dataToSend = { location };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let response = await axios.post("/biz/mostviews", dataToSend, config);
      if (response) {
        // Extract only the first three businesses
        const topThreeBiz = response.data.slice(0, 5);
        setPopularBiz(topThreeBiz);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetchFeatured();
  }, []);

  const { user } = useSelector((state) => state.auth);

  return (
    <div>
      {/* wrapper */}
      {popularBiz?.length > 0 && (
        <>
          <div>
            <div className="mb-[15px] flex justify-between items-center">
              <h1 className="mb-3 text-zinc-300">Popular Businesses</h1>
              {user ? (
                <Link to="/business">
                  <div className="flex justify-between items-center gap-[1-px] hover:text-teal-800">
                    <p>SEE ALL</p>
                    <p>
                      <AiOutlineArrowRight />
                    </p>
                  </div>
                </Link>
              ) : (
                <Link to="/login">
                  <div className="flex justify-between items-center gap-[1-px] hover:text-teal-800">
                    <p>SEE ALL</p>
                    <p>
                      <AiOutlineArrowRight />
                    </p>
                  </div>
                </Link>
              )}
            </div>
          </div>
          <div className="flex gap-[10px] overflow-x-scroll w-full prompt pb-2">
            <div className="flex flex-nowrap">
              {popularBiz?.map((item) => (
                <div key={item.id} className="flex-shrink-0 mr-[15px]">
                  <Link to={`/business/${item._id}`}>
                    <div className="relative rounded-lg group ">
                      <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                        <div
                          className="bg-gradient-to-t
                                  from-transparent to-black opacity-90 w-full h-full rounded-md"
                        >
                          {/* top stats */}
                          <div>
                            <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                              <div>
                                <a href={`tel:+${item.phone}`}>
                                  {" "}
                                  <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                </a>
                              </div>
                              <div className="flex gap-[20px]">
                                {/* <p className="text-white text-md flex items-center gap-[5px]">
                              <AiOutlineDislike className="text-lg" />
                              <span>{item.dislikes}</span>
                            </p> */}
                                <p className="text-white text-md flex items-center gap-[5px]">
                                  <AiOutlineLike className="text-lg" />
                                  <span>{item.likes.length}</span>
                                </p>
                                {/* <p className="text-white text-md flex items-center gap-[5px]">
                                  <AiOutlineComment className="text-lg" />
                                  <span>{item.comments.length}</span>
                                </p> */}
                              </div>
                            </div>
                            <div className="absolute top-[80px] left-3">
                              <p className="text-white text-lg">{item.title}</p>
                            </div>
                          </div>
                          {/*  */}
                          <div className="absolute bottom-[20px] left-[20px]  flex gap-[10%] w-full ">
                            <div>
                              <div className="flex gap-[10px] text-zinc-300">
                                {/* <p>{item.location}</p> */}
                              </div>
                              {/* rating system */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <img
                        src={item.photo}
                        alt=""
                        loading="lazy"
                        className="w-72 h-80 rounded-lg object-cover"
                      />
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PopularBiz;
