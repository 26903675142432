import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../axios";
import moment from "moment";
import Spinner from "./Spinner";
import { FcAddImage } from "react-icons/fc";
import { AiOutlineArrowRight, AiOutlineClose } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import { BsTrash3 } from "react-icons/bs";
import imageCompression from "browser-image-compression";

const AddProduct = ({ item }) => {
  const { user } = useSelector((state) => state.auth);

  const [showCreateForm, setShowCreateForm] = useState(false);

  // product
  const [title, setTitle] = useState("");
  const [photo, setPhoto] = useState("");
  const [price, setPrice] = useState(0);

  // upload photo to cloudinary
  const [loadingPhoto, setLoadingPhoto] = useState(false);

  const postPhoto = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingPhoto(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingPhoto(false);
      setPhoto(urlData.url);
      toast.success("Uploaded Photo", { theme: "dark" });
    } catch (error) {
      setLoadingPhoto(false);
      toast.error("Error uploading Photo", { theme: "dark" });
    }
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();

    try {
      if (!title) return toast.error("Title is empty", { theme: "dark" });
      if (!photo) return toast.error("Please Upload Photo", { theme: "dark" });
      if (!price)
        return toast.error("Price of product needed", { theme: "dark" });

      let id = item._id;
      let productData = { title, photo, price };

      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        "/biz/product/" + id,
        productData,
        config
      );

      if (response) {
        toast.success("Added Product");
        setShowCreateForm(false);
        // console.log(response);
        // window.location.reload();
      }
    } catch (error) {
      toast.error("Failed To Add Product");
    }
  };

  // handle delete
  const [businessId, setBusinessId] = useState("");
  const [productId, setproductId] = useState("");

  return (
    <div>
      {/* form to add a new product */}

      {user?.username === item.owner && (
        <div className="mt-[20px] mb-[15px]">
          <div className="mb-[20px] flex justify-between items-center">
            <h2 className=" font-bold text-zinc-500">
              You can Add Your Products Here
            </h2>
            <div>
              {showCreateForm ? (
                <AiOutlineClose
                  className="text-4xl bg-red-800 text-white font-bold p-2 rounded-md cursor-pointer"
                  onClick={() => setShowCreateForm(false)}
                />
              ) : (
                <IoMdAdd
                  className="text-4xl bg-emerald-800 text-white font-bold p-2 rounded-md cursor-pointer"
                  onClick={() => setShowCreateForm(true)}
                />
              )}
            </div>
          </div>

          {showCreateForm && (
            <form onSubmit={handleAddProduct}>
              <div className="flex flex-col gap-[5px] mb-[16px]">
                <label htmlFor="title" className="font-bold">
                  Product Title
                </label>
                <input
                  type="text"
                  id="title"
                  placeholder="Enter Title"
                  className="bg-transparent p-1 border border-zinc-400 outline-none rounded-md"
                  value={title}
                  maxLength={15}
                  minLength={3}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              {loadingPhoto ? (
                <Spinner message="uploading photo" />
              ) : (
                <div className="flex flex-col gap-[5px] mb-[16px]">
                  {photo ? (
                    <img
                      src={photo}
                      alt=""
                      className="h-[100px] object-contain"
                    />
                  ) : (
                    <label htmlFor="myphoto">
                      <FcAddImage className="text-4xl" />
                    </label>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => postPhoto(e.target.files[0])}
                    id="myphoto"
                    name="myFile"
                    className="hidden"
                  />
                </div>
              )}
              <div className="flex flex-col gap-[5px] mb-[16px]">
                <label htmlFor="price" className="font-bold">
                  Product Price. (Just the number)
                </label>
                <input
                  type="number"
                  id="price"
                  placeholder="Enter Price"
                  className="bg-transparent p-1 border border-zinc-400 outline-none rounded-md"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="flex w-full justify-center">
                <button
                  className="bg-emerald-800 text-white p-[9px] rounded-lg"
                  onClick={handleAddProduct}
                >
                  Add Product
                </button>
              </div>
            </form>
          )}
        </div>
      )}

      {/*  */}
      {/* show all products */}
      <div>
        {item?.products?.length > 0 && (
          <h2 className="font-bold mb-[10px] text-lg text-zinc-400">
            Products / Services From {item.title}
          </h2>
        )}
        <div className="overflow-x-auto pb-[15px]">
          <div className="flex flex-nowrap space-x-4">
            <>
              {item?.products?.map((p) => (
                <div key={p.id} className="flex-shrink-0 w-48">
                  <img
                    src={p.photo}
                    alt=""
                    className="w-full h-48 object-cover rounded-lg"
                  />
                  <div>
                    <p className="mt-2">{p.title}</p>
                    <div className="flex justify-between">
                      <p>Ksh. {p.price}</p>
                      {item.owner === user.username && (
                        <BsTrash3
                          className="text-xl text-red-500 cursor-pointer"
                          onClick={async () => {
                            try {
                              let token = user?.token;
                              const config = {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                },
                              };

                              const response = await axios.delete(
                                `/biz/product/${item._id}/products/${p._id}`,
                                config
                              );

                              toast.success("deleted");
                              window.location.reload();
                              return response.data;
                            } catch (error) {
                              console.error(error);
                              throw error;
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          </div>
        </div>
      </div>
      {/*  */}
    </div>
  );
};

export default AddProduct;
