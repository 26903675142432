import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DummyBizCategory } from "../Dummydata";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import axios from "../axios";
import Hello from "../assets/hello.png";
import imageCompression from "browser-image-compression";

const CreateBiz = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  if (!user) {
    navigate("/login");
    toast.warning("Please Login", { theme: "dark" });
  }

  const [bizCount, setBizCount] = useState(0);
  const [loadCount, setLoadCount] = useState(false);
  const handleFetchBizCount = async () => {
    try {
      setLoadCount(true);
      let owner = user?.username;
      let dataToSend = { owner };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let response = await axios.post("/biz/counter", dataToSend, config);
      if (response) {
        console.log(response.data);
        setLoadCount(false);
        setBizCount(parseInt(response.data));
      }
    } catch (error) {
      navigate("/business");
      setLoadCount(false);
      toast.warning("Could not validate count");
    }
  };

  useEffect(() => {
    handleFetchBizCount();
  }, []);

  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [photo, setPhoto] = useState("");
  const [specificAddress, setSpecificAddress] = useState("");
  const [availability, setAvailability] = useState("");
  const [searchTerms, setSearchTerms] = useState("");
  const [chargeRates, setChargeRates] = useState("");

  // upload photot to cloudinary
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const postPhoto = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingPhoto(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingPhoto(false);
      setPhoto(urlData.url);
      toast.success("Uploaded Photo", { theme: "dark" });
    } catch (error) {
      setLoadingPhoto(false);
      toast.error("Error uploading Photo", { theme: "dark" });
    }
  };

  const [nowValidateBiz, setNowValidateBiz] = useState(false);

  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  const handleCreate = async (e) => {
    e.preventDefault();

    if (!user) return navigate("/login");
    if (!title) return toast.error("title missing", { theme: "dark" });
    if (!category) return toast.error("category missing", { theme: "dark" });
    if (!description)
      return toast.error("description missing", { theme: "dark" });
    if (!photo) return toast.error("photo missing", { theme: "dark" });
    if (!specificAddress)
      return toast.error("specificAddress missing", { theme: "dark" });
    if (!availability)
      return toast.error("availability missing", { theme: "dark" });
    if (!chargeRates)
      return toast.error("charging rates missing", { theme: "dark" });
    if (!searchTerms)
      return toast.error("searchTerms missing", { theme: "dark" });

    // add charging rates
    try {
      setLoading(true);
      let location = user?.location;
      let phone = user?.phone;
      let owner = user?.username;
      let ownerEmail = user?.email;

      if (!ownerEmail || ownerEmail == "")
        return toast.error("Please re-login, we can't find your email", {
          theme: "dark",
        });

      let dataToSend = {
        title,
        category,
        description,
        location,
        photo,
        phone,
        owner,
        ownerEmail,
        specificAddress,
        availability,
        searchTerms,
        chargeRates,
      };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post("/biz/", dataToSend, config);
      if (response) {
        setLoading(false);
        toast.success("Created " + title, { theme: "dark" });
        // navigate("/business");
        setNowValidateBiz(true);
      }
    } catch (error) {
      setLoading(false);
      setNowValidateBiz(false);
      toast.error("Error creating Biz", { theme: "dark" });
    }
  };

  return (
    <div>
      {/* wrapper */}
      {loadCount ? (
        <div className="w-full h-[78vh] flex justify-center items-center">
          <Spinner message="Verifying Status ..." />
        </div>
      ) : (
        <>
          {/* check if user is allowd to add more than one */}

          {/* allow creation if no business created before*/}
          {bizCount === 0 && (
            <>
              {nowValidateBiz ? (
                <div className=" px-[8px] sm:px-[2em] lg:px-[5em] py-[1em]">
                  <div>
                    <Link to="/business">
                      <AiOutlineArrowLeft className="text-2xl font-bold" />
                    </Link>
                  </div>
                  <div className="h-[60vh] w-full flex flex-col justify-center ">
                    <div className="flex justify-center">
                      <div>
                        <div className="w-full flex justify-center mb-2">
                          <img src={Hello} alt="hello" />
                        </div>
                        <h2 className="mb-2 text-center">
                          Hello Thank You For Adding A Business in{" "}
                          {user?.location}
                        </h2>
                        <h2 className="mb-2 text-center">
                          For safety and credibility, we will{" "}
                          <span className="text-emerald-300">verify</span> this
                          business and get back to you.
                        </h2>
                        <h2 className="mb-2 text-center">
                          Verification process up to 48 hours
                        </h2>
                        <p className="pb-2 text-center">
                          We will email you when completed.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="px-[10px] pt-[10px]">
                    {/* topbar */}
                    <div>
                      <div className="flex gap-8 items-center">
                        <Link to="/business">
                          <AiOutlineArrowLeft className="text-2xl" />
                        </Link>
                        <div>
                          <h2 className="font-bold text-md text-zinc-400">
                            Add New Business
                          </h2>
                          <Link to="/my-profile">
                            <p className="text-sm underline text-emerald-500">
                              Your Location : {user?.location}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* form */}
                    <div className="mt-[2em] pb-[2em] w-full">
                      <form
                        className=" w-[98%] sm:w-[80%] lg:w-[50%] m-auto"
                        onSubmit={handleCreate}
                      >
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="title"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            Name of Your Business
                          </label>
                          <input
                            type="text"
                            placeholder="title here"
                            id="title"
                            value={title}
                            required
                            minLength={2}
                            maxLength={80}
                            onChange={(e) => setTitle(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          />
                        </div>
                        {/* category */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="category"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            Best Category For Your Biz
                          </label>

                          <select
                            name="category"
                            id="category"
                            className="p-[8px] bg-[#1E1C1C] text-white  border border-zinc-400 rounded-lg"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option value="">Choose</option>
                            {DummyBizCategory.map((item) => (
                              <option key={item.id}>{item.title}</option>
                            ))}
                          </select>
                        </div>
                        {/* description */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="desc"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            Briefly Describe What You Do
                          </label>
                          <textarea
                            name="desc"
                            id="desc"
                            cols="30"
                            rows="3"
                            minLength={5}
                            maxLength={500}
                            required
                            placeholder="what you do max(500)"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          ></textarea>
                        </div>
                        {/* charging rates */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="chargeRates"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            What Are Your Charging Rates
                          </label>

                          <textarea
                            name="chargeRates"
                            id="chargeRates"
                            cols="30"
                            rows="2"
                            minLength={5}
                            maxLength={500}
                            required
                            placeholder="Discounts, offers, holiday cuts e.t.c"
                            value={chargeRates}
                            onChange={(e) => setChargeRates(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          ></textarea>
                        </div>
                        {/* upload image */}
                        <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                          <div className="flex flex-col gap-2 mt-[20px]">
                            <label
                              htmlFor="mainPhoto"
                              className="flex items-center gap-[20px] flex-wrap"
                            >
                              <p>Please Select Photo</p>
                              <div className="flex flex-col items-center">
                                {loadingPhoto ? (
                                  <Spinner message="uploading ..." />
                                ) : (
                                  <img
                                    src={
                                      photo
                                        ? photo
                                        : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                    }
                                    alt=""
                                    className="w-[100px] h-[100px] object-cover"
                                  />
                                )}
                              </div>
                            </label>
                            <input
                              type="file"
                              placeholder="Add Image"
                              accept="image/*"
                              onChange={(e) => postPhoto(e.target.files[0])}
                              required
                              id="mainPhoto"
                              className="hidden"
                            />
                          </div>
                        </div>
                        {/* specificAddress */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="specificAdd"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            <p>Campus Location Name</p>
                            <p className="text-sm text-emerald-600">
                              Where is your campus located ?
                            </p>
                          </label>
                          <input
                            type="text"
                            placeholder="popular local name"
                            id="specificAdd"
                            value={specificAddress}
                            required
                            minLength={2}
                            maxLength={50}
                            onChange={(e) => setSpecificAddress(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          />
                        </div>
                        {/* availability */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="availability"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            <p>Opening And Closing Hours</p>
                            <p className="text-sm text-emerald-600">
                              i.e 8:00AM - 10:00PM
                            </p>
                          </label>
                          <input
                            type="text"
                            placeholder="enter your availability"
                            id="availability"
                            value={availability}
                            required
                            minLength={2}
                            maxLength={100}
                            onChange={(e) => setAvailability(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          />
                        </div>
                        {/* searchTerms */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="specificAdd"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            <p>Search terms associated.</p>
                            <p className="text-sm text-emerald-600">
                              separate by comma (,)
                            </p>
                          </label>
                          <input
                            type="text"
                            placeholder="searchTerms separated by comma"
                            id="specificAdd"
                            value={searchTerms}
                            required
                            minLength={2}
                            maxLength={70}
                            onChange={(e) => setSearchTerms(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          />
                        </div>
                        <div className="mb-[14px]">
                          <h2 className="text-center font-bold mb-[10px] text-zinc-500">
                            IMPORTANT
                          </h2>
                          <p className="text-center">
                            {user.phone} is the phone number to be used.
                          </p>
                          <p className="text-center">
                            {user.location} will be used as your location
                          </p>
                          <p className="text-center">
                            You can always update visibility later on
                          </p>
                          <Link to="/my-profile" className="text-center">
                            <div className="text-emerald-600 underline">
                              You Can Change Here
                            </div>
                          </Link>
                        </div>
                        {loading || loadingPhoto ? (
                          <div className="w-full">
                            <p className="text-center">Please wait 😊 ... </p>
                          </div>
                        ) : (
                          <button
                            className="bg-emerald-800 text-white w-full p-[8px] rounded-md"
                            onClick={handleCreate}
                          >
                            Add New Biz
                          </button>
                        )}
                      </form>
                    </div>
                    {/*  */}
                  </div>
                </>
              )}
            </>
          )}

          {/* allow creation of up to 4 id user has paid */}
          {bizCount > 0 && bizCount < 4 && user?.isPaid && (
            <>
              {nowValidateBiz ? (
                <div className=" px-[8px] sm:px-[2em] lg:px-[5em] py-[1em]">
                  <div>
                    <Link to="/business">
                      <AiOutlineArrowLeft className="text-2xl font-bold" />
                    </Link>
                  </div>
                  <div className="h-[60vh] w-full flex flex-col justify-center ">
                    <div className="flex justify-center">
                      <div>
                        <div className="w-full flex justify-center mb-2">
                          <img src={Hello} alt="hello" />
                        </div>
                        <h2 className="mb-2 text-center">
                          Hello Thank You For Adding A Business in{" "}
                          {user?.location}
                        </h2>
                        <h2 className="mb-2 text-center">
                          For safety and credibility, we will{" "}
                          <span className="text-emerald-300">verify</span> this
                          business and get back to you.
                        </h2>
                        <h2 className="mb-2 text-center">
                          Verification process up to 48 hours
                        </h2>
                        <p className="pb-2 text-center">
                          We will text you using 0798 556471 when completed.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="px-[10px] pt-[10px]">
                    {/* topbar */}
                    <div>
                      <div className="flex gap-8 items-center">
                        <Link to="/business">
                          <AiOutlineArrowLeft className="text-2xl" />
                        </Link>
                        <div>
                          <h2 className="font-bold text-md text-zinc-400">
                            Add New Business
                          </h2>
                          <Link to="/my-profile">
                            <p className="text-sm underline text-emerald-500">
                              Your Location : {user?.location}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* form */}
                    <div className="mt-[2em] pb-[2em] w-full">
                      <form
                        className=" w-[98%] sm:w-[80%] lg:w-[50%] m-auto"
                        onSubmit={handleCreate}
                      >
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="title"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            Name of Your Business
                          </label>
                          <input
                            type="text"
                            placeholder="title here"
                            id="title"
                            value={title}
                            required
                            minLength={2}
                            maxLength={80}
                            onChange={(e) => setTitle(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          />
                        </div>
                        {/* category */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="category"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            Best Category For Your Biz
                          </label>

                          <select
                            name="category"
                            id="category"
                            className="p-[8px] bg-[#1E1C1C] text-white  border border-zinc-400 rounded-lg"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option value="">Choose</option>
                            {DummyBizCategory.map((item) => (
                              <option key={item.id}>{item.title}</option>
                            ))}
                          </select>
                        </div>
                        {/* description */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="desc"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            Briefly Describe What You Do
                          </label>
                          <textarea
                            name="desc"
                            id="desc"
                            cols="30"
                            rows="3"
                            minLength={5}
                            maxLength={500}
                            required
                            placeholder="what you do max(500)"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          ></textarea>
                        </div>
                        {/* charging rates */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="chargeRates"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            What Are Your Charging Rates
                          </label>

                          <textarea
                            name="chargeRates"
                            id="chargeRates"
                            cols="30"
                            rows="2"
                            minLength={5}
                            maxLength={500}
                            required
                            placeholder="Discounts, offers, holiday cuts e.t.c"
                            value={chargeRates}
                            onChange={(e) => setChargeRates(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          ></textarea>
                        </div>
                        {/* upload image */}
                        <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                          <div className="flex flex-col gap-2 mt-[20px]">
                            <label
                              htmlFor="mainPhoto"
                              className="flex items-center gap-[20px] flex-wrap"
                            >
                              <p>Please Select Photo</p>
                              <div className="flex flex-col items-center">
                                {loadingPhoto ? (
                                  <Spinner message="uploading ..." />
                                ) : (
                                  <img
                                    src={
                                      photo
                                        ? photo
                                        : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                    }
                                    alt=""
                                    className="w-[100px] h-[100px] object-cover"
                                  />
                                )}
                              </div>
                            </label>
                            <input
                              type="file"
                              placeholder="Add Image"
                              accept="image/*"
                              onChange={(e) => postPhoto(e.target.files[0])}
                              required
                              id="mainPhoto"
                              className="hidden"
                            />
                          </div>
                        </div>
                        {/* specificAddress */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="specificAdd"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            <p>Campus Location Name</p>
                            <p className="text-sm text-emerald-600">
                              Where is your campus located ?
                            </p>
                          </label>
                          <input
                            type="text"
                            placeholder="popular local name"
                            id="specificAdd"
                            value={specificAddress}
                            required
                            minLength={2}
                            maxLength={50}
                            onChange={(e) => setSpecificAddress(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          />
                        </div>
                        {/* availability */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="availability"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            <p>Opening And Closing Hours</p>
                            <p className="text-sm text-emerald-600">
                              i.e 8:00AM - 10:00PM
                            </p>
                          </label>
                          <input
                            type="text"
                            placeholder="enter your availability"
                            id="availability"
                            value={availability}
                            required
                            minLength={2}
                            maxLength={100}
                            onChange={(e) => setAvailability(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          />
                        </div>
                        {/* searchTerms */}
                        <div className="flex flex-col gap-[8px] mb-[12px]">
                          <label
                            htmlFor="specificAdd"
                            className="text-lg font-bold"
                            style={{ fontWeight: 600 }}
                          >
                            <p>Search terms associated.</p>
                            <p className="text-sm text-emerald-600">
                              separate by comma (,)
                            </p>
                          </label>
                          <input
                            type="text"
                            placeholder="searchTerms separated by comma"
                            id="specificAdd"
                            value={searchTerms}
                            required
                            minLength={2}
                            maxLength={70}
                            onChange={(e) => setSearchTerms(e.target.value)}
                            className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                          />
                        </div>
                        <div className="mb-[14px]">
                          <h2 className="text-center font-bold mb-[10px] text-zinc-500">
                            IMPORTANT
                          </h2>
                          <p className="text-center">
                            {user.phone} is the phone number to be used.
                          </p>
                          <p className="text-center">
                            {user.location} will be used as your location
                          </p>
                          <p className="text-center">
                            You can always update visibility later on
                          </p>
                          <Link to="/my-profile" className="text-center">
                            <div className="text-emerald-600 underline">
                              You Can Change Here
                            </div>
                          </Link>
                        </div>
                        {loading || loadingPhoto ? (
                          <div className="w-full">
                            <p className="text-center">Please wait 😊 ... </p>
                          </div>
                        ) : (
                          <button
                            className="bg-emerald-800 text-white w-full p-[8px] rounded-md"
                            onClick={handleCreate}
                          >
                            Add New Biz
                          </button>
                        )}
                      </form>
                    </div>
                    {/*  */}
                  </div>
                </>
              )}
            </>
          )}

          {/* no creation till payment */}
          {bizCount > 0 && user?.isPaid === false && (
            <div>
              <div className="h-[80vh] w-full flex justify-center items-center">
                <div>
                  <div className="w-full flex justify-center">
                    <img src={Hello} alt="" />
                  </div>
                  <h2 className="text-xl  text-zinc-300 text-center">
                    Hold Up ✋. You can Only Create One Business On Free Account
                  </h2>
                  <p className="text-md text-zinc-300 text-center mb-3">
                    Upgrade to our gold plan to add up to 4 businesses
                  </p>
                  <p className="text-sm text-zinc-300 text-center">
                    Alternatively you can replace your current business with a
                    new one
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* no creation at all. Limit reached */}
          {bizCount > 4 && (
            <div>
              <div className="h-[80vh] w-full flex justify-center items-center">
                <div>
                  <div className="w-full flex justify-center">
                    <img src={Hello} alt="" />
                  </div>
                  <h2 className="text-xl  text-zinc-300 text-center">
                    Hold Up ✋. Limit Reached
                  </h2>
                  <p className="text-md text-zinc-300 text-center mb-3">
                    You can only create a maximum of 4 businesses
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CreateBiz;
