import React, { useEffect, useState } from "react";
import logo from "../../assets/officialOne.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import { useSelector, useDispatch } from "react-redux";
import { register, reset } from "../../features/auth/authSlice";
import axios from "../../axios";
import { DummyLocations } from "../../Dummydata";
import { Helmet } from "react-helmet";
import { MdOutlinePhone } from "react-icons/md";

const Register = () => {
  const [seePass, setSeePass] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [location, setLocation] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (navigator.onLine) {
      console.log("online");
    } else {
      console.log("offline");
      toast.error("Network Error");
    }

    if (isError) {
      // toast.error("Please Check Login Details");
      toast.error("Please Check Network");
    }

    if (isSuccess || user) {
      // toast.success("Welcome to chilltons!");
      navigate("/home");
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const [loading, setLoading] = useState(false);

  const handleCreateAccount = async (e) => {
    e.preventDefault();

    if (!username)
      return toast.error("Please enter username", { theme: "dark" });

    if (!location)
      return toast.error("Please Choose Campus", { theme: "dark" });

    if (!email) return toast.error("Please enter email", { theme: "dark" });
    if (!phone) return toast.error("Please enter phone", { theme: "dark" });
    if (!password)
      return toast.error("Please enter password", { theme: "dark" });

    //verify username
    if (username.length < 3 || username.length > 20) {
      toast.warning("username between 3 - 20 letters", { theme: "dark" });
      toast.info("username is just for login", { theme: "dark" });
      return;
    }

    //verify phone number accuracy
    if (phone.length < 8)
      return toast.error("Phone number incomplete", { theme: "dark" });
    const containsLetters = /[a-zA-Z]/.test(phone);
    if (containsLetters)
      return toast.error("Phone cannot have letters.", { theme: "dark" });

    //verify that it is gmail.com
    if (email.length < 12)
      return toast.error("Email incomplete", { theme: "dark" });
    let verifyEmail = email.includes("gmail.com");
    if (verifyEmail === false) {
      toast.error("Enter Gmail address", { theme: "dark" });
      toast.warning("xxx@gmail.com", { theme: "dark" });
      return;
    }

    //verify password
    if (password.length < 8)
      return toast.warning("Password too short", { theme: "dark" });

    try {
      setLoading(true);

      // check whether username already exists
      const nameToCheck = { username };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.post("/users/check", nameToCheck, config);
      if (data == "not exist") {
        const userData = { username, email, phone, password, location };
        dispatch(register(userData));
        setLoading(false);
        return;
      } else {
        toast.error(`username ${username} exists.`, { theme: "dark" });
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error creating account. Contact admin", { theme: "dark" });
    }
  };

  return (
    <div>
      <Helmet>
        <title>{`Register | iLocca`}</title>
        <meta
          name="description"
          content="Bridging the gap between local businesses and the community, offering a streamlined platform for residents to discover and acquire quality local products and services."
        />
      </Helmet>
      {/* topbar */}
      <div className=" w-full h-full top-0  text-white px-[10px] sm:px-[1em] md:px-[3em] lg:px-[4em] xl:px-[5em]  ">
        <div className="flex justify-between items-center pt-4">
          {/* logo */}
          <div>
            <Link to="/">
              <img src={logo} alt="" className="w-[60px] object-contain" />
            </Link>
          </div>
        </div>
      </div>
      <h2 className="text-center mb-[2em] mt-[2em] font-bold text-lg">
        Please Create An Account
      </h2>
      <form
        className=" w-[93%] sm:w-[80%]  md:w-[60%] xl:w-[50%]  2xl:w-[40%] m-auto"
        onSubmit={handleCreateAccount}
      >
        <div className="flex flex-col gap-[10px] mb-[22px]">
          <label htmlFor="username" className=" text-zinc-400 text-lg">
            <p className="font-bold">Create a username. No spaces</p>
            <p className="text-emerald-400 text-md">
              Cannot change later (3-20)
            </p>
          </label>
          <input
            type="text"
            id="username"
            placeholder="username i.e lucythegreat"
            className="bg-transparent border border-zinc-400 p-[8px] rounded-md outline-none"
            value={username}
            maxLength={40}
            minLength={3}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-[10px] mb-[22px]">
          <label htmlFor="phone" className=" text-zinc-400 text-lg">
            <p className="font-bold">Enter Your Phone Number</p>
          </label>
          <input
            type="text"
            id="phone"
            placeholder="Phone i.e 0xxx xxxxxx"
            className="bg-transparent border border-zinc-400 p-[8px] rounded-md outline-none"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-[10px] mb-[22px]">
          <label htmlFor="email" className="font-bold text-zinc-400 text-lg">
            Enter Your <span className="text-emerald-500">Gmail</span> Address
          </label>
          <input
            type="email"
            id="email"
            placeholder="youremail@gmail.com"
            className="bg-transparent border border-zinc-400 p-[8px] rounded-md outline-none"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-[10px] mb-[22px]">
          <label htmlFor="location" className="font-bold text-zinc-400 text-lg">
            Choose Your Campus
          </label>
          <select
            name="location"
            id="location"
            className="bg-[#1E1C1C] text-white border border-zinc-400 p-[8px] rounded-md outline-none"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          >
            <option value="">Choose</option>
            {DummyLocations.map((item) => (
              <option key={item.id}>{item.title}</option>
            ))}
          </select>
          <Link to="/add-new-location">
            <p className="text-sm text-emerald-300 underline">
              Can't See Your Campus ?
            </p>
          </Link>
        </div>
        <div className="flex items-center gap-[10px]">
          <div className="flex flex-col gap-[10px] mb-[22px] flex-[0.98]">
            <label
              htmlFor="password"
              className="font-bold text-zinc-400 text-lg"
            >
              Create A Strong Password
            </label>
            <input
              type={seePass ? "text" : "password"}
              id="password"
              placeholder="create password"
              className="bg-transparent border border-zinc-400 p-[8px] rounded-md outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex-[0.02]">
            {seePass ? (
              <AiOutlineEyeInvisible
                className="text-2xl cursor-pointer"
                onClick={() => setSeePass(false)}
              />
            ) : (
              <AiOutlineEye
                className="text-2xl cursor-pointer"
                onClick={() => setSeePass(true)}
              />
            )}
          </div>
        </div>

        <div>
          {isLoading || loading ? (
            <>
              <Spinner message="setting up ..." />
            </>
          ) : (
            <button
              className="bg-emerald-800 text-white p-[10px] w-full rounded-md outline-none"
              onClick={handleCreateAccount}
            >
              Create Account
            </button>
          )}
        </div>
        <div className="mt-[2em] w-full flex justify-between items-center gap-[1em] flex-wrap pb-3">
          <Link to="/login" className="underline text-emerald-400">
            <p>Sign in ?</p>
          </Link>
          <div className="">
            <a href="tel:+254 798 556471" className="hidden sm:flex">
              Call Us +254 798 556471
            </a>
            <a href="tel:+254 798 556471" className="sm:hidden">
              <MdOutlinePhone className="text-2xl text-emerald-400" />
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Register;
