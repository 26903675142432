import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import { AiOutlineArrowUp } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
const Terms = () => {
  const { user } = useSelector((state) => state.auth);
  // scroll to top functionality
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      {/* arrow to scroll to top */}
      {showArrow && (
        <div
          className="fixed bottom-20 right-4 text-3xl z-[999] cursor-pointer bg-teal-700 text-zinc-50 rounded-full p-[5px]"
          onClick={handleScrollTop}
        >
          <AiOutlineArrowUp />
        </div>
      )}

      <Helmet>
        <title>{`Terms | ${user?.location} | iLocca`}</title>
        <meta
          name="description"
          content="Bridging the gap between local businesses and the community, offering a streamlined platform for residents to discover and acquire quality local products and services."
        />
      </Helmet>
      {/* wrapper */}
      <div className=" px-[10px] sm:px-[2em] md:px-[5em] pt-[1em]">
        {/* topbar */}
        <div className="mb-[2em]">
          <Link to="/business">
            <IoArrowBack className="text-2xl" />
          </Link>
        </div>
        <div className=" w-[98%] sm:w-[90%] md:w-[80%] m-auto">
          <div className="mb-[1em]">
            <h2>Please Read Our Terms And Conditions</h2>
          </div>
          <div className="mb-[1em]">
            <h2 className="text-emerald-700 font-bold mb-2">1. Introduction</h2>
            <p>
              Welcome to iLocca, the platform that connects local businesses and
              consumers.
            </p>
            <p>
              By using our app, you agree to follow these terms and conditions.
            </p>
          </div>
          <div className="mb-[1em]">
            <h2 className="text-emerald-700 font-bold mb-2">
              2. Contractual Relationship
            </h2>
            <p>
              These terms and conditions govern the use of ilocca by any
              business owner and/or its customers. Your access and use of these
              services constitutes an agreement to be bound by the terms and
              conditions set herein. If you do not agree to the terms and
              conditions herein, you may not be eligible to use our services.
            </p>
            <p>
              ilocca may amend the Terms related to the Services from time to
              time. Amendments will be effective upon ilocca’s posting of such
              updated Terms at this location or the amended policies or
              supplemental terms on the applicable Service. Your continued
              access or use of the Services after such posting constitutes your
              consent to be bound by the Terms, as amended.
            </p>
            <p>
              In addition, ilocca reserves the right to update, amend and or
              delete the information contained in its APP, at any time without
              the need to give prior notice unless mandatory in accordance with
              the applicable laws, and without thereby accepting any liability
              in relation thereto.
            </p>
          </div>
          <div className="mb-[1em]">
            <h2 className="text-emerald-700 font-bold mb-2">3. Services</h2>
            <p>
              The services herein constitute the use of a technology platform
              whereby local businesses and service providers are able to connect
              with consumers via mobile applications or the website provided.
              Unless otherwise agreed by ilocca in a separate written agreement
              with you, the services are made solely available for your personal
              use.
            </p>
          </div>
          <div className="mb-[1em]">
            <h2 className="text-emerald-700 font-bold mb-2">4. License</h2>
            <p>
              Subject to your agreement to these terms and conditions, ilocca
              grants you a limited, non-exclusive, non-sublicensable, revocable
              and non-transferable license to;
            </p>
            <p>
              a) access and use the applications on your personal device solely
              in connection with your use of the services,
            </p>
            <p>
              b) access and use any content, materials and information that may
              be provided through the app, for personal use and noncommercial
              use.
            </p>
          </div>
          <div className="mb-[1em]">
            <h2 className="text-emerald-700 font-bold mb-2">5. Restrictions</h2>
            <p>You may not;</p>
            <p>
              a) remove any copyright, trademark or other proprietary services
              from the app,
            </p>
            <p>
              b) reproduce, modify, prepare derivative works based upon,
              distribute, license, lease, sell, resell, transfer, publicly
              display, publicly perform or otherwise exploit the services
              provided for by ilocca.
            </p>
            <p>
              c) You shall not use the app in a manner that is illegal,
              contradictory and obnoxious to the terms and conditions therein.
            </p>
            <p>
              You are responsible for keeping your account information and
              password secure.
            </p>
          </div>
          <div className="mb-[1em]">
            <h2 className="text-emerald-700 font-bold mb-2">6. Ownership</h2>
            <p>
              The services and all rights therein shall remain ilocca’s property
              or the property of ilocca’s licensors. You shall not claim any
              rights of ilocca’s property.
            </p>
          </div>
          <div className="mb-[1em]">
            <h2 className="text-emerald-700 font-bold mb-2">
              7. Business Listings
            </h2>
            <p>
              ilocca reserves the right to delete and/or remove any business
              listings that violates its terms and conditions as well as the
              policies regarding the use of the app.
            </p>
            <p>
              Your businesses are responsible for their listings and must be
              accurate and up-to-date, neither does ilocca guarantee the
              accuracy or completeness of business listings on its platform.
            </p>
          </div>
          <div className="mb-[1em]">
            <h2 className="text-emerald-700 font-bold mb-2">8. Payment</h2>
            <p>
              Take note that ilocca does not process any payment through the
              platform and therefore non-refundable, we do not accept
              responsibility for any issues related to payment processing and
              that disputes regarding refunds should be solved directly with the
              business owners and their customers.
            </p>
          </div>
          <div className="mb-[1em]">
            <h2 className="text-emerald-700 font-bold mb-2">
              9. Intellectual Property
            </h2>
            <p>
              ilocca is the owner or licensee of all intellectual and industrial
              property rights that relate to the software and the APP, as well
              as the content that can be accessed through it, which is uploaded
              to the APP by ilocca and/or on its behalf. These intellectual
              property rights, as well as the text, images, graphic design,
              navigation structure, information and content contained therein,
              are the property of ilocca.
            </p>
            <p>
              In spite of the foregoing, ilocca may not be the owner or license
              holder of content such as names or images, among others, of
              companies with which ilocca does not have a business relationship.
              In such cases, ilocca acquires the content from publicly available
              sources, and ilocca shall in no event be deemed to be related to
              any right belonging to ilocca.
            </p>
            <p>
              In spite of the foregoing, ilocca may not be the owner or license
              holder of content such as names or images, among others, of
              companies with which ilocca does not have a business relationship.
              In such cases, ilocca acquires the content from publicly available
              sources, and ilocca shall in no event be deemed to be related to
              any right belonging to ilocca.
            </p>
            <p>
              By registering on the APP and agreeing to these General Terms, the
              User grants ilocca, in relation to the content that he/she may
              provide, a worldwide, irrevocable, and transferable licence, free
              of charge, with the right to sub-license, use, copy, modify,
              create derivative works, distribute, publicize and exploit it in
              any way that may be deemed appropriate by ilocca, with or without
              any further communication to the User and without having to pay
              any amounts for such uses. All the above shall be understood as
              granting the indicated rights only to the maximum extent permitted
              by the applicable legislation. 
            </p>
          </div>
          <div className="mb-[1em]">
            <h2 className="text-emerald-700 font-bold mb-2">8. Severability</h2>
            <p>
              If any of the clauses of these general terms and conditions are
              found to be void and voidable under operation of any law, such
              clause(s) shall be deemed not to have been included. Such
              declaration of nullity will not cause the other clauses to be
              void, and shall remain effective between the parties.
            </p>
          </div>
          <div className="mb-[1em]">
            <h2 className="text-emerald-700 font-bold mb-2">
              10. Applicable Law
            </h2>
            <p>
              The relationship between ilocca and its users shall be governed
              and construed by the general terms and conditions therein and the
              relevant laws of the country it operates in.
            </p>
            <p>
              Except as otherwise set forth in these terms and conditions, the
              terms shall exclusively be governed by and construed in accordance
              with the laws of Kenya. Any disputes, conflicts, claims and
              controversy arising in relation with or connection to the services
              herein, shall first mandatorily be submitted to mediation
              proceedings. If such disputes have not been settled within sixty
              (60) days after a request for mediation has been submitted, such
              disputes can be referred to and shall be exclusively and finally
              resolved by arbitration under the Rules of Arbitration.
            </p>
          </div>
          <div className="pb-[1em]">
            <h2 className="text-emerald-700 font-bold mb-2">8. Contact Us</h2>
            <p>
              If you have any questions or concerns about our app or these terms
              and conditions, please contact us at{" "}
              <span>
                <a
                  href="mailto:daysseller@gmail.com"
                  className="font-bold text-emerald-700 underline"
                >
                  {" "}
                  daysseller@gmail.com
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
