export const NavLinks = [
  { id: 1, title: "Campus Hustle", path: "/business" },
  { id: 2, title: "Barter Trade", path: "/trade" },
  { id: 3, title: "Rent Today", path: "/rent-out" },
  { id: 4, title: "Campus Events", path: "/events" },
  // { id: 4, title: "Articles", path: "/articles" },
  { id: 5, title: "Socialize", path: "/social" },
  // { id: 3, title: "Innovate", path: "/innovate" },
  { id: 6, title: "Movies", path: "/movies" },
  { id: 7, title: "Terms & Conditions", path: "/terms" },
];

export const appBaseUrl = "https://ilocca.com/";
