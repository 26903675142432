import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../axios";
import moment from "moment";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";
import { BsTrash } from "react-icons/bs";

const RentComment = ({ rental }) => {
  const { user } = useSelector((state) => state.auth);

  // comment
  const [comment, setComment] = useState("");
  const [loadingComment, setLoadingComment] = useState(false);

  const handleComment = async (product) => {
    try {
      if (!comment) {
        toast.error("Comment cannot be empty", { theme: "dark" });
        return;
      }

      setLoadingComment(true);

      let username = user.username;
      let profile = user.profile;
      let phone = user.phone;
      let id = product._id;
      let commentData = { username, comment, profile, phone };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // console.log(commentData);

      await axios.post("/rent/comment/" + id, commentData, config);

      // notification
      if (user?.username !== product.owner) {
        // create notification
        let sender = user.username;
        let receiver = rental.owner;
        let receiverEmail = rental.ownerEmail;
        let message = `Your rental ${product.title} has a new comment - ${comment}`;
        let referID = `/rent-out/${id}`;
        let notifyData = { sender, receiver, receiverEmail, message, referID };

        await axios.post("/notify/create", notifyData, config);
      }

      setLoadingComment(false);
      setComment("");
      window.location.reload();
    } catch (error) {
      setLoadingComment(false);
      toast.error("Failed To Add Comment", { theme: "dark" });
    }
  };

  const handleDeleteComment = async (commentID) => {
    if (!commentID) return;

    try {
      let id = rental._id;
      // console.log(commentID, id);
      const response = await axios.delete(`/rent/comment/${id}/${commentID}`);
      if (response) {
        toast.success("deleted", { theme: "dark" });
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed", { theme: "dark" });
    }
  };

  return (
    <div>
      {/*  */}
      <>
        {!user ? (
          <>
            <p className="font-bold">You Must Sign In To Comment</p>
          </>
        ) : (
          <>
            <div className="mb-8 border border-emerald-400 rounded-lg p-1 text-center">
              <p>Please Note That iLocca does not facilitate any rentals</p>
              <p>Exchange wisely. Meet in open spaces, daytime</p>
              <p>Only transact when you are satisfied.</p>
            </div>
            <form onSubmit={() => handleComment(rental)}>
              <div>
                <label htmlFor="comment" className="flex items-center gap-8">
                  {user?.profile !== "" ? (
                    <div className="">
                      <img
                        src={user?.profile}
                        alt="profilePic"
                        className="w-[40px] h-[40px] rounded-full object-cover"
                      />
                    </div>
                  ) : (
                    <div className="w-[40px] h-[40px] bg-orange-700 rounded-full flex justify-center items-center text-zinc-200 text-xl">
                      {user?.username.substring(0, 1)}
                    </div>
                  )}

                  <p className="text-zinc-400">Add A Comment</p>
                </label>
              </div>
              <div className="flex items-center pt-[20px] w-[100%]  gap-[10px] ">
                <textarea
                  name="comment"
                  id="comment"
                  cols="30"
                  rows="2"
                  placeholder="Enter Comment"
                  className="w-[100%] bg-transparent p-[8px] outline-none border-none rounded-md text-white"
                  style={{ border: "1px solid #5e5d5d" }}
                  required
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>

                <p
                  className="cursor-pointer"
                  onClick={() => handleComment(rental)}
                >
                  {loadingComment ? (
                    <p className="bg-orange-800 text-zinc-200 p-[8px] rounded-lg">
                      wait...
                    </p>
                  ) : (
                    <p className="bg-[#087fd4] p-[8px] text-white rounded-lg">
                      Add
                    </p>
                  )}
                </p>
              </div>
            </form>
          </>
        )}
      </>
      {/*  */}
      {/* show all comments */}
      <div className="mt-[30px] max-h-[50vh] overflow-y-scroll prompt  p-[5px] rounded-lg">
        {/* fetch comments from latest to earliest */}
        {rental.comments.length >= 1 ? (
          <>
            {[...rental.comments].reverse().map((item, index) => (
              <div
                className="bg-[#212121] p-2 rounded-xl mb-3 flex justify-between items-center"
                key={index}
              >
                <div className=" block md:flex items-start md:items-center gap-[20px] mb-[16px] pb-[10px]">
                  <p className="">
                    <span className="text-emerald-600">
                      {item.username == user?.username ? (
                        "You"
                      ) : (
                        <Link to={`/other-profile?owner=${item.username}`}>
                          {item.profile && item.profile !== "" ? (
                            <>
                              <div className="">
                                <img
                                  src={item?.profile}
                                  alt="profilePic"
                                  className="w-[40px] h-[40px] rounded-full object-cover"
                                />
                              </div>
                            </>
                          ) : (
                            item.username
                          )}
                        </Link>
                      )}
                    </span>
                  </p>
                  <div className="flex flex-col gap-[10px] items-start">
                    <p className="text-zinc-300 text-md mt-2">{item.comment}</p>

                    <div>
                      <div>
                        <p className="text-zinc-500 text-sm">
                          {moment(item.createdAt).fromNow()}
                        </p>
                      </div>
                      <div>
                        {rental.owner === user?.username && (
                          <>
                            {rental.phone !== item.phone && (
                              <a
                                href={`tel:+${item.phone}`}
                                className="text-emerald-400"
                              >
                                Call {item.phone}
                              </a>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {item.username === user?.username && (
                  <div>
                    <BsTrash
                      className="text-red-500 cursor-pointer text-xl"
                      onClick={() => handleDeleteComment(item._id)}
                    />
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <div className="p-[10px] text-gray-400">
            <p>
              No Comments Yet for{" "}
              <span className="text-gray-600">{rental?.title}</span> Yet
            </p>
          </div>
        )}
      </div>
      {/*  */}
    </div>
  );
};

export default RentComment;
