import React, { useEffect, useRef, useState } from "react";
import {
  AiFillStar,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineArrowUp,
  AiOutlineComment,
  AiOutlineLike,
  AiOutlinePhone,
  AiOutlineSearch,
  AiOutlineStar,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";
import {
  MdOutlineAddBusiness,
  MdOutlineContentCopy,
  MdOutlineReportProblem,
} from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import "./masonry.css";
import axios from "../axios";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { useSelector } from "react-redux";
import { DummyBizCategory } from "../Dummydata";
import { IoReload, IoShareSocialOutline } from "react-icons/io5";
import { Helmet } from "react-helmet";
import { appBaseUrl } from "../constants";

const Businesses = () => {
  const [allBiz, setAllBiz] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate("/login");
      toast.warning("Account Needed");
    }
  }, [user, navigate]);

  // console.log(user);

  // fetch businesses around user location
  const handleFetchBiz = async () => {
    try {
      setLoading(true);
      // fetch based on <location> from user
      let location = user?.location;
      let approved = "yes";
      let dataToSend = { location, approved };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post("/biz/location", dataToSend, config);
      if (response) {
        setLoading(false);
        setAllBiz(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Biz", { theme: "dark" });
    }
  };

  useEffect(() => {
    handleFetchBiz();
  }, []);

  const breakpointColumnsObj = {
    default: 5,
    3000: 5,
    2000: 5,
    1800: 4,
    1500: 3,
    900: 2,
    500: 1,
  };

  //   pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 14;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = allBiz?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(allBiz?.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(4);

  const handleClick = (number) => {
    setStart(number);
    setEnd(number + 3);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      handleClick(currentPage);
    }
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      handleClick(currentPage);
    }
  };

  const changeCurrentPage = (id) => {
    setCurrentPage(id);
  };

  // search  states
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setsearchTimeout] = useState(null);
  const [searchedResults, setSearchedResults] = useState(null);

  //search Function 2

  // search user func
  const handleSearchChange = async (e) => {
    e.preventDefault();

    clearTimeout(setsearchTimeout);

    setSearchText(e.target.value);

    // console.log(searchText);

    setsearchTimeout(
      setTimeout(() => {
        const searchResults = allBiz.filter(
          (item) =>
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.searchTerms.toLowerCase().includes(searchText.toLowerCase()) ||
            item.specificAddress
              .toLowerCase()
              .includes(searchText.toLowerCase())
        );

        setSearchedResults(searchResults);
      }, 500)
    );
  };

  // scroll to top functionality
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const textRef = useRef(null);

  const handleCopyToClipboard = async (textToCopy) => {
    try {
      // Get the text from your input or any other source
      // You can replace this with your dynamic input

      // Use the Clipboard API to write the text to the clipboard
      await navigator.clipboard.writeText(textToCopy);

      // Log success or update UI accordingly
      console.log("Text copied to clipboard:", textToCopy);
      toast.success("Copied The Link. Share", { theme: "dark" });
    } catch (err) {
      // Handle errors
      console.error("Error copying to clipboard:", err);
    }
  };

  const handleAddViews = async (id) => {
    try {
      if (user) {
        let username = user.username;
        let viewsData = { username };
        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios.post("/biz/view/" + id, viewsData, config);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCall = async (item) => {
    try {
      if (user && user?.username !== item.owner) {
        let caller = user?.username;
        let receiver = item.owner;
        let dataToSend = { caller, receiver };
        // console.log(dataToSend);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let visibleToAllCampuses = "no";

  const [loadingImage, setLoadingImage] = useState(true);

  const handleImageLoaded = async () => {
    setLoadingImage(false);
  };

  return (
    <div>
      {/* arrow to scroll to top */}
      {showArrow && (
        <div
          className="fixed bottom-20 right-4 text-3xl z-[999] cursor-pointer bg-teal-700 text-zinc-50 rounded-full p-[5px]"
          onClick={handleScrollTop}
        >
          <AiOutlineArrowUp />
        </div>
      )}

      <Helmet>
        <title>{`Businesses | ${user?.location} | iLocca`}</title>
        <meta
          name="description"
          content="Bridging the gap between local businesses and the community, offering a streamlined platform for residents to discover and acquire quality local products and services."
        />
      </Helmet>

      {/* wrapper */}
      <div className="px-[10px] sm:px-[3em] lg:px-[4em] pt-3">
        {/* topbar */}
        {!searchText && (
          <div className="flex gap-4 items-center justify-between">
            <Link to="/home">
              <AiOutlineArrowLeft className="text-2xl" />
            </Link>
            <div>
              <h2 className="font-bold text-lg mb-1 text-zinc-400 text-end">
                Browse Campus Businesses
              </h2>

              <p className="text-sm text-emerald-500">
                Call Sellers Directly and Negotiate Price
              </p>
            </div>
          </div>
        )}

        {!searchText && (
          <div className="flex justify-between w-full my-[2.3em]">
            <div className="flex flex-col gap-1">
              <label htmlFor="allCampuses" className="text-zinc-400">
                Browse Public Biz
              </label>
              <select
                name="allCampuses"
                id="allCampuses"
                className="bg-[#212121] p-1 rounded-xl outline-none"
                onChange={async (e) => {
                  visibleToAllCampuses = e.target.value;
                  console.log(visibleToAllCampuses);
                  if (visibleToAllCampuses == "yes") {
                    setLoading(true);
                    let approved = "yes";
                    let dataToSend = { visibleToAllCampuses, approved };
                    let token = user?.token;
                    const config = {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    };
                    let response = await axios.post(
                      "/biz/public",
                      dataToSend,
                      config
                    );
                    if (response) {
                      setLoading(false);
                      setAllBiz(response.data);
                      console.log(response.data);
                    } else {
                      setLoading(false);
                      toast.error("Public mode not available", {
                        theme: "dark",
                      });
                    }
                  } else {
                    handleFetchBiz();
                  }
                }}
              >
                <option value="nope">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
            <Link to="/create-biz">
              <IoMdAdd className="bg-emerald-800 text-4xl p-[5px] text-white rounded-lg" />
            </Link>
          </div>
        )}

        {loading ? (
          <>
            <div className="h-[70vh] w-full flex justify-center items-center">
              <Spinner message="fetching ..." />
            </div>
          </>
        ) : (
          <>
            {allBiz?.length < 1 ? (
              <>
                <div
                  className="flex items-center gap-[10px] cursor-pointer"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <p>Reload</p>
                  <IoReload className="text-xl" />
                </div>
                <div className="h-[70vh] w-full flex justify-center items-center">
                  <div>
                    <h2 className="text-center mb-2">😢Ouch.</h2>
                    <h2 className="text-center">There are no results</h2>
                    <h2 className="mt-[20px] mb-[10px] text-center">
                      Share This Link To Businesses Owners
                    </h2>
                    <div
                      className="flex justify-center gap-[20px] border border-emerald-500 rounded-xl p-2 cursor-pointer"
                      onClick={() => handleCopyToClipboard(appBaseUrl)}
                    >
                      <input
                        type="text"
                        value={appBaseUrl}
                        readOnly
                        className="bg-transparent outline-none text-zinc-600"
                      />
                      <div className="flex justify-center gap-[20px]  ">
                        <button>
                          <IoShareSocialOutline
                            className="text-3xl text-emerald-500"
                            title="Click To Copy"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* searchbar */}
                <div className="mt-[1em] mb-[1.2em] w-full flex justify-center">
                  <form
                    className="flex items-center gap-3
            w-[99%] sm:w-[70%] md:w-[60%] xl:w-[40%] border border-zinc-600   p-[8px] rounded-lg"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <AiOutlineSearch className="text-xl text-emerald-400" />
                    <input
                      type="text"
                      placeholder="mboga, boda, fua"
                      className="w-full bg-transparent border-none outline-none"
                      value={searchText}
                      onChange={handleSearchChange}
                    />
                  </form>
                </div>

                {/* categories */}
                {!searchText && (
                  <div className="mt-4 text-zinc-400">
                    <div className="mb-[15px] flex md:hidden items-center gap-2">
                      <p className="">SCROLL TO FILTER</p>
                      <p>
                        <AiOutlineArrowRight />
                      </p>
                    </div>
                    <div className=" overflow-x-scroll prompt">
                      <div className="flex sm:justify-center ">
                        <ul className="flex  space-x-7  pb-1 ">
                          <li
                            className="cursor-pointer flex items-center gap-1 hover:text-emerald-500"
                            onClick={handleFetchBiz}
                          >
                            <MdOutlineAddBusiness />
                            all
                          </li>

                          {DummyBizCategory?.map((item) => (
                            <li
                              key={item.id}
                              className="cursor-pointer flex items-center gap-1 hover:text-emerald-500"
                              onClick={async () => {
                                setLoading(true);
                                let category = item.title;
                                let approved = "yes";
                                let dataToSend = { category, approved };
                                try {
                                  let token = user?.token;
                                  const config = {
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                    },
                                  };
                                  const response = await axios.post(
                                    "/biz/cat",
                                    dataToSend,
                                    config
                                  );
                                  if (response) {
                                    setLoading(false);

                                    setAllBiz(response.data);
                                    // console.log(response.data);
                                  }
                                } catch (error) {
                                  setLoading(false);
                                  toast.error("Failed to find " + item.title);
                                }
                              }}
                            >
                              <MdOutlineAddBusiness />
                              {item.title}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {/* data */}
                <div>
                  {/* nav numbers */}
                  {!searchText && (
                    <nav className="flex justify-center">
                      <ul className="flex gap-[2em] mt-[10px] px-[5px] py-[10px] items-center ">
                        {/* map */}

                        <>
                          <li>
                            <a
                              href="#"
                              onClick={prevPage}
                              className="text-zinc-700"
                            >
                              <p className="text-zinc-300">Prev</p>
                            </a>
                          </li>
                          <li className="flex gap-[10px] ">
                            {numbers
                              .slice(start - 1, end)
                              .map((item, index) => (
                                <li
                                  key={index}
                                  className={`normal-nav ${
                                    currentPage === item && "active-nav"
                                  }`}
                                >
                                  <a
                                    href="#"
                                    onClick={() => {
                                      handleClick(item);
                                      changeCurrentPage(item);
                                    }}
                                  >
                                    <p>{item}</p>
                                  </a>
                                </li>
                              ))}
                          </li>

                          <li>
                            <a href="#" onClick={nextPage}>
                              <p className="text-zinc-300">Next</p>
                            </a>
                          </li>
                        </>
                      </ul>
                    </nav>
                  )}
                  {/* products */}
                  <div>
                    <div className="mt-[2em]">
                      {searchedResults?.length > 0 ? (
                        <>
                          <div className="mb-[15px] text-zinc-400">
                            {searchText && <p>Results For : {searchText}</p>}
                          </div>

                          {searchedResults?.length > 0 ? (
                            <>
                              <div>
                                <Masonry
                                  breakpointCols={breakpointColumnsObj}
                                  className="my-masonry-grid "
                                  columnClassName="my-masonry-grid_column"
                                >
                                  {searchedResults?.map((item) => (
                                    <div className="mb-8" key={item._id}>
                                      <div
                                        className="relative rounded-lg group "
                                        onClick={() => handleAddViews(item._id)}
                                      >
                                        <Link to={`/business/${item._id}`}>
                                          <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100 ">
                                            <div
                                              className="bg-gradient-to-t
                                                 from-transparent to-black opacity-90 w-full h-full rounded-md"
                                            >
                                              {/* top stats */}
                                              <div>
                                                <div className="absolute top-[20px]   w-full px-2 ">
                                                  <div className="mb-[2em] w-full flex justify-between">
                                                    <p className="text-white text-md flex items-center gap-[5px]">
                                                      <AiOutlineLike className="text-lg" />
                                                      <span>
                                                        {item.likes.length}
                                                      </span>
                                                    </p>
                                                    <a
                                                      href={`tel:+${item.phone}`}
                                                      onClick={() =>
                                                        handleCall(item)
                                                      }
                                                    >
                                                      {" "}
                                                      <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                                    </a>
                                                  </div>
                                                  {/* likes and stats */}
                                                  <div className="flex gap-[30px] mb-[1em]">
                                                    <p className="text-white">
                                                      {item.title}
                                                    </p>
                                                  </div>
                                                  {/* rating */}
                                                  <div>
                                                    <p>
                                                      {item.calcAvgRate < 1 && (
                                                        <div className="flex items-center gap-1">
                                                          <AiFillStar className="text-3xl text-yellow-400" />
                                                          <AiOutlineStar className="text-3xl text-yellow-400" />
                                                          <AiOutlineStar className="text-3xl text-yellow-400" />
                                                          <AiOutlineStar className="text-3xl text-yellow-400" />
                                                          <AiOutlineStar className="text-3xl text-yellow-400" />
                                                        </div>
                                                      )}
                                                    </p>
                                                    <p>
                                                      {item.calcAvgRate >= 1 &&
                                                        item.calcAvgRate <=
                                                          2 && (
                                                          <div className="flex items-center gap-1">
                                                            <AiFillStar className="text-3xl text-yellow-400" />
                                                            <AiFillStar className="text-3xl text-yellow-400" />
                                                            <AiOutlineStar className="text-3xl text-yellow-400" />
                                                            <AiOutlineStar className="text-3xl text-yellow-400" />
                                                            <AiOutlineStar className="text-3xl text-yellow-400" />
                                                          </div>
                                                        )}
                                                    </p>
                                                    <p>
                                                      {item.calcAvgRate > 2 &&
                                                        item.calcAvgRate <=
                                                          3 && (
                                                          <div className="flex items-center gap-1">
                                                            <AiFillStar className="text-3xl text-yellow-400" />
                                                            <AiFillStar className="text-3xl text-yellow-400" />
                                                            <AiFillStar className="text-3xl text-yellow-400" />
                                                            <AiOutlineStar className="text-3xl text-yellow-400" />
                                                            <AiOutlineStar className="text-3xl text-yellow-400" />
                                                          </div>
                                                        )}
                                                    </p>
                                                    <p>
                                                      {item.calcAvgRate > 3 &&
                                                        item.calcAvgRate <=
                                                          4 && (
                                                          <div className="flex items-center gap-1">
                                                            <AiFillStar className="text-3xl text-yellow-400" />
                                                            <AiFillStar className="text-3xl text-yellow-400" />
                                                            <AiFillStar className="text-3xl text-yellow-400" />
                                                            <AiFillStar className="text-3xl text-yellow-400" />
                                                            <AiOutlineStar className="text-3xl text-yellow-400" />
                                                          </div>
                                                        )}
                                                    </p>
                                                    <p>
                                                      {item.calcAvgRate > 4 &&
                                                        item.calcAvgRate <=
                                                          5 && (
                                                          <div className="flex items-center gap-1">
                                                            <AiFillStar className="text-2xl text-yellow-700" />
                                                            <AiFillStar className="text-2xl text-yellow-700" />
                                                            <AiFillStar className="text-2xl text-yellow-700" />
                                                            <AiFillStar className="text-2xl text-yellow-700" />
                                                            <AiFillStar className="text-2xl text-yellow-700" />
                                                          </div>
                                                        )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              {/*  */}
                                              <div className="absolute bottom-[20px]  flex gap-[10%] w-full ">
                                                <div className="pl-2">
                                                  <div className="flex gap-[10px] text-zinc-300">
                                                    {/* <p>#{item.category}</p> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="min-h-[200px]">
                                            <img
                                              src={
                                                item.photo ||
                                                "https://images.pexels.com/photos/4049449/pexels-photo-4049449.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                              }
                                              alt=""
                                              className="rounded-md object-cover"
                                              loading="lazy"
                                              // onLoad={() => alert("loaded")}
                                            />
                                          </div>
                                        </Link>
                                      </div>
                                      {/* more items */}
                                      <div className="flex justify-between items-center mt-[10px]">
                                        {/* user */}
                                        <div>
                                          <Link
                                            to={
                                              item.owner == user?.username
                                                ? "/my-profile"
                                                : `/other-profile?owner=${item.owner}`
                                            }
                                          >
                                            <p
                                              className={`w-8 h-8 flex items-center justify-center rounded-full bg-emerald-800 text-white text-lg`}
                                            >
                                              {item.owner.substring(0, 1)}
                                            </p>
                                          </Link>
                                        </div>
                                        {/* rating */}
                                        <div className="flex gap-[26px] items-center">
                                          {item.owner !== user?.username && (
                                            <div>
                                              <div>
                                                <Link
                                                  to={`/report?owner=${item.owner}&phone=${item.phone}`}
                                                >
                                                  <MdOutlineReportProblem
                                                    className="text-xl text-yellow-400 cursor-pointer"
                                                    title={`report ${item.owner}`}
                                                  />
                                                </Link>
                                              </div>
                                            </div>
                                          )}

                                          {/* share */}
                                          <div className="flex justify-center gap-[20px]  ">
                                            <button
                                              onClick={() =>
                                                handleCopyToClipboard(
                                                  ` ${appBaseUrl}business/${item._id}`
                                                )
                                              }
                                            >
                                              <IoShareSocialOutline
                                                className="text-2xl text-emerald-300"
                                                title="Click To Copy"
                                              />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </Masonry>
                              </div>
                            </>
                          ) : (
                            <div className="flex justify-center mt-[7em]">
                              <p>😥 No results for {searchText}</p>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div>
                            <Masonry
                              breakpointCols={breakpointColumnsObj}
                              className="my-masonry-grid "
                              columnClassName="my-masonry-grid_column"
                            >
                              {records?.map((item) => (
                                <div className="mb-8" key={item._id}>
                                  <div
                                    className="relative rounded-lg group "
                                    onClick={() => handleAddViews(item._id)}
                                  >
                                    <Link to={`/business/${item._id}`}>
                                      <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100 ">
                                        <div
                                          className="bg-gradient-to-t
                                                from-transparent to-black  opacity-90 w-full h-full rounded-md"
                                        >
                                          {/* top stats */}
                                          <div>
                                            <div className="absolute top-[20px]   w-full px-2 ">
                                              <div className="mb-[2em] w-full flex justify-between">
                                                <p className="text-white text-md flex items-center gap-[5px]">
                                                  <AiOutlineLike className="text-lg" />
                                                  <span>
                                                    {item.likes.length}
                                                  </span>
                                                </p>
                                                <p className="text-white text-md flex items-center gap-[5px]">
                                                  <AiOutlineComment className="text-lg" />
                                                  <span>
                                                    {item.comments.length}
                                                  </span>
                                                </p>
                                              </div>
                                              {/* likes and stats */}
                                              <div className="flex gap-[30px] mb-[1em]">
                                                <p className="text-white">
                                                  {item.title}
                                                </p>
                                              </div>
                                              {/* rating */}
                                              <div>
                                                <p>
                                                  {item.calcAvgRate < 1 && (
                                                    <div className="flex items-center gap-1">
                                                      <AiFillStar className="text-3xl text-yellow-400" />
                                                      <AiOutlineStar className="text-3xl text-yellow-400" />
                                                      <AiOutlineStar className="text-3xl text-yellow-400" />
                                                      <AiOutlineStar className="text-3xl text-yellow-400" />
                                                      <AiOutlineStar className="text-3xl text-yellow-400" />
                                                    </div>
                                                  )}
                                                </p>
                                                <p>
                                                  {item.calcAvgRate >= 1 &&
                                                    item.calcAvgRate <= 2 && (
                                                      <div className="flex items-center gap-1">
                                                        <AiFillStar className="text-3xl text-yellow-400" />
                                                        <AiFillStar className="text-3xl text-yellow-400" />
                                                        <AiOutlineStar className="text-3xl text-yellow-400" />
                                                        <AiOutlineStar className="text-3xl text-yellow-400" />
                                                        <AiOutlineStar className="text-3xl text-yellow-400" />
                                                      </div>
                                                    )}
                                                </p>
                                                <p>
                                                  {item.calcAvgRate > 2 &&
                                                    item.calcAvgRate <= 3 && (
                                                      <div className="flex items-center gap-1">
                                                        <AiFillStar className="text-3xl text-yellow-400" />
                                                        <AiFillStar className="text-3xl text-yellow-400" />
                                                        <AiFillStar className="text-3xl text-yellow-400" />
                                                        <AiOutlineStar className="text-3xl text-yellow-400" />
                                                        <AiOutlineStar className="text-3xl text-yellow-400" />
                                                      </div>
                                                    )}
                                                </p>
                                                <p>
                                                  {item.calcAvgRate > 3 &&
                                                    item.calcAvgRate <= 4 && (
                                                      <div className="flex items-center gap-1">
                                                        <AiFillStar className="text-3xl text-yellow-400" />
                                                        <AiFillStar className="text-3xl text-yellow-400" />
                                                        <AiFillStar className="text-3xl text-yellow-400" />
                                                        <AiFillStar className="text-3xl text-yellow-400" />
                                                        <AiOutlineStar className="text-3xl text-yellow-400" />
                                                      </div>
                                                    )}
                                                </p>
                                                <p>
                                                  {item.calcAvgRate > 4 &&
                                                    item.calcAvgRate <= 5 && (
                                                      <div className="flex items-center gap-1">
                                                        <AiFillStar className="text-2xl text-yellow-700" />
                                                        <AiFillStar className="text-2xl text-yellow-700" />
                                                        <AiFillStar className="text-2xl text-yellow-700" />
                                                        <AiFillStar className="text-2xl text-yellow-700" />
                                                        <AiFillStar className="text-2xl text-yellow-700" />
                                                      </div>
                                                    )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          {/*  */}
                                          <div className="absolute bottom-[20px]  flex gap-[10%] w-full">
                                            <div className="pl-2">
                                              <div className="flex gap-[10px] text-zinc-300"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="min-h-[200px] w-full">
                                        {loadingImage && (
                                          <div className="w-full h-64 bg-zinc-500 rounded-md object-cover animate-pulse"></div>
                                        )}

                                        <img
                                          src={
                                            item.photo ||
                                            "https://images.pexels.com/photos/4049449/pexels-photo-4049449.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                          }
                                          alt=""
                                          className="rounded-md object-cover"
                                          loading="lazy"
                                          onLoad={handleImageLoaded}
                                        />
                                      </div>
                                    </Link>
                                  </div>
                                  {/* more items */}
                                  <div className="flex justify-between items-center mt-[10px]">
                                    {/* user */}
                                    <div>
                                      <Link
                                        to={
                                          item.owner == user?.username
                                            ? "/my-profile"
                                            : `/other-profile?owner=${item.owner}`
                                        }
                                      >
                                        <p
                                          className={`w-8 h-8 flex items-center justify-center rounded-full bg-emerald-800 text-white text-lg`}
                                        >
                                          {item.owner.substring(0, 1)}
                                        </p>
                                      </Link>
                                    </div>
                                    {/* rating */}
                                    <div className="flex gap-[26px] items-center">
                                      {item.owner !== user?.username && (
                                        <div>
                                          <div>
                                            <Link
                                              to={`/report?owner=${item.owner}&phone=${item.phone}`}
                                            >
                                              <MdOutlineReportProblem
                                                className="text-xl text-yellow-400 cursor-pointer"
                                                title={`report ${item.owner}`}
                                              />
                                            </Link>
                                          </div>
                                        </div>
                                      )}

                                      {/* share */}
                                      <div className="flex justify-center gap-[20px]  ">
                                        <button
                                          onClick={() =>
                                            handleCopyToClipboard(
                                              ` ${appBaseUrl}business/${item._id}`
                                            )
                                          }
                                        >
                                          <IoShareSocialOutline
                                            className="text-2xl text-red-300"
                                            title="Click To Copy"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Masonry>
                          </div>
                        </>
                      )}

                      {/*  */}
                    </div>
                  </div>
                  {/*  */}
                </div>
              </>
            )}
          </>
        )}

        {/*  */}
      </div>
      {/*  */}
    </div>
  );
};

export default Businesses;
