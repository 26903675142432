import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DummyBizCategory } from "../../Dummydata";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import axios from "../../axios";
import imageCompression from "browser-image-compression";

const EditBiz = () => {
  const { user } = useSelector((state) => state.auth);
  // fetch the Business
  const { id } = useParams();
  const [myBusiness, setMyBusiness] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchTheBiz = async () => {
    try {
      setLoading(true);
      let checkParam = id;
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get("/biz/" + checkParam, config);
      if (response) {
        setLoading(false);
        setMyBusiness([response.data.biz]);
        console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Business.");
      toast.error("Network error or doesn't exist");
    }
  };

  useEffect(() => {
    fetchTheBiz();
  }, []);

  // upload photo to cloudinary
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const postPhoto = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingPhoto(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingPhoto(false);
      setPhoto(urlData.url);
      toast.success("Uploaded Photo");
    } catch (error) {
      setLoadingPhoto(false);
      toast.error("Error uploading Photo");
    }
  };

  //update data
  const [showForm, setShowForm] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [photo, setPhoto] = useState("");
  const [specificAddress, setSpecificAddress] = useState("");
  const [availability, setAvailability] = useState("");
  const [searchTerms, setSearchTerms] = useState("");
  const [chargeRates, setChargeRates] = useState("");
  const [googleMaps, setGoogleMaps] = useState("");

  const [updatetitle, setupdateTitle] = useState("");
  const [updatecategory, setupdateCategory] = useState("");
  const [updatedescription, setupdateDescription] = useState("");
  const [updatephoto, setupdatePhoto] = useState("");
  const [updatespecificAddress, setupdateSpecificAddress] = useState("");
  const [updateavailability, setupdateAvailability] = useState("");
  const [updatesearchTerms, setupdateSearchTerms] = useState("");
  const [updatechargeRates, setupdateChargeRates] = useState("");
  const [updateGoogleMaps, setupdateGoogleMaps] = useState("");

  useEffect(() => {
    setTitle(updatetitle);
    setCategory(updatecategory);
    setDescription(updatedescription);
    setPhoto(updatephoto);
    setSpecificAddress(updatespecificAddress);
    setAvailability(updateavailability);
    setSearchTerms(updatesearchTerms);
    setChargeRates(updatechargeRates);
    setGoogleMaps(updateGoogleMaps);
  }, [updatetitle, updatespecificAddress, updatechargeRates]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!title) return toast.error("title missing");
    if (!category) return toast.error("category missing");
    if (!description) return toast.error("description missing");
    if (!photo) return toast.error("photo missing");
    if (!specificAddress) return toast.error("specificAddress missing");
    if (!availability) return toast.error("availability missing");
    if (!chargeRates) return toast.error("charging rates missing");
    if (!searchTerms) return toast.error("searchTerms missing");

    try {
      setLoading(true);
      let location = user?.location;
      let phone = user?.phone;
      let owner = user?.username;
      let ownerEmail = user?.email;
      let dataToSend = {
        title,
        category,
        description,
        location,
        photo,
        phone,
        owner,
        ownerEmail,
        specificAddress,
        availability,
        searchTerms,
        chargeRates,
        googleMaps,
      };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put("/biz/" + id, dataToSend, config);
      if (response) {
        setLoading(false);
        toast.success("Updated " + title);
        navigate("/my-profile");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error updating Biz");
    }
  };

  return (
    <div>
      {/* wrapper */}
      <div className="px-[10px] pt-[10px]">
        {/* topbar */}
        <div>
          <div className="flex gap-4 items-center">
            <Link to="/my-profile">
              <AiOutlineArrowLeft className="text-lg" />
            </Link>
            <div>
              <h2 className="font-bold text-md text-zinc-600">
                Update Business
              </h2>
              <Link to="/change-location">
                <p className="text-sm underline text-emerald-700">
                  Your Location : {user?.location}
                </p>
              </Link>
            </div>
          </div>
        </div>

        {console.log(myBusiness)}

        {myBusiness?.map((item) => (
          <div key={item._id}>
            {showForm ? (
              <>
                {/* form */}
                <div className="mt-[2em] pb-[1em] w-full">
                  <form
                    className=" w-[99%] sm:w-[75%] lg:w-[50%] m-auto pb-[1em]"
                    onSubmit={handleUpdate}
                  >
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="title"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        Update Name of Your Business
                      </label>
                      <input
                        type="text"
                        placeholder="title here"
                        id="title"
                        value={title}
                        required
                        minLength={2}
                        maxLength={14}
                        onChange={(e) => setTitle(e.target.value)}
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      />
                    </div>
                    {/* category */}
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="category"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        Update Category For Your Biz
                      </label>

                      <select
                        name="category"
                        id="category"
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option value="">Choose</option>
                        {DummyBizCategory.map((item) => (
                          <option key={item.id}>{item.title}</option>
                        ))}
                      </select>
                    </div>
                    {/* description */}
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="desc"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        Update Business Description
                      </label>
                      <textarea
                        name="desc"
                        id="desc"
                        cols="30"
                        rows="3"
                        minLength={5}
                        maxLength={500}
                        required
                        placeholder="what you do max(500)"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      ></textarea>
                    </div>
                    {/* charging rates */}
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="chargeRates"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        Update Charging Rates
                      </label>

                      <textarea
                        name="chargeRates"
                        id="chargeRates"
                        cols="30"
                        rows="2"
                        minLength={5}
                        maxLength={500}
                        required
                        placeholder="Discounts, offers, holiday cuts e.t.c"
                        value={chargeRates}
                        onChange={(e) => setChargeRates(e.target.value)}
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      ></textarea>
                    </div>
                    {/* upload image */}
                    <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                      <div className="flex flex-col gap-2 mt-[20px]">
                        <label
                          htmlFor="mainPhoto"
                          className="flex items-center gap-[20px] flex-wrap"
                        >
                          <p>Update Business Photo</p>
                          <div className="flex flex-col items-center">
                            {loadingPhoto ? (
                              <Spinner message="uploading ..." />
                            ) : (
                              <img
                                src={
                                  photo
                                    ? photo
                                    : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                }
                                alt=""
                                className="w-[100px] h-[100px] object-cover"
                              />
                            )}
                          </div>
                        </label>
                        <input
                          type="file"
                          placeholder="Add Image"
                          accept="image/*"
                          onChange={(e) => postPhoto(e.target.files[0])}
                          required
                          id="mainPhoto"
                          className="hidden"
                        />
                      </div>
                    </div>
                    {/* specificAddress */}
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="specificAdd"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        <p>Update Local Town / Estate Name</p>
                        <p className="text-sm text-emerald-600">
                          Please Enter Popular name
                        </p>
                      </label>
                      <input
                        type="text"
                        placeholder="popular local name"
                        id="specificAdd"
                        value={specificAddress}
                        required
                        minLength={2}
                        maxLength={50}
                        onChange={(e) => setSpecificAddress(e.target.value)}
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      />
                    </div>
                    {/* availability */}
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="availability"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        <p>Update Opening And Closing Hours</p>
                        <p className="text-sm text-emerald-600">
                          i.e 8:00AM - 10:00PM
                        </p>
                      </label>
                      <input
                        type="text"
                        placeholder="enter your availability"
                        id="availability"
                        value={availability}
                        required
                        minLength={2}
                        maxLength={24}
                        onChange={(e) => setAvailability(e.target.value)}
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      />
                    </div>
                    {/* searchTerms */}
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="specificAdd"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        <p>Update Search terms associated</p>
                        <p className="text-sm text-emerald-600">
                          separate by comma (,)
                        </p>
                      </label>
                      <input
                        type="text"
                        placeholder="searchTerms separated by comma"
                        id="specificAdd"
                        value={searchTerms}
                        required
                        minLength={2}
                        maxLength={70}
                        onChange={(e) => setSearchTerms(e.target.value)}
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      />
                    </div>
                    {/* google maps */}
                    <div className="flex flex-col gap-[8px] mb-[12px]">
                      <label
                        htmlFor="googleMaps"
                        className=""
                        style={{ fontWeight: 600 }}
                      >
                        <p>Update Google Maps Link</p>
                        <p className="text-sm text-emerald-600">
                          just share the link
                        </p>
                      </label>
                      <input
                        type="text"
                        placeholder="enter here"
                        id="specificAdd"
                        value={googleMaps}
                        required
                        minLength={2}
                        onChange={(e) => setGoogleMaps(e.target.value)}
                        className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
                      />
                    </div>
                    <div className="mb-[14px]">
                      <h2 className="text-center font-bold mb-[10px] text-zinc-500">
                        IMPORTANT
                      </h2>
                      <p className="text-center">
                        {user.phone} is the phone number to be used.
                      </p>
                      <p className="text-center">
                        {user.location} will be used as your location
                      </p>
                      <Link to="/my-profile" className="text-center">
                        <div className="text-emerald-600 underline">
                          You Can Change Here
                        </div>
                      </Link>
                    </div>
                    {loading || loadingPhoto ? (
                      <div className="w-full">
                        <p className="text-center">Please wait 😊 ... </p>
                      </div>
                    ) : (
                      <button
                        className="bg-emerald-800 text-white w-full p-[8px] rounded-md"
                        onClick={handleUpdate}
                      >
                        Update Business
                      </button>
                    )}
                  </form>
                </div>
              </>
            ) : (
              <>
                <h2
                  className="font-bold flex w-full h-[70vh] justify-center items-center text-green-800 underline cursor-pointer text-center"
                  onClick={() => {
                    setShowForm(true);

                    setupdateTitle(item.title);
                    setupdateCategory(item.category);
                    setupdateDescription(item.description);
                    setupdatePhoto(item.photo);
                    setupdateSpecificAddress(item.specificAddress);
                    setupdateAvailability(item.availability);
                    setupdateSearchTerms(item.searchTerms);
                    setupdateChargeRates(item.chargeRates);
                    setupdateGoogleMaps(item.googleMaps);
                  }}
                >
                  Click Here To Update {item.title}
                </h2>
              </>
            )}
          </div>
        ))}

        {/*  */}
      </div>
    </div>
  );
};

export default EditBiz;
