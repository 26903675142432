import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../axios";
import moment from "moment";
import Spinner from "../components/Spinner";
import { IoReload } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import Logo from "../assets/official.png";

const Notifications = () => {
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const [MyNotif, setMyNotif] = useState([]);
  const [loadingNotif, setLoadingNotif] = useState(false);
  const handleFetchNotification = async () => {
    if (!user) {
      navigate("/login");
      return toast.error("Please Login", { theme: "dark" });
    }

    try {
      setLoadingNotif(true);
      let username = user.username;
      let dataToSend = { username };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post("/notify/mine", dataToSend, config);

      if (res && res.data) {
        const notifications = Array.isArray(res.data) ? res.data : [res.data];
        setMyNotif(notifications);
        setLoadingNotif(false);
      } else {
        setMyNotif([]); // Set an empty array if no data is received
        setLoadingNotif(false);
        console.error("Invalid data format received:", res.data);
      }
    } catch (error) {
      toast.error("Error Fetching Notifications", { theme: "dark" });
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    handleFetchNotification();
  }, [user]);

  const handleDeleteNotification = async (id) => {
    try {
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.delete("/notify/delete/" + id, config);
      handleFetchNotification();
    } catch (error) {
      toast.error("Something went wrong", { theme: "dark" });
    }
  };

  const handleDeleteAll = async () => {
    let isConfirm = window.confirm("Delete all notifications?");
    if (isConfirm) {
      try {
        const username = user?.username;
        let dataToSend = { username };
        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        await axios.post("/notify/deletemine", dataToSend, config);
        handleFetchNotification();
      } catch (error) {
        toast.error("Something went wrong", { theme: "dark" });
      }
    }
  };

  //========Push Notifications
  const [loadPush, setLoadPush] = useState(false);
  const handleSubscribe = async () => {
    //ask user for permission to send notifications
    let sw = await navigator.serviceWorker.ready;
    let push = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        "BGJwXjvS-CXEewuGf4VjtgGSGDKMzKvthko04HhY6GHPa6yLg_sCK4KD94Xlw_p3PNauhC8F8B49FoSPgyXzBIU",
    });
    console.log(JSON.stringify(push));
    try {
      setLoadPush(true);
      let dataToSend = push;
      let res = await axios.post("/subscription", dataToSend);
      setLoadPush(false);
      if (!res) {
        toast.error("Failed To Subscribe", { theme: "dark" });
        setLoadPush(false);
      }
    } catch (error) {
      setLoadPush(false);
      console.log(error);
    }

    //you should send push and save to DB and iterate when sending back
  };

  return (
    <div>
      {/* wrapper */}
      <div className="pt-[1em] pb-[1em] px-[8px] sm:px-[2em] md:px-[3em]">
        {/* topbar */}
        <div>
          <Link to="/home">
            <AiOutlineArrowLeft className="text-3xl " />
          </Link>
        </div>

        {/* all Notifications */}
        {/* {alert(Notification.permission)} */}
        {Notification.permission === "denied" && (
          <div className="mt-[3em]">
            <h2 className="text-zinc-300">
              We would like to keep you posted about your profile
            </h2>
            <p className="text-zinc-300">
              On likes, comments or when somebody calls your business
            </p>
            <p className="text-zinc-300">
              You can always revert back this option{" "}
            </p>
            {loadPush ? (
              <p>👋 Please wait ...</p>
            ) : (
              <>
                <button
                  className="bg-[#212121] text-emerald-400 my-6 rounded-lg"
                  onClick={() => handleSubscribe()}
                >
                  Subscribe today ?
                </button>
              </>
            )}
          </div>
        )}

        {Notification.permission === "default" && (
          <div className="mt-[3em]">
            <h2 className="text-zinc-300">
              We would like to keep you posted about your profile
            </h2>
            <p className="text-zinc-300">
              On likes, comments or when somebody calls your business
            </p>
            <p className="text-zinc-300">
              You can always revert back this option{" "}
            </p>
            {loadPush ? (
              <p>👋 Please wait ...</p>
            ) : (
              <>
                <button
                  className="bg-[#212121] text-emerald-400 my-6 rounded-lg"
                  onClick={() => handleSubscribe()}
                >
                  Subscribe today ?
                </button>
              </>
            )}
          </div>
        )}

        <div>
          {loadingNotif ? (
            <div className="h-[70vh] w-full flex justify-center items-center">
              <Spinner message="Fetching Notifications" />
            </div>
          ) : (
            <>
              {MyNotif.length < 1 ? (
                <div className="w-[full] h-[70vh] flex justify-center items-center">
                  <p>👋Hello. You are all caught up</p>
                </div>
              ) : (
                <div className="mt-[3em] ">
                  {/* delete all my notification */}
                  <div className="mb-[20px] w-full flex justify-end">
                    <div className="flex items-center gap-[20px]">
                      <IoReload
                        className="text-xl text-emerald-500 cursor-pointer"
                        onClick={() => handleFetchNotification()}
                        title="refresh"
                      />
                      <MdDeleteOutline
                        className="text-xl text-red-600 cursor-pointer"
                        title="delete all"
                        onClick={() => handleDeleteAll()}
                      />
                    </div>
                  </div>
                  {MyNotif.map((item) => (
                    <div
                      key={item._id}
                      onClick={() => handleDeleteNotification(item._id)}
                      className=""
                    >
                      <div className="mb-[20px] bg-[#212121] p-2 rounded-lg">
                        <Link to={`${item.referID}`}>
                          <p className="text-sm text-zinc-400">
                            Notification from {item.sender}
                          </p>
                          <p className="my-[10px] text-white">{item.message}</p>
                          <p className="text-sm text-emerald-400">
                            {moment(item.createdAt).fromNow()}
                          </p>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
