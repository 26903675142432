import React from "react";
import { AiOutlinePhone } from "react-icons/ai";
import { FiArrowUpRight } from "react-icons/fi";
import { appBaseUrl } from "../constants";

const Ads = () => {
  const DummyAds = [
    {
      id: 32,
      title: "Book This Space ?",
      photo:
        "https://images.pexels.com/photos/7708809/pexels-photo-7708809.jpeg?auto=compress&cs=tinysrgb&w=600",
      link: appBaseUrl,
      phone: "0798 556471",
    },

    {
      id: 1,
      title: "Nike Store",
      photo:
        "https://images.pexels.com/photos/2529148/pexels-photo-2529148.jpeg?auto=compress&cs=tinysrgb&w=1600",
      link: "https://www.nike.com/eg/",
      phone: "0798 556471",
    },
    {
      id: 2,
      title: "Hardware store",
      photo:
        "https://images.pexels.com/photos/1727684/pexels-photo-1727684.jpeg?auto=compress&cs=tinysrgb&w=1600",
      link: "https://muthokinju.co.ke/",
      phone: "0790 022 022",
    },
    {
      id: 3,
      title: "Avis Car Rental",
      photo:
        "https://images.pexels.com/photos/810357/pexels-photo-810357.jpeg?auto=compress&cs=tinysrgb&w=600",
      link: "https://www.avis.com/en/locations/ke/nairobi",
      phone: "0731663000",
    },

    {
      id: 5,
      title: "Flower shop",
      photo:
        "https://images.pexels.com/photos/697259/pexels-photo-697259.jpeg?auto=compress&cs=tinysrgb&w=600",
      link: "https://www.purpink.co.ke/collections/flowers",
      phone: "0709953400",
    },
  ];
  return (
    <div>
      <div>
        <p className="mb-3 text-zinc-300">#Ads</p>
      </div>
      <div className="flex gap-[10px] overflow-x-scroll w-full prompt pb-2">
        <div className="flex flex-nowrap">
          {DummyAds?.map((item) => (
            <div key={item.id} className="flex-shrink-0 mr-[15px]">
              <div className="relative rounded-lg group ">
                <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                  <div
                    className="bg-gradient-to-t
                                  from-transparent to-black opacity-95 w-full h-full rounded-md"
                  >
                    {/* top stats */}
                    <div>
                      <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                        <div>
                          <a href={`tel:+${item.phone}`}>
                            {" "}
                            <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                          </a>
                        </div>
                        <div className="flex gap-[20px]">
                          <a href={`${item.link}`} target="_blank">
                            {" "}
                            <FiArrowUpRight className="text-2xl text-white hover:text-orange-200" />
                          </a>
                        </div>
                      </div>
                      <div className="absolute top-[80px] left-3">
                        <p className="text-white text-lg">{item.title}</p>
                      </div>
                    </div>
                    {/*  */}
                    <div className="absolute bottom-[20px] left-[20px]  flex gap-[10%] w-full ">
                      <div>
                        <div className="flex gap-[10px] text-zinc-200">
                          {/* <p>{item.availability}</p> */}
                          <p>#Ads</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <img
                  src={item.photo}
                  alt=""
                  className="w-72 h-80 rounded-lg object-cover"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ads;
