import React, { useEffect, useState } from "react";
import {
  AiOutlineArrowRight,
  AiOutlineArrowUp,
  AiOutlineEye,
  AiOutlineGold,
  AiOutlinePhone,
  AiOutlineSearch,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";
import axios from "../axios";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { useSelector } from "react-redux";
import { DummyToRentCategory } from "../Dummydata";

import { MdOutlineAddBusiness } from "react-icons/md";
import { IoMdAdd, IoMdArrowBack } from "react-icons/io";
import moment from "moment";
import { Helmet } from "react-helmet";
import { IoReload, IoShareSocialOutline } from "react-icons/io5";
import { appBaseUrl } from "../constants";

const RentOut = () => {
  const [allToRent, setAllToRent] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate("/login");
      toast.warning("Account Needed");
    }
  }, [user, navigate]);

  // fetch to rent around user location
  const handleFetchToRent = async () => {
    try {
      setLoading(true);
      // fetch based on <location> from user
      let location = user?.location;
      let approved = "yes";
      let dataToSend = { location, approved };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post("/rent/location", dataToSend, config);
      if (response) {
        setLoading(false);
        setAllToRent(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Rent Items", { theme: "dark" });
    }
  };

  useEffect(() => {
    handleFetchToRent();
  }, []);

  const breakpointColumnsObj = {
    default: 5,
    3000: 5,
    2000: 4,
    1500: 3,
    1200: 2,
    800: 1,
  };

  //   pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = allToRent?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(allToRent?.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(4);

  const handleClick = (number) => {
    setStart(number);
    setEnd(number + 3);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      handleClick(currentPage);
    }
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      handleClick(currentPage);
    }
  };

  const changeCurrentPage = (id) => {
    setCurrentPage(id);
  };

  // search  states
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setsearchTimeout] = useState(null);
  const [searchedResults, setSearchedResults] = useState(null);

  // search user func
  const handleSearchChange = async (e) => {
    e.preventDefault();
    clearTimeout(setsearchTimeout);

    setSearchText(e.target.value);

    // console.log(searchText);

    setsearchTimeout(
      setTimeout(() => {
        const searchResults = allToRent.filter(
          (item) =>
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.searchTerms.toLowerCase().includes(searchText.toLowerCase())
        );

        setSearchedResults(searchResults);
      }, 500)
    );
  };

  // scroll to top functionality
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCopyToClipboard = async (textToCopy) => {
    try {
      // Get the text from your input or any other source
      // You can replace this with your dynamic input

      // Use the Clipboard API to write the text to the clipboard
      await navigator.clipboard.writeText(textToCopy);

      // Log success or update UI accordingly
      console.log("Text copied to clipboard:", textToCopy);
      toast.success("Copied The Link. Share", { theme: "dark" });
    } catch (err) {
      // Handle errors
      console.error("Error copying to clipboard:", err);
    }
  };

  const handleAddViews = async (id) => {
    try {
      if (user) {
        let username = user?.username;
        let viewsData = { username };
        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios.post("/rent/view/" + id, viewsData, config);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // like a rental
  const handleLikeRental = async (rental) => {
    try {
      if (!user) {
        toast.error("Please Login To leave a like", { theme: "dark" });
        return;
      }

      let username = user.username;
      let id = rental._id;
      let likeData = { username };

      let res = await axios.post("/rent/like/" + id, likeData);
      if (res) {
        // notification
        if (user?.username !== rental.owner) {
          // create notification
          let sender = user.username;
          let receiver = rental.owner;
          let message = `${user?.username} liked ${rental.title} 👍`;
          let referID = `/rent-out/`;
          let notifyData = { sender, receiver, message, referID };

          await axios.post("/notify/create", notifyData);
        }

        toast.success("Action Succesful");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed To Like");
    }
  };

  // dislike biz
  const handleDislikeRental = async (rental) => {
    try {
      if (!user) {
        toast.error("Please Login To dislike", { theme: "dark" });
        return;
      }

      let username = user.username;
      let id = rental._id;
      let likeData = { username };

      await axios.post("/rent/dislike/" + id, likeData);
      // notification
      if (user?.username !== rental.owner) {
        // create notification
        let sender = user.username;
        let receiver = rental.owner;
        let message = `${user?.username} disliked ${rental.title} 👎`;
        let referID = `/rent-out/`;
        let notifyData = { sender, receiver, message, referID };

        await axios.post("/notify/create", notifyData);
      }
      window.location.reload();
    } catch (error) {
      toast.error("Failed To Dislike");
    }
  };

  let topBarBg = ["rentOutBgOne", "rentOutBgTwo", "rentOutBgThree"];
  const [chosenBg, setChosenBg] = useState("rentOutBgOne");
  useEffect(() => {
    const interval = setInterval(() => {
      // Find the index of the next background in the array
      const currentIndex = topBarBg.indexOf(chosenBg);
      const nextIndex = (currentIndex + 1) % topBarBg.length;
      setChosenBg(topBarBg[nextIndex]);
    }, 10000); // Change background every 4 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [chosenBg, topBarBg]);

  const getCurrentTime = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  return (
    <div>
      {/* arrow to scroll to top */}
      {showArrow && (
        <div
          className="fixed bottom-20 right-4 text-3xl z-[999] cursor-pointer bg-teal-700 text-zinc-50 rounded-full p-[5px]"
          onClick={handleScrollTop}
        >
          <AiOutlineArrowUp />
        </div>
      )}

      <Helmet>
        <title>{`Rentals | ${user?.location} | iLocca`}</title>
        <meta
          name="description"
          content="Bridging the gap between local businesses and the community, offering a streamlined platform for residents to discover and acquire quality local products and services."
        />
      </Helmet>

      {/* wrapper */}

      {/* topbar */}
      <div className="mx-2 pt-4">
        {!searchText && (
          <div
            className={`h-[28vh] sm:h-[30vh]  rounded-xl ${chosenBg} relative`}
          >
            {/* overlay div */}
            <div className="bg-gradient-to-b from-transparent to-black opacity-90 w-full h-[28vh] sm:h-[30vh] rounded-sm absolute top-0 left-0">
              {/* overlay div */}
              <div className="absolute top-0  rounded-sm left-0 w-full h-[28vh] sm:h-[30vh] bg-[rgba(0,0,0,.2)]">
                <div className="px-4 py-2 flex justify-between gap-4">
                  <div>
                    <div className="hidden md:flex mb-3">
                      <Link to="/home">
                        <IoMdArrowBack className="text-4xl" />
                      </Link>
                    </div>
                    <p className=" text-xl sm:text-4xl font-semibold mb-8">{`${getCurrentTime()} ${
                      user?.username
                    }`}</p>
                    <p className=" mb-8 sm:mb-1">
                      Earn By Renting Out What you don't need
                    </p>
                    <p className="sm:mb-4">Why buy when you can rent ? 😎</p>
                    <div className=" hidden md:flex gap-4 items-center">
                      <div>
                        <p className="mb-1">👌Add item</p>
                        <p>👌Quick phone calls</p>
                      </div>
                      <div>
                        <p className="mb-1">👌Email Notifications</p>
                        <p>👌Rent, Start Balling</p>
                      </div>
                    </div>
                  </div>
                  {/* create if user has paid */}
                  <div>
                    {user?.isPaid ? (
                      <div className="flex justify-end w-full mt-[15px]">
                        <Link to="/create-rent-out">
                          <IoMdAdd className="bg-zinc-800 text-4xl p-[5px] text-white rounded-lg" />
                        </Link>
                      </div>
                    ) : (
                      <div className="flex justify-end w-full mt-[15px]">
                        <Link to="/gold-subscription">
                          <AiOutlineGold className="bg-zinc-800 text-4xl p-[5px] text-white rounded-lg" />
                        </Link>
                      </div>
                    )}
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="px-[10px] sm:px-[3em] lg:px-[4em] pt-3">
        {loading ? (
          <>
            <div className="h-[70vh] w-full flex justify-center items-center">
              <Spinner message="Fetching Items ..." />
            </div>
          </>
        ) : (
          <>
            {allToRent.length < 1 ? (
              <>
                <div
                  className="flex items-center gap-[10px] cursor-pointer"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <p>Reload</p>
                  <IoReload className="text-xl" />
                </div>
                <div className="h-[70vh] w-full flex justify-center items-center">
                  <div>
                    <h2 className="text-center mb-2">😢Ouch.</h2>
                    <h2 className="text-center">Looks Like no results</h2>
                    <h2 className="mt-[20px] mb-[10px] text-center">
                      Share This Link With Your Campus Friends
                    </h2>
                    <div
                      className="flex justify-center gap-[20px] border border-emerald-500 rounded-xl p-2 cursor-pointer"
                      onClick={() => handleCopyToClipboard(appBaseUrl)}
                    >
                      <input
                        type="text"
                        value={appBaseUrl}
                        readOnly
                        className="bg-transparent outline-none text-zinc-600"
                      />
                      <div className="flex justify-center gap-[20px]  ">
                        <button>
                          <IoShareSocialOutline
                            className="text-3xl text-emerald-500"
                            title="Click To Copy"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <>
                  {/* searchbar */}
                  <div className="mt-[1em] mb-[1em] w-full flex justify-center z-20">
                    <form
                      className="flex items-center gap-3
            w-[99%] sm:w-[70%] md:w-[60%] xl:w-[40%] border border-zinc-600 p-[8px] rounded-lg"
                    >
                      <AiOutlineSearch className="text-xl" />
                      <input
                        type="text"
                        placeholder="search anything"
                        className="w-full bg-transparent border-none outline-none"
                        value={searchText}
                        onChange={handleSearchChange}
                      />
                    </form>
                  </div>

                  {/* categories */}
                  {!searchText && (
                    <div className="mt-4 text-zinc-300">
                      <div className="mb-[15px] flex items-center gap-2">
                        <p className="">SCROLL TO FILTER</p>
                        <p>
                          <AiOutlineArrowRight />
                        </p>
                      </div>
                      <div className=" overflow-x-scroll prompt">
                        <div className="flex justify-start">
                          <ul className="flex  space-x-7  pb-1 ">
                            <li
                              className="cursor-pointer flex items-center gap-1"
                              onClick={handleFetchToRent}
                            >
                              <MdOutlineAddBusiness />
                              all
                            </li>
                            {DummyToRentCategory?.map((item) => (
                              <li
                                key={item.id}
                                className="cursor-pointer flex items-center gap-1 hover:text-teal-700"
                                onClick={async () => {
                                  setLoading(true);
                                  let category = item.title;
                                  let token = user?.token;
                                  const config = {
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                    },
                                  };
                                  let approved = "yes";
                                  let dataToSend = { category, approved };
                                  try {
                                    const response = await axios.post(
                                      "/rent/cat",
                                      dataToSend,
                                      config
                                    );
                                    if (response) {
                                      setLoading(false);
                                      setAllToRent(response.data);
                                    }
                                  } catch (error) {
                                    setLoading(false);
                                    toast.error("Failed to find " + item.title);
                                  }
                                }}
                              >
                                <MdOutlineAddBusiness />
                                {item.title}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* data */}
                  <div>
                    {/* nav numbers */}
                    {!searchText && (
                      <nav className="flex justify-center">
                        <ul className="flex gap-[2em] mt-[10px] px-[5px] py-[10px] items-center ">
                          {/* map */}

                          <>
                            <li>
                              <a
                                href="#"
                                onClick={prevPage}
                                className="text-zinc-700"
                              >
                                <p className="text-zinc-300">Prev</p>
                              </a>
                            </li>
                            <li className="flex gap-[10px] ">
                              {numbers
                                .slice(start - 1, end)
                                .map((item, index) => (
                                  <li
                                    key={index}
                                    className={`normal-nav ${
                                      currentPage === item && "active-nav"
                                    }`}
                                  >
                                    <a
                                      href="#"
                                      onClick={() => {
                                        handleClick(item);
                                        changeCurrentPage(item);
                                      }}
                                    >
                                      <p>{item}</p>
                                    </a>
                                  </li>
                                ))}
                            </li>

                            <li>
                              <a href="#" onClick={nextPage}>
                                <p className="text-zinc-300">Next</p>
                              </a>
                            </li>
                          </>
                        </ul>
                      </nav>
                    )}
                    {/* products */}
                    <div>
                      <div className="mt-[2em]">
                        {searchText ? (
                          <>
                            <div className="mb-[15px] text-zinc-400">
                              {searchText && <p>Results For : {searchText}</p>}
                            </div>

                            {searchedResults?.length > 0 ? (
                              <>
                                <div>
                                  <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="my-masonry-grid "
                                    columnClassName="my-masonry-grid_column"
                                  >
                                    {searchedResults?.map((item) => (
                                      <div
                                        className="mb-8"
                                        key={item._id}
                                        onClick={() => {
                                          handleAddViews(item._id);
                                        }}
                                      >
                                        <div className="relative rounded-lg group ">
                                          <Link to={`/rent-out/${item._id}`}>
                                            <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                                              <div
                                                className="bg-gradient-to-t
                                                   from-transparent to-black opacity-90 w-full h-full rounded-md"
                                              >
                                                {/* top stats */}
                                                <div>
                                                  <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                                                    <div>
                                                      <a
                                                        href={`tel:+${item.phone}`}
                                                      >
                                                        {" "}
                                                        <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                                      </a>
                                                    </div>
                                                    <div className="flex gap-[20px] items-center">
                                                      <p className="text-white text-md flex items-center gap-[5px]">
                                                        <AiOutlineEye className="text-lg" />
                                                        <span>
                                                          {item.views.length}
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="absolute top-[80px] left-3">
                                                    <p className="text-white text-xl">
                                                      {item.title}
                                                    </p>
                                                  </div>
                                                </div>
                                                {/*  */}
                                                <div className="absolute bottom-[20px]  flex gap-[10%] w-full ">
                                                  <div className="pl-2"></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Link>

                                          <div className="min-h-[300px]">
                                            <img
                                              src={item.firstPhoto}
                                              alt=""
                                              className="rounded-md object-cover w-full"
                                              loading="lazy"
                                            />
                                          </div>
                                        </div>
                                        {/* more items */}
                                        <div className="flex items-center justify-between mt-[10px]">
                                          <Link
                                            to={
                                              item.owner == user?.username
                                                ? "/my-profile"
                                                : `/other-profile?owner=${item.owner}`
                                            }
                                          >
                                            <p
                                              className={`w-8 h-8 flex items-center justify-center rounded-full  text-white`}
                                            >
                                              {item.owner.substring(0, 1)}
                                            </p>
                                          </Link>
                                          <p className="text-center text-zinc-400">
                                            {moment(item.createdAt).fromNow()}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                  </Masonry>
                                </div>
                              </>
                            ) : (
                              <div className="flex justify-center mt-[7em]">
                                <p>😥 No results for {searchText}</p>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <div>
                              <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid "
                                columnClassName="my-masonry-grid_column"
                              >
                                {records?.map((item) => (
                                  <div
                                    className="mb-8"
                                    key={item._id}
                                    onClick={() => {
                                      handleAddViews(item._id);
                                    }}
                                  >
                                    <div className="relative rounded-lg group ">
                                      <Link to={`/rent-out/${item._id}`}>
                                        <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                                          <div
                                            className="bg-gradient-to-t
                                                from-transparent to-black opacity-90 w-full h-full rounded-md"
                                          >
                                            {/* top stats */}
                                            <div>
                                              <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                                                <div>
                                                  <a
                                                    href={`tel:+${item.phone}`}
                                                  >
                                                    {" "}
                                                    <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                                  </a>
                                                </div>
                                                <div className="flex gap-[20px] items-center">
                                                  <p className="text-white text-md flex items-center gap-[5px]">
                                                    <AiOutlineEye className="text-lg" />
                                                    <span>
                                                      {item.views.length}
                                                    </span>
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="absolute top-[80px] left-3">
                                                <p className="text-white text-xl">
                                                  {item.title}
                                                </p>
                                              </div>
                                            </div>
                                            {/*  */}
                                            <div className="absolute bottom-[20px]  flex gap-[10%] w-full ">
                                              <div className="pl-2"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>

                                      <div className="min-h-[300px]">
                                        <img
                                          src={item.firstPhoto}
                                          alt=""
                                          className="rounded-md object-cover w-full"
                                          loading="lazy"
                                        />
                                      </div>
                                    </div>
                                    {/* more items */}
                                    <div className="flex items-center justify-between mt-[10px]">
                                      <Link
                                        to={
                                          item.owner == user?.username
                                            ? "/my-profile"
                                            : `/other-profile?owner=${item.owner}`
                                        }
                                      >
                                        <p
                                          className={`w-8 h-8 flex items-center justify-center rounded-full  text-white`}
                                        >
                                          {item.owner.substring(0, 1)}
                                        </p>
                                      </Link>
                                      <p className="text-center text-zinc-400">
                                        {moment(item.createdAt).fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </Masonry>
                            </div>
                          </>
                        )}

                        {/*  */}
                      </div>
                    </div>
                  </div>
                </>
              </>
            )}
          </>
        )}

        {/*  */}
      </div>
      {/*  */}
    </div>
  );
};

export default RentOut;
