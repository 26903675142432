import React, { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Articlecategories } from "../Dummydata";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";

const CreateArticle = () => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const [photo, setPhoto] = useState("");

  const handleCreate = async (e) => {
    e.preventDefault();
    if (!title) return toast.error("Title required", { theme: "dark" });
    if (!category) return toast.error("Category required", { theme: "dark" });
    if (!content) return toast.error("Content required", { theme: "dark" });

    const dataToSend = { title, category, content };
    console.log(dataToSend);
  };

  return (
    <div className="">
      {/* topbar */}
      <div className="ml-4 pt-3">
        <div className="flex gap-8 items-center">
          <Link to="/articles">
            <AiOutlineArrowLeft className="text-2xl" />
          </Link>
          <div>
            <h2 className="font-bold text-md text-zinc-400">Add New Article</h2>
          </div>
        </div>
      </div>
      {/* form */}
      <div className="mt-[2em] pb-[2em] w-full">
        <form
          className=" w-[98%] sm:w-[80%] lg:w-[50%] m-auto"
          onSubmit={handleCreate}
        >
          <div className="flex flex-col gap-[8px] mb-[20px]">
            <label
              htmlFor="title"
              className="text-lg font-bold"
              style={{ fontWeight: 600 }}
            >
              Add A Title
            </label>
            <input
              type="text"
              placeholder="title here"
              id="title"
              value={title}
              required
              minLength={2}
              maxLength={80}
              onChange={(e) => setTitle(e.target.value)}
              className="p-[8px] bg-transparent border border-zinc-400 rounded-lg"
            />
          </div>
          {/* category */}
          <div className="flex flex-col gap-[8px] mb-[20px]">
            <label
              htmlFor="category"
              className="text-lg font-bold"
              style={{ fontWeight: 600 }}
            >
              Best Category For Your Article
            </label>

            <select
              name="category"
              id="category"
              className="p-[8px] bg-[#1E1C1C] text-white  border border-zinc-400 rounded-lg"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Choose</option>
              {Articlecategories.map((item) => (
                <option key={item.id}>{item.title}</option>
              ))}
            </select>
          </div>
          {/* content */}
          <div className="flex flex-col gap-[8px] mb-[12px]">
            <label
              htmlFor="content"
              className="text-lg font-bold"
              style={{ fontWeight: 600 }}
            >
              Add Your Content
            </label>

            <ReactQuill
              theme="snow"
              value={content}
              onChange={setContent}
              className="p-[8px] bg-transparent rounded-lg h-[600px] md:h-[700px] mb-10"
              placeholder="enter content min(20)"
              style={{ color: "white" }} // Change placeholder color
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link"],
                  ["clean"],
                ],
                clipboard: {
                  matchVisual: false,
                },
              }}
            />
          </div>

          {/* {loading || loadingPhoto ? (
                          <div className="w-full">
                            <p className="text-center">Please wait 😊 ... </p>
                          </div>
                        ) : (
                          <button
                            className="bg-emerald-800 text-white w-full p-[8px] rounded-md"
                            // onClick={handleCreate}
                          >
                            Add New Biz
                          </button>
                        )} */}
          <button
            className="bg-emerald-800 text-white w-full p-[8px] rounded-md mt-12"
            onClick={handleCreate}
          >
            Add New Article
          </button>
        </form>
      </div>
      {/*  */}
    </div>
  );
};

export default CreateArticle;
