import React, { useEffect, useState } from "react";
import {
  AiOutlineArrowRight,
  AiOutlineClose,
  AiOutlinePhone,
} from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../axios";

const UpcomingEvents = () => {
  const { user } = useSelector((state) => state.auth);

  // working on modal
  const [selectedPost, setSelectedPost] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  // what happens when we click on a post
  const handlePostClick = (post) => {
    setSelectedPost(post);
    setIsPopUpOpen(true);
  };

  const handleAddComment = (e) => {
    e.preventDefault();
  };

  const PopUpPage = ({ post, onClose }) => {
    return (
      <div className="pop-up-page prompt ">
        {/* close btn */}

        {/* data */}
        <div className="pop-up-content">
          <div className="flex justify-center mt-[60px] z-[999] ">
            <button onClick={onClose}>
              <AiOutlineClose
                className="text-5xl text-white p-[10px] rounded-full "
                style={{
                  border: "2px solid #188c56",
                  position: "sticky",
                  top: "20px",
                }}
                title="close"
              />
            </button>
          </div>
          <div className=" h-[70vh] sm:h-[60vh] overflow-y-scroll prompt">
            <div className="px-[10px]">
              <div className="mb-[40px] flex justify-between items-center">
                <p className="text-lg text-white font-bold mb-2">
                  {post.title}
                </p>
                <p className="text-teal-600">#{post.location}</p>
              </div>
            </div>

            <div>
              <img
                src={post.image}
                alt=""
                className="max-h-[400px] object-contain w-full"
              />
            </div>

            <div className="mt-[20px] flex justify-between w-full gap-[10px] flex-wrap">
              <div className="flex gap-[20px] items-center">
                <div className="flex gap-2 items-center">
                  <BsPerson className="text-xl text-white" />
                  <p className="text-white">{post.attending}</p>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <AiOutlinePhone className="text-white" />
                <a href={`tel:${post.phone}`} className="text-white">
                  {post.phone}
                </a>
              </div>
            </div>

            <div className=" my-[30px]">
              <p className="text-zinc-200 mb-3">{post.desc}</p>
              <div>
                <p className="text-emerald-700 font-bold">Price Range</p>
                <p className="text-zinc-200"> {post.prices}</p>
              </div>
            </div>

            {/* comment */}
            {/* <div className="text-white mt-[20px]">comments</div> */}
          </div>
        </div>
      </div>
    );
  };

  const [allEvents, setAllEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate("/login");
      toast.warning("Account Needed");
    }
  }, [user, navigate]);

  // fetch events around user location
  const handleFetchEvents = async () => {
    try {
      setLoading(true);
      // fetch based on <location> from user
      let location = user?.location;
      let approved = "yes";
      let dataToSend = { location, approved };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post("/event/location", dataToSend, config);
      if (response) {
        setLoading(false);
        setAllEvents(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Events");
    }
  };

  useEffect(() => {
    handleFetchEvents();
  }, []);

  return (
    <div className="">
      {allEvents?.length > 0 && (
        <>
          {/* topbar */}
          <div>
            <div className="mb-[15px] flex justify-between items-center">
              <h1 className="mb-3 text-zinc-300">Events in {user?.location}</h1>
              {user ? (
                <Link to="/events">
                  <div className="flex justify-between items-center gap-[1-px] hover:text-teal-800">
                    <p>SEE ALL</p>
                    <p>
                      <AiOutlineArrowRight />
                    </p>
                  </div>
                </Link>
              ) : (
                <Link to="/login">
                  <div className="flex justify-between items-center gap-[1-px] hover:text-teal-800">
                    <p>SEE ALL</p>
                    <p>
                      <AiOutlineArrowRight />
                    </p>
                  </div>
                </Link>
              )}
            </div>
          </div>
          {/* wrapper */}

          <div className="flex gap-[10px] overflow-x-scroll w-full prompt pb-2">
            <div className="flex flex-nowrap">
              {allEvents?.map((item) => (
                <>
                  <div
                    key={item._id}
                    className="flex-shrink-0 mr-[15px]"
                    onClick={() => navigate(`/events/${item._id}`)}
                  >
                    <div className="relative rounded-lg group ">
                      <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                        <div
                          className="bg-gradient-to-t
                                from-transparent to-black opacity-90 w-full h-full rounded-md"
                        >
                          {/* top stats */}
                          <div>
                            <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                              <div>
                                <a href={`tel:+${item.phone}`}>
                                  {" "}
                                  <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                </a>
                              </div>
                              <div className="flex gap-[20px]">
                                <p className="text-white text-md flex items-center gap-[5px]">
                                  <BsPerson className="text-lg" />
                                  <span>{item.attending.length}</span>
                                </p>
                              </div>
                            </div>
                            <div className="absolute top-[80px] left-3">
                              <p className="text-white">{item.title}</p>
                            </div>
                          </div>
                          {/*  */}
                          <div className="absolute bottom-[20px] left-[20px]  flex gap-[10%] w-full ">
                            <div>
                              <div className="flex gap-[10px] text-zinc-300">
                                <p>{item.location}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <img
                        src={item.photo}
                        loading="lazy"
                        alt=""
                        className="w-72 h-80 rounded-lg object-cover"
                      />
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}

      {/* pop up screen */}
      {isPopUpOpen && (
        <div className="pop-up-overlay">
          <PopUpPage
            post={selectedPost}
            onClose={() => setIsPopUpOpen(false)}
          />
        </div>
      )}
    </div>
  );
};

export default UpcomingEvents;
