import React, { useEffect, useState } from "react";
import {
  AiOutlineClose,
  AiOutlineMail,
  AiOutlineMenu,
  AiOutlineBell,
  AiOutlinePhone,
  AiOutlineUser,
  AiOutlineMessage,

  // AiOutlineHeart,
} from "react-icons/ai";
import { Link, json, useNavigate } from "react-router-dom";
import Ads from "../components/Ads";
// import Services from "./Services";
// import FeaturedProducts from "../components/FeaturedProducts";
import Logo from "../assets/officialOne.png";
import { FaWalkieTalkie } from "react-icons/fa6";

// import FeaturedArt from "../components/FeaturedArt";
import UpcomingEvents from "../components/UpcomingEvents";
import { CiLocationOn } from "react-icons/ci";
import FeaturedBiz from "../components/FeaturedBiz";

import { useSelector } from "react-redux";

import axios from "../axios";
import { toast } from "react-toastify";
import { IoMdNotificationsOutline } from "react-icons/io";
import { NavLinks } from "../constants";
import ImageChanger from "../components/ImageChanger";
import { Helmet } from "react-helmet";
import PopularBiz from "../components/PopularBiz";
import NewestBiz from "../components/NewestBiz";
import Hello from "../assets/hello.png";

const Home = () => {
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  // make sure is set to online
  const handleSetOnline = async () => {
    try {
      let userStatus = "online";
      let userId = user?._id;
      let dataToSend = { userStatus };
      // Make the PUT request to update the user's status

      let response = await axios.put(`/users/${userId}`, dataToSend);
      if (response) {
        console.log("user set to onine");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [toggleDrawer, setToggleDrawer] = useState(false);

  // handle user backgroun colors
  const getRandomColorClass = () => {
    const colors = [
      "bg-red-500",
      "bg-blue-500",
      "bg-green-700",
      "bg-yellow-700",
      "bg-orange-700",
      "bg-purple-500",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const [backgroundClass, setBackgroundClass] = useState(getRandomColorClass());

  const changeBackgroundColor = () => {
    setBackgroundClass(getRandomColorClass());
  };

  useEffect(() => {
    const intervalId = setInterval(changeBackgroundColor, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [MyNotif, setMyNotif] = useState([]);
  const [loadingNotif, setLoadingNotif] = useState(false);

  const handleFetchNotification = async () => {
    if (!user) {
      navigate("/login");
      return toast.error("Please Login", { theme: "dark" });
    }

    try {
      setLoadingNotif(true);
      let username = user.username;
      let dataToSend = { username };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post("/notify/mine", dataToSend, config);

      if (res && res.data) {
        const notifications = Array.isArray(res.data) ? res.data : [res.data];
        setMyNotif(notifications);
        setLoadingNotif(false);
      } else {
        setMyNotif([]); // Set an empty array if no data is received
        setLoadingNotif(false);
        console.error("Invalid data format received:", res.data);
      }
    } catch (error) {
      toast.error("Error Fetching Notifications", { theme: "dark" });
      console.error("Error fetching notifications:", error);
    }
  };

  // if (MyNotif.length > 0) {
  //   document.addEventListener("visibilitychange", () => {
  //     if (document.visibilityState === "hidden") {
  //       new Notification("Unchecked Notifications", {
  //         body: "You have " + MyNotif.length + " notifications ",
  //         tag: "People Interested",
  //         icon: Logo,
  //         badge: Logo,
  //       });
  //     }
  //   });
  // }

  useEffect(() => {
    if (user) {
      handleFetchNotification();
      handleSetOnline();
    } else {
      toast.warning("Please Login", { theme: "dark" });
    }
  }, [user]);

  const getCurrentTime = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  // working on modal
  const [selectedPost, setSelectedPost] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const handlePostClick = () => {
    document.body.style.overflow = "hidden";
    setIsPopUpOpen(true);
  };

  const PopUpPage = () => {
    return (
      <div className="">
        {/* close btn */}

        {/* data */}
        <div className=" prompt px-3">
          <div className="flex justify-center my-[10px] z-[999] hide-scrollbar">
            <button
              onClick={() => {
                setIsPopUpOpen(false);
                document.body.style.overflow = "auto";
              }}
            >
              <AiOutlineClose
                className="text-5xl text-white p-[10px] rounded-full "
                style={{
                  border: "2px solid zinc",
                  position: "sticky",
                  top: "60px",
                }}
                title="close"
              />
            </button>
          </div>
          <div className=" h-[70vh] sm:h-[60vh] mt-4 overflow-y-scroll prompt">
            <div>
              <div className="mb-[20px]">
                <div className="flex w-full justify-center mb-3">
                  <img src={Hello} alt="" />
                </div>

                <p
                  className="text-2xl text-zinc-300 font-bold mb-4 text-center"
                  style={{ wordWrap: "break-word" }}
                >
                  Hello {user ? user?.username : "There"}
                </p>
                <p
                  className="text-lg text-zinc-300  mb-4 text-center"
                  style={{ wordWrap: "break-word" }}
                >
                  Subscribe to the Gold Plan For Full Access
                </p>

                <div className="w-full flex justify-center mb-4">
                  <Link to="/gold-subscription">
                    <p className="underline">Subsribe Now ?</p>
                  </Link>
                </div>

                <div className="w-full flex justify-center">
                  <a
                    href="tel:+254 798556471"
                    className="flex gap-2 items-center"
                  >
                    <p>
                      <AiOutlinePhone className="text-white text-2xl" />
                    </p>
                    <p>+254 798556471</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!user?.isPaid) {
      handlePostClick();
    }
  }, [user]);

  return (
    <div>
      {/* wrapper */}
      <div className=" w-[98%] sm:w-[90%] m-auto py-[3px] md:py-[1em] ">
        {/* {console.log(user)} */}
        {/* navbar */}
        <div
          className="mb-[30px] my-[5px] py-[15px] px-1 bg-[#212121] sm:rounded-2xl z-20"
          style={{
            // position: "-webkit-sticky",
            position: "sticky",
            top: 0,
          }}
        >
          <Helmet>
            <title>{`Feed | iLocca`}</title>
            <meta
              name="description"
              content="Bridging the gap between local businesses and the community, offering a streamlined platform for residents to discover and acquire quality local products and services."
            />
          </Helmet>

          <div className="flex justify-between items-center gap-[10px] ">
            <Link to="/">
              <div className="flex item-center flex-col">
                <div>
                  <img src={Logo} alt="" className="w-[50px] object-contain" />
                </div>
              </div>
            </Link>

            <div className="flex gap-[26px]  md:gap-[60px] items-center">
              <Link to="/notifications">
                <div className="relative">
                  <IoMdNotificationsOutline
                    className="text-3xl text-emerald-500"
                    title="Notifications"
                  />
                  <p
                    className="absolute bottom-[20px] left-[32px] z-[999]"
                    style={{ fontWeight: 700 }}
                  >
                    {loadingNotif ? 0 : MyNotif.length}
                  </p>
                </div>
              </Link>
              <div>
                {!user ? (
                  <Link to="/login">
                    <AiOutlineUser className="text-3xl text-emerald-400" />
                  </Link>
                ) : (
                  <div>
                    <Link to="/my-profile">
                      <div>
                        {/* {user?.username?.substring(0, 2)} */}

                        <img
                          src={user?.profile}
                          alt=""
                          className={`w-[50px] h-[50px] flex items-center object-cover justify-center rounded-full`}
                        />
                      </div>
                    </Link>
                  </div>
                )}
              </div>
              <div className="">
                {user?.username && user?.location && (
                  <AiOutlineMenu
                    className="text-emerald-500 text-3xl cursor-pointer"
                    onClick={() => {
                      setToggleDrawer(true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* side drawer and content */}
        {/* sideDrawer */}
        {toggleDrawer && (
          <div className="fixed top-1 left-0 z-[999] bg-[#212121] text-zinc-300 min-h-[100vh] w-[100%] md:w-[50%] rounded-tr-3xl rounded-br-3xl slide-in-left">
            {/* content */}
            <div className="pl-[30px]">
              <div className="flex justify-between py-[1em] items-center">
                <div>
                  <img src={Logo} alt="" className="w-[50px] object-contain" />
                </div>
                <AiOutlineClose
                  className=" text-3xl sm:text-4xl cursor-pointer mr-[10px] rounded-full"
                  // style={{ border: "2px solid #44ab71" }}
                  onClick={() => setToggleDrawer(false)}
                />
              </div>
              <div className="mt-10">
                <ul>
                  {NavLinks?.map((item) => (
                    <li
                      key={item.id}
                      className=" w-[80%] sm:w-[70%] pb-[4px] mb-[10px]"
                      style={{ borderBottom: "2px solid #1c3b29" }}
                    >
                      <Link
                        to={`${item.path}`}
                        onClick={() => setToggleDrawer(false)}
                      >
                        <p
                          className="text-zinc-300 hover:text-emerald-300"
                          style={{ fontWeight: 500 }}
                        >
                          {item.title}
                        </p>
                      </Link>
                    </li>
                  ))}
                </ul>
                <div>
                  <div className="">
                    <h2 className="py-[10px] text-emerald-500">
                      How To Contact Us
                    </h2>
                    <div className="flex gap-[40px] mt-[10px]">
                      <div>
                        <a href="tel:0798556471">
                          {" "}
                          <AiOutlinePhone className="text-3xl" />
                        </a>
                      </div>
                      <div>
                        <a href="mailto:daysseller@gmail.com">
                          <AiOutlineMail className="text-3xl" />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://wa.me/+254798556471?text=Hello%20there!"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <AiOutlineMessage className="text-3xl" />
                        </a>
                      </div>
                    </div>
                    {/* <div className="flex gap-[10px] items-center mb-[15px]">
                        <AiOutlinePhone className="text-xl" />
                        <a href="tel:0798556471">Click To Call Us</a>
                      </div>
                      <div className="flex gap-[10px] items-center mb-[15px]">
                        <AiOutlineMail className="text-xl" />
                        <a href="mailto:daysseller@gmail.com">
                          Click To Email Us
                        </a>
                      </div>
                      <div className="flex gap-[10px] items-center mb-[15px]">
                        <AiOutlineMessage className="text-xl" />
                        <a
                          href="https://wa.me/+254798556471?text=Hello%20there!"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Message Us on WhatsApp
                        </a>
                      </div> */}
                  </div>
                  {/* social media */}
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*  */}
        {/* topbar */}
        <div>
          <div className="flex flex-col-reverse sm:flex-row justify-between mb-[2em] sm:px-2">
            <div>
              {user?.username && user?.location ? (
                <>
                  <h1 className="font-bold text-md text-emerald-400 mb-1">
                    {getCurrentTime()} {user?.username.substring(0, 14)}.
                  </h1>
                  <h1 className="font-bold text-md text-emerald-400">
                    Wacha tukuconnect.
                  </h1>
                </>
              ) : (
                <h1 className="font-bold text-md text-zinc-400">
                  Welcome. Let's connect you
                </h1>
              )}
            </div>

            {user && (
              <div className="flex justify-between sm:gap-[2em] items-center">
                <div className="flex items-center  gap-[10px] mb-[20px] text-emerald-400">
                  <FaWalkieTalkie className="text-lg" />
                  <Link to="/feedback" className="underline">
                    Talk To Us
                  </Link>
                </div>

                <div className="flex items-center  gap-[10px] mb-[20px] text-emerald-400">
                  <CiLocationOn className="text-lg" />
                  <Link to="/my-profile" className="underline">
                    {user?.location}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* main content */}
        <div className="flex  gap-[20px] px-[10px] lg:px-[20px]">
          <div className="flex-1 ">
            <div className="my-[1.2em]">
              <ImageChanger />
            </div>
            <div className="mb-[2.2em]" />
            <Ads />
            <div className="mb-[2.2em]" />

            {user ? (
              <>
                <div className="mb-[2.2em]" />
                <FeaturedBiz />
                <div className="mb-[2.2em]" />
                {/* <RentOut /> */}
                <div className="mb-[2.2em]" />
                {/* <FeaturedArt /> */}
                <PopularBiz />
                <div className="mb-[2.2em]" />
                <NewestBiz />
                <div className="mb-[2.2em]" />
                <UpcomingEvents />
              </>
            ) : (
              <div>
                <div className="w-full flex justify-center">
                  <div>
                    <p className="text-center mb-4 font-semibold text-zinc-300 ">
                      😎 Due To Privacy Reasons, You Must Sign In
                    </p>
                    <p className="text-center mb-4 font-semibold text-zinc-300 ">
                      {/* To see {owner}'s activity */}
                    </p>
                    <Link to="/login">
                      <p className="text-emerald-400 font-semibold text-center">
                        LOGIN
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <div className="flex-[0.3] hidden lg:block bg-yellow-300 ">
            <Ads />
          </div> */}
        </div>
        {/*  */}
        {/* pop up screen */}
        {isPopUpOpen && (
          <div className="">
            {/* overlay div */}
            <div
              className="fixed z-[999] top-0 bottom-0 left-0 right-0 w-full  bg-[rgba(0,0,0,0.6)]"
              onClick={() => {
                setIsPopUpOpen(false);
                document.body.style.overflow = "auto";
              }}
            >
              <div className=" mt-[23%] md:mt-[15%] lg:mt-[13%]  xl:mt-[7%] w-[96%]  md:w-[80%]   lg:w-[70%]   2xl:w-[60%] m-auto">
                <div
                  className="  bg-emerald-700 rounded-xl"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <PopUpPage />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
