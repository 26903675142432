import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../axios";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { useSelector } from "react-redux";
import moment from "moment";
import { CiLocationOn } from "react-icons/ci";
import imageCompression from "browser-image-compression";
import {
  AiOutlineArrowUp,
  AiOutlineClose,
  AiOutlineComment,
  AiOutlineGold,
  AiOutlineLike,
  AiOutlineSearch,
} from "react-icons/ai";
import { IoMdAdd, IoMdArrowBack } from "react-icons/io";
import { Helmet } from "react-helmet";
import { LuPartyPopper } from "react-icons/lu";

const Fununu = () => {
  const [allFununu, setAllFununu] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);

  // fetch fununu around user location
  const handleFetchFununu = async () => {
    try {
      setLoading(true);
      if (!user) {
        // fetch all fununus
        const response = await axios.get("/fununu/");
        if (response) {
          setLoading(false);
          setAllFununu(response.data);
          // console.log(response.data);
        }
      } else {
        // fetch based on <location> from user if user
        let location = user?.location;
        let dataToSend = { location };
        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.post(
          "/fununu/location",
          dataToSend,
          config
        );
        if (response) {
          setLoading(false);
          setAllFununu(response.data);
          // console.log(response.data);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Fununu");
    }
  };

  useEffect(() => {
    handleFetchFununu();
  }, []);

  //   pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = allFununu?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(allFununu?.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(4);

  const handleClick = (number) => {
    setStart(number);
    setEnd(number + 3);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      handleClick(currentPage);
    }
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      handleClick(currentPage);
    }
  };

  const changeCurrentPage = (id) => {
    setCurrentPage(id);
  };

  // search  states
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setsearchTimeout] = useState(null);
  const [searchedResults, setSearchedResults] = useState(null);

  // search user func
  const handleSearchChange = async (e) => {
    e.preventDefault();
    clearTimeout(setsearchTimeout);

    setSearchText(e.target.value);

    // console.log(searchText);

    setsearchTimeout(
      setTimeout(() => {
        const searchResults = allFununu.filter(
          (item) =>
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.owner.toLowerCase().includes(searchText.toLowerCase()) ||
            item.description.toLowerCase().includes(searchText.toLowerCase()) ||
            item.searchTerms.toLowerCase().includes(searchText.toLowerCase())
        );

        setSearchedResults(searchResults);
      }, 500)
    );
  };

  // scroll to top functionality
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // handle user backgroun colors
  const getRandomColorClass = () => {
    const colors = [
      "bg-red-500",
      "bg-blue-500",
      "bg-green-700",
      "bg-yellow-700",
      "bg-orange-700",
      "bg-purple-500",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const [backgroundClass, setBackgroundClass] = useState(getRandomColorClass());

  const changeBackgroundColor = () => {
    setBackgroundClass(getRandomColorClass());
  };

  const textRef = useRef(null);

  const handleCopy = () => {
    if (textRef.current) {
      textRef.current.select();
      document.execCommand("copy");
      // You can also display a success message or perform any other action after copying.
      toast.success("Copied To Clipboard");
    }
  };

  const [showCreate, setShowCreate] = useState(false);
  const [photo, setPhoto] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [searchTerms, setSearchTerms] = useState("");
  const [visibleToAllCampuses, setVisibleToAllCampuses] = useState("");

  // upload photo to cloudinary
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const postPhoto = async (pic) => {
    if (pic === null || undefined) {
      toast.error("Please select photo");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Adjust the maximum size of the compressed image
      maxWidthOrHeight: 1920, // Adjust the maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for better performance
    };

    try {
      setLoadingPhoto(true);
      const compressedFile = await imageCompression(pic, options);
      const data = new FormData();
      data.append("file", compressedFile);
      data.append("upload_preset", "p2jnu3t2");
      let res = await fetch(
        "https://api.cloudinary.com/v1_1/ddqs3ukux/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await res.json();
      setLoadingPhoto(false);
      setPhoto(urlData.url);
      toast.success("Uploaded Photo", { theme: "dark" });
    } catch (error) {
      setLoadingPhoto(false);
      toast.error("Error uploading Photo", { theme: "dark" });
    }
  };

  const handleClearStates = () => {
    setPhoto("");
    setTitle("");
    setDescription("");
    setSearchTerms("");
    setVisibleToAllCampuses("");
  };

  const handleAddPost = async () => {
    if (!title) return toast.error("Title missing", { theme: "dark" });
    if (!description)
      return toast.error("Description missing", { theme: "dark" });

    if (!visibleToAllCampuses)
      return toast.error("visibility option missing", { theme: "dark" });

    if (!visibleToAllCampuses) {
      setVisibleToAllCampuses("nope");
    }

    try {
      setLoading(true);
      let location = user?.location;
      let phone = user?.phone;
      let owner = user?.username;
      let ownerEmail = user?.email;

      if (!ownerEmail || ownerEmail == "")
        return toast.error("Please re-login, we can't find your email", {
          theme: "dark",
        });

      let dataToSend = {
        title,
        description,
        location,
        photo,
        phone,
        owner,
        ownerEmail,
        searchTerms,
        visibleToAllCampuses,
      };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post("/fununu/", dataToSend, config);
      if (response) {
        setLoading(false);
        toast.success("Added To Social", { theme: "dark" });
        await handleFetchFununu();
        setShowCreate(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error Adding To Social", { theme: "dark" });
    }
  };

  // like a post
  const handleLikePost = async (post) => {
    try {
      if (!user) {
        toast.error("Please Login To leave a like", { theme: "dark" });
        return;
      }

      let username = user?.username;
      let id = post._id;
      let likeData = { username };

      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let res = await axios.post("/fununu/like/" + id, likeData, config);
      toast.success("Like Added", { theme: "dark" });

      if (res) {
        // notification
        if (user?.username !== post.owner) {
          // create notification
          let sender = user?.username;
          let receiver = post.owner;
          let receiverEmail = post.ownerEmail;
          let message = ` ${post.title} got a like 👍 from ${sender} at ${user?.location}`;
          let referID = `/social`;
          let notifyData = {
            sender,
            receiver,
            receiverEmail,
            message,
            referID,
          };
          await axios.post("/notify/create", notifyData, config);
        }
      }
    } catch (error) {
      toast.error("Failed To Like");
    }
  };

  const handleAddViews = async (id) => {
    try {
      if (user) {
        let username = user.username;
        let viewsData = { username };

        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios.post("/fununu/view/" + id, viewsData, config);
      } else {
        console.log("Not Logged in");
      }
    } catch (error) {
      console.log(error);
    }
  };

  let topBarBg = ["rentOutBgOne", "rentOutBgTwo", "rentOutBgThree"];
  const [chosenBg, setChosenBg] = useState("rentOutBgOne");
  useEffect(() => {
    const interval = setInterval(() => {
      // Find the index of the next background in the array
      const currentIndex = topBarBg.indexOf(chosenBg);
      const nextIndex = (currentIndex + 1) % topBarBg.length;
      setChosenBg(topBarBg[nextIndex]);
    }, 10000); // Change background every 4 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [chosenBg, topBarBg]);

  const getCurrentTime = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  return (
    <div>
      {/* arrow to scroll to top */}
      {showArrow && (
        <div
          className="fixed bottom-20 right-4 text-3xl z-[999] cursor-pointer bg-teal-700 text-zinc-50 rounded-full p-[5px]"
          onClick={handleScrollTop}
        >
          <AiOutlineArrowUp />
        </div>
      )}

      {user ? (
        <Helmet>
          <title>
            Social | {user?.username} | {user?.location} | iLocca
          </title>
          <meta
            name="description"
            content={`Welcome To iLocca our goal is to connect local businesses and local consumers`}
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>Social | iLocca</title>
          <meta
            name="description"
            content={`Welcome To iLocca our goal is to connect local businesses and local consumers`}
          />
        </Helmet>
      )}

      {/* topbar */}
      <div className="mx-2 pt-4">
        {!searchText && (
          <div
            className={`h-[28vh] sm:h-[30vh]  rounded-xl ${chosenBg} relative`}
          >
            {/* overlay div */}
            <div className="bg-gradient-to-b from-transparent to-black opacity-90 w-full h-[28vh] sm:h-[30vh] rounded-sm absolute top-0 left-0">
              {/* overlay div */}
              <div className="absolute top-0  rounded-sm left-0 w-full h-[28vh] sm:h-[30vh] bg-[rgba(0,0,0,.2)]">
                <div className="px-4 py-2 flex justify-between gap-4">
                  <div>
                    <div className="hidden md:flex mb-3">
                      <Link to="/home">
                        <IoMdArrowBack className="text-4xl" />
                      </Link>
                    </div>
                    <p className=" text-xl sm:text-4xl font-semibold mb-8">{`${getCurrentTime()} ${
                      user?.username
                    }`}</p>
                    <p className=" mb-8 sm:mb-1">
                      Post your hustle updates here
                    </p>
                    <p className="sm:mb-4">
                      Notify the community what's up ? 😎
                    </p>
                  </div>
                  {/* create if user has paid */}
                  <div>
                    {user?.isPaid ? (
                      <div className="flex justify-end w-full mt-[15px]">
                        {/* create */}
                        <div>
                          {showCreate ? (
                            <div
                              onClick={() => {
                                handleClearStates();
                                setShowCreate(false);
                              }}
                            >
                              {user?.username && (
                                <div className="flex justify-end w-full mt-[15px] cursor-pointer">
                                  <AiOutlineClose className="bg-red-800 text-4xl p-[5px] text-white rounded-lg" />
                                </div>
                              )}
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                setShowCreate(true);
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                            >
                              {user?.username ? (
                                <div className="flex justify-end w-full mt-[15px] cursor-pointer">
                                  <IoMdAdd className="bg-zinc-800 text-4xl p-[5px] text-white rounded-lg" />
                                </div>
                              ) : (
                                <Link to="/login">
                                  <p className="font-semibold text-zinc-800">
                                    LOGIN
                                  </p>
                                </Link>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-end w-full mt-[15px]">
                        <Link to="/gold-subscription">
                          <AiOutlineGold className="bg-zinc-800 text-4xl p-[5px] text-white rounded-lg" />
                        </Link>
                      </div>
                    )}
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* wrapper */}
      <div className=" px-[8px] sm:px-[2em] lg:px-[5em] pt-[10px]">
        {/*  */}
        {loading ? (
          <div className="h-[70vh] w-full flex justify-center items-center">
            <Spinner message="Fetching Feed ..." />
          </div>
        ) : (
          <div className={`${searchText ? "mt-[10px]" : "mt-[1em]"} `}>
            {allFununu?.length < 1 ? (
              <>
                <div>
                  {/* form */}
                  {!searchText && showCreate && (
                    <div className="w-full">
                      <form
                        className=" w-[98%] sm:w-[70%]  lg:w-[50%] m-auto "
                        onSubmit={handleAddPost}
                      >
                        <h2 className="mb-[10px] text-zinc-500 font-bold">
                          Add A New Post
                        </h2>
                        <div className="flex flex-col gap-[15px]">
                          <input
                            type="text"
                            placeholder="Title Of Post"
                            className="bg-transparent p-[6px] border border-zinc-500 rounded-md outline-none"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                            minLength={3}
                            maxLength={50}
                          />
                          <textarea
                            cols="30"
                            rows="2"
                            placeholder="Enter a short description"
                            className="bg-transparent p-[6px] border border-zinc-500 rounded-md outline-none"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                            minLength={3}
                            maxLength={160}
                          ></textarea>
                          <div>
                            {/* upload image */}
                            <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                              <div className="flex flex-col gap-2 mt-[20px]">
                                <label
                                  htmlFor="mainPhoto"
                                  className="flex items-center gap-[20px] flex-wrap"
                                >
                                  <div>
                                    <p className="text-zinc-400">Add A Photo</p>
                                    <p className="text-sm">
                                      We only accept .jpg .jpeg or png format
                                    </p>
                                  </div>
                                  <div className="flex flex-col items-center">
                                    {loadingPhoto ? (
                                      <Spinner message="uploading ..." />
                                    ) : (
                                      <img
                                        src={
                                          photo
                                            ? photo
                                            : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                        }
                                        alt=""
                                        className="w-[100px] h-[100px] object-cover"
                                      />
                                    )}
                                  </div>
                                </label>
                                <input
                                  type="file"
                                  placeholder="Add Image"
                                  accept="image/*"
                                  onChange={(e) => postPhoto(e.target.files[0])}
                                  required
                                  id="mainPhoto"
                                  className="hidden"
                                />
                              </div>
                            </div>
                          </div>
                          <input
                            type="text"
                            placeholder="Add hashtags, separate by comma"
                            className="bg-transparent p-[6px] border border-zinc-500 rounded-md outline-none"
                            value={searchTerms}
                            onChange={(e) => setSearchTerms(e.target.value)}
                            required
                            minLength={3}
                            maxLength={50}
                          />
                          {/* visibleToAllCampuses */}
                          <div className="flex flex-col gap-2">
                            <label htmlFor="visibleToAllCampuses">
                              Share With All Campuses apart from{" "}
                              {user?.location} ?
                            </label>
                            <select
                              name="visibleToAllCampuses"
                              id="visibleToAllCampuses"
                              value={visibleToAllCampuses}
                              onChange={(e) =>
                                setVisibleToAllCampuses(e.target.value)
                              }
                              className="bg-[#212121] p-[6px] border border-zinc-500 rounded-md outline-none"
                            >
                              <option value="">Choose</option>
                              <option value="nope">No</option>
                              <option value="yes">Yes</option>
                            </select>
                          </div>

                          {loading ? (
                            <Spinner message="Adding To Social" />
                          ) : (
                            <button
                              className="mb-[10px] bg-emerald-700 text-white p-[6px] rounded-md"
                              onClick={handleAddPost}
                            >
                              Add To Social
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  )}
                  <div className="mt-[8vh]">
                    <h2 className="text-center mb-2">😢Ouch.</h2>
                    <h2 className="text-center">
                      No Feed Within {user?.location}
                    </h2>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* form to create */}
                {!searchText && showCreate && user && (
                  <div className="w-full mt-[4em]">
                    <form
                      className=" w-[98%] sm:w-[70%]  lg:w-[50%] m-auto "
                      onSubmit={handleAddPost}
                    >
                      <h2 className="mb-[10px] text-zinc-300 font-bold">
                        Add A New Post
                      </h2>
                      <div className="flex flex-col gap-[15px]">
                        <input
                          type="text"
                          placeholder="Title Of Post"
                          className="bg-transparent p-[6px] border border-zinc-500 rounded-md outline-none"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                          minLength={3}
                          maxLength={50}
                        />
                        <textarea
                          cols="30"
                          rows="2"
                          placeholder="Enter a short description"
                          className="bg-transparent p-[6px] border border-zinc-500 rounded-md outline-none"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          required
                          minLength={3}
                          maxLength={500}
                        ></textarea>
                        <div>
                          {/* upload image */}
                          <div className="flex flex-col items-start gap-[20px] sm:gap-0 sm:flex-row sm:items-center mt-[20px] mb-[20px]  px-[5px] rounded-lg">
                            <div className="flex flex-col gap-2 mt-[20px]">
                              <label
                                htmlFor="mainPhoto"
                                className="flex items-center gap-[20px] flex-wrap"
                              >
                                <div>
                                  <p className="text-zinc-400">Add A Photo</p>
                                  <p className="text-sm">
                                    We only accept .jpg .jpeg or png format
                                  </p>
                                </div>
                                <div className="flex flex-col items-center">
                                  {loadingPhoto ? (
                                    <Spinner message="uploading ..." />
                                  ) : (
                                    <img
                                      src={
                                        photo
                                          ? photo
                                          : "https://pixel-share-25.netlify.app/assets/preview-35b286f0.png"
                                      }
                                      alt=""
                                      className="w-[100px] h-[100px] object-cover"
                                    />
                                  )}
                                </div>
                              </label>
                              <input
                                type="file"
                                placeholder="Add Image"
                                accept="image/*"
                                onChange={(e) => postPhoto(e.target.files[0])}
                                required
                                id="mainPhoto"
                                className="hidden"
                              />
                            </div>
                          </div>
                        </div>
                        <input
                          type="text"
                          placeholder="Add hashtags, separate by comma"
                          className="bg-transparent p-[6px] border border-zinc-500 rounded-md outline-none"
                          value={searchTerms}
                          onChange={(e) => setSearchTerms(e.target.value)}
                          required
                          minLength={3}
                          maxLength={50}
                        />
                        {/* visibleToAllCampuses */}
                        <div className="flex flex-col gap-2">
                          <label
                            htmlFor="visibleToAllCampuses"
                            className="text-zinc-400"
                          >
                            Share With All Campuses apart from {user?.location}{" "}
                            ?
                          </label>
                          <select
                            name="visibleToAllCampuses"
                            id="visibleToAllCampuses"
                            value={visibleToAllCampuses}
                            onChange={(e) =>
                              setVisibleToAllCampuses(e.target.value)
                            }
                            className="bg-[#212121] p-[6px] border border-zinc-500 rounded-md outline-none"
                          >
                            <option value="">Choose</option>
                            <option value="nope">No</option>
                            <option value="yes">Yes</option>
                          </select>
                        </div>

                        {loading ? (
                          <Spinner message="Adding To Social" />
                        ) : (
                          <button
                            className="mb-[10px] bg-emerald-700 text-white p-[6px] rounded-md"
                            onClick={handleAddPost}
                          >
                            Add To Social
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                )}

                {/* BROWSING OPTIONS */}
                <div className="flex justify-between items-center gap-4">
                  <div>
                    {!searchText && user && (
                      <div className="flex justify-between w-full   mb-[1.3em]">
                        <div className="flex flex-col gap-1">
                          <label
                            htmlFor="allCampuses"
                            className="text-zinc-400"
                          >
                            Browse public posts ?
                          </label>
                          <select
                            name="allCampuses"
                            id="allCampuses"
                            className="bg-[#212121] p-1 rounded-xl outline-none"
                            onChange={async (e) => {
                              if (e.target.value == "yes") {
                                let option = e.target.value;
                                let dataToSend = { option };
                                let token = user?.token;
                                const config = {
                                  headers: {
                                    Authorization: `Bearer ${token}`,
                                  },
                                };
                                let response = await axios.post(
                                  "/fununu/public",
                                  dataToSend,
                                  config
                                );
                                if (response) {
                                  setAllFununu(response.data);
                                }
                              } else {
                                handleFetchFununu();
                              }
                            }}
                          >
                            <option value="">Choose</option>
                            <option value="nope">No</option>
                            <option value="yes">Yes</option>
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {!searchText && !showCreate && user && (
                      <>
                        {user?.isStudent && (
                          <div className=" w-full flex justify-end">
                            <Link to="/social-profiles">
                              <div className="flex items-center gap-3 text-emerald-400">
                                <LuPartyPopper />
                              </div>
                            </Link>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {/* searchbar */}
                <div
                  className={` ${
                    !user ? "mt-[4em]" : "mt-[1em]"
                  }  mb-[1em] w-full flex justify-center z-20`}
                >
                  <form
                    className="flex items-center gap-3
            w-[99%] sm:w-[70%] md:w-[60%] xl:w-[40%] border border-zinc-600 p-[8px] rounded-lg  "
                  >
                    <AiOutlineSearch className="text-xl" />
                    <input
                      type="text"
                      placeholder="search feed"
                      className="w-full bg-transparent border-none outline-none"
                      value={searchText}
                      onChange={handleSearchChange}
                    />
                  </form>
                </div>
                {/* nav numbers */}
                {!searchText && (
                  <nav className="flex justify-center">
                    <ul className="flex gap-[2em] mt-[10px] px-[5px] py-[10px] items-center ">
                      {/* map */}

                      <>
                        <li>
                          <a
                            href="#"
                            onClick={prevPage}
                            className="text-zinc-700"
                          >
                            <p className="text-zinc-300">Prev</p>
                          </a>
                        </li>
                        <li className="flex gap-[10px] ">
                          {numbers.slice(start - 1, end).map((item, index) => (
                            <li
                              key={index}
                              className={`normal-nav ${
                                currentPage === item && "active-nav"
                              }`}
                            >
                              <a
                                href="#"
                                onClick={() => {
                                  handleClick(item);
                                  changeCurrentPage(item);
                                }}
                              >
                                <p>{item}</p>
                              </a>
                            </li>
                          ))}
                        </li>

                        <li>
                          <a href="#" onClick={nextPage}>
                            <p className="text-zinc-300">Next</p>
                          </a>
                        </li>
                      </>
                    </ul>
                  </nav>
                )}
                {/* data */}
                <div>
                  {searchText ? (
                    <>
                      <div className="mb-[15px] text-zinc-400">
                        {searchText && <p>Results For : {searchText}</p>}
                      </div>

                      {searchedResults?.length < 1 ? (
                        <>
                          {" "}
                          <div className="flex justify-center mt-[7em]">
                            <p>😥 No results for {searchText}</p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="pt-[2em]">
                            <div className=" w-[100%]  sm:w-[80%]  md:w-[65%]  xl:w-[50%] 2xl:w-[40%] m-auto">
                              {searchedResults?.map((item) => (
                                <div
                                  className="pb-8"
                                  key={item._id}
                                  onClick={() => {
                                    handleAddViews(item._id);
                                  }}
                                >
                                  {/* top part */}
                                  <div className="flex gap-4 flex-wrap items-center justify-between mt-[10px] mb-[15px]">
                                    <Link
                                      to={
                                        item.owner == user?.username
                                          ? "/my-profile"
                                          : `/other-profile?owner=${item.owner}`
                                      }
                                    >
                                      <div className="flex items-center gap-[20px]">
                                        <p
                                          className={`w-8 h-8 flex items-center justify-center text-lg rounded-full ${backgroundClass} text-white`}
                                        >
                                          {item.owner.substring(0, 1)}
                                        </p>
                                        <p className="text-zinc-400">
                                          {moment(item.createdAt).fromNow()}
                                        </p>
                                      </div>
                                    </Link>
                                  </div>

                                  {/* img part */}
                                  <Link to={`/social/${item._id}`}>
                                    <div className="mb-[15px] w-full flex justify-center min-h-[280px]">
                                      <img
                                        src={
                                          item.photo ||
                                          "https://images.pexels.com/photos/235994/pexels-photo-235994.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                        }
                                        alt=""
                                        className="rounded-md object-cover max-h-[550px]"
                                      />
                                    </div>
                                  </Link>

                                  {/* likes and comments */}
                                  <div className=" flex justify-between items-center">
                                    <div className="flex items-center gap-4">
                                      <div
                                        className="flex gap-2 items-center cursor-pointer text-zinc-400 hover:text-zinc-200"
                                        onClick={() =>
                                          user
                                            ? handleLikePost(item)
                                            : toast.warning("login please", {
                                                theme: "dark",
                                              })
                                        }
                                        title="Click To Like"
                                      >
                                        <p>
                                          <AiOutlineLike className="text-xl  " />
                                        </p>
                                        <p className="">
                                          {item.likes.length === 1
                                            ? `${item.likes.length} like`
                                            : `${item.likes.length} likes`}
                                        </p>
                                      </div>
                                      <div>.</div>
                                      <div className="flex items-center gap-2 text-zinc-400">
                                        {item.views.length === 1
                                          ? `${item.views.length} view`
                                          : `${item.views.length} views`}
                                      </div>
                                    </div>

                                    <Link to={`/social/${item._id}`}>
                                      <div
                                        className="flex gap-2 items-center text-zinc-400 "
                                        onClick={() => {
                                          handleAddViews(item._id);
                                        }}
                                      >
                                        <AiOutlineComment className="text-xl " />
                                        <p className="">
                                          {item.comments.length === 1
                                            ? `${item.comments.length} comment`
                                            : `${item.comments.length} comments`}
                                        </p>
                                      </div>
                                    </Link>
                                  </div>
                                  {/* comments */}
                                  <div className="mt-[10px] w-full flex justify-end"></div>

                                  <div className="my-[15px]">
                                    <h2 className="font-semibold text-zinc-200">
                                      {item.title}
                                    </h2>
                                  </div>

                                  {/* description part */}
                                  <div className="mt-[15px]">
                                    <p className="text-zinc-300">
                                      {item.description.substring(0, 80)}...
                                    </p>
                                  </div>

                                  <div className="flex justify-between items-center gap-4">
                                    <div className="flex items-center gap-3 mt-[15px]">
                                      <CiLocationOn className="text-emerald-600 text-2xl font-bold" />
                                      <p className="text-sm text-zinc-400">
                                        {item.location}
                                      </p>
                                    </div>
                                    {/* search terms */}
                                    <div className="flex items-center gap-3 mt-[15px]">
                                      <p className="text-sm text-emerald-500">
                                        {item.searchTerms.substring(0, 12)}...
                                      </p>
                                    </div>
                                  </div>

                                  <div className="h-[3px] w-full bg-zinc-500 mt-[1.8em] rounded-xl" />
                                </div>
                              ))}
                            </div>

                            {/*  */}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="pt-[2em]">
                        <div className=" w-[100%]  sm:w-[80%]  md:w-[65%]  xl:w-[50%] 2xl:w-[40%] m-auto">
                          {records?.map((item) => (
                            <div
                              className="pb-8"
                              key={item._id}
                              onClick={() => {
                                handleAddViews(item._id);
                              }}
                            >
                              {/* top part */}
                              <div className="flex gap-4 flex-wrap items-center justify-between mt-[10px] mb-[15px]">
                                <Link
                                  to={
                                    item.owner == user?.username
                                      ? "/my-profile"
                                      : `/other-profile?owner=${item.owner}`
                                  }
                                >
                                  <div className="flex items-center gap-[20px]">
                                    <p
                                      className={`w-8 h-8 flex items-center justify-center text-lg rounded-full ${backgroundClass} text-white`}
                                    >
                                      {item.owner.substring(0, 1)}
                                    </p>
                                    <p className="text-zinc-400">
                                      {moment(item.createdAt).fromNow()}
                                    </p>
                                  </div>
                                </Link>
                              </div>

                              {/* img part */}
                              <Link to={`/social/${item._id}`}>
                                <div className="mb-[15px] w-full flex justify-center min-h-[280px]">
                                  <img
                                    src={
                                      item.photo ||
                                      "https://images.pexels.com/photos/235994/pexels-photo-235994.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                    }
                                    alt=""
                                    className="rounded-md object-cover max-h-[550px]"
                                  />
                                </div>
                              </Link>

                              {/* likes and comments */}
                              <div className=" flex justify-between items-center">
                                <div className="flex items-center gap-4">
                                  <div
                                    className="flex gap-2 items-center cursor-pointer text-zinc-400 hover:text-zinc-200"
                                    onClick={() =>
                                      user
                                        ? handleLikePost(item)
                                        : toast.warning("login please", {
                                            theme: "dark",
                                          })
                                    }
                                    title="Click To Like"
                                  >
                                    <p>
                                      <AiOutlineLike className="text-xl  " />
                                    </p>
                                    <p className="">
                                      {item.likes.length === 1
                                        ? `${item.likes.length} like`
                                        : `${item.likes.length} likes`}
                                    </p>
                                  </div>
                                  <div>.</div>
                                  <div className="flex items-center gap-2 text-zinc-400">
                                    {item.views.length === 1
                                      ? `${item.views.length} view`
                                      : `${item.views.length} views`}
                                  </div>
                                </div>

                                <Link to={`/social/${item._id}`}>
                                  <div
                                    className="flex gap-2 items-center text-zinc-400 "
                                    onClick={() => {
                                      handleAddViews(item._id);
                                    }}
                                  >
                                    <AiOutlineComment className="text-xl " />
                                    <p className="">
                                      {item.comments.length === 1
                                        ? `${item.comments.length} comment`
                                        : `${item.comments.length} comments`}
                                    </p>
                                  </div>
                                </Link>
                              </div>
                              {/* comments */}
                              <div className="mt-[10px] w-full flex justify-end"></div>

                              <div className="my-[15px]">
                                <h2 className="font-semibold text-zinc-200">
                                  {item.title}
                                </h2>
                              </div>

                              {/* description part */}
                              <div className="mt-[15px]">
                                <p className="text-zinc-300">
                                  {item.description.substring(0, 80)}...
                                </p>
                              </div>

                              <div className="flex justify-between items-center gap-4">
                                <div className="flex items-center gap-3 mt-[15px]">
                                  <CiLocationOn className="text-emerald-600 text-2xl font-bold" />
                                  <p className="text-sm text-zinc-400">
                                    {item.location}
                                  </p>
                                </div>
                                {/* search terms */}
                                <div className="flex items-center gap-3 mt-[15px]">
                                  <p className="text-sm text-emerald-500">
                                    {item.searchTerms.substring(0, 12)}...
                                  </p>
                                </div>
                              </div>

                              <div className="h-[3px] w-full bg-zinc-500 mt-[1.8em] rounded-xl" />
                            </div>
                          ))}
                        </div>
                        {/*  */}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Fununu;
