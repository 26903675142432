import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoPersonOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";

const Scrollbanner = () => {
  const { user } = useSelector((state) => state.auth);
  const dummyAds = [
    {
      id: 1,
      title: "allSchoolsExpo",
      location: "Daystar University",
      dateAndTime: "20th May 2024",
      description:
        "Call for innovators in the education system. If your innovation is about education and empowering education systems, we are looking for you",
      link: "https://allschoolsexpo.co.ke/",
      photo:
        "https://images.pexels.com/photos/2833037/pexels-photo-2833037.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 2,
      title: "The Ajira Digital Conference",
      location: "Daystar University",
      dateAndTime: "15th June 2024",
      link: "https://youtube.com",
      description:
        "Call for innovators in the education system. If your innovation is about education and empowering education systems, we are looking for you",
      photo:
        "https://images.pexels.com/photos/1181400/pexels-photo-1181400.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [slideDirection, setSlideDirection] = useState("slideIn"); // Initial direction

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Toggle the slide direction
      setSlideDirection((prevDirection) =>
        prevDirection === "slideIn" ? "slideOut" : "slideIn"
      );
      setTimeout(() => {
        // Rotate to the next image and toggle the slide direction back
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % dummyAds.length);
        setSlideDirection("slideIn");
      }, 400); // Adjust the duration based on your transition duration
    }, 8000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const currentImage = dummyAds[currentImageIndex];

  // check size of screen
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      // Check if the screen width is less than or equal to 600 pixels
      setIsSmallScreen(window.matchMedia("(max-width: 300px)").matches);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="">
        <a href={currentImage.link} target="_blank" rel="noreferrer">
          <div className="w-full h-[65vh] relative ">
            <img
              src={currentImage.photo}
              alt={currentImage.title}
              className={`object-cover rounded-xl w-full h-[65vh] ${
                slideDirection === "slideIn" ? "slide-in" : "slide-out"
              }`}
            />

            {/* overlay div */}
            <div className="absolute top-0 left-0 w-full h-[65vh] bg-[rgba(0,0,0,.8)] rounded-xl" />
            <div className="absolute w-full h-full top-0 flex flex-col justify-center items-start text-white">
              <div className="p-3">
                {/*  */}
                <div
                  className={`  ${
                    slideDirection === "slideIn" ? "scale-in-center" : ""
                  }`}
                >
                  <p className=" text-3xl sm:text-5xl tex-center text-zinc-300 font-bold mb-10">
                    {currentImage.title}
                  </p>
                  <p className={`${isSmallScreen}`}>
                    {currentImage.description.substring(0, 400)}{" "}
                  </p>

                  <div className="mt-[4em]">
                    <div className="flex items-center gap-2 mb-6 text-emerald-400">
                      <p>
                        <CiLocationOn className="text-2xl" />
                      </p>
                      <p>{currentImage.location}</p>
                    </div>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </a>
      </div>
      {/*  */}
    </div>
  );
};

export default Scrollbanner;
