import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Payment = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="px-4 py-2">
      {/* topbar */}
      <div className="mb-6">
        <Link to="/home">
          <AiOutlineArrowLeft className="text-2xl text-emerald-500 mb-3" />
        </Link>
        <p className="text-3xl font-semibold mb-5">Hello {user.username}</p>
        <h2>Subscription Options</h2>
      </div>
      {/* data */}
      <div className="w-full flex justify-center my-10 items-center">
        <div className="">
          <div>
            <div className="overflow-x-auto prompt">
              <table className="min-w-full tableStyling ">
                <thead>
                  <tr>
                    <th className="px-4 py-2 border"> </th>
                    <th className="px-4 py-2 border">Standard</th>
                    <th className="px-4 py-2 border">Premium</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-4 py-2 border">Monthly Price</td>
                    <td className="px-4 py-2 border">Ksh.0</td>
                    <td className="px-4 py-2 border">Ksh.200</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">Number Of Hustles</td>
                    <td className="px-4 py-2 border">1 biz</td>
                    <td className="px-4 py-2 border">Up to 4 biz</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">Barter Trade</td>
                    <td className="px-4 py-2 border">Cannot Create A trade</td>
                    <td className="px-4 py-2 border">Trade up to 4 items</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">Posts And Social</td>
                    <td className="px-4 py-2 border">Cannot Add Post</td>
                    <td className="px-4 py-2 border">Unlimited Posts</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">Adding Events</td>
                    <td className="px-4 py-2 border">1 event</td>
                    <td className="px-4 py-2 border">Up to 4 events</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">Renting Out</td>
                    <td className="px-4 py-2 border">
                      Cannot Add Item to rent
                    </td>
                    <td className="px-4 py-2 border">
                      Rent Away Up to 4 items
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* more info */}
      <p className="font-semibold text-emerald-500 my-3">More Information</p>
      <div>
        <ul>
          <li>Hero Section Ad Space Ksh. 100 per 24 hours</li>
          <li>
            <p>Ad Space Ksh. 80 per 24 hours </p>
          </li>
          <li>
            <p>Featured Section Ad Space Ksh. 80 per 24 hours</p>
          </li>
        </ul>
      </div>
      {/* more info */}
      <p className="font-semibold text-emerald-500 my-3">How To Pay</p>
      <div>
        <ol>
          <li>1. Please Use M-Pesa</li>
          <li>2. Go To Send Money</li>
          <li>3. Phone Number 07 98 55 64 71</li>
          <li>
            4. Send Confirmation message with your username to the phone number
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Payment;
