import React, { useState, useEffect } from "react";
import { MdOutlineDateRange } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoPersonOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import Bee from "../assets/bee.jpg";
import Wick from "../assets/wick.jpg";
import Raya from "../assets/raya.jpg";

const MovieSlider = () => {
  const { user } = useSelector((state) => state.auth);
  const dummyMovies = [
    {
      id: 1,
      title: "The Beekeeper",
      owner: "Josh",
      category: "Action & thriller",
      link: "https://fmoviesz.to/movie/the-beekeeper2-81xyn/1-1",
      likes: [],
      comments: [],
      photo: Bee,
    },
    {
      id: 2,
      title: "John Wick",
      owner: "Josh",
      category: "Action & thriller",
      link: "https://fmoviesz.to/movie/john-wick-chapter-4-x1n08/1-1",
      likes: [],
      comments: [],
      photo: Wick,
    },
    {
      id: 3,
      title: "Raya And The Last Dragon",
      owner: "Josh",
      category: "Animation",
      link: "https://fmoviesz.to/movie/raya-and-the-last-dragon-2pl1l/1-1",
      likes: [],
      comments: [],
      photo: Raya,
    },
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [slideDirection, setSlideDirection] = useState("slideIn"); // Initial direction

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Toggle the slide direction
      setSlideDirection((prevDirection) =>
        prevDirection === "slideIn" ? "slideOut" : "slideIn"
      );
      setTimeout(() => {
        // Rotate to the next image and toggle the slide direction back
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % dummyMovies.length
        );
        setSlideDirection("slideIn");
      }, 500); // Adjust the duration based on your transition duration
    }, 7000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const currentImage = dummyMovies[currentImageIndex];

  // check size of screen
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      // Check if the screen width is less than or equal to 600 pixels
      setIsSmallScreen(window.matchMedia("(max-width: 300px)").matches);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className=" w-[100%] m-auto">
        <a href={currentImage.link} target="_blank" rel="noreferrer">
          <div className="w-full h-[48vh] sm:h-[65vh] relative ">
            <img
              src={currentImage.photo}
              alt={currentImage.title}
              className={`object-cover rounded-xl w-full  sm:h-[65vh] ${
                slideDirection === "slideIn" ? "slide-in" : "slide-out"
              }`}
            />

            {/* overlay div */}
            <div className="absolute top-0 left-0 w-full h-[49vh] sm:h-[65vh] bg-[rgba(0,0,0,.7)] rounded-xl" />
            <div className="absolute w-full h-full top-0 flex flex-col justify-end items-start  text-white">
              <div className="p-3">
                {/*  */}
                <div
                  className={` w-full ${
                    slideDirection === "slideIn" ? "scale-in-center" : ""
                  }`}
                >
                  <div className="w-full  flex justify-center">
                    <p className="text-3xl text-center font-bold mb-8">
                      {currentImage.title}
                    </p>
                  </div>

                  <div className="mt-[20px]">
                    <Link
                      to={
                        currentImage.owner == user?.username
                          ? "/my-profile"
                          : `/other-profile?owner=${currentImage.owner}`
                      }
                    >
                      <div className="flex items-center gap-2 mb-6">
                        <p>
                          <IoPersonOutline />
                        </p>
                        <p className="underline">{currentImage.owner}</p>
                      </div>
                    </Link>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default MovieSlider;
