import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Logo from "../assets/officialOne.png";
import { IoMdAdd, IoMdNotificationsOutline } from "react-icons/io";
import axios from "../axios";
import { toast } from "react-toastify";
import {
  AiOutlineArrowRight,
  AiOutlineGold,
  AiOutlineSearch,
  AiOutlineUser,
} from "react-icons/ai";
import { CiFilter } from "react-icons/ci";
import { Articlecategories, DummyArticles } from "../Dummydata";

const Articles = () => {
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const [MyNotif, setMyNotif] = useState([]);
  const [loadingNotif, setLoadingNotif] = useState(false);

  const handleFetchNotification = async () => {
    if (!user) {
      navigate("/login");
      return toast.error("Please Login", { theme: "dark" });
    }

    try {
      setLoadingNotif(true);
      let username = user.username;
      let dataToSend = { username };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post("/notify/mine", dataToSend, config);

      if (res && res.data) {
        const notifications = Array.isArray(res.data) ? res.data : [res.data];
        setMyNotif(notifications);
        setLoadingNotif(false);
      } else {
        setMyNotif([]); // Set an empty array if no data is received
        setLoadingNotif(false);
        console.error("Invalid data format received:", res.data);
      }
    } catch (error) {
      toast.error("Error Fetching Notifications", { theme: "dark" });
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    if (user) {
      handleFetchNotification();
    } else {
      toast.warning("Please Login", { theme: "dark" });
    }
  }, [user]);

  //   pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = DummyArticles?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(DummyArticles?.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(4);

  const handleClick = (number) => {
    setStart(number);
    setEnd(number + 3);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      handleClick(currentPage);
    }
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      handleClick(currentPage);
    }
  };

  const changeCurrentPage = (id) => {
    setCurrentPage(id);
  };

  // search  states
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setsearchTimeout] = useState(null);
  const [searchedResults, setSearchedResults] = useState(null);

  //search Function 2

  // search user func
  const handleSearchChange = async (e) => {
    e.preventDefault();

    clearTimeout(setsearchTimeout);

    setSearchText(e.target.value);

    // console.log(searchText);

    setsearchTimeout(
      setTimeout(() => {
        const searchResults = DummyArticles.filter(
          (item) =>
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.owner.toLowerCase().includes(searchText.toLowerCase())
        );

        setSearchedResults(searchResults);
      }, 500)
    );
  };

  return (
    <div>
      {/* wrapper */}
      <div className=" w-[93%] sm:w-[90%] m-auto py-[3px] md:py-[1em]">
        {/* navbar */}
        <div
          className="mb-[30px] my-[5px] py-[15px] px-1 bg-[#212121] sm:rounded-2xl z-20"
          style={{
            // position: "-webkit-sticky",
            position: "sticky",
            top: 0,
          }}
        >
          <Helmet>
            <title>{`Articles | iLocca`}</title>
            <meta
              name="description"
              content="Bridging the gap between local businesses and the community, offering a streamlined platform for residents to discover and acquire quality local products and services."
            />
          </Helmet>

          <div className="flex justify-between items-center gap-[10px] ">
            <Link to="/home">
              <div className="flex item-center flex-col">
                <div>
                  <img src={Logo} alt="" className="w-[50px] object-contain" />
                </div>
              </div>
            </Link>

            <div className="flex gap-[26px]  md:gap-[60px] items-center">
              <Link to="/notifications">
                <div className="relative">
                  <IoMdNotificationsOutline
                    className="text-3xl text-emerald-500"
                    title="Notifications"
                  />
                  <p
                    className="absolute bottom-[20px] left-[32px] z-[999]"
                    style={{ fontWeight: 700 }}
                  >
                    {loadingNotif ? 0 : MyNotif.length}
                  </p>
                </div>
              </Link>
              <div>
                {!user ? (
                  <Link to="/login">
                    <AiOutlineUser className="text-3xl text-emerald-400" />
                  </Link>
                ) : (
                  <div>
                    <Link to="/my-profile">
                      <div>
                        {/* {user?.username?.substring(0, 2)} */}

                        <img
                          src={user?.profile}
                          alt=""
                          className={`w-[50px] h-[50px] flex items-center object-cover justify-center rounded-full`}
                        />
                      </div>
                    </Link>
                  </div>
                )}
              </div>
              {/* create */}
              <div>
                {user?.isPaid ? (
                  <div className="flex justify-end w-full ">
                    <Link to="/article/create">
                      <IoMdAdd className="bg-zinc-800 text-4xl p-[5px] text-white rounded-lg" />
                    </Link>
                  </div>
                ) : (
                  <div className="flex justify-end w-full ">
                    <Link to="/gold-subscription">
                      <AiOutlineGold className="bg-zinc-800 text-4xl p-[5px] text-white rounded-lg" />
                    </Link>
                  </div>
                )}
              </div>
              {/*  */}
            </div>
          </div>
        </div>
        {/* content */}
        <div className=" sm:w-[90%]  md:w-[80%] xl:w-[60%] m-auto">
          {/* categories */}
          {!searchText && (
            <>
              <div className="flex gap-4 mb-8 justify-start lg:justify-center overflow-x-scroll prompt">
                {Articlecategories.map((item) => (
                  <div index={item.id}>
                    <p className="hover:bg-[#212121] cursor-pointer pb-3">
                      {item.title}
                    </p>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* searchbar */}
          <div className="w-full flex justify-center mb-8">
            <form className=" w-[98%] sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[40%] bg-[#212121] flex gap-[15px] items-center p-[8px] rounded-xl">
              <AiOutlineSearch className="text-xl" />
              <input
                type="text"
                placeholder="Search"
                className="bg-transparent outline-none w-full "
                required
                // maxLength={15}
                // minLength={2}
                value={searchText}
                onChange={handleSearchChange}
              />
            </form>
          </div>

          {/* nav numbers */}
          {!searchText && (
            <nav className="flex justify-end mb-8">
              <ul className="flex gap-[2em] mt-[10px] px-[5px] py-[10px] items-center ">
                {/* map */}

                <>
                  <li>
                    <a href="#" onClick={prevPage} className="text-zinc-700">
                      <p className="text-zinc-300">Prev</p>
                    </a>
                  </li>
                  <li className="flex gap-[10px] ">
                    {numbers.slice(start - 1, end).map((item, index) => (
                      <li
                        key={index}
                        className={`normal-nav ${
                          currentPage === item && "active-nav"
                        }`}
                      >
                        <a
                          href="#"
                          onClick={() => {
                            handleClick(item);
                            changeCurrentPage(item);
                          }}
                        >
                          <p>{item}</p>
                        </a>
                      </li>
                    ))}
                  </li>

                  <li>
                    <a href="#" onClick={nextPage}>
                      <p className="text-zinc-300">Next</p>
                    </a>
                  </li>
                </>
              </ul>
            </nav>
          )}

          {searchText && searchedResults?.length > 0 ? (
            <>
              {searchedResults?.map((article, index) => (
                <div className="mb-10">
                  <div className="flex items-center gap-4 sm:gap-8" key={index}>
                    {/* text side */}
                    <div>
                      {/* toppart */}
                      <div className="flex items-center gap-4 mb-4">
                        <div>
                          <img
                            src={article.profile}
                            alt=""
                            className="h-[40px] w-[40px] rounded-full object-cover"
                          />
                        </div>
                        <div>
                          <div className="mb-2 font-semibold">
                            <p>{article.owner}</p>
                          </div>
                          <div>
                            <p className="text-sm text-emerald-400">
                              {article.createdAt}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* mid part */}
                      <div>
                        <h2 className="font-bold text-lg mb-2">
                          {article.title.length > 30
                            ? article.title.length.substring(0, 30)
                            : article.title}
                        </h2>
                        <p className="mb-2 hidden md:flex">
                          {article.desc.substring(0, 200)}...
                        </p>
                        <p className="mb-2 md:hidden">
                          {article.desc.substring(0, 80)}...
                        </p>
                      </div>
                      {/* bottom part */}
                      <div className="mt-4">
                        <p>
                          {" "}
                          <span className="bg-[#212121] p-2 rounded-xl">
                            {article.category}
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* img side */}
                    <div>
                      <img
                        src={article.photo}
                        alt=""
                        className="w-[300px] object-contain rounded-md"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {records?.map((article, index) => (
                <div className="mb-10">
                  <div className="flex items-center gap-4 sm:gap-8" key={index}>
                    {/* text side */}
                    <div>
                      {/* toppart */}
                      <div className="flex items-center gap-4 mb-4">
                        <div>
                          <img
                            src={article.profile}
                            alt=""
                            className="h-[40px] w-[40px] rounded-full object-cover"
                          />
                        </div>
                        <div>
                          <div className="mb-2 font-semibold">
                            <p>{article.owner}</p>
                          </div>
                          <div>
                            <p className="text-sm text-emerald-400">
                              {article.createdAt}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* mid part */}
                      <div>
                        <h2 className="font-bold text-lg mb-2">
                          {article.title}
                        </h2>
                        <p className="mb-2 hidden md:flex">
                          {article.desc.substring(0, 200)}...
                        </p>
                        <p className="mb-2 md:hidden">
                          {article.desc.substring(0, 80)}...
                        </p>
                      </div>
                      {/* bottom part */}
                      <div className="mt-4">
                        <p>
                          {" "}
                          <span className="bg-[#212121] p-2 rounded-xl">
                            {article.category}
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* img side */}
                    <div>
                      <img
                        src={article.photo}
                        alt=""
                        className="w-[300px] object-contain rounded-md"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}

          {/* nav numbers */}
          {!searchText && (
            <nav className="flex justify-center mt-8">
              <ul className="flex gap-[2em] mt-[10px] px-[5px] py-[10px] items-center ">
                {/* map */}

                <>
                  <li>
                    <a href="#" onClick={prevPage} className="text-zinc-700">
                      <p className="text-zinc-300">Prev</p>
                    </a>
                  </li>
                  <li className="flex gap-[10px] ">
                    {numbers.slice(start - 1, end).map((item, index) => (
                      <li
                        key={index}
                        className={`normal-nav ${
                          currentPage === item && "active-nav"
                        }`}
                      >
                        <a
                          href="#"
                          onClick={() => {
                            handleClick(item);
                            changeCurrentPage(item);
                          }}
                        >
                          <p>{item}</p>
                        </a>
                      </li>
                    ))}
                  </li>

                  <li>
                    <a href="#" onClick={nextPage}>
                      <p className="text-zinc-300">Next</p>
                    </a>
                  </li>
                </>
              </ul>
            </nav>
          )}
        </div>
        {/*  */}
      </div>
    </div>
  );
};

export default Articles;
