import React, { useEffect, useState } from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineClose,
  AiOutlineEye,
  AiOutlineLike,
  AiOutlinePhone,
  AiOutlineSearch,
} from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../axios";
import { IoMdAdd } from "react-icons/io";
import Spinner from "../components/Spinner";
import { MdOutlineContentCopy } from "react-icons/md";
import moment from "moment";
import { IoShareSocialOutline } from "react-icons/io5";
import { Helmet } from "react-helmet";
import { appBaseUrl } from "../constants";

const Events = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate("/login");
      toast.warning("Account Needed", { theme: "dark" });
    }
  }, [user, navigate]);

  // fetch events around user location
  const handleFetchEvents = async () => {
    try {
      setLoading(true);
      // fetch based on <location> from user
      let location = user?.location;
      let approved = "yes";

      let dataToSend = { location, approved };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post("/event/location", dataToSend, config);
      if (response) {
        setLoading(false);
        setAllEvents(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Events", { theme: "dark" });
    }
  };

  useEffect(() => {
    handleFetchEvents();
  }, []);

  const breakpointColumnsObj = {
    default: 5,
    3000: 5,
    2000: 4,
    1500: 3,
    1200: 2,
    800: 1,
  };

  //   pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = allEvents?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(allEvents?.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(4);

  const handleClick = (number) => {
    setStart(number);
    setEnd(number + 3);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      handleClick(currentPage);
    }
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      handleClick(currentPage);
    }
  };

  const changeCurrentPage = (id) => {
    setCurrentPage(id);
  };

  // search  states
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setsearchTimeout] = useState(null);
  const [searchedResults, setSearchedResults] = useState(null);

  // search user func
  const handleSearchChange = async (e) => {
    e.preventDefault();
    clearTimeout(setsearchTimeout);

    setSearchText(e.target.value);

    // console.log(searchText);

    setsearchTimeout(
      setTimeout(() => {
        const searchResults = allEvents.filter(
          (item) =>
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.searchTerms.toLowerCase().includes(searchText.toLowerCase())
        );

        setSearchedResults(searchResults);
      }, 500)
    );
  };

  // handle user backgroun colors
  const getRandomColorClass = () => {
    const colors = [
      "bg-red-500",
      "bg-blue-500",
      "bg-green-700",
      "bg-yellow-700",
      "bg-orange-700",
      "bg-purple-500",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const [backgroundClass, setBackgroundClass] = useState(getRandomColorClass());

  const changeBackgroundColor = () => {
    setBackgroundClass(getRandomColorClass());
  };

  // like a event
  const handleLikeEvent = async (event) => {
    try {
      if (!user) {
        toast.error("Please Login To leave a like", { theme: "dark" });
        return;
      }

      let username = user?.username;
      let id = event._id;
      let likeData = { username };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.post("/event/like/" + id, likeData, config);
      if (res) {
        // notification
        if (user?.username !== event.owner) {
          // create notification
          let sender = user.username;
          let receiver = event.owner;
          let receiverEmail = event.ownerEmail;
          let message = `${user?.username} from ${user?.location} liked your event ${event.title} 😃`;
          let referID = `/events/${id}`;
          let notifyData = {
            sender,
            receiver,
            receiverEmail,
            message,
            referID,
          };

          await axios.post("/notify/create", notifyData, config);
        }

        toast.success("Action Succesful", { theme: "dark" });
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed To Like");
    }
  };

  // attend event
  const handleAttend = async (event) => {
    try {
      if (!user) {
        toast.error("Please Login", { theme: "dark" });
        return;
      }

      let username = user.username;
      let id = event._id;
      let likeData = { username };

      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post("/event/attend/" + id, likeData, config);

      // notification
      if (user?.username !== event.owner) {
        // create notification
        let sender = user.username;
        let receiver = event.owner;
        let receiverEmail = event.ownerEmail;
        let message = `${user?.username} from ${user?.location} wants to attend ${event.title} 😃`;
        let referID = `/events/${id}`;
        let notifyData = { sender, receiver, receiverEmail, message, referID };

        await axios.post("/notify/create", notifyData, config);
      }

      window.location.reload();
    } catch (error) {
      toast.error("Failed To Add", { theme: "dark" });
    }
  };

  const handleAddViews = async (id) => {
    try {
      if (user) {
        let username = user.username;
        let viewsData = { username };

        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios.post("/event/view/" + id, viewsData, config);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // working on modal
  const [selectedPost, setSelectedPost] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  // what happens when we click on a post
  const handlePostClick = (post) => {
    setSelectedPost(post);
    setIsPopUpOpen(true);
  };

  const PopUpPage = ({ post, onClose }) => {
    return (
      <div className="pop-up-page prompt ">
        {/* close btn */}

        {/* data */}
        <div className="pop-up-content">
          <div className="flex justify-center my-[20px] z-[999] ">
            <button onClick={onClose}>
              <AiOutlineClose
                className="text-5xl text-white p-[10px] rounded-full "
                style={{
                  border: "2px solid #188c56",
                  position: "sticky",
                  top: "20px",
                }}
                title="close"
              />
            </button>
          </div>
          <div className=" h-[70vh] sm:h-[60vh] overflow-y-scroll prompt">
            <div className="px-[10px]">
              <div className="mb-[40px] flex justify-between items-center">
                <p className="text-lg text-white font-bold mb-2">
                  {post.title}
                </p>
              </div>
            </div>

            <div>
              <img
                src={post.photo}
                alt=""
                className="max-h-[400px] object-contain w-full"
              />
            </div>

            <div className="mt-[20px] flex justify-between w-full gap-[10px] flex-wrap">
              <div className="flex gap-[30px] items-center">
                <div
                  className="flex gap-2 items-center"
                  onClick={() => handleAttend(post)}
                >
                  <BsPerson className="text-xl text-white" />
                  <p className="text-white">{post.attending.length}</p>
                </div>
                <div
                  className="flex gap-2 items-center"
                  onClick={() => handleLikeEvent(post)}
                >
                  <AiOutlineLike className="text-xl text-white" />
                  <p className="text-white">{post.likes.length}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <AiOutlineEye className="text-xl text-white" />
                  <p className="text-white">{post.views.length}</p>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <AiOutlinePhone className="text-white" />
                <a href={`tel:${post.phone}`} className="text-white">
                  {post.phone}
                </a>
              </div>
            </div>

            <div className=" my-[30px]">
              <div className="mb-[22px]">
                <h2 className="text-emerald-700 font-bold mb-2">
                  Location Details
                </h2>
                <p className="text-zinc-200">{post.location}</p>
                <p className="text-zinc-200">{post.specificAddress}</p>
              </div>
              <div className="mb-[22px]">
                <h2 className="text-emerald-700 font-bold mb-2">
                  Prices and Charges
                </h2>
                <p className="text-zinc-200"> {post.prices}</p>
              </div>

              <div className="mb-[22px]">
                <h2 className="text-emerald-700 font-bold mb-2">
                  Time And Date Details
                </h2>
                <p className="text-zinc-200"> {post.timeAndDate}</p>
              </div>

              <p className="text-zinc-200 mb-[12px]">{post.description}</p>
            </div>

            {/* comment */}
            <div className="mt-[22px] pb-[10px] w-full flex justify-center">
              <button
                className="bg-teal-800 text-white p-[8px] rounded-lg"
                onClick={() => handleAttend(post)}
              >
                CONFIRM ATTENDING ?
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleCopyToClipboard = async (textToCopy) => {
    try {
      // Get the text from your input or any other source
      // You can replace this with your dynamic input

      // Use the Clipboard API to write the text to the clipboard
      await navigator.clipboard.writeText(textToCopy);

      // Log success or update UI accordingly
      console.log("Text copied to clipboard:", textToCopy);
      toast.success("Copied The Link. Share", { theme: "dark" });
    } catch (err) {
      // Handle errors
      console.error("Error copying to clipboard:", err);
    }
  };

  let visibleToAllCampuses = "no";

  return (
    <div className="">
      <Helmet>
        <title>{`Events | ${user?.location} | iLocca`}</title>
        <meta
          name="description"
          content="Bridging the gap between local businesses and the community, offering a streamlined platform for residents to discover and acquire quality local products and services."
        />
      </Helmet>
      {/* wrapper */}
      <div className="px-[10px] sm:px-[3em] lg:px-[4em] pt-3">
        {/* topbar */}

        {!searchText && (
          <div className="flex gap-4 items-center justify-between">
            <Link to="/home">
              <AiOutlineArrowLeft className="text-2xl" />
            </Link>
            <div>
              <h2 className="font-bold text-lg text-end text-zinc-400">
                Enjoy local events
              </h2>
              <p className="text-sm text-zinc-300">
                Interact, connect, live a little
              </p>
            </div>
          </div>
        )}

        {/* create */}

        {!searchText && (
          <div className="flex justify-between w-full my-[1.3em]">
            <div className="flex flex-col gap-1">
              <label htmlFor="allCampuses" className="text-zinc-400">
                Browse All Campuses
              </label>
              <select
                name="allCampuses"
                id="allCampuses"
                className="bg-[#212121] p-1 rounded-xl outline-none"
                onChange={async (e) => {
                  visibleToAllCampuses = e.target.value;
                  console.log(visibleToAllCampuses);
                  if (visibleToAllCampuses == "yes") {
                    setLoading(true);
                    let approved = "yes";
                    let dataToSend = { visibleToAllCampuses, approved };
                    let token = user?.token;
                    const config = {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    };
                    let response = await axios.post(
                      "/event/public",
                      dataToSend,
                      config
                    );
                    if (response) {
                      setLoading(false);
                      setAllEvents(response.data);
                      // console.log(response.data);
                    } else {
                      setLoading(false);
                      toast.error("Public mode not available", {
                        theme: "dark",
                      });
                    }
                  } else {
                    handleFetchEvents();
                  }
                }}
              >
                <option value="nope">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
            <Link to="/create-event">
              <IoMdAdd className="bg-emerald-800 text-4xl p-[5px] text-white rounded-lg" />
            </Link>
          </div>
        )}

        {loading ? (
          <div className="h-[70vh] w-full flex justify-center items-center">
            <Spinner message="Fetching events near you" />
          </div>
        ) : (
          <>
            {allEvents.length < 1 ? (
              <>
                <div className="h-[70vh] w-full flex justify-center items-center">
                  <div className="text-zinc-300">
                    <h2 className="text-center mb-2">😢Ouch.</h2>
                    <h2 className="text-center">
                      No recent event near {user?.location}
                    </h2>
                    <h2 className="mt-[20px] mb-[10px] text-center">
                      it's an easy way to advertise 🤼
                    </h2>
                    <h2 className="mt-[20px] mb-[10px] text-center">
                      Share our app with event planners in this area
                    </h2>
                    <div className="flex justify-center gap-[20px]  ">
                      <div>
                        <button
                          onClick={() => handleCopyToClipboard(`${appBaseUrl}`)}
                          className="flex justify-center gap-[20px] items-center border border-emerald-500 p-2 rounded-lg"
                        >
                          <IoShareSocialOutline
                            className="text-xl text-emerald-500"
                            title="Click To Copy"
                          />
                          share
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  {/* searchbar */}
                  <div className="mt-[1em] mb-[1em] w-full flex justify-center z-20">
                    <form
                      className="flex items-center gap-3
            w-[99%] sm:w-[70%] md:w-[60%] xl:w-[40%]  p-[8px] rounded-lg border border-zinc-600"
                    >
                      <AiOutlineSearch className="text-xl text-emerald-500" />
                      <input
                        type="text"
                        placeholder="search anything"
                        className="w-full bg-transparent border-none outline-none text-zinc-100"
                        value={searchText}
                        onChange={handleSearchChange}
                      />
                    </form>
                  </div>
                  {/* data */}
                  <div>
                    {/* nav numbers */}
                    {!searchText && (
                      <nav className="flex justify-center">
                        <ul className="flex gap-[2em] mt-[10px] px-[5px] py-[10px] items-center ">
                          {/* map */}

                          <>
                            <li>
                              <a
                                href="#"
                                onClick={prevPage}
                                className="text-zinc-700"
                              >
                                <p className="text-zinc-300">Prev</p>
                              </a>
                            </li>
                            <li className="flex gap-[10px] ">
                              {numbers
                                .slice(start - 1, end)
                                .map((item, index) => (
                                  <li
                                    key={index}
                                    className={`normal-nav ${
                                      currentPage === item && "active-nav"
                                    }`}
                                  >
                                    <a
                                      href="#"
                                      onClick={() => {
                                        handleClick(item);
                                        changeCurrentPage(item);
                                      }}
                                    >
                                      <p>{item}</p>
                                    </a>
                                  </li>
                                ))}
                            </li>

                            <li>
                              <a href="#" onClick={nextPage}>
                                <p className="text-zinc-300">Next</p>
                              </a>
                            </li>
                          </>
                        </ul>
                      </nav>
                    )}
                    {/* products */}
                    <div>
                      {searchText ? (
                        <>
                          <div className="mb-[15px] text-zinc-400">
                            {searchText && <p>Results For : {searchText}</p>}
                          </div>

                          {searchedResults < 1 ? (
                            <>
                              <div className="flex justify-center mt-[7em]">
                                <p>😥 No results for {searchText}</p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="mt-[2em]">
                                <div>
                                  <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="my-masonry-grid "
                                    columnClassName="my-masonry-grid_column"
                                  >
                                    {searchedResults?.map((item) => (
                                      <div className="mb-8" key={item._id}>
                                        <div
                                          className="relative rounded-lg group "
                                          onClick={() => {
                                            // handlePostClick(item);
                                            handleAddViews(item._id);
                                            navigate(`/events/${item._id}`);
                                          }}
                                        >
                                          <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                                            <div
                                              className="bg-gradient-to-t
                                                  from-transparent to-black opacity-75 w-full h-full rounded-md"
                                            >
                                              {/* top stats */}
                                              <div>
                                                <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                                                  <div>
                                                    <a
                                                      href={`tel:+${item.phone}`}
                                                    >
                                                      {" "}
                                                      <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                                    </a>
                                                  </div>
                                                  <div className="flex gap-[20px]">
                                                    <p className="text-white text-md flex items-center gap-[5px]">
                                                      <BsPerson className="text-lg" />
                                                      <span>
                                                        {item.attending.length}
                                                      </span>
                                                    </p>
                                                    {/* <p className="text-white text-md flex items-center gap-[5px]">
                                                      <AiOutlineLike className="text-lg" />
                                                      <span>
                                                        {item.likes.length}
                                                      </span>
                                                    </p> */}
                                                  </div>
                                                </div>
                                                <div className="absolute top-[80px] left-3">
                                                  <p className="text-white">
                                                    {item.title}
                                                  </p>
                                                </div>
                                              </div>
                                              {/*  */}
                                              <div className="absolute bottom-[20px]  flex gap-[10%] w-full ">
                                                <div className="pl-2">
                                                  <div className="flex gap-[10px] text-zinc-300">
                                                    {/* <p>{item.location}</p> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <img
                                            src={item.photo}
                                            alt=""
                                            className="rounded-md object-cover"
                                          />
                                        </div>
                                        <div className="flex items-center justify-between mt-[10px]">
                                          <Link
                                            to={
                                              item.owner == user?.username
                                                ? "/my-profile"
                                                : `/other-profile?owner=${item.owner}`
                                            }
                                          >
                                            <p
                                              className={`w-8 h-8 flex items-center justify-center rounded-full ${backgroundClass} text-white`}
                                            >
                                              {item.owner.substring(0, 1)}
                                            </p>
                                          </Link>

                                          <div className="flex items-center gap-3">
                                            <p>
                                              {moment(item.createdAt).fromNow()}
                                            </p>
                                            {/* share */}
                                            <div className="flex justify-center gap-[20px]  ">
                                              <button
                                                onClick={() =>
                                                  handleCopyToClipboard(
                                                    `${appBaseUrl}events/${item._id}`
                                                  )
                                                }
                                              >
                                                <IoShareSocialOutline
                                                  className="text-xl text-red-600"
                                                  title="Click To Copy"
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </Masonry>
                                </div>

                                {/*  */}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="mt-[2em]">
                            <div>
                              <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid "
                                columnClassName="my-masonry-grid_column"
                              >
                                {records?.map((item) => (
                                  <div className="mb-8" key={item._id}>
                                    <div
                                      className="relative rounded-lg group "
                                      onClick={() => {
                                        // handlePostClick(item);
                                        handleAddViews(item._id);
                                        navigate(`/events/${item._id}`);
                                      }}
                                    >
                                      <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                                        <div
                                          className="bg-gradient-to-t
                                                from-transparent to-black opacity-90 w-full h-full rounded-md"
                                        >
                                          {/* top stats */}
                                          <div>
                                            <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                                              <div>
                                                <a href={`tel:+${item.phone}`}>
                                                  {" "}
                                                  <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                                </a>
                                              </div>
                                              <div className="flex gap-[20px]">
                                                <p className="text-white text-md flex items-center gap-[5px]">
                                                  <BsPerson className="text-lg" />
                                                  <span>
                                                    {item.attending.length}
                                                  </span>
                                                </p>
                                                {/* <p className="text-white text-md flex items-center gap-[5px]">
                                                  <AiOutlineLike className="text-lg" />
                                                  <span>
                                                    {item.likes.length}
                                                  </span>
                                                </p> */}
                                              </div>
                                            </div>
                                            <div className="absolute top-[80px] left-3">
                                              <p className="text-white">
                                                {item.title}
                                              </p>
                                            </div>
                                          </div>
                                          {/*  */}
                                          <div className="absolute bottom-[20px]  flex gap-[10%] w-full ">
                                            <div className="pl-2">
                                              <div className="flex gap-[10px] text-zinc-300">
                                                {/* <p>{item.location}</p> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="min-h-[200px]">
                                        <img
                                          src={item.photo}
                                          alt=""
                                          className="rounded-lg object-cover"
                                        />
                                      </div>
                                    </div>
                                    <div className="flex items-center justify-between mt-[10px]">
                                      <Link
                                        to={
                                          item.owner == user?.username
                                            ? "/my-profile"
                                            : `/other-profile?owner=${item.owner}`
                                        }
                                      >
                                        <p
                                          className={`w-8 h-8 flex items-center justify-center rounded-full bg-emerald-800 text-white`}
                                        >
                                          {item.owner.substring(0, 1)}
                                        </p>
                                      </Link>

                                      <div className="flex items-center gap-3">
                                        <p>
                                          {moment(item.createdAt).fromNow()}
                                        </p>
                                        {/* share */}
                                        <div className="flex justify-center gap-[20px]  ">
                                          <button
                                            onClick={() =>
                                              handleCopyToClipboard(
                                                `${appBaseUrl}events/${item._id}`
                                              )
                                            }
                                          >
                                            <IoShareSocialOutline
                                              className="text-2xl text-emerald-500"
                                              title="Click To Copy"
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Masonry>
                            </div>

                            {/*  */}
                          </div>
                        </>
                      )}
                    </div>

                    {/* pop up screen */}
                    {isPopUpOpen && (
                      <div className="pop-up-overlay">
                        <PopUpPage
                          post={selectedPost}
                          onClose={() => setIsPopUpOpen(false)}
                        />
                      </div>
                    )}
                  </div>
                  {/*  */}
                </div>
              </>
            )}
          </>
        )}

        {/*  */}
      </div>
      {/*  */}
    </div>
  );
};

export default Events;
