import React, { useEffect, useState } from "react";
import MovieSlider from "../components/MovieSlider";
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

import { useSelector } from "react-redux";
import {
  AiOutlineArrowUp,
  AiOutlineLike,
  AiOutlineSearch,
} from "react-icons/ai";
import { DummyGenre } from "../Dummydata";
import axios from "../axios";
import { toast } from "react-toastify";
import moment from "moment";
import { SlScreenDesktop } from "react-icons/sl";
import Spinner from "../components/Spinner";

const Movies = () => {
  const { user } = useSelector((state) => state.auth);

  const [allMovies, setAllMovies] = useState([]);
  const [loading, setLoading] = useState();
  const handleFetchMovies = async () => {
    try {
      setLoading(true);
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let response = await axios.get("/movies", config);
      if (response) {
        setLoading(false);
        setAllMovies(response.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed To Fetch Movies", { theme: "dark" });
    }
  };

  useEffect(() => {
    handleFetchMovies();
  }, []);

  //   pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = allMovies?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(allMovies?.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(4);

  const handleClick = (number) => {
    setStart(number);
    setEnd(number + 3);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      handleClick(currentPage);
    }
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      handleClick(currentPage);
    }
  };

  const changeCurrentPage = (id) => {
    setCurrentPage(id);
  };

  // search  states
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setsearchTimeout] = useState(null);
  const [searchedResults, setSearchedResults] = useState(null);

  //search Function 2

  // search user func
  const handleSearchChange = async (e) => {
    e.preventDefault();

    clearTimeout(setsearchTimeout);

    setSearchText(e.target.value);

    // console.log(searchText);

    setsearchTimeout(
      setTimeout(() => {
        const searchResults = allMovies.filter(
          (item) =>
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.owner.toLowerCase().includes(searchText.toLowerCase()) ||
            item.genre.toLowerCase().includes(searchText.toLowerCase()) ||
            item.category.toLowerCase().includes(searchText.toLowerCase())
        );

        setSearchedResults(searchResults);
      }, 500)
    );
  };

  // scroll to top functionality
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [genre, setGenre] = useState("");
  const [link, setLink] = useState("");
  const [createLoad, setCreateLoad] = useState(false);

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      if (!title) return toast.error("Title required", { theme: "dark" });
      if (!category) return toast.error("Category required", { theme: "dark" });
      if (!genre) return toast.error("Genre required", { theme: "dark" });

      setCreateLoad(true);
      let owner = user?.username;
      let location = user?.location;
      let movieData = { title, category, genre, link, owner, location };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let response = await axios.post("/movies", movieData, config);
      if (response) {
        setCreateLoad(false);
        handleFetchMovies();
      }
    } catch (error) {
      console.log(error);
      setCreateLoad(false);
      toast.error("Failed To Create", { theme: "dark" });
    }
  };

  const [showForm, setShowForm] = useState(false);

  // like movie
  const handleLikeMovie = async (item) => {
    try {
      if (!user) {
        toast.error("Please Login To leave a like", { theme: "dark" });
        return;
      }

      let username = user?.username;
      let id = item._id;
      let likeData = { username };

      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // await axios.post("/movies/like/" + id, likeData, config);

      if (user?.username !== item.owner) {
        // create notification
        let sender = user?.username;
        let receiver = item.owner;
        let receiverEmail = item.ownerEmail;
        let message = `Your movie recommendation ${item.title} Got a Like 👍 From ${user?.username}`;
        let referID = `/movies`;
        let notifyData = { sender, receiver, receiverEmail, message, referID };
        await axios.post("/notify/create", notifyData, config);
      }

      window.location.reload();
    } catch (error) {
      toast.error("Failed To Like");
    }
  };

  return (
    <div className=" px-[10px] sm:px-[1em]  md:px-[2em] xl:px-[5em] pt-2">
      <div className="mb-[2em] flex justify-between items-center gap-2">
        <Link to="/home">
          <IoArrowBack className="text-3xl" />
        </Link>

        {!searchText && (
          <div onClick={() => setShowForm(!showForm)} className="">
            {showForm ? (
              <p className="text-red-400 font-bold cursor-pointer">CLOSE</p>
            ) : (
              <p className="text-emerald-400 font-bold cursor-pointer">
                CREATE
              </p>
            )}
          </div>
        )}
        {/* arrow to scroll to top */}
        {showArrow && (
          <div
            className="fixed bottom-20 right-4 text-3xl z-[999] cursor-pointer bg-teal-700 text-zinc-50 rounded-full p-[5px]"
            onClick={handleScrollTop}
          >
            <AiOutlineArrowUp />
          </div>
        )}
      </div>

      {/* form to create */}
      {showForm && !searchText && (
        <form
          className=" w-[100%]  sm:w-[80%]  md:w-[70%]  lg:w-[50%] m-auto pb-4 bg-[#212121] p-2 rounded-lg bounce-in-top"
          onSubmit={handleCreate}
        >
          <h2 className="text-2xl font-bold mb-8 text-zinc-500">
            😎🍿Add A Movie Recommendation
          </h2>
          <div className="flex flex-col gap-1 mb-8">
            <label
              htmlFor="title"
              className="font-bold text-lg text-zinc-400 mb-5"
            >
              {" "}
              Title Of Movie / Series
              <sup className="text-emerald-500 ml-2 text-lg">*</sup>
            </label>
            <input
              type="text"
              id="title"
              placeholder="Title"
              className="bg-transparent border border-zinc-700 p-1 rounded-lg outline-none"
              value={title}
              minLength={3}
              maxLength={30}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="flex flex-col gap-1 mb-5">
            <label
              htmlFor="category"
              className="font-bold text-lg text-zinc-400 mb-5"
            >
              Which Category
              <sup className="text-emerald-500 ml-2 text-lg">*</sup>
            </label>
            <select
              name="category"
              id="category"
              className=" border border-zinc-700 p-1 rounded-lg outline-none bg-[#212121]"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="">Choose</option>
              <option value="Movie">Movie</option>
              <option value="Series">Series</option>
            </select>
          </div>
          {/* genre */}
          <div className="flex flex-col gap-1 mb-5">
            <label
              htmlFor="genre"
              className="font-bold text-lg text-zinc-400 mb-5"
            >
              Which Genre Best Fits ?
              <sup className="text-emerald-500 ml-2 text-lg">*</sup>
            </label>
            <select
              name="genre"
              id="genre"
              className=" border border-zinc-700 p-1 rounded-lg outline-none bg-[#212121]"
              value={genre}
              onChange={(e) => setGenre(e.target.value)}
              required
            >
              <option value="">Choose</option>
              {DummyGenre.map((item) => (
                <option value={item.title} key={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-1 mb-8">
            <label htmlFor="link" className=" mb-5">
              {" "}
              <p className="font-bold text-lg text-zinc-400 mb-2">
                Is There A Link To Access ?
              </p>
              <p className="text-sm">Trailer Or Movie Link ?</p>
            </label>
            <input
              type="text"
              id="link"
              placeholder="Enter Link"
              className="bg-transparent border border-zinc-700 p-1 rounded-lg outline-none"
              value={link}
              minLength={3}
              onChange={(e) => setLink(e.target.value)}
              required
            />
          </div>
          <div className="w-full flex justify-center">
            {createLoad ? (
              <p>😎 Please Wait ...</p>
            ) : (
              <button
                className="bg-emerald-800 px-3 py-1 rounded-lg"
                onSubmit={handleCreate}
              >
                Recommend Now ?
              </button>
            )}
          </div>
        </form>
      )}

      {!showForm && !searchText && <MovieSlider />}

      {/* wrapper for showing movies */}
      {loading ? (
        <div className="mt-[3em]">
          <Spinner message="Fetching Recommendations" />
        </div>
      ) : (
        <div>
          <div className="pb-[1em]">
            <div className="mt-[1em]">
              {/* searchbar */}
              <div className="mt-[1em] mb-[1em] w-full flex justify-center">
                <form
                  className="flex items-center gap-3
     w-[99%] sm:w-[70%] md:w-[60%] xl:w-[40%] border border-zinc-600   p-[8px] rounded-lg"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <AiOutlineSearch className="text-xl text-emerald-400" />
                  <input
                    type="text"
                    placeholder="search"
                    className="w-full bg-transparent border-none outline-none"
                    value={searchText}
                    onChange={handleSearchChange}
                  />
                </form>
              </div>

              {/* pagination */}
              {/* nav numbers */}
              {!searchText && (
                <nav className="flex justify-center">
                  <ul className="flex gap-[2em] mt-[10px] px-[5px] py-[10px] items-center ">
                    {/* map */}

                    <>
                      <li>
                        <a
                          href="#"
                          onClick={prevPage}
                          className="text-zinc-700"
                        >
                          <p className="text-zinc-300">Prev</p>
                        </a>
                      </li>
                      <li className="flex gap-[10px] ">
                        {numbers.slice(start - 1, end).map((item, index) => (
                          <li
                            key={index}
                            className={`normal-nav ${
                              currentPage === item && "active-nav"
                            }`}
                          >
                            <a
                              href="#"
                              onClick={() => {
                                handleClick(item);
                                changeCurrentPage(item);
                              }}
                            >
                              <p>{item}</p>
                            </a>
                          </li>
                        ))}
                      </li>

                      <li>
                        <a href="#" onClick={nextPage}>
                          <p className="text-zinc-300">Next</p>
                        </a>
                      </li>
                    </>
                  </ul>
                </nav>
              )}
              {/* feed */}

              {searchText ? (
                <>
                  <div className="mb-[15px] text-zinc-400">
                    {searchText && <p>Results For : {searchText}</p>}
                  </div>
                  {searchedResults?.length > 0 ? (
                    <>
                      <div className="movieGrid mt-[2em] ">
                        {searchedResults?.map((item, index) => (
                          <div
                            key={index}
                            className="bg-[#212121] p-2 rounded-lg"
                          >
                            <div className="flex gap-[20px] mb-5">
                              <Link
                                to={
                                  item.owner == user?.username
                                    ? "/my-profile"
                                    : `/other-profile?owner=${item.owner}`
                                }
                              >
                                <p
                                  className={`w-8 h-8 flex items-center justify-center rounded-full bg-emerald-800 text-white text-lg`}
                                >
                                  {item.owner.substring(0, 1)}
                                </p>
                              </Link>

                              <p className="text-xl fon-bold text-emerald-500">
                                {item.title}
                              </p>
                            </div>

                            <div className="flex justify-between">
                              <div className="mb-3 flex gap-2 items-center text-zinc-400">
                                <SlScreenDesktop />
                                <p>{item.category}</p>
                              </div>

                              <div
                                className="mb-3 flex gap-2 items-center text-xl cursor-pointer text-zinc-500 hover:text-zinc-200"
                                onClick={() => handleLikeMovie(item)}
                              >
                                <AiOutlineLike />
                                <p>{item.likes.length}</p>
                              </div>
                            </div>

                            <p className="mb-3 text-zinc-500">
                              Genre : {item.genre}
                            </p>

                            <div className="flex justify-between gap-2 items-center">
                              <div className="text-zinc-200">
                                <a
                                  href={`${item.link}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Open Link ?
                                </a>
                              </div>
                              <div>
                                <p className="text-sm text-zinc-500">
                                  {moment(item.createdAt).fromNow()}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className="w-full flex justify-center mt-[30vh]">
                      <p className="text-xl">🙆‍♂️No Results For {searchText}</p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="movieGrid mt-[2em] ">
                    {records.map((item, index) => (
                      <div key={index} className="bg-[#212121] p-2 rounded-lg">
                        <div className="flex gap-[20px] mb-5">
                          <Link
                            to={
                              item.owner == user?.username
                                ? "/my-profile"
                                : `/other-profile?owner=${item.owner}`
                            }
                          >
                            <p
                              className={`w-8 h-8 flex items-center justify-center rounded-full bg-emerald-800 text-white text-lg`}
                            >
                              {item.owner.substring(0, 1)}
                            </p>
                          </Link>

                          <p className="text-xl fon-bold text-emerald-500">
                            {item.title}
                          </p>
                        </div>

                        <div className="flex justify-between">
                          <div className="mb-3 flex gap-2 items-center text-zinc-400">
                            <SlScreenDesktop />
                            <p>{item.category}</p>
                          </div>

                          <div
                            className="mb-3 flex gap-2 items-center text-xl cursor-pointer text-zinc-500 hover:text-zinc-200"
                            onClick={() => handleLikeMovie(item)}
                          >
                            <AiOutlineLike />
                            <p>{item.likes.length}</p>
                          </div>
                        </div>

                        <p className="mb-3 text-zinc-500">
                          Genre : {item.genre}
                        </p>

                        <div className="flex justify-between gap-2 items-center">
                          <div className="text-zinc-200">
                            <a
                              href={`${item.link}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Open Link ?
                            </a>
                          </div>
                          <div>
                            <p className="text-sm text-zinc-500">
                              {moment(item.createdAt).fromNow()}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {/*  */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Movies;
