import React, { useState, useEffect, useRef } from "react";
import axios from "../axios";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineSearch } from "react-icons/ai";
import Spinner from "../components/Spinner";
import { IoReload, IoShareSocialOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { appBaseUrl } from "../constants";
import Masonry from "react-masonry-css";
import { CiLocationOn } from "react-icons/ci";

const SocialPage = () => {
  const { user } = useSelector((state) => state.auth);
  //fetch all users with whose instagram username is added. and isStudent is set to true

  // const students = [
  //   {
  //     id: 1,
  //     location: "Daystar University",
  //     instaUsername: "getach",
  //     userStatus: "offline",
  //     username: "Josh",
  //     phone: "0748348348834",
  //     coverPic:
  //       "https://images.pexels.com/photos/1536619/pexels-photo-1536619.jpeg?auto=compress&cs=tinysrgb&w=400",
  //     profile:
  //       "https://images.pexels.com/photos/157675/fashion-men-s-individuality-black-and-white-157675.jpeg?auto=compress&cs=tinysrgb&w=400",
  //   },
  //   {
  //     id: 2,
  //     location: "Zetech University",
  //     instaUsername: "getach",
  //     userStatus: "online",
  //     username: "Mercy",
  //     phone: "0748348348834",
  //     coverPic:
  //       "https://images.pexels.com/photos/934070/pexels-photo-934070.jpeg?auto=compress&cs=tinysrgb&w=400",
  //     profile:
  //       "https://images.pexels.com/photos/965324/pexels-photo-965324.jpeg?auto=compress&cs=tinysrgb&w=400",
  //   },
  //   {
  //     id: 3,
  //     location: "Riara University",
  //     instaUsername: "getach",
  //     userStatus: "online",
  //     username: "Tim",
  //     phone: "0748348348834",
  //     coverPic:
  //       "https://images.pexels.com/photos/1082529/pexels-photo-1082529.jpeg?auto=compress&cs=tinysrgb&w=400",
  //     profile:
  //       "https://images.pexels.com/photos/428340/pexels-photo-428340.jpeg?auto=compress&cs=tinysrgb&w=400",
  //   },
  //   {
  //     id: 4,
  //     location: "Makerere University",
  //     instaUsername: "getach",
  //     userStatus: "online",
  //     username: "Anastacia",
  //     phone: "0748348348834",
  //     coverPic:
  //       "https://images.pexels.com/photos/1266139/pexels-photo-1266139.jpeg?auto=compress&cs=tinysrgb&w=400",
  //     profile:
  //       "https://images.pexels.com/photos/1163194/pexels-photo-1163194.jpeg?auto=compress&cs=tinysrgb&w=400",
  //   },
  //   {
  //     id: 5,
  //     location: "Wote University",
  //     instaUsername: "getach",
  //     userStatus: "online",
  //     username: "Wambua",
  //     phone: "0748348348834",
  //     coverPic:
  //       "https://images.pexels.com/photos/380782/pexels-photo-380782.jpeg?auto=compress&cs=tinysrgb&w=400",
  //     profile:
  //       "https://images.pexels.com/photos/1192609/pexels-photo-1192609.jpeg?auto=compress&cs=tinysrgb&w=400",
  //   },
  // ];

  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFetchStudents = async () => {
    try {
      setLoading(true);
      let isStudent = true;
      let dataToSend = { isStudent };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let response = await axios.post("/users/students", dataToSend, config);
      if (response) {
        setStudents(response.data);
        // console.log(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchStudents();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const breakpointColumnsObj = {
    default: 5,
    3000: 5,
    2000: 4,
    1500: 3,
    1200: 2,
    800: 1,
  };

  //   pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = students?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(students?.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(4);

  const handleClick = (number) => {
    setStart(number);
    setEnd(number + 3);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      handleClick(currentPage);
    }
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      handleClick(currentPage);
    }
  };

  const changeCurrentPage = (id) => {
    setCurrentPage(id);
  };

  // search  states
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setsearchTimeout] = useState(null);
  const [searchedResults, setSearchedResults] = useState(null);

  //search Function 2

  // search user func
  const handleSearchChange = async (e) => {
    e.preventDefault();

    clearTimeout(setsearchTimeout);

    setSearchText(e.target.value);

    // console.log(searchText);

    setsearchTimeout(
      setTimeout(() => {
        const searchResults = students.filter(
          (item) =>
            item.username.toLowerCase().includes(searchText.toLowerCase()) ||
            item.location.toLowerCase().includes(searchText.toLowerCase()) ||
            item.instaUsername.toLowerCase().includes(searchText.toLowerCase())
        );

        setSearchedResults(searchResults);
      }, 500)
    );
  };

  // scroll to top functionality
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const textRef = useRef(null);

  const handleCopyToClipboard = async (textToCopy) => {
    try {
      // Get the text from your input or any other source
      // You can replace this with your dynamic input

      // Use the Clipboard API to write the text to the clipboard
      await navigator.clipboard.writeText(textToCopy);

      // Log success or update UI accordingly
      console.log("Text copied to clipboard:", textToCopy);
      toast.success("Copied The Link. Share", { theme: "dark" });
    } catch (err) {
      // Handle errors
      console.error("Error copying to clipboard:", err);
    }
  };

  return (
    <div className="">
      {/* wrapper */}
      <div>
        <Helmet>
          <title>{`Social | ${user?.location} | ${user?.username} | iLocca`}</title>
          <meta
            name="description"
            content="Bridging the gap between local businesses and the community, offering a streamlined platform for residents to discover and acquire quality local products and services."
          />
        </Helmet>
        {/* wrapper */}
        {loading ? (
          <div className="pt-[40vh]">
            <Spinner message="Fetching profiles" />
          </div>
        ) : (
          <>
            <div className="px-[10px] sm:px-[3em] lg:px-[4em] pt-3">
              {/* topbar */}
              {!searchText && (
                <div className="flex gap-4 items-center justify-between">
                  <Link to="/social">
                    <AiOutlineArrowLeft className="text-2xl" />
                  </Link>
                  <div>
                    <h2 className="font-bold text-lg text-zinc-400 text-end">
                      Browse Users
                    </h2>

                    <p className="text-sm text-emerald-500">
                      Connect Via Instagram
                    </p>
                  </div>
                </div>
              )}

              {loading ? (
                <>
                  <div className="h-[70vh] w-full flex justify-center items-center">
                    <Spinner message="fetching ..." />
                  </div>
                </>
              ) : (
                <>
                  {students?.length < 1 ? (
                    <>
                      <div
                        className="flex items-center gap-[10px] cursor-pointer"
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        <p>Reload</p>
                        <IoReload className="text-xl" />
                      </div>
                      <div className="h-[70vh] w-full flex justify-center items-center">
                        <div>
                          <h2 className="text-center mb-2">😢Ouch.</h2>
                          <h2 className="text-center">
                            There are no public users
                          </h2>
                          <h2 className="mt-[20px] mb-[10px] text-center">
                            Share This Link To Fellow Students
                          </h2>
                          <div
                            className="flex justify-center gap-[20px] border border-emerald-500 rounded-xl p-2 cursor-pointer"
                            onClick={() => handleCopyToClipboard(appBaseUrl)}
                          >
                            <input
                              type="text"
                              value={appBaseUrl}
                              readOnly
                              className="bg-transparent outline-none text-zinc-600"
                            />
                            <div className="flex justify-center gap-[20px]  ">
                              <button>
                                <IoShareSocialOutline
                                  className="text-3xl text-emerald-500"
                                  title="Click To Copy"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {!searchText && (
                        <>
                          {console.log(user)}
                          {user?.instaUsername == "" && (
                            <div className="mt-[1em]">
                              <h2 className="text-zinc-400">
                                You can Add An Instagram Public Profile
                              </h2>
                              <h2 className="text-zinc-400">
                                Other students can follow and socialize with you
                              </h2>
                              <p className="text-zinc-400">
                                simply update your IG username{" "}
                                <Link
                                  to="/my-profile"
                                  className="text-emerald-500"
                                >
                                  here
                                </Link>
                              </p>
                            </div>
                          )}
                        </>
                      )}
                      {/* searchbar */}
                      <div className="mt-[1em] mb-[1em] w-full flex justify-center">
                        <form
                          className="flex items-center gap-3
            w-[99%] sm:w-[70%] md:w-[60%] xl:w-[40%] border border-zinc-600   p-[8px] rounded-lg"
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <AiOutlineSearch className="text-xl text-emerald-400" />
                          <input
                            type="text"
                            placeholder="search"
                            className="w-full bg-transparent border-none outline-none"
                            value={searchText}
                            onChange={handleSearchChange}
                          />
                        </form>
                      </div>

                      {/* data */}
                      <div>
                        {/* nav numbers */}
                        {!searchText && (
                          <nav className="flex justify-center">
                            <ul className="flex gap-[2em] mt-[10px] px-[5px] py-[10px] items-center ">
                              {/* map */}

                              <>
                                <li>
                                  <a
                                    href="#"
                                    onClick={prevPage}
                                    className="text-zinc-700"
                                  >
                                    <p className="text-zinc-300">Prev</p>
                                  </a>
                                </li>
                                <li className="flex gap-[10px] ">
                                  {numbers
                                    .slice(start - 1, end)
                                    .map((item, index) => (
                                      <li
                                        key={index}
                                        className={`normal-nav ${
                                          currentPage === item && "active-nav"
                                        }`}
                                      >
                                        <a
                                          href="#"
                                          onClick={() => {
                                            handleClick(item);
                                            changeCurrentPage(item);
                                          }}
                                        >
                                          <p>{item}</p>
                                        </a>
                                      </li>
                                    ))}
                                </li>

                                <li>
                                  <a href="#" onClick={nextPage}>
                                    <p className="text-zinc-300">Next</p>
                                  </a>
                                </li>
                              </>
                            </ul>
                          </nav>
                        )}
                        {/* users */}
                        <div>
                          <div className="mt-[2em]">
                            {searchedResults?.length > 0 ? (
                              <>
                                <div className="mb-[15px] text-zinc-400">
                                  {searchText && (
                                    <p>Results For : {searchText}</p>
                                  )}
                                </div>

                                {searchedResults?.length > 0 ? (
                                  <>
                                    <div>
                                      <Masonry
                                        breakpointCols={breakpointColumnsObj}
                                        className="my-masonry-grid "
                                        columnClassName="my-masonry-grid_column"
                                      >
                                        {searchedResults?.map((item) => (
                                          <div className="mb-8" key={item.id}>
                                            {item.instaUsername !== "" && (
                                              <>
                                                <div className="relative rounded-lg group ">
                                                  <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100 ">
                                                    <div
                                                      className="bg-gradient-to-t
                                                  from-transparent to-black opacity-90 w-full h-full rounded-md"
                                                    >
                                                      {/* top stats */}
                                                      <div>
                                                        <div className="absolute top-[20px]   w-full px-2 ">
                                                          <div className="mb-[2em] w-full flex justify-between">
                                                            <p className="text-white text-md flex items-center gap-[5px]">
                                                              <CiLocationOn className="text-lg" />
                                                              <span>
                                                                {item.location}
                                                              </span>
                                                            </p>
                                                            <div>
                                                              {item.userStatus ==
                                                              "online" ? (
                                                                <>
                                                                  <div className="h-4 w-4 bg-emerald-500 rounded-full" />
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <div className="h-4 w-4 bg-red-500 rounded-full" />
                                                                </>
                                                              )}
                                                            </div>
                                                          </div>
                                                          {/* likes and stats */}
                                                          {/* <div className="flex gap-[30px] mb-[1em]">
                                                  <p className="text-white">
                                                    {item.username}
                                                  </p>
                                                </div> */}
                                                        </div>
                                                      </div>
                                                      {/*  */}
                                                      <div className="absolute bottom-[20px]  flex gap-[10%] w-full">
                                                        <div className="pl-2">
                                                          <div className="flex gap-[10px] text-zinc-300">
                                                            {/* <p>#{item.category}</p> */}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="min-h-[200px]">
                                                    <img
                                                      src={
                                                        item.profile ||
                                                        "https://images.pexels.com/photos/4049449/pexels-photo-4049449.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                                      }
                                                      alt=""
                                                      className="rounded-md object-cover w-full"
                                                      loading="lazy"
                                                      // onLoad={() => alert("loaded")}
                                                    />
                                                  </div>
                                                </div>

                                                {/* more items */}
                                                <div className="flex justify-between items-center mt-[10px]">
                                                  <div>
                                                    IG @ {""}
                                                    <a
                                                      href={`https://www.instagram.com/${item.instaUsername}/`}
                                                      className="text-emerald-500 underline"
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      {item.instaUsername}
                                                    </a>
                                                  </div>
                                                  {/* user */}
                                                  <div>
                                                    <Link
                                                      to={
                                                        item.username ==
                                                        user?.username
                                                          ? "/my-profile"
                                                          : `/other-profile?owner=${item.username}`
                                                      }
                                                    >
                                                      <p
                                                        className={`w-8 h-8 flex items-center justify-center rounded-full bg-emerald-800 text-white text-lg`}
                                                      >
                                                        {item.username.substring(
                                                          0,
                                                          1
                                                        )}
                                                      </p>
                                                    </Link>
                                                  </div>
                                                  {/* reporting */}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        ))}
                                      </Masonry>
                                    </div>
                                  </>
                                ) : (
                                  <div className="flex justify-center mt-[7em]">
                                    <p>😥 No results for {searchText}</p>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <div>
                                  <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="my-masonry-grid "
                                    columnClassName="my-masonry-grid_column"
                                  >
                                    {records?.map((item) => (
                                      <div className="mb-8" key={item.id}>
                                        {item.instaUsername !== "" && (
                                          <>
                                            <div className="relative rounded-lg group ">
                                              <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100 ">
                                                <div
                                                  className="bg-gradient-to-t
                                                from-transparent to-black opacity-90 w-full h-full rounded-md"
                                                >
                                                  {/* top stats */}
                                                  <div>
                                                    <div className="absolute top-[20px]   w-full px-2 ">
                                                      <div className="mb-[2em] w-full flex justify-between">
                                                        <p className="text-white text-md flex items-center gap-[5px]">
                                                          <CiLocationOn className="text-lg" />
                                                          <span>
                                                            {item.location}
                                                          </span>
                                                        </p>
                                                        <div>
                                                          {item.userStatus ==
                                                          "online" ? (
                                                            <>
                                                              <div className="h-4 w-4 bg-emerald-500 rounded-full" />
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div className="h-4 w-4 bg-red-500 rounded-full" />
                                                            </>
                                                          )}
                                                        </div>
                                                      </div>
                                                      {/* likes and stats */}
                                                      {/* <div className="flex gap-[30px] mb-[1em]">
                                                <p className="text-white">
                                                  {item.username}
                                                </p>
                                              </div> */}
                                                    </div>
                                                  </div>
                                                  {/*  */}
                                                  <div className="absolute bottom-[20px]  flex gap-[10%] w-full">
                                                    <div className="pl-2">
                                                      <div className="flex gap-[10px] text-zinc-300">
                                                        {/* <p>#{item.category}</p> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="min-h-[200px]">
                                                <img
                                                  src={
                                                    item.profile ||
                                                    "https://images.pexels.com/photos/4049449/pexels-photo-4049449.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                                  }
                                                  alt=""
                                                  className="rounded-md object-cover w-full"
                                                  loading="lazy"
                                                  // onLoad={() => alert("loaded")}
                                                />
                                              </div>
                                            </div>

                                            {/* more items */}
                                            <div className="flex justify-between items-center mt-[10px]">
                                              <div>
                                                IG @ {""}
                                                <a
                                                  href={`https://www.instagram.com/${item.instaUsername}/`}
                                                  className="text-emerald-500 underline"
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  {item.instaUsername}
                                                </a>
                                              </div>
                                              {/* user */}
                                              <div>
                                                <Link
                                                  to={
                                                    item.username ==
                                                    user?.username
                                                      ? "/my-profile"
                                                      : `/other-profile?owner=${item.username}`
                                                  }
                                                >
                                                  <p
                                                    className={`w-8 h-8 flex items-center justify-center rounded-full bg-emerald-800 text-white text-lg`}
                                                  >
                                                    {item.username.substring(
                                                      0,
                                                      1
                                                    )}
                                                  </p>
                                                </Link>
                                              </div>
                                              {/* reporting */}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    ))}
                                  </Masonry>
                                </div>
                              </>
                            )}

                            {/*  */}
                          </div>
                        </div>
                        {/*  */}
                      </div>
                    </>
                  )}
                </>
              )}

              {/*  */}
            </div>
            {/* more stuff */}
          </>
        )}
      </div>
    </div>
  );
};

export default SocialPage;
