import React, { useEffect, useState } from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineComment,
  AiOutlineDislike,
  AiOutlineEye,
  AiOutlineLike,
  AiOutlinePhone,
} from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import { Link, useNavigate, useParams } from "react-router-dom";
import Comment from "../components/TradeComment";
import { useSelector } from "react-redux";
import axios from "../axios";
import { toast } from "react-toastify";
import moment from "moment";
import Spinner from "../components/Spinner";
import { MdCancel, MdOutlineVerifiedUser } from "react-icons/md";
import { Helmet } from "react-helmet";
import { appBaseUrl } from "../constants";
import { IoShareSocialOutline } from "react-icons/io5";

const SpecificTrade = () => {
  const { user } = useSelector((state) => state.auth);

  // fetch the trade
  const { id } = useParams();
  const [singleTrade, setSingleTrade] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTrade = async () => {
    try {
      setLoading(true);
      let checkParam = id;
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get("/trade/" + checkParam, config);
      if (response) {
        setLoading(false);
        setSingleTrade([response.data]);
        console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Trade.", { theme: "dark" });
      toast.error("Network error or doesn't exist", { theme: "dark" });
    }
  };

  useEffect(() => {
    fetchTrade();
  }, []);

  // like trade
  const handleLikeTrade = async (trade) => {
    try {
      if (!user) {
        toast.error("Please Login To leave a like", { theme: "dark" });
        return;
      }

      let username = user?.username;
      let id = trade._id;
      let likeData = { username };

      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post("/trade/like/" + id, likeData, config);
      toast.success("Like Added", { theme: "dark" });

      if (user?.username !== trade.owner) {
        // create notification
        let sender = user?.username;
        let receiver = trade.owner;
        let receiverEmail = trade.ownerEmail;
        let message = `${trade.title} Got a Like 👍 From ${user?.username} in ${user?.location}`;
        let referID = `/trade/${id}`;
        let notifyData = { sender, receiver, receiverEmail, message, referID };

        await axios.post("/notify/create", notifyData, config);
      }

      window.location.reload();
    } catch (error) {
      toast.error("Failed To Like", { theme: "dark" });
    }
  };

  // dislike trade
  const handleDisLikeTrade = async (trade) => {
    try {
      if (!user) {
        toast.error("Please Login To dislike", { theme: "dark" });
        return;
      }

      let username = user?.username;
      let id = trade._id;
      let likeData = { username };

      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post("/trade/dislike/" + id, likeData, config);
      toast.success("Dislike Added", { theme: "dark" });
      if (user?.username !== trade.owner) {
        // create notification
        let sender = user?.username;
        let receiver = trade.owner;
        let receiverEmail = trade.ownerEmail;
        let message = `${trade.title} Got a Dislike 👎 From ${user?.username}`;
        let referID = `/trade/${id}`;
        let notifyData = { sender, receiver, receiverEmail, message, referID };

        // await axios.post("/notify/create", notifyData, config);
      }
      window.location.reload();
    } catch (error) {
      toast.error("Failed To Dislike", { theme: "dark" });
    }
  };

  const handleCall = async (item) => {
    try {
      if (user && user?.username !== item.owner) {
        let caller = user?.username;
        let receiver = item.owner;
        let receiverEmail = item.ownerEmail;
        let dataToSend = { caller, receiver };
        // console.log(dataToSend);
        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios.post("/call/create", dataToSend, config);

        // create notification
        let sender = user?.username;
        let message = `${caller} is reaching out regarding your trade : ${item.title}. phone number is ${user?.phone}`;
        let referID = `/trade/${item._id}`;
        let notifyData = { sender, receiver, receiverEmail, message, referID };

        try {
          let token = user?.token;
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await axios.post(
            "/notify/create",
            notifyData,
            config
          );

          if (response) {
            console.log("call sent successfully");
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [activeImg, setActiveImg] = useState(null);

  const checkTheMainPhoto = (url) => {
    setActiveImg(url);
  };

  useEffect(() => {
    checkTheMainPhoto();
  }, []);

  const handleCopyToClipboard = async (textToCopy) => {
    try {
      // Get the text from your input or any other source
      // You can replace this with your dynamic input

      // Use the Clipboard API to write the text to the clipboard
      await navigator.clipboard.writeText(textToCopy);

      // Log success or update UI accordingly
      console.log("Text copied to clipboard:", textToCopy);
      toast.success("Copied The Link. Share", { theme: "dark" });
    } catch (err) {
      // Handle errors
      console.error("Error copying to clipboard:", err);
    }
  };

  return (
    <div>
      {/* wrapper */}
      <div className=" px-[8px] sm:px-[2em] lg:px-[4em] pt-2">
        {/* topbar */}
        <div>
          {user ? (
            <Link to="/trade">
              <AiOutlineArrowLeft className="text-2xl" />
            </Link>
          ) : (
            <>
              <Link to="/">GET STARTED</Link>
            </>
          )}
        </div>
        {/* data */}
        {loading ? (
          <>
            <div className="h-[80vh] w-full flex justify-between items-center">
              <Spinner message="Fetching Trade" />
            </div>
          </>
        ) : (
          <>
            <div className=" mt-[30px] ">
              {singleTrade?.map((item) => (
                <div key={item._id}>
                  <div className="flex flex-col lg:flex-row gap-[20px]  justify-between">
                    {/* image side */}
                    <div className=" flex-[0.5] xl:flex-[0.5]">
                      <img
                        src={activeImg ? activeImg : item.firstPhoto}
                        alt=""
                        className="w-full max-h-[500px] rounded-xl object-contain"
                      />

                      {/* small images */}
                      <div className="mt-[20px] flex gap-[10px] justify-center">
                        <img
                          src={item.secPhoto}
                          alt=""
                          className=" h-[80px] w-[80px] sm:h-[200px] sm:w-[200px]  object-cover rounded-lg"
                          onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                            checkTheMainPhoto(item.secPhoto);
                          }}
                        />
                        <img
                          src={item.thirdPhoto}
                          alt=""
                          className="h-[80px] w-[80px] sm:h-[200px] sm:w-[200px] object-cover rounded-lg"
                          onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                            checkTheMainPhoto(item.thirdPhoto);
                          }}
                        />
                        <img
                          src={item.firstPhoto}
                          alt=""
                          className="h-[80px] w-[80px] sm:h-[200px] sm:w-[200px] object-cover rounded-lg"
                          onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                            checkTheMainPhoto(item.firstPhoto);
                          }}
                        />
                      </div>
                    </div>

                    {/* {console.log(item)} */}
                    <Helmet>
                      <title>
                        {" "}
                        {item.title} | {item.owner} | {item.location} | iLocca
                      </title>
                      <meta
                        name="description"
                        content={`${item.username} owns ${item.title}. Check The Trade Today.`}
                      />
                    </Helmet>

                    {/* text side */}
                    <div className="flex-[0.5]">
                      {/* share */}
                      <div className="flex justify-center gap-[20px] mb-4  ">
                        <button
                          onClick={() =>
                            handleCopyToClipboard(
                              ` ${appBaseUrl}trade/${item._id}`
                            )
                          }
                          className="flex justify-center gap-[20px] border border-emerald-400 rounded-lg px-4 py-1  "
                        >
                          <IoShareSocialOutline
                            className="text-2xl text-emerald-400"
                            title="Click To Copy"
                          />
                          <p>Share With Friends</p>
                        </button>
                      </div>
                      {/* owner info */}
                      <div className="mb-[20px]">
                        <div className="mb-[10px] flex justify-between items-center">
                          <p className="text-xl font-bold text-zinc-300">
                            {item.title}
                          </p>
                          <p className="text-emerald-600">#{item.category}</p>
                        </div>

                        <p className="mb-[15px] text-sm text-teal-600">
                          Added {moment(item.createdAt).fromNow()}
                        </p>

                        <div className="flex justify-between items-center mb-6 ">
                          <div>
                            {item.approved == "yes" ? (
                              <div className="flex items-center gap-2 text-emerald-400">
                                <p>
                                  <MdOutlineVerifiedUser />
                                </p>
                                <p>approved</p>
                              </div>
                            ) : (
                              <div className="flex items-center gap-2 text-red-400">
                                <p>
                                  <MdCancel />
                                </p>
                                <p>Not - approved</p>
                              </div>
                            )}
                          </div>
                          <div>
                            <Link
                              to={
                                item.owner == user?.username
                                  ? "/my-profile"
                                  : `/other-profile?owner=${item.owner}`
                              }
                            >
                              <p className="underline text-zinc-300">
                                Owned By{" "}
                                {item.owner == user?.username
                                  ? "you"
                                  : item.owner}
                              </p>
                            </Link>
                          </div>
                        </div>

                        <a
                          href={`tel:${item.phone}`}
                          className=" mt-[18px] bg-emerald-800 text-white p-2 rounded-lg w-full flex  gap-2 justify-center"
                          onClick={() => handleCall(item)}
                        >
                          <span>
                            <AiOutlinePhone className="text-xl" />
                          </span>
                          <span>{item.phone}(Call Now)</span>
                        </a>
                      </div>

                      {/* options */}
                      {item.owner == user?.username && (
                        <p className="mb-[10px] text-zinc-400">
                          Only you can see dislikes
                        </p>
                      )}
                      {user ? (
                        <div className="flex justify-between">
                          <div className="flex items-center gap-3 cursor-pointer">
                            <AiOutlineEye className="text-xl" />
                            <p>{item.views.length}</p>
                            {/* {console.log(item)} */}
                          </div>
                          <div
                            className="flex items-center gap-3 cursor-pointer"
                            onClick={() => handleLikeTrade(item)}
                          >
                            <AiOutlineLike className="text-xl" />
                            <p>{item.likes.length}</p>
                          </div>
                          <div
                            className="flex items-center gap-3 cursor-pointer"
                            onClick={() => handleDisLikeTrade(item)}
                          >
                            <AiOutlineDislike className="text-xl" />
                            {item.owner == user?.username && (
                              <p>{item.dislikes.length}</p>
                            )}
                          </div>
                          <div className="flex items-center gap-3">
                            <AiOutlineComment className="text-xl" />
                            <p>{item.comments.length}</p>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="flex justify-between">
                            <div className="flex items-center gap-3 cursor-pointer">
                              <AiOutlineEye className="text-xl" />
                              <p>{item.views.length}</p>
                              {/* {console.log(item)} */}
                            </div>
                            <div
                              className="flex items-center gap-3 cursor-pointer"
                              // onClick={() => handleLikeBiz(item)}
                            >
                              <AiOutlineLike className="text-xl" />
                              <p>{item.likes.length}</p>
                            </div>
                            <div
                              className="flex items-center gap-3 cursor-pointer"
                              // onClick={() => handleDisLikeBiz(item)}
                            >
                              <AiOutlineDislike className="text-xl" />
                              {/* <p>{item.dislikes.length}</p> */}
                            </div>
                            <div className="flex items-center gap-3">
                              <AiOutlineComment className="text-xl" />
                              <p>{item.comments.length}</p>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="mt-[45px] mb-[15px]">
                        <p className="font-semibold text-zinc-400 mb-2">
                          Description
                        </p>
                        <p className=" text-zinc-300">{item.description}</p>
                      </div>

                      <div className=" mb-[15px]">
                        <p className="font-semibold text-zinc-400 mb-2">
                          In Exchange
                        </p>
                        <p className=" text-zinc-300">{item.exchangeFor}</p>
                      </div>

                      <div className=" mb-[15px]">
                        <p className="font-semibold text-zinc-400 mb-2">
                          Defects
                        </p>
                        <p className=" text-zinc-300">{item.defect}</p>
                      </div>

                      <div className="flex justify-between items-center mb-[15px]">
                        <div className="flex items-center gap-[6px]">
                          <CiLocationOn className="text-2xl" />
                          <div>
                            <p className="text-zinc-300 mb-2">
                              {item.location}
                            </p>
                            <p className="text-zinc-300">
                              {item.specificAddress}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="mb-[20px]">
                        <h2 className="mb-[8px] font-semibold text-zinc-400">
                          Available To Exchange
                        </h2>
                        <p className="text-zinc-300">{item.availability}</p>
                      </div>

                      {/* comments*/}
                      <div>
                        {user ? (
                          <Comment trade={item} />
                        ) : (
                          <div>
                            <Link to="/login">
                              <p className="text-emerald-700 font-bold">
                                LOGIN TO ACCESS BIDS
                              </p>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* recommended */}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SpecificTrade;
