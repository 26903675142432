import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../axios";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { useSelector } from "react-redux";
import moment from "moment";
import OtherBusinesses from "../components/others/OtherBusinesses";
import OtherEvents from "../components/others/OtherEvents";
import OtherTrades from "../components/others/OtherTrades";
import OtherRents from "../components/others/OtherRents";
import Logo from "../assets/officialOne.png";
import OtherFununu from "../components/others/OtherFununu";
import { MdOutlineLocalPhone } from "react-icons/md";
import { Helmet } from "react-helmet";
import NotFound from "../assets/notFound.gif";
import { CiLocationOn } from "react-icons/ci";

const UserProfile = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const owner = searchParams.get("owner");
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  // fetch this user
  const [newUser, setNewUser] = useState([]);

  const [userNotFound, setUserNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchUser = async () => {
    if (!owner) {
      navigate("/home");
      toast.warning("User Cannot Be Accessed", { theme: "dark" });
      return;
    }

    // check if this user exists in the DB
    setLoading(true);
    let dataToCheck = { username: owner };
    const { data } = await axios.post("/users/check", dataToCheck);
    if (data == "not exist") {
      setUserNotFound(true);
      setLoading(false);
      return;
    } else {
      try {
        setLoading(true);
        let username = owner;
        let dataToSend = { username };
        let token = user?.token;

        let res = await axios.post("/users/specific", dataToSend);
        if (res) {
          setNewUser([res.data]);
          setLoading(false);
          // toast.success("fetched");
          console.log(res.data);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Failed To Fetch User. Check Network", { theme: "dark" });
      }
    }
  };

  useEffect(() => {
    if (owner) {
      fetchUser();
    }
  }, [owner]);

  return (
    <div>
      {/* wrapper */}
      <div className=" px-[10px] sm:px-[2em] md:px-[5em] pt-[1em]">
        {/* user details */}
        <div>
          {/* {console.log(newUser)} */}
          {/* topbar */}
          <div
            style={{
              background: "rgba(34, 33, 33, 0.9)",
              backdropFilter: "blur(4px)",
              zIndex: 2,
            }}
            className=" top-0 left-0 fixed w-full mb-[1em] sm:mb-[3em] flex justify-between items-center py-2 px-[10px] sm:px-[2em] md:px-[5em]"
          >
            <Link to="/home">
              <img src={Logo} alt="" className="w-[50px] object-contain" />
            </Link>
            <div>
              {!user?.username ? (
                <div>
                  <Link to="/register">
                    <p className="text-emerald-400 text-lg">
                      GET STARTED TODAY
                    </p>
                  </Link>
                </div>
              ) : (
                <div>
                  <Link to="/home">
                    <p className="font-bold text-emerald-400 text-lg">BACK</p>
                  </Link>
                </div>
              )}
            </div>
          </div>

          {loading ? (
            <div className="h-[80vh] w-full flex justify-center items-center">
              <Spinner message="Loading ..." />
            </div>
          ) : (
            <>
              {userNotFound ? (
                <div className="h-[80vh] w-full flex justify-center items-center">
                  <div>
                    <div className="flex justify-center">
                      <img
                        src={NotFound}
                        alt="notFound"
                        className="h-[140px] object-contain mb-[10px]"
                      />
                    </div>
                    <h2 className="text-center text-zinc-400 font-semibold text-lg mb-5">
                      {owner}'s Profile Is Not Available
                    </h2>
                    <Link to="/home">
                      <p className="text-center text-emerald-500 underline">
                        Back Home ?
                      </p>
                    </Link>
                  </div>
                </div>
              ) : (
                <>
                  {/* all details */}
                  {newUser.length > 0 && (
                    <>
                      <div className="pt-[4.2em]">
                        {newUser?.map((item) => (
                          <>
                            <Helmet>
                              <title>
                                {item?.username} | {item?.location} | iLocca
                              </title>
                              <meta
                                name="description"
                                content={`${item.username} is an iLocca user currently at ${item.location}. Check Out His Profile`}
                              />
                            </Helmet>

                            {/* cover pic and profile */}
                            <div>
                              {/* cover */}
                              <div>
                                <img
                                  src={
                                    item?.coverPic ||
                                    "https://images.pexels.com/photos/459277/pexels-photo-459277.jpeg?auto=compress&cs=tinysrgb&w=600"
                                  }
                                  alt=""
                                  className="max-h-[300px] w-full object-cover rounded-lg"
                                />
                              </div>

                              {/* profile */}
                              <div className="w-full flex justify-center mt-[-80px]">
                                <img
                                  src={
                                    item.profile ||
                                    "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&w=600"
                                  }
                                  alt=""
                                  className="w-[150px] h-[150px] object-cover rounded-full"
                                />
                              </div>
                            </div>

                            {/* user data */}
                            <div className="flex flex-col justify-center w-full mb-[2em] mt-[20px]">
                              <div className="mb-[25px] flex justify-between items-center">
                                <p className=" text-zinc-400 font-bold text-xl">
                                  {item.username}
                                </p>
                                <div>
                                  {item.userStatus == "online" ? (
                                    <>
                                      <div className="h-4 w-4 bg-emerald-500 rounded-full" />
                                    </>
                                  ) : (
                                    <>
                                      <div className="h-4 w-4 bg-red-500 rounded-full" />
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="mb-[10px]">
                                <a
                                  href={`tel:${item.phone}`}
                                  className="flex items-center text-lg gap-[10px]"
                                >
                                  <MdOutlineLocalPhone />
                                  {item.phone}
                                </a>
                              </div>
                              {/* {console.log(item)} */}
                              <p className="text-sm text-emerald-500">
                                Joined us {moment(item.createdAt).fromNow()}
                              </p>

                              <div className="my-4 flex gap-5 items-center">
                                <CiLocationOn className="text-2xl text-emerald-500" />
                                {item.location}
                              </div>
                            </div>

                            {user ? (
                              <>
                                {/* businesses */}
                                <div className="pb-[30px]">
                                  <OtherBusinesses data={item} />
                                </div>
                                {/* events */}
                                <div className="pb-[30px]">
                                  <OtherEvents data={item} />
                                </div>
                                {/* trades */}
                                <div className="pb-[30px]">
                                  {/* <OtherTrades data={item} /> */}
                                </div>
                                {/* to rent */}
                                <div className="pb-[30px]">
                                  {/* <OtherRents data={item} /> */}
                                </div>
                                {/* to rent */}
                                <div>
                                  <OtherFununu data={item} />
                                </div>
                              </>
                            ) : (
                              <div className="w-full flex justify-center">
                                <div>
                                  <p className="text-center mb-4 font-semibold text-zinc-300 ">
                                    😎 Due To Privacy Reasons, You Must Sign In
                                  </p>
                                  <p className="text-center mb-4 font-semibold text-zinc-300 ">
                                    To see {owner}'s activity
                                  </p>
                                  <Link to="/login">
                                    <p className="text-emerald-400 font-semibold text-center">
                                      LOGIN
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
