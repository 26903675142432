import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  AiFillStar,
  AiOutlineClose,
  AiOutlineComment,
  AiOutlineDislike,
  AiOutlineEye,
  AiOutlineLike,
  AiOutlinePhone,
  AiOutlineStar,
} from "react-icons/ai";
import { BsPen, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import RentComment from "../RentComment";

const MyRents = () => {
  // fetch all my rents

  const [allMyRentals, setAllMyRentals] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const handleFetchMyToRent = async () => {
    try {
      setLoading(true);
      let username = user.username;
      let dataToSend = { username };
      let token = user?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post("/rent/mine", dataToSend, config);
      if (response) {
        setLoading(false);
        setAllMyRentals(response.data);
        console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Your To Rent", { theme: "dark" });
    }
  };

  useEffect(() => {
    handleFetchMyToRent();
  }, []);

  // delete my to rent
  const handleDeleteMyToRent = async (id) => {
    if (!id) return toast.error("Error mapping id. Contact admin");

    const userConfirmed = window.confirm("Delete This To Rent ? ");

    if (userConfirmed) {
      try {
        setLoading(true);
        let token = user?.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        await axios.delete("/rent/" + id, config);
        toast.success("deleted succesfully", { theme: "dark" });
        await handleFetchMyToRent();
      } catch (error) {
        setLoading(false);
        toast.error("failed to delete", { theme: "dark" });
      }
    }
  };

  return (
    <div>
      {/*  */}

      {loading ? (
        <>
          <Spinner message="Fetching Your to rent .." />
        </>
      ) : (
        <>
          {allMyRentals.length < 1 ? (
            <>
              <div className="h-[20vh] w-full flex justify-center items-center">
                <div>
                  <h2 className="text-center mb-2">😢Ouch.</h2>
                  <h2>You have not added an item to rent</h2>
                  <Link to="/create-rent-out">
                    <h2 className="mt-[10px] mb-[10px] text-center text-emerald-600 underline">
                      Add one in {user.location}
                    </h2>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <h2 className="text-xl mb-[10px] text-zinc-600 font-bold">
                  Your To Rent
                </h2>
              </div>

              <div className="flex gap-[10px] overflow-x-scroll w-full prompt pb-2">
                <div className="flex flex-nowrap">
                  {allMyRentals?.map((item) => (
                    <div key={item._id} className="flex-shrink-0 mr-[15px] ">
                      <Link to={`/rent-out/${item._id}`}>
                        <div className="relative rounded-lg group ">
                          <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                            <div
                              className="bg-gradient-to-t
                                  from-transparent to-black opacity-80 w-full h-full rounded-md"
                            >
                              {/* top stats */}
                              <div>
                                <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                                  <div>
                                    <a href={`tel:+${item.phone}`}>
                                      {" "}
                                      <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                    </a>
                                  </div>
                                  <div className="flex gap-[20px]">
                                    <p className="text-white text-md flex items-center gap-[5px]">
                                      <AiOutlineEye className="text-lg" />
                                      <span>{item.views.length}</span>
                                    </p>
                                  </div>
                                </div>
                                <div className="absolute top-[80px] left-3">
                                  <p className="text-white">{item.title}</p>
                                </div>
                              </div>
                              {/*  */}
                              <div className="absolute bottom-[20px] left-[20px]  flex gap-[10%] w-full ">
                                <div>
                                  <div className="flex gap-[10px] text-zinc-300">
                                    {/* <p>{item.location}</p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <img
                            src={item.firstPhoto}
                            alt=""
                            className="w-72 h-80 rounded-md object-cover"
                          />
                        </div>
                      </Link>

                      {/* options */}
                      <div className="flex justify-between items-center mt-[10px]">
                        <Link to={`/edit-to-rent/${item._id}`}>
                          <BsPen
                            className="text-xl text-emerald-600 cursor-pointer"
                            title="update"
                          />
                        </Link>
                        <BsTrash
                          className="text-xl text-red-600 cursor-pointer"
                          onClick={() => handleDeleteMyToRent(item._id)}
                          title="delete"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MyRents;
