import React, { useEffect, useRef, useState } from "react";
import {
  AiFillStar,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineArrowUp,
  AiOutlineClose,
  AiOutlineComment,
  AiOutlineDislike,
  AiOutlineEye,
  AiOutlineGold,
  AiOutlineLike,
  AiOutlinePhone,
  AiOutlinePlus,
  AiOutlineSearch,
  AiOutlineStar,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";
import axios from "../axios";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { useSelector } from "react-redux";
import { DummyToTradeCategory } from "../Dummydata";
import TradeComment from "../components/TradeComment";

import { MdOutlineAddBusiness, MdOutlineContentCopy } from "react-icons/md";
import { IoMdAdd, IoMdArrowBack } from "react-icons/io";
import moment from "moment";
import { IoShareSocialOutline } from "react-icons/io5";
import { appBaseUrl } from "../constants";
import { Helmet } from "react-helmet";

const Trade = () => {
  const [allToTrade, setAllToTrade] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate("/login");
      toast.error("Please Login", { theme: "dark" });
    }
  }, [user, navigate]);

  // fetch to trade
  const handleFetchToTrade = async () => {
    try {
      setLoading(true);
      let approved = "yes";
      let dataToSend = { approved };
      const response = await axios.post("/trade/all", dataToSend);
      if (response) {
        setLoading(false);
        setAllToTrade(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Trades");
    }
  };

  useEffect(() => {
    handleFetchToTrade();
  }, []);

  const breakpointColumnsObj = {
    default: 5,
    3000: 5,
    2000: 5,
    1500: 4,
    1200: 3,
    1000: 2,
    500: 1,
  };

  //   pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = allToTrade?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(allToTrade?.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(4);

  const handleClick = (number) => {
    setStart(number);
    setEnd(number + 3);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      handleClick(currentPage);
    }
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      handleClick(currentPage);
    }
  };

  const changeCurrentPage = (id) => {
    setCurrentPage(id);
  };

  // search  states
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setsearchTimeout] = useState(null);
  const [searchedResults, setSearchedResults] = useState(null);

  // search user func
  const handleSearchChange = async (e) => {
    e.preventDefault();
    clearTimeout(setsearchTimeout);

    setSearchText(e.target.value);

    // console.log(searchText);

    setsearchTimeout(
      setTimeout(() => {
        const searchResults = allToTrade.filter(
          (item) =>
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.searchTerms.toLowerCase().includes(searchText.toLowerCase())
        );

        setSearchedResults(searchResults);
      }, 500)
    );
  };

  // scroll to top functionality
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // handle user backgroun colors
  const getRandomColorClass = () => {
    const colors = [
      "bg-red-500",
      "bg-blue-500",
      "bg-green-700",
      "bg-yellow-700",
      "bg-orange-700",
      "bg-purple-500",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const [backgroundClass, setBackgroundClass] = useState(getRandomColorClass());

  const changeBackgroundColor = () => {
    setBackgroundClass(getRandomColorClass());
  };

  // working on modal
  const [selectedPost, setSelectedPost] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  // what happens when we click on a post
  const handlePostClick = (post) => {
    setSelectedPost(post);
    setIsPopUpOpen(true);
  };

  const handleAddComment = (e) => {
    e.preventDefault();
  };

  // like a trade
  const handleLikeTrade = async (trade) => {
    try {
      if (!user) {
        toast.error("Please Login To leave a like", { theme: "dark" });
        return;
      }

      let username = user.username;
      let id = trade._id;
      let likeData = { username };

      let res = await axios.post("/trade/like/" + id, likeData);
      if (res) {
        // notification
        if (user?.username !== trade.owner) {
          // create notification
          let sender = user.username;
          let receiver = trade.owner;
          let receiverEmail = trade.ownerEmail;
          let message = `${trade.title} Got A Like from ${sender} 👍 in ${user?.location}`;
          let referID = `/trade/${id}`;
          let notifyData = {
            sender,
            receiver,
            receiverEmail,
            message,
            referID,
          };

          await axios.post("/notify/create", notifyData);
        }
        toast.success("Action Succesful");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed To Like");
    }
  };

  // dislike trade
  const handleDislikeTrade = async (trade) => {
    try {
      if (!user) {
        toast.error("Please Login To dislike", { theme: "dark" });
        return;
      }

      let username = user.username;
      let id = trade._id;
      let dislikeData = { username };

      await axios.post("/trade/dislike/" + id, dislikeData);

      // notification
      if (user?.username !== trade.owner) {
        // create notification
        let sender = user.username;
        let receiver = trade.owner;
        let receiverEmail = trade.ownerEmail;
        let message = `${trade.title} Got A Dislike 👎 from ${sender}`;
        let referID = `/trade`;
        let notifyData = { sender, receiver, receiverEmail, message, referID };

        // await axios.post("/notify/create", notifyData);
      }

      window.location.reload();
    } catch (error) {
      toast.error("Failed To Dislike");
    }
  };

  const handleAddViews = async (id) => {
    try {
      if (user) {
        let username = user.username;
        let viewsData = { username };

        await axios.post("/trade/view/" + id, viewsData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const PopUpPage = ({ post, onClose }) => {
    // moving between images
    const [currentIndex, setCurrentIndex] = useState(0);

    const imagesArray = [`${post.firstPhoto}`, `${post.secPhoto}`];

    const prevImage = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? imagesArray.length - 1 : prevIndex - 1
      );
    };

    const nextImage = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === imagesArray.length - 1 ? 0 : prevIndex + 1
      );
    };
    return (
      <div className="pop-up-page prompt ">
        {/* close btn */}

        {/* data */}
        <div className="pop-up-content">
          <div className="flex justify-center my-[20px] z-[999] ">
            <button onClick={onClose}>
              <AiOutlineClose
                className="text-5xl text-white p-[10px] rounded-full "
                style={{
                  border: "2px solid #188c56",
                  position: "sticky",
                  top: "20px",
                }}
                title="close"
              />
            </button>
          </div>
          <div className=" h-[70vh] sm:h-[60vh] overflow-y-scroll prompt">
            <div className="px-[10px]">
              <div className="mb-[40px] flex justify-between items-center">
                <p className="text-lg text-white font-bold mb-2">
                  {post.title}
                </p>
                <p className="text-teal-600">#{post.category}</p>
              </div>
            </div>

            {/* images */}
            <div className="relative">
              <img
                src={imagesArray[currentIndex]}
                alt=""
                className="max-h-[400px] object-contain w-full"
              />
              <div className="absolute bottom-0 right-0 left-0 flex justify-center space-x-4">
                <button
                  onClick={prevImage}
                  className="text-white bg-teal-800 p-2 rounded-full"
                >
                  <AiOutlineArrowLeft className="text-2xl" />
                </button>
                <button
                  onClick={nextImage}
                  className="text-white bg-teal-800 p-2 rounded-full"
                >
                  <AiOutlineArrowRight className="text-2xl" />
                </button>
              </div>
            </div>
            {/*  */}

            <div className="mt-[20px] flex flex-col-reverse md:flex-row justify-between w-full gap-[20px] flex-wrap">
              <div className="flex gap-[40px] items-center">
                <div
                  className="flex gap-2 items-center"
                  onClick={() => handleLikeTrade(post)}
                >
                  <AiOutlineLike className="text-xl text-teal-500" />
                  <p className="text-teal-500">{post.likes.length}</p>
                </div>
                <div
                  className="flex gap-2 items-center"
                  onClick={() => handleDislikeTrade(post)}
                >
                  <AiOutlineDislike className="text-xl text-teal-500" />
                  <p className="text-teal-500">{post.dislikes.length}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <AiOutlineComment className="text-xl text-white" />
                  <p className="text-white">{post.comments.length}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <AiOutlineEye className="text-xl text-white" />
                  <p className="text-white">{post.views.length}</p>
                </div>
              </div>
              <div className="flex gap-2 items-center ">
                <AiOutlinePhone className=" text-xl text-teal-500" />
                <a href={`tel:${post.phone}`} className="text-teal-500">
                  {post.phone}
                </a>
              </div>
            </div>

            <div className=" my-[30px]">
              <h2 className="mb-[10px] font-bold text-zinc-500 text-lg">
                Description
              </h2>
              <p className="text-zinc-200">{post.description}</p>
            </div>

            <div className=" my-[30px]">
              <h2 className="mb-[10px] font-bold text-zinc-500 text-lg">
                Defects Information
              </h2>
              <p className="text-zinc-200">{post.defect}</p>
            </div>

            <div className=" my-[30px]">
              <h2 className="mb-[10px] font-bold text-zinc-500 text-lg">
                In Exchange
              </h2>
              <p className="text-zinc-200">{post.exchangeFor}</p>
            </div>

            <div className=" my-[30px]">
              <h2 className="mb-[10px] font-bold text-zinc-500 text-lg">
                Specific Address
              </h2>
              <p className="text-zinc-200">{post.specificAddress}</p>
            </div>

            <div className=" my-[30px]">
              <h2 className="mb-[10px] font-bold text-zinc-500 text-lg">
                Availability
              </h2>
              <p className="text-zinc-200">{post.availability}</p>
            </div>

            {/* comment */}

            <TradeComment item={post} />
          </div>
        </div>
      </div>
    );
  };

  const textRef = useRef(null);

  const handleCopyToClipboard = async (textToCopy) => {
    try {
      // Get the text from your input or any other source
      // You can replace this with your dynamic input

      // Use the Clipboard API to write the text to the clipboard
      await navigator.clipboard.writeText(textToCopy);

      // Log success or update UI accordingly
      console.log("Text copied to clipboard:", textToCopy);
      toast.success("Copied The Link. Share", { theme: "dark" });
    } catch (err) {
      // Handle errors
      console.error("Error copying to clipboard:", err);
    }
  };

  let topBarBg = ["rentOutBgOne", "rentOutBgTwo", "rentOutBgThree"];
  const [chosenBg, setChosenBg] = useState("rentOutBgOne");
  useEffect(() => {
    const interval = setInterval(() => {
      // Find the index of the next background in the array
      const currentIndex = topBarBg.indexOf(chosenBg);
      const nextIndex = (currentIndex + 1) % topBarBg.length;
      setChosenBg(topBarBg[nextIndex]);
    }, 10000); // Change background every 4 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [chosenBg, topBarBg]);

  const getCurrentTime = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  return (
    <div>
      {/* arrow to scroll to top */}
      {showArrow && (
        <div
          className="fixed bottom-20 right-4 text-3xl z-[999] cursor-pointer bg-teal-700 text-zinc-50 rounded-full p-[5px]"
          onClick={handleScrollTop}
        >
          <AiOutlineArrowUp />
        </div>
      )}
      {/* wrapper */}
      <div className="px-[10px] sm:px-[3em] lg:px-[4em] pt-3">
        {/* topbar */}
        <div className="mx-2 pt-4">
          {!searchText && (
            <div
              className={`h-[28vh] sm:h-[30vh]  rounded-xl ${chosenBg} relative`}
            >
              {/* overlay div */}
              <div className="bg-gradient-to-b from-transparent to-black opacity-90 w-full h-[28vh] sm:h-[30vh] rounded-sm absolute top-0 left-0">
                {/* overlay div */}
                <div className="absolute top-0  rounded-sm left-0 w-full h-[28vh] sm:h-[30vh] bg-[rgba(0,0,0,.2)]">
                  <div className="px-4 py-2 flex justify-between gap-4">
                    <div>
                      <div className="hidden md:flex mb-3">
                        <Link to="/home">
                          <IoMdArrowBack className="text-4xl" />
                        </Link>
                      </div>
                      <p className=" text-xl sm:text-4xl font-semibold mb-8">{`${getCurrentTime()} ${
                        user?.username
                      }`}</p>
                      <p className=" mb-8 sm:mb-1">
                        Exchange Goods And Services
                      </p>
                      <p className="sm:mb-4">Your Junk is My Gold ? 😎</p>
                      <div className=" hidden md:flex gap-4 items-center">
                        <div>
                          <p className="mb-1">👌Shop Cashless</p>
                          <p>👌Conserve Environment</p>
                        </div>
                        <div>
                          <p className="mb-1">👌Barter Trade</p>
                          <p>👌Invite Friends</p>
                        </div>
                      </div>
                    </div>
                    {/* create */}
                    <div>
                      {user?.isPaid ? (
                        <div className="flex justify-end w-full mt-[15px]">
                          <Link to="/create-rent-out">
                            <IoMdAdd className="bg-zinc-800 text-4xl p-[5px] text-white rounded-lg" />
                          </Link>
                        </div>
                      ) : (
                        <div className="flex justify-end w-full mt-[15px]">
                          <Link to="/gold-subscription">
                            <AiOutlineGold className="bg-zinc-800 text-4xl p-[5px] text-white rounded-lg" />
                          </Link>
                        </div>
                      )}
                    </div>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* create
        {user?.isPaid == "yes" ? (
          <div>
            {!searchText && user?.username && (
              <div className="flex justify-end w-full mt-[15px]">
                <Link to="/create-trade">
                  <IoMdAdd className="bg-emerald-800 text-4xl p-[5px] text-white rounded-lg" />
                </Link>
              </div>
            )}
          </div>
        ) : (
          <div>
            {!searchText && user?.username && (
              <div className="flex justify-end w-full mt-[15px]">
                <IoMdAdd
                  className="bg-emerald-800 text-4xl p-[5px] text-white rounded-lg"
                  onClick={() =>
                    toast.info("Subscribe to Gold Plan", { theme: "dark" })
                  }
                />
              </div>
            )}
          </div>
        )} */}

        <Helmet>
          <title>{`Exchange | ${
            user ? user?.username : "Profile"
          } | iLocca`}</title>
          <meta
            name="description"
            content="Bridging the gap between local businesses and the community, offering a streamlined platform for residents to discover and acquire quality local products and services."
          />
        </Helmet>

        {loading ? (
          <>
            <div className="h-[70vh] w-full flex justify-center items-center">
              <Spinner message="Fetching Items ..." />
            </div>
          </>
        ) : (
          <>
            {allToTrade?.length < 1 ? (
              <>
                <div className="h-[70vh] w-full flex justify-center items-center">
                  <div>
                    <h2 className="text-center mb-2">😢Ouch.No results Yet</h2>

                    <h2 className="mt-[20px] mb-[10px] text-center">
                      Copy And Share This Link To People interested to exchange
                    </h2>
                    <div className="flex justify-center mt-3">
                      <button
                        onClick={() => handleCopyToClipboard(`${appBaseUrl}`)}
                        className="flex justify-center gap-[20px] items-center border border-emerald-500 p-2 rounded-lg"
                      >
                        <IoShareSocialOutline
                          className="text-xl text-emerald-500"
                          title="Click To Copy"
                        />
                        share
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <>
                  {/* searchbar */}
                  <div className="mt-[1em] mb-[1em] w-full flex justify-center z-20">
                    <form
                      className="flex items-center gap-3
            w-[99%] sm:w-[70%] md:w-[60%] xl:w-[40%] border border-zinc-600 p-[8px] rounded-lg  "
                    >
                      <AiOutlineSearch className="text-xl" />
                      <input
                        type="text"
                        placeholder="search"
                        className="w-full bg-transparent border-none outline-none"
                        value={searchText}
                        onChange={handleSearchChange}
                      />
                    </form>
                  </div>

                  {/* categories */}
                  {!searchText && (
                    <div className="mt-4">
                      <div className="mb-[15px] flex items-center gap-2">
                        <p className="">SCROLL TO FILTER</p>
                        <p>
                          <AiOutlineArrowRight />
                        </p>
                      </div>
                      <div className=" overflow-x-scroll prompt">
                        <div className="flex justify-start xl:justify-center">
                          <ul className="flex  space-x-7  pb-1 ">
                            <li
                              className="cursor-pointer flex items-center gap-1"
                              onClick={handleFetchToTrade}
                            >
                              <MdOutlineAddBusiness />
                              all
                            </li>
                            {DummyToTradeCategory?.map((item) => (
                              <li
                                key={item.id}
                                className="cursor-pointer flex items-center gap-1 hover:text-emerald-500"
                                onClick={async () => {
                                  setLoading(true);
                                  let category = item.title;
                                  let approved = "yes";
                                  let dataToSend = { category, approved };
                                  let token = user?.token;
                                  const config = {
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                    },
                                  };
                                  try {
                                    const response = await axios.post(
                                      "/trade/cat",
                                      dataToSend,
                                      config
                                    );
                                    if (response) {
                                      setLoading(false);
                                      setAllToTrade(response.data);
                                      // console.log(response.data);
                                    }
                                  } catch (error) {
                                    setLoading(false);
                                    toast.error("Failed to find " + item.title);
                                  }
                                }}
                              >
                                <MdOutlineAddBusiness />
                                {item.title}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* data */}
                  <div>
                    {/* nav numbers */}
                    {!searchText && (
                      <nav className="flex justify-center">
                        <ul className="flex gap-[2em] mt-[10px] px-[5px] py-[10px] items-center ">
                          {/* map */}

                          <>
                            <li>
                              <a
                                href="#"
                                onClick={prevPage}
                                className="text-zinc-300"
                              >
                                <p className="text-zinc-300">Prev</p>
                              </a>
                            </li>
                            <li className="flex gap-[10px] ">
                              {numbers
                                .slice(start - 1, end)
                                .map((item, index) => (
                                  <li
                                    key={index}
                                    className={`normal-nav ${
                                      currentPage === item && "active-nav"
                                    }`}
                                  >
                                    <a
                                      href="#"
                                      onClick={() => {
                                        handleClick(item);
                                        changeCurrentPage(item);
                                      }}
                                    >
                                      <p>{item}</p>
                                    </a>
                                  </li>
                                ))}
                            </li>

                            <li>
                              <a href="#" onClick={nextPage}>
                                <p className="text-zinc-300">Next</p>
                              </a>
                            </li>
                          </>
                        </ul>
                      </nav>
                    )}
                    {/* products */}
                    <div>
                      <div className="mt-[2em]">
                        {searchText ? (
                          <>
                            <div className="mb-[15px] text-zinc-400">
                              {searchText && <p>Results For : {searchText}</p>}
                            </div>

                            {searchedResults?.length > 0 ? (
                              <>
                                <div>
                                  <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="my-masonry-grid "
                                    columnClassName="my-masonry-grid_column"
                                  >
                                    {searchedResults?.map((item) => (
                                      <div
                                        className="mb-8"
                                        key={item._id}
                                        onClick={() => {
                                          handleAddViews(item._id);
                                        }}
                                      >
                                        <div className="relative rounded-lg group ">
                                          <Link to={`/trade/${item._id}`}>
                                            <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                                              <div
                                                className="bg-gradient-to-t
                                                  from-transparent to-black opacity-90 w-full h-full rounded-md"
                                              >
                                                {/* top stats */}
                                                <div>
                                                  <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                                                    <div>
                                                      <a
                                                        href={`tel:+${item.phone}`}
                                                      >
                                                        {" "}
                                                        <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                                      </a>
                                                    </div>
                                                    <div className="flex gap-[20px] items-center">
                                                      {/* <p className="text-white text-md flex items-center gap-[5px]">
                                                    <AiOutlineLike className="text-lg" />
                                                    <span>
                                                      {item.likes.length}
                                                    </span>
                                                  </p> */}
                                                      <p className="text-white text-md flex items-center gap-[5px]">
                                                        <AiOutlineEye className="text-lg" />
                                                        <span>
                                                          {item.views.length}
                                                        </span>
                                                      </p>
                                                      {/* <p className="text-white text-md flex items-center gap-[5px]">
                                                    <AiOutlineDislike className="text-lg" />
                                                    <span>
                                                      {item.dislikes.length}
                                                    </span>
                                                  </p>
                                                  <p className="text-white text-md flex items-center gap-[5px]">
                                                    <AiOutlineComment className="text-lg" />
                                                    <span>
                                                      {item.comments.length}
                                                    </span>
                                                  </p> */}
                                                    </div>
                                                  </div>
                                                  <div className="absolute top-[80px] left-3">
                                                    <p className="text-white text-2xl">
                                                      {item.title}
                                                    </p>
                                                  </div>
                                                </div>
                                                {/*  */}
                                                <div className="absolute bottom-[20px]  flex gap-[10%] w-full ">
                                                  <div className="pl-2"></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Link>

                                          <div className="min-h-[200px]">
                                            <img
                                              src={
                                                item.firstPhoto ||
                                                "https://images.pexels.com/photos/4049449/pexels-photo-4049449.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                              }
                                              alt=""
                                              className="rounded-md object-cover w-full"
                                              loading="lazy"
                                              // onLoad={() => alert("loaded")}
                                            />
                                          </div>
                                        </div>
                                        {/* more items */}
                                        <div className="flex items-center justify-between mt-[10px]">
                                          <Link
                                            to={
                                              item.owner == user?.username
                                                ? "/my-profile"
                                                : `/other-profile?owner=${item.owner}`
                                            }
                                          >
                                            <p
                                              className={`w-8 h-8 flex items-center justify-center rounded-full ${backgroundClass} text-white`}
                                            >
                                              {item.owner.substring(0, 1)}
                                            </p>
                                          </Link>
                                          <p className="text-center text-zinc-400">
                                            {moment(item.createdAt).fromNow()}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                  </Masonry>
                                </div>
                              </>
                            ) : (
                              <div className="flex justify-center mt-[7em]">
                                <p>😥 No results for {searchText}</p>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <div>
                              <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid "
                                columnClassName="my-masonry-grid_column"
                              >
                                {records?.map((item) => (
                                  <div
                                    className="mb-8"
                                    key={item._id}
                                    onClick={() => {
                                      handleAddViews(item._id);
                                    }}
                                  >
                                    <div className="relative rounded-lg group ">
                                      <Link to={`/trade/${item._id}`}>
                                        <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                                          <div
                                            className="bg-gradient-to-t
                                                from-transparent to-black opacity-90 w-full h-full rounded-md"
                                          >
                                            {/* top stats */}
                                            <div>
                                              <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                                                <div>
                                                  <a
                                                    href={`tel:+${item.phone}`}
                                                  >
                                                    {" "}
                                                    <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                                  </a>
                                                </div>
                                                <div className="flex gap-[20px] items-center">
                                                  {/* <p className="text-white text-md flex items-center gap-[5px]">
                                                  <AiOutlineLike className="text-lg" />
                                                  <span>
                                                    {item.likes.length}
                                                  </span>
                                                </p> */}
                                                  <p className="text-white text-md flex items-center gap-[5px]">
                                                    <AiOutlineEye className="text-lg" />
                                                    <span>
                                                      {item.views.length}
                                                    </span>
                                                  </p>
                                                  {/* <p className="text-white text-md flex items-center gap-[5px]">
                                                  <AiOutlineDislike className="text-lg" />
                                                  <span>
                                                    {item.dislikes.length}
                                                  </span>
                                                </p>
                                                <p className="text-white text-md flex items-center gap-[5px]">
                                                  <AiOutlineComment className="text-lg" />
                                                  <span>
                                                    {item.comments.length}
                                                  </span>
                                                </p> */}
                                                </div>
                                              </div>
                                              <div className="absolute top-[80px] left-3">
                                                <p className="text-white text-2xl">
                                                  {item.title}
                                                </p>
                                              </div>
                                            </div>
                                            {/*  */}
                                            <div className="absolute bottom-[20px]  flex gap-[10%] w-full ">
                                              <div className="pl-2"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>

                                      <div className="min-h-[200px]">
                                        <img
                                          src={
                                            item.firstPhoto ||
                                            "https://images.pexels.com/photos/4049449/pexels-photo-4049449.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                          }
                                          alt=""
                                          className="rounded-md object-cover w-full"
                                          loading="lazy"
                                          // onLoad={() => alert("loaded")}
                                        />
                                      </div>
                                    </div>
                                    {/* more items */}
                                    <div className="flex items-center justify-between mt-[10px]">
                                      <Link
                                        to={
                                          item.owner == user?.username
                                            ? "/my-profile"
                                            : `/other-profile?owner=${item.owner}`
                                        }
                                      >
                                        <p
                                          className={`w-8 h-8 flex items-center justify-center rounded-full ${backgroundClass} text-white`}
                                        >
                                          {item.owner.substring(0, 1)}
                                        </p>
                                      </Link>
                                      <p className="text-center text-zinc-400">
                                        {moment(item.createdAt).fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </Masonry>
                            </div>
                          </>
                        )}

                        {/*  */}
                      </div>
                    </div>

                    {/* pop up screen */}
                    {isPopUpOpen && (
                      <div className="pop-up-overlay">
                        <PopUpPage
                          post={selectedPost}
                          onClose={() => setIsPopUpOpen(false)}
                        />
                      </div>
                    )}
                  </div>
                </>
              </>
            )}
          </>
        )}

        {/*  */}
      </div>
      {/*  */}
    </div>
  );
};

export default Trade;
