import React, { useEffect, useState } from "react";
import {
  AiFillStar,
  // AiOutlineArrowRight,
  AiOutlineComment,
  AiOutlineDislike,
  AiOutlineLike,
  AiOutlinePhone,
  AiOutlineStar,
} from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "../axios";

const Recommended = ({ productID, category }) => {
  const { user } = useSelector((state) => state.auth);

  const [otherBiz, setOtherBiz] = useState([]);

  useEffect(() => {
    const handleFetchRecommended = async () => {
      if (category !== "") {
        let approved = "yes";
        let dataToSend = { category, approved };
        try {
          let token = user?.token;
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          let response = await axios.post("/biz/cat", dataToSend, config);
          if (response) {
            setOtherBiz(response.data);
          }
        } catch (error) {
          console.log(error);
        }

        return;
      }
    };
    handleFetchRecommended();
  }, []);

  return (
    <div>
      <div className="flex gap-[10px] overflow-x-scroll w-full prompt pb-2">
        <div className="flex flex-nowrap">
          {otherBiz?.map((item) => (
            <>
              {item._id !== productID && (
                <>
                  <div
                    key={item._id}
                    className="flex-shrink-0 mr-[15px]"
                    onClick={() => window.location.reload()}
                  >
                    <Link to={`/business/${item._id}`}>
                      <div className="relative rounded-lg group ">
                        <div className="overlay absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-100">
                          <div
                            className="bg-gradient-to-t
                                  from-transparent to-black opacity-90 w-full h-full rounded-md"
                          >
                            {/* top stats */}
                            <div>
                              <div className="absolute top-[20px] flex gap-[10%]  w-full justify-between px-2 ">
                                <div>
                                  <a href={`tel:+${item.phone}`}>
                                    {" "}
                                    <AiOutlinePhone className="text-2xl text-white hover:text-orange-200" />
                                  </a>
                                </div>
                                <div className="flex gap-[20px]">
                                  {/* <p className="text-white text-md flex items-center gap-[5px]">
                              <AiOutlineDislike className="text-lg" />
                              <span>{item.dislikes}</span>
                            </p> */}
                                  <p className="text-white text-md flex items-center gap-[5px]">
                                    <AiOutlineLike className="text-lg" />
                                    <span>{item.likes.length}</span>
                                  </p>
                                  {/* <p className="text-white text-md flex items-center gap-[5px]">
                              <AiOutlineComment className="text-lg" />
                              <span>{item.comments.length}</span>
                            </p> */}
                                </div>
                              </div>
                              <div className="absolute top-[80px] left-3">
                                <p className="text-white">{item.title}</p>
                              </div>
                            </div>
                            {/*  */}
                            <div className="absolute bottom-[20px] left-[20px]  flex gap-[10%] w-full ">
                              <div>
                                <div className="flex gap-[10px] text-zinc-300">
                                  {/* <p>{item.location}</p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <img
                          src={item.photo}
                          alt=""
                          className="w-72 h-80 rounded-lg object-cover"
                        />
                      </div>
                    </Link>
                  </div>
                </>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Recommended;
